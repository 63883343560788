import React, { useState, useEffect } from "react"
import LiveCard from "../../Components/LiveCard/LiveCard"
import Logo from "../../Assets/cric.png"
import './index.css';
import MaterialTable from "material-table"
import Header from "../../Module/Header"

let Endpoint = "wss://7r6vw07ihj.execute-api.ap-south-1.amazonaws.com/dev"
const LiveDetails = () => {
    const [data1, setData1] = useState([])
    const [data2, setData2] = useState([])
    const [teamTitle1, setteamTitle1] = useState("")
    const [teamTitle2, setteamTitle2] = useState("")

    const [data3, setData3] = useState([])
    const [data4, setData4] = useState([])
    const [teamTitle3, setteamTitle3] = useState("")
    const [teamTitle4, setteamTitle4] = useState("")

    const columns1 = [
        { title: "Batsman", field: "batsman" },
        { title: "", field: "dismissal-info" },
        { title: "Runs", field: "R" },
        { title: "Balls", field: "B" },
        { title: "4s", field: "4s" },
        { title: "6s", field: "6s" },
        { title: "Strike Rate", field: "SR" }
    ]
    const columns2 = [
        { title: "Bowler", field: "bowler" },
        { title: "Overs", field: "O" },
        { title: "M", field: "M" },
        { title: "Runs", field: "4s" },
        { title: "ECON", field: "6s" },
        { title: "0s", field: "0s" },
        { title: "4s", field: "4s" },
        { title: "6s", field: "6s" },
        { title: "Wide", field: "WD" },
        { title: "No Balls", field: "NB" }
    ]


    // LIVE CARD
    const [battingTeam, setBattingTeam] = useState({
        name: "",
        score: ""
    })
    const [bowlingTeam, setBowlingTeam] = useState({
        name: "",
        score: ""
    })
    const [Img1, setImg1] = useState("")
    const [Img2, setImg2] = useState("")
    const [Toss, setToss] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [Manofthmatch, setManoftheMatch] = useState()
    const [winnerteam, setWinnerTeam] = useState()
    const [score, setScore] = useState()
    const [team1, setTeam1] = useState("")
    const [team2, setTeam2] = useState("")
    const [check, setcheck] = useState({})

    useEffect(() => {
        let ws = new WebSocket(Endpoint)
        ws.onopen = (e) => {
            console.log("connected")
            ws.send("hello server")
        }
        ws.addEventListener('message', (e) => {
            console.log("hello")
            // console.log(e.data)
            let obj = JSON.parse(e.data)
            obj = JSON.parse(obj)
            console.log(obj)
            setcheck(obj.stats.data["man-of-the-match"])
            // setData(obj.stats.data.batting.scores)
            console.log(obj.score.score)

            let battingArray = obj.stats.data.batting.length
            console.log(battingArray)

            // TABLE LOGIC
            if (battingArray === 0) {
                setData1("")
                setData2("")
                setData3("")
                setData4("")
                setteamTitle1("")
                setteamTitle2("")
                setteamTitle3("")
                setteamTitle4("")
            }
            else if (battingArray === 1) {
                setData1(obj.stats.data.batting[0].scores)
                setteamTitle1(obj.stats.data.batting[0].title)
                setData3(obj.stats.data.bowling[0].scores)
                setteamTitle3(obj.stats.data.bowling[0].title)
                setData2("")
                setData4("")
                setteamTitle2("")
                setteamTitle4("")
            }
            else if (battingArray === 2) {
                setData1(obj.stats.data.batting[0].scores)
                setData2(obj.stats.data.batting[1].scores)
                setData3(obj.stats.data.bowling[0].scores)
                setData4(obj.stats.data.bowling[1].scores)
                setteamTitle1(obj.stats.data.batting[0].title)
                setteamTitle2(obj.stats.data.batting[1].title)
                setteamTitle3(obj.stats.data.bowling[0].title)
                setteamTitle4(obj.stats.data.bowling[1].title)

            } else {
                setData1("")
                setData2("")
                setData3("")
                setData4("")
                setteamTitle1("")
                setteamTitle2("")
                setteamTitle3("")
                setteamTitle4("")
            }

            // Live Card
            const scoreArray = obj.score.description.split(' ')
            const scoreScore = obj.score.description.match(/\d+/g)
            const scoreTest = obj.score.description.split('v')
            console.log(scoreArray)
            console.log(obj["team-1"])
            console.log(obj["team-2"])
            console.log(obj["matchStarted"])
            const checkPlayingTeam = scoreTest[0].includes("*")
            console.log(checkPlayingTeam)
            console.log(scoreScore)
            if (obj.score.description.includes("/")) {
                scoreArray.map((item, i) => {
                    // if(item.includes("/")) {
                    //     console.log(item)
                    // console.log(scoreArray[i-1])
                    if (obj["matchStarted"] === true) {
                        if (scoreScore.length === 4) {
                            setBattingTeam({
                                name: obj["team-1"],
                                score: scoreScore[0] + "/" + scoreScore[1],
                            })
                            setBowlingTeam({
                                name: obj["team-2"],
                                score: scoreScore[2] + "/" + scoreScore[3],
                            })
                        }
                        else if (scoreScore.length === 3) {
                            if (scoreTest[0].includes("*")) {
                                setBattingTeam({
                                    name: obj["team-1"],
                                    score: scoreScore[0] + "*",
                                })
                                setBowlingTeam({
                                    name: obj["team-2"],
                                    score: scoreScore[1] + "/" + scoreScore[2],
                                })
                            } else {
                                setBattingTeam({
                                    name: obj["team-1"],
                                    score: scoreScore[0] + "/" + scoreScore[1],
                                })
                                setBowlingTeam({
                                    name: obj["team-2"],
                                    score: scoreScore[2] + "*",
                                })
                            }
                        }

                        else if (scoreScore.length === 2) {
                            if (checkPlayingTeam === true) {
                                setBattingTeam({
                                    name: obj["team-1"],
                                    score: scoreScore[0] + "/" + scoreScore[1] + "*",
                                })
                                setBowlingTeam({
                                    name: obj["team-2"],
                                    // score: "",
                                })
                            } else {
                                setBattingTeam({
                                    name: obj["team-1"],
                                    score: "",
                                })
                                setBowlingTeam({
                                    name: obj["team-2"],
                                    score: scoreScore[0] + "/" + scoreScore[1] + "*",
                                })
                            }
                        } else {
                            setBattingTeam({
                                name: obj["team-1"],
                                score: "",
                            })
                            setBowlingTeam({
                                name: obj["team-2"],
                                score: "",
                            })
                        }

                    }
                })
            }
            // if (scoreTest[1].includes("*") === false) {
                // let wicketWinningTeam = scoreTest[1]
                // wicketWinningTeam = wicketWinningTeam.split(" ")
                // console.log(wicketWinningTeam[1])
                // console.log(obj.stats.data.winner_team.split(" ")[0])
                // if (wicketWinningTeam[1] === obj.stats.data.winner_team.split(" ")[0]) {
                //     let wicket = " won by " + (10 - scoreScore[3]) + " Wickets";
                //     setScore(wicket)
                // }
                // Winner Logic  
                    if (obj.stats.data.hasOwnProperty('winner_team') === false) {
                        console.log("winner yet to decide")
                    }
                    else if (scoreScore.length === 4 ) {
                    if(scoreTest[0].includes("*")) {
                    let wicketWinningTeam = scoreTest[0]
                    wicketWinningTeam = wicketWinningTeam.split(" ")
                    if (wicketWinningTeam[0] === obj.stats.data.winner_team.split(" ")[0]) {

                            let wicket =   " won by " + (10 - scoreScore[1]) + " Wickets";
                            setScore(wicket) 
                        }}
                        else if(scoreTest[1].includes("*")) {
                            let wicketWinningTeam = scoreTest[1]
                            wicketWinningTeam = wicketWinningTeam.split(" ")
                            if (wicketWinningTeam[1] === obj.stats.data.winner_team.split(" ")[0]) {
                                    let wicket =  " won by " + (10 - scoreScore[3]) + " Wickets" ;
                                    setScore(wicket)
                                }
                    }

                else if (scoreScore[0] > scoreScore[2]) {
                       let winnerScore = " won by " + (scoreScore[0] - scoreScore[2]) + " Runs";
                       setScore(winnerScore)
                    }else {
                        let winnerScore = " won by " + (scoreScore[2] - scoreScore[0]) + " Runs";
                       setScore(winnerScore)
                    }
                }
                setTeam1(obj["team-1"])
                setTeam2(obj["team-2"])
                setImg1(obj["team-1-image"])
                setImg2(obj["team-2-image"])
                setToss(obj.stats.data.toss_winner_team)
                setManoftheMatch(obj.stats.data["man-of-the-match"].name)
                setWinnerTeam(obj.stats.data.winner_team)
                setIsLoading(true)
                ws.close();
            })
    }, [])
    return (<>
        <Header />
        {/* Card */}
        <div className="all"><div className="Main_Card card_width">
            <div className="Header">
                <div className="Live heading"><h3>{team1 + " "}<span>Vs</span>{" " + team2}</h3></div>
                {/* <div className="Img"><img src={Logo}/></div> */}
                <hr></hr>
            </div>
            <div className="scoreCard">
                {isLoading ? <div className="Team_Score border_right TeamLive">
                    <div className="TeamImg ImgLive"><img src={Img1} /></div>
                    {/* <div className="Team_1"><h3>{battingTeam.name}</h3></div> */}
                    <div className="Score_1 LiveScore"><h3>{battingTeam.score}</h3></div>
                </div> : <h3>Loading...</h3>}
                {isLoading ? <div className="Team_Score TeamLive">
                    <div className="TeamImg ImgLive" ><img src={Img2} /></div>
                    {/* <div className="Team_2"><h3>{bowlingTeam.name}</h3></div> */}
                    <div className="Score_2 LiveScore"><h3>{bowlingTeam.score}</h3></div>
                </div> : null}</div>
            <div id="Bottom" className="Bottom BottomLive">
                {Toss === null ? <div className="Footer Game FooterLive"><h5><span>The game is about to begin</span></h5></div> :
                    <div className="Footer FooterLive"><h5><span>{Toss}</span></h5></div>}
                {check.hasOwnProperty("name") === false ?
                    <div className="Footer"><h5><span></span></h5></div> : <div className="Footer FooterLive"><h5><span>{Manofthmatch}</span> was the Man of the Match.</h5></div>}
                {winnerteam === null ?
                    <div className="Footer Row FooterLive"><h5><span></span></h5>
                    </div> :
                    <div className="Footer Row FooterLive"><h5><span>{winnerteam}</span>{score}</h5></div>
                }
            </div>
        </div></div>

        {/* Table */}
        <div className="Batting">
            <h1>Batting</h1>
            <hr />
            <div className="Seperation">
                <MaterialTable
                    title={teamTitle1}
                    data={data1}
                    columns={columns1}
                    options={
                        {
                            search: false,
                            paging: false,
                            headerStyle: {
                                backgroundColor: '#c60f5e',
                                color: 'white',
                                fontSize: "12px"
                            }
                        }
                    }
                />
            </div>
            <div className="Seperation">
                <MaterialTable
                    title={teamTitle2}
                    data={data2}
                    columns={columns1}
                    options={
                        {
                            search: false,
                            paging: false,
                            headerStyle: {
                                backgroundColor: '#c60f5e',
                                color: '#FFF',
                                fontSize: "12px"
                            }
                        }
                    }
                />
            </div>
        </div>
        <div className="Bowling">
            <h1>Bowling</h1>
            <hr />
            <div className="Seperation">
                <MaterialTable
                    title={teamTitle3}
                    data={data3}
                    columns={columns2}
                    options={
                        {
                            search: false,
                            paging: false,
                            headerStyle: {
                                backgroundColor: '#01579b',
                                color: '#FFF',
                                fontSize: "12px"
                            }
                        }
                    }
                />
            </div>
            <div className="Seperation">
                <MaterialTable
                    title={teamTitle4}
                    data={data4}
                    columns={columns2}
                    options={
                        {
                            search: false,
                            paging: false,
                            headerStyle: {
                                backgroundColor: '#01579b',
                                color: '#FFF',
                                fontSize: "12px"
                            }
                        }
                    }
                />
            </div>
        </div>
    </>)
}
export default LiveDetails;