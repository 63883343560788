import React, { Fragment, useState, useEffect } from "react"
import "./style.scss";
// import scoreDetails from"./scoreDetails";
import Carousel from "react-grid-carousel";
import Axios from "axios";
// import {iplScoreBoard} from "../../redux/helpers/iplScoreBoard";
// import { connect } from "react-redux";
import { ArrowLeft, ArrowRight } from "../../Components/customizedArrows";
const LiveScore = () => {
    const [liveMatchData, setLiveMatchData] = useState([])
    const [upcomingMatchData, setUpcomingMatchData] = useState([])
    const [liveScore, setLiveScore] = useState([])
    const responsive = [
        {
          breakpoint: 768,
          cols: 1,
          rows: 1,
          gap: 10,
          loop: true,
        //   autoplay: 5000,
        },
      ];
    useEffect(() => {
        Axios({
            url: `https://cricapi.com/api/matches?apikey=vNkjQ3sJ9WVZAosCc9Ksn9SOuxn1`,
            method: "GET"
        })
            .then((response) => {
                const twentyTwenty = response.data.matches.filter(item => { return item.type === "Twenty20" })
                const liveEndMatchData = twentyTwenty.filter(item => { return item.matchStarted === true })
                const notStarted = twentyTwenty.filter(item => { return item.matchStarted === false })
                let score = []
                liveEndMatchData.map((item, index) => {
                    scoreDetailApi(item.unique_id).then((item) => {
                        console.log(item)
                        score[index] = item
                    })
                })
                console.log(liveEndMatchData)
                console.log(score)
                console.log(score.reverse())
                setLiveMatchData(liveEndMatchData)
                setLiveScore(score)

                setUpcomingMatchData(notStarted.slice(0, 4))

            }).catch((error) => {
                console.log(error)
            })
    }, []);
    const scoreDetailApi = async (score) => {
        const { data } = await Axios({
            url: `https://cricapi.com/api/cricketScore?apikey=vNkjQ3sJ9WVZAosCc9Ksn9SOuxn1&unique_id=${score}`,
            method: "GET"
        })
        const finalData = data
        console.log({ finalData })
        return finalData
    }
    return (
        <Fragment>
            <div className="liveScore-container">
                <div className="liveScoreInner">

                    <div className="heading">
                        IPL <span style={{ color: "#00bcf3" }}>SCOREBOARD</span>
                    </div>
                    <Carousel
                        cols={2}
                        rows={1}
                        loop
                        showDots={false}
                        gap={10}
                        responsiveLayout={responsive}
                        // autoplay={3000}
                        dot={false}
                        hideArrow={false}
                        arrowLeft={ArrowLeft}
                        arrowRight={ArrowRight}
                    >
                        {liveMatchData.length > 0 && liveMatchData.map((item, index) => {
                            // console.log({ index })
                            let time = new Date(item.dateTimeGMT).toLocaleString("hi-IN");
                            const matchTime = new Date(item.dateTimeGMT).getTime();
                            const currentTime = new Date().getTime();
                            const gap = currentTime - matchTime
                            const secs = gap / 1000
                            const mins = secs / 60
                            const hrs = mins / 60
                            const totalGap = Math.floor(Math.round(hrs))
                            return (
                                <Carousel.Item key={index}>

                                    <div className="fullScoreCard">
                                        <div className="firstRow">
                                            <div className="live">
                                                <div className="dot">
                                                    {totalGap < 6 && <img src={require("../../Assets/liveDot.gif")} />}

                                                </div>
                                                <div className="text">
                                                    {totalGap > 6 ? "Ended" : "Live"}
                                                </div>
                                            </div>
                                            <div className="Today">{time}</div>
                                        </div>
                                        <div className="secondRow">
                                            <div className="player">{item["team-1"]}</div>
                                            <div className="vs">VS</div>
                                            <div className="player">{item["team-2"]}</div>
                                        </div>
                                        <div className="thirdRow">
                                            <div className="score"></div>
                                        </div>
                                        <div className="fourthRow">
                                            {liveScore && liveScore[index] && liveScore[index].score}
                                        </div>
                                        <div className="fourthRow">
                                            {liveScore && liveScore[index] && liveScore[index].stat}
                                        </div>
                                    </div>
                                </Carousel.Item>

                            )
                            // }

                        })}
                        {upcomingMatchData.map((item, index) => {
                            let time = new Date(item.dateTimeGMT).toLocaleString("hi-IN");
                            const currentDate = new Date();
                            let time2 = new Date(currentDate).toLocaleString("hi-IN");
                            return (
                                <Carousel.Item key={index}>

                                    <div className="fullScoreCard">
                                        <div className="firstRow marginSet">
                                            <div className="live">
                                                Upcoming Match
                        </div>
                                            <div className="Today">{time}</div>
                                        </div>
                                        <div style={{ padding: "7% 2%" }} className="secondRow">
                                            <div className="player">{item["team-1"]}</div>
                                            <div className="vs">VS</div>
                                            <div className="player">{item["team-2"]}</div>
                                        </div>
                                    </div>
                                </Carousel.Item>

                            )
                        })}
                    </Carousel>
                </div>
            </div>
            <div className="border1"></div>
            <div className="border1"></div>
            <div className="border1"></div>
        </Fragment>
    )
}

export default LiveScore;