import React, { Fragment } from "react";
import withLayout from "../../layout/default";
import VideoModule from "../../Module/VideoModule";
import { getModuleData } from "../../util/getModuleData";
import { connect } from "react-redux";

const Videos = ({ content }) => {
  const { Articles = {}, Instagram = {}, Twitter = {}, articles = [], youtube = {} } = content || {};
  const { top = [], latest = [] } = youtube;
  const { latestPosts = [], latestVideos = [], topVideos = [] } = Instagram;
  const { cricviewCrawler = [], latestCricview = [], topCricview = [] } = Articles;

  // const { top: instaTop = [], latest: instaLatest = [] } = content && content.data ? getModuleData(content.data, "Instagram Videos").videos : {};
  const { top: youtubeTop = [], latest: youtubeLatest = [] } = (content && content.youtube) || {};
  const { website = [] } = content || {};
  return (
    <Fragment>
      {youtubeLatest.length > 0 && (
        <div className="row row_1">
          <VideoModule
            contentData={[...youtubeLatest.slice(0, 5)].filter(item => item && item.title && !item.title.toLowerCase().includes("interview"))}
            contentTitle={"Trending Videos"}
            showPlayIcon={true}
            showMoreNavigate={"https://www.instagram.com/talenthuntcricview/?hl=en"}
            rowCount={[0, 4]}
            videoCategory = 'latestVideos'
          />
        </div>
      )}
      {youtubeTop.length > 0 && (
        <div className="row row_1">
          <VideoModule 
          contentData={[...youtubeTop.slice(0, 25)]} 
          contentTitle={"Top Videos"} 
          showPlayIcon={true} 
          rowCount={[0, 4]} 
          videoCategory = 'topVideos'
          />
        </div>
      )}
      {youtubeLatest.length > 0 && (
        <div className="row row_1">
          <VideoModule 
          contentData={[...website]} 
          moduleName={"All Videos"} 
          contentTitle={"All Videos"} 
          showPlayIcon={true} 
          rowCount={[0, 4]}
          videoCategory = {"allvideos"}
          />
        </div>
      )}
    </Fragment>
  );
};
const temp = connect(state => ({ content: state.content }))(Videos);
export default withLayout(temp);
