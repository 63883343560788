import React, { Fragment, useState } from "react";
import { parseDate, linkProps, removeTags } from "../../util";
import parse from "html-react-parser";
import "./style.scss";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LiveCard from "../../Components/LiveCard/LiveCard"
const FeaturedNews = ({ contentData, moduleName, contentTitle = "Latest News", rowCount = [0, 4, 8, 12], contentSubHeading }) => {
  const [results, setResults] = useState({ start: [...rowCount], length: 4 });
  // const featured = oddContentData[0];
  let len = contentData.slice(1).length;
  const itemToShow = window.innerWidth < 769 && window.innerWidth > 425 ? 2 : 4;
  const slicedItems = len - (len % itemToShow);
  // const otherNews = oddContentData.slice(1, slicedItems + 1);

  const handleClick = () => {
    setResults(state => ({ ...state, start: [...state.start, state.start[state.start.length - 1] + results.length] }));
    setResults(state => ({ ...state, start: [...state.start, state.start[state.start.length - 1] + results.length] }));
  };
  const moduleTitle = contentTitle ? contentTitle.split(" ") : moduleName.split(" ");

  
  const featured = contentData[0];
  const otherNews = contentData.slice(1, slicedItems + 1);

  
  return (
    <Fragment>
      <section id="featurenews" className="widget" style={{ width: "100%" }}>
        <header className="widget__header widget__header--tablet-img">
          <div className="widget__title">
            {moduleTitle && moduleTitle.length > 0 && (
              <div className="subheading">
                <span className="feaured">{moduleTitle[0] || ""}</span>
                <span className="news">{moduleTitle.splice(1, moduleTitle.length).join(" ") || " "}</span>
              </div>
            )}
            {contentSubHeading && <h6 className="widget__header-desc">{contentSubHeading}</h6>}
          </div>
        </header>

        <section className="uniform-grid">
          <div className="row1">
            <div className="uniform-grid__section_image">
              <div className="shadowCard1">
                <Link {...linkProps(featured)} className="thumbnail thumbnail--hero ">
                  <figure className="thumbnail__container">
                    <img className="image" alt="WC Winner" src={featured && featured.urlToImage} />
                  </figure>
                </Link>
              </div>
            </div>
            <div className="uniform-grid__section_text">
              <div className="thumbnail__container">
                <div className="thumbnail_container_text">
                  <Link {...linkProps(featured)} className="thumbnail thumbnail--hero ">
                    <div className="date-month-year">{featured && parseDate(featured.publishedAt)}</div>
                    <div className="thumbnail__text">
                      <h5 className="thumbnail__title">{featured && featured.title}</h5>
                      <div className="thumbnail__summary">{featured && featured.description && parse(removeTags(featured.description))}</div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* <LiveCard className="margin-left" /> */}
          <div className="uniform-grid__section-ads ads-banner-container" onClick={() => { window.open("https://play.google.com/store/apps/details?id=com.fac1world.foodapp") }}>

            <figure className="thumbnail__container ads-banner-container">
              <img className="ads-image" alt="Ads" title="Ads" src={require("../../Assets/banner1.jpg")} />
            </figure>
          </div>
          {results.start.map((start, j) => (
            <Fragment key={start + j}>
              {otherNews.slice(start, start + results.length).map((item, index) => {
                const { urlToImage, publishedAt, title } = item || {};
                return (
                  <div className="uniform-grid__section-below-line" key={index}>
                    <div className="shadowCard">
                      <div className="hidee">
                        <Link {...linkProps(item)} className="thumbnail thumbnail--horizontal-phablet thumbnail--featured">
                          <img className="image" alt="WC Winner" src={urlToImage} />
                        </Link>
                      </div>
                      <div className="date-month-year-2nd-line">{parseDate(publishedAt)}</div>
                      <div className="thumbnail thumbnail--horizontal-phablet thumbnail--featured">
                        <div className="thumbnail__title-2nd-line">{title}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Fragment>
          ))}

          {results.start[results.start.length - 1] + results.length < otherNews.length ? (
            <div className="viewall-container">
              <button href="news" className="viewall-button" onClick={handleClick}>
                Load More
              </button>
            </div>
          ) : (
              <Fragment />
            )}
        </section>
      </section>
    </Fragment>
  );
};

export default connect(state => ({ content: state.content }))(FeaturedNews);
