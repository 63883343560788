import React, { Fragment } from 'react';
import Header from '../../Module/Header'
import Footer from '../../Components/Footers'
import Sticky from '../../Components/Sticky'
import LanguageBar from '../../Components/LanguageBar';

const About = () => {
    return (<Fragment>
        <Sticky>
            <div className="row">
                <LanguageBar />
            </div>
            <div className="row"><Header /></div>
        </Sticky>
        <div className="row">
            <Footer />
        </div>
    </Fragment>);
}

export default About;