import React, { Fragment, useState } from 'react';

import './banner.css'
import Live from "../../Components/Live"
import LiveIplScore from "../../Components/LiveIplScore"
function MobileBanner(props) {
    const [showModal, setShowModal] = useState(true);
    function handleClose() {
        setShowModal(false);
    }
    return (<Fragment>
        <div className="col-md-12" style={{ padding: '0px' }}>
            {/* {showModal ? <div className="banner-container ">
                <div onClick={handleClose} className="modal-close">
                    <img className="img-close" src={require('../../Assets/banner_close.png')}></img>
                </div>
                <div>
                    {props.children}
                </div>
            </div>
                : ''} */}

            <div className="row row_1" style={{ width: "100%" }}>
                <Live></Live>
                {/* <LiveIplScore /> */}
            </div>

        </div>
    </Fragment>)
}

export default MobileBanner;