import React, { Fragment, Component } from "react";

import "./style.scss";
import { Link } from "react-router-dom";    
const youtubeSrc=require("../../Assets/you@3x.png");
const instaSrc=require("../../Assets/insta@3x.png");
const facebookSrc=require("../../Assets/fb@3x.png");
const locationSrc=require("../../Assets/location-white.png");
const mailSrc=require("../../Assets/mail_white.png");
const phoneSrc=require("../../Assets/phone_white.png");
const logoSrc=require("../../Assets/cricketalenthunt-logo.png");

const TalentHuntFooter = () => {
    return (
    <div className="cricview-talnet-footer">
    <footer className="footer-talnet">
        <div className="container-talnet">
          <div className="row-talnet">
            <div className="col-md-4">
              <div className="full-talnet">
                <div className="footer-widget-talnet">
                  <div className="footer-logo-talnet">
                    <a href="#">
                      <img src={logoSrc} alt="#"></img>
                    </a>
                  </div>
                  <p></p>
                  <ul className="social-icons pull-left">
                     <li>
                        <a className="facebook" href="https://www.facebook.com/cricviewFB/">
                          <img className="icon" src={facebookSrc} alt />

                        </a>
                      </li>
                      <li>
                        <a className="instagram" href="https://www.instagram.com/cricview.in/">
                          <img className="icon" src={instaSrc} alt />

                        </a>
                      </li>
                      <li>
                        <a className="youtube" href="https://www.youtube.com/cricviewmedia">
                          {/* <i className="fa fa-youtube-play"></i> */}
                           <img className="icon" src={youtubeSrc} alt />

                        </a>
                      </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="full-talnet">
                <div className="footer-widget-talnet">
                  <h3>Menu</h3>
                  <ul className="footer-menu">
                    <li>
                      <a className="footer-menu-a" href="#">
                        Register Now
                      </a>
                    </li>
                    <li>
                      <a className="footer-menu-a" href="https://www.cricview.in/">Cricview.in</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="full-talnet">
                <div className="footer-widget-talnet">
                  <h3>Contact us</h3>
                  <ul className="address-list">
                    <li>
                      <img src={locationSrc}/>  Wework, 32 Milestone, Gurgaon
                    </li>
                    <li>
                    <img src={phoneSrc}/> +91 82874 44901 and +91 96505 19854
                    </li>
                    <li>
                    <img src={mailSrc}/> cricview15@gmail.com
                    </li>
                  </ul>
                </div>
              </div>
            </div>
           
          </div>
        </div>
        <div className="footer-bottom-talnet">
          <div className="container-talnet">
            <p>Copyright © 2019</p>
          </div>
        </div>
    </footer>
    </div>
    );
}
export default TalentHuntFooter;
