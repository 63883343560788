import React, { Fragment, Component } from "react";

import "./style.scss";
import { Link } from "react-router-dom";

const socialLinksMap = [
  {
    iconSrc: require("../../Assets/you@3x.png"),
    link: "https://www.youtube.com/cricviewmedia",
  },
  {
    iconSrc: require("../../Assets/twitter@3x.png"),
    link: "https://twitter.com/cricviewmedia",
  },
  {
    iconSrc: require("../../Assets/insta@3x.png"),
    link: "https://www.instagram.com/talenthuntcricview/",
  },
  {
    iconSrc: require("../../Assets/fb@3x.png"),
    link: "https://www.facebook.com/cricviewFB/",
  },
];
const navigationItem = [
  { label: "BLOGS", path: "/blogs" },
  { label: "VIDEOS", path: "/videos" },
  { label: "INTERVIEWS", path: "/interviews" },
  { label: "TALENT HUNT", path: "/talenthunt" },
  { label: "ONLINE TALENT HUNT", path: "/cricketonlinetalenthunt" },
]
export default class Footer extends Component {
  componentDidMount() {
    window.addEventListener("onload", this.expandCollaps());
  }

  expandCollaps = () => {
    var coll = document.getElementsByClassName("collapsible");
    var i;

    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var content = this.nextElementSibling;
        if (content.style.display === "block") {
          content.style.display = "none";
        } else {
          content.style.display = "block";
        }
      });
    }
  };

  render() {
    return (
      <Fragment>
        <div className="border1"></div>
        <div className="border1"></div>
        <div className="border1"></div>
        <footer className="footer" role="contentinfo" style={{ marginTop: "10px" }}>
          <div className="global-footer global-footer--active" data-observe="global-footer">
            <div className="global-footer__background"></div>
            <div className="wrapper">
              <div className="roww1">
                <ul className="footer-top-section">
                  <li className="global-footer-corporate__item">
                    <div className="footer-logo">
                      <div>
                        <a href="https://nexgeniots.com/">
                          <img className="nexg" src={require("../../Assets/nexg-colour.png")} alt="" />
                        </a>
                      </div>
                      <div className="slash-line"></div>
                      <a href="/">
                        <img alt="CRICVIEW" src={require("../../Assets/cricview-logo-redesign.png")} />
                      </a>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="roww2">
                <div className="global-footer__lists">
                  <ul className="global-footer__list">
                    <div className="footer-menu-head">
                      <a href="/" >
                        <h4 className="global-footer__title showList"> HOME</h4>
                      </a>
                    </div>
                  </ul>
                  {navigationItem.map((item, index) => {
                    return (<Fragment key={index}>
                      <ul className="global-footer__list">
                        <div className="footer-menu-head">
                          <Link to={item.path}>
                            <h4 className="global-footer__title"> {item.label}</h4>
                          </Link>
                        </div>
                      </ul>
                    </Fragment>)
                  })}
                </div>
              </div>

              <div className="roww3">

                <div className="global-footer-corporate">
                  <ul className="global-footer-corporate__list">
                    <li className="global-footer-corporate__item copy-right">
                      ©2020,&nbsp;&nbsp;
                    <a className="global-footer-corporate__link" href="#!">
                        CRICVIEW&nbsp;&nbsp;
                    </a>
                    All rights reserved
                  </li>


                    <li className="global-footer-corporate__item">

                      <ul className="social-follow-footer">
                        <li className="follow-us-on">
                          <h5>FOLLOW US ON:</h5>
                        </li>
                        {socialLinksMap.map(({ iconSrc, link }, index) => (
                          <li className="social-follow-footer-item hello " key={index}>
                            <a href={link} className="social-follow-footer__link">
                              <img className="icon" src={iconSrc} alt={link} />
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                    {/*     <ul className="footer-corporate-2nd-line">
                    <li className="item-name">
                      <a className="global-footer-corporate__link" href="">
                        Terms & Conditions
                      </a>
                    </li>
                    <li className="item-name">
                      <a className="global-footer-corporate__link" href="">
                        Privacy Policy
                      </a>
                    </li>
                  </ul>*/}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </Fragment>
    );
  }
}
