import React, { Fragment, useState, useEffect } from "react";
import Carousel from "react-grid-carousel";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import 'font-awesome/css/font-awesome.min.css';
import ShopIcon from '@material-ui/icons/Shop';
import AppleIcon from '@material-ui/icons/Apple';

import { parseDate, navigateTo, linkProps } from "../../util";
import { Pause, Play } from "../../Assets/icons";
import "./style.scss";
import MyDot from "../../Components/customizedDots";
import { ArrowLeft, ArrowRight } from "../../Components/customizedArrows";
const TopSearch = ({ contentData = [], moduleName }) => {
  const [marqueeStatus, setMarqueeStatus] = useState("stop");
  const [paused, setPaused] = useState(false);
  const marqueeRef = React.createRef();
  const marqueeToggle = () => {
    setPaused(!paused);
    marqueeRef.current[marqueeStatus]();
    setMarqueeStatus(marqueeStatus === "stop" ? "start" : "stop");
  };
  const moduleTitle = moduleName && moduleName.split(" ");
  contentData.length > 0 && contentData.splice(3, 0, { type: "ad" });
  contentData.length > 0 && contentData.splice(11, 0, { type: "ad" });
  contentData.length > 0 && contentData.splice(19, 0, { type: "ad" });
  const responsive = [
    {
      breakpoint: 768,
      cols: 3,
      rows: 2,
      gap: 10,
      loop: true,
      autoplay: 5000,
    },
  ];
  console.log(parseDate)
  console.log(contentData)
  console.log(linkProps)


  return (
    <React.Fragment>
      <div id="topsearch" className="topsearches-marquee">
        <div className="marquee-title-container">
          {moduleTitle && (
            <div className="title-text">
              {moduleTitle[0]} <span> {moduleTitle.splice(1, moduleTitle.length).join(" ")}</span>
            </div>
          )}
<div className="download">
  <h4 className="download_Text">Download App on</h4>
  <Link to={{ pathname: "https://play.google.com/store/apps/details?id=com.nexgen.cricview" }} target="_blank"><h5 className="app_Icon"><ShopIcon style={{ fontSize: 25 }} /></h5></Link>
  <Link to={{ pathname: "https://apps.apple.com/us/app/cricviewapp/id1554457039?ign-mpt=uo%3D2" }} target="_blank"><h5 className="app_Icon"><AppleIcon style={{ fontSize: 27 }} /></h5></Link>
</div>

          {/* <div className="title-image" onClick={marqueeToggle}>
            {paused ? <Play fill={"#fff"} /> : <Pause fill={"#fff"} />}
          </div> */}
        </div>
        <marquee ref={marqueeRef} className="marquee">
          {contentData &&
            contentData.length > 0 &&
            contentData.map((item, index) => {
              const { url = null, title } = item || {};
              return url && title ? (
                <span className="margin-span" key={index}>
                  <span style={{ backgroundColor: "white", color: "white", borderRadius: "50%", fontSize: "8px", verticalAlign: "middle" }}>◯</span>{" "}
                  &nbsp;&nbsp;
                  <Link {...linkProps(item)}>{title}</Link>
                  {console.log(linkProps)}
                </span>
              ) : (
                <Fragment key={index} />
              );
            })}
        </marquee>
      </div>
      <div className="cricview-topsearch-container">
        <div className="cricview-topsearch-wrapper">
          {/* <header className="topsearch-header">
            <div className="topsearch-banner-container ads-banner-container">
              <img className="topsearch-banner" src={require("../../Assets/banner.png")} alt="" />
            </div>
          </header> */}
          <section className="topsearchs">
            <Carousel
              responsiveLayout={responsive}
              cols={4}
              rows={2}
              gap={10}
              loop
              autoplay={5000}
              showDots={true}
              dot={MyDot}
              arrowLeft={ArrowLeft}
              arrowRight={ArrowRight}
            >
              {contentData && contentData.length > 0 ? (
                contentData.map((item, index) => {
                  const { urlToImage, publishedAt, title } = item || {};
                  return (
                    <Carousel.Item key={index}>
                      <div className={`topsearch-item-small` + (index === 0 || index === 8 || index === 16 ? " extra-width" : "")}>
                        {item && item.type !== "ad" ? (
                          <Link {...linkProps(item)}>
                            <div className="topsearch-small-image-container">
                              <img className="image" src={urlToImage} alt="" />
                            </div>
                            <div className="topsearch-small-title-container">
                              {index === 0 ? (
                                <div className="title-container-image">
                                  <div className="featuredTag">
                                    <span className="featuredtitle">Featured</span>
                                  </div>
                                </div>
                              ) : (
                                <Fragment />
                              )}
                              <div className="title-container-date">{parseDate(publishedAt)}</div>
                              <div className="title-container-text">{title}</div>
                            </div>
                          </Link>
                        )
                          : (
                            <div className="Bg_color">
                            <div className="uniform-grid__section-ads ads-banner-container" onClick={() => { window.open("https://play.google.com/store/apps/details?id=com.fac1world.foodapp") }}> <figure className="thumbnail__container ads-banner-container">
                                <img className="ads-image" alt="Ads" title="Ads" src={require("../../Assets/banner1.jpg")} />
                              </figure>
                            </div></div>
                          )
                        }
                      </div>
                    </Carousel.Item>
                  );
                })
              ) : (
                <Fragment />
              )}
            </Carousel>
          </section>
        </div>
      </div>
      <div id="mustread" className="border1" />
      <div className="border1" />
      <div className="border1" />
      <div className="row row_1" style={{ marginBottom: "10px" }}></div>
    </React.Fragment>
  );
};

export default connect(state => ({ content: state.content }))(TopSearch);
