import React, { useState, useEffect } from "react";
import withLayout from "../../layout/default";
import FeaturedNews from "../../Components/FeaturedNews";
import ItemCarousel from "../../Components/ItemCarousel";
import { getModuleData } from "../../util/getModuleData";
import { connect } from "react-redux";
import "./style.scss";
const Blogs = ({ content }) => {
  const [contentProp, setContentProp] = useState({});
  useEffect(() => {
    if (content && Object.keys(content).length > 0) {
      setContentProp({ ...content });
    }
  }, [content]);

  const { cricviewTwitter = [], popularOnTwitter = [] } = contentProp && contentProp.Twitter ? contentProp.Twitter : {};
  const { latestCricview = [], cricviewCrawler = [], topCricview = [] } = contentProp && contentProp.Articles ? contentProp.Articles : {};

  return (
    <div className="blogs-container">
      <div className="row row_1">
        <FeaturedNews
          blogs={true}
          contentData={[...cricviewCrawler, ...topCricview]}
          contentTitle="Trending Blogs"
          contentSubHeading="The sporting activities in the world has come to a standstill."
          rowCount={[0, 4]}
        />
      </div>
      <div className="row row_1">
        <ItemCarousel contentTitle="Cricview Twitter Feeds" contentData={[...cricviewTwitter]} />
      </div>
      <div className="row row_1">
        <ItemCarousel contentTitle="Popular on twitter" contentData={[...popularOnTwitter]} />
      </div>
      {/* <div className="row row_1">
        <FeaturedNews blogs={true} contentData={[...instaArticles]} moduleName={blogsModuleName} contentTitle="Trending on twitter" rowCount={[0]} />
      </div> */}
    </div>
  );
};
const temp = connect(state => ({ content: state.content }))(Blogs);
export default withLayout(temp);
