import React, { Fragment, useEffect, useState } from "react";
import './style.scss';
import withLayout from '../../layout/default'

const FullScore = () => {
    const[fullScoreImage,setFullScoreImage] = useState("https://wearetesting.s3.ap-south-1.amazonaws.com/live33.png")
    
    useEffect(()=>{
    const interval = setInterval(() =>{
        let x = Math.floor(Math.random() * 1000000000000);    
        setFullScoreImage(`https://wearetesting.s3.ap-south-1.amazonaws.com/live33.png?x=${+x}`)
    }, 4000);
    return () => {
        clearInterval(interval);
      };
 })

    return(
     <div className="fullScore-container">
        <div className="headingScore">
            <div className="headingText">Scorecard</div>
            <div className="cricImage">
                <img src = {require("../../Assets/cricview_logo-192x192.png")}/>
            </div>
        </div>
        <div className="scoreImage"> 
            {/* <img src = {require("../../Assets/spons.jpeg")}/> */}
            <img src = {fullScoreImage}/>
        </div>
        <div className="scoreFooter">
            <div className="footerText">Please stay tuned for more updates.</div>
        </div>
     </div>
 )
}

export default withLayout(FullScore);