// import WomenCricket from "./Views/WomenCricket";
import TalentHunt from "./Views/TalentHunt";
import RegMethods from "./Views/TalentRegMethods";
import TalentContact from "./Views/TalentContact";
import DetailPage from "./Views/DetailPage";
import Interviews from "./Views/Interviews";
import Videos from "./Views/Videos";
import About from "./Views/About";
import Blogs from "./Views/Blogs";
import Home from "./Views/Home";
import Editor from "./Views/Editor";
import TalentOnline from "./Views/TalentOnline";
import LeaderBoard from "./Components/LeaderBoard";
import GameZone from "./Views/GameZone";
import Match from "./Views/Match";
import CreateMatch from "./Views/CreateMatch";
import AllMatches from "./Views/AllMatches";
import AllProfile from "./Views/AllProfile";
import HomeClone from "./Views/HomeWithIplScore"
import FullScore from "./Views/FullScore"
import LiveDetails from "./Views/Livedetails/LiveDetails";
import CovidDetails from "./Views/CovidDetails/CovidDetails";

const routes = [
  {
    Component: LeaderBoard, path: "/leaderboard", needAuth: true
  },
  {
    Component: AllProfile, path: "/allProfile"
  },
  {
    Component: AllMatches, path: "/allmatches",
    needAuth: true, adminAccess: true
  },
  {
    Component: CreateMatch, path: "/createMatch",
    needAuth: true, adminAccess: true
  },
  {
    Component: About, path: "/about",
  },
  {
    Component: DetailPage, path: "/article/:id/:title",
  },
  {
    Component: Blogs, path: "/blogs",
  },
  {
    Component: Interviews, path: "/interviews",
  },
  {
    Component: TalentHunt, path: "/talenthunt", registrationClosed: true
  },
  {
    Component: RegMethods, path: "/regmethods",
  },
  {
    Component: TalentContact, path: "/talentContact",
  },
  {
    Component: Videos, path: "/videos",
  },
  {
    Component: Editor, path: "/editor/:id/:edit", needAuth: true, adminAccess: true, authorAccess: true
  },
  {
    Component: Editor, path: "/editor", needAuth: true, adminAccess: true, authorAccess: true
  },
  {
    Component: TalentOnline, path: "/cricketonlinetalenthunt",
  },
  {
    Component: TalentHunt, path: "/talentHunt",
  },
  {
    Component: GameZone, path: "/gamezone", needAuth: true
  },
  {
    Component: Match, path: "/match/:id"
  },
  {
    Component: FullScore, path: "/fullScore"
  },
  {
    Component: HomeClone, path: "/homeClone"
  },
  {
    Component: LiveDetails, path: "/LiveDetails"
  },
  {
    Component: CovidDetails, path: "/CovidDetails"
  },
  {
    Component: Home, path: "/"
  }
];

export default routes;
