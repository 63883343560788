import React, { useEffect } from 'react';
import withLayout from '../../layout/default';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const isGuest = sessionStorage.getItem("guestUser")
const GameZone = ({ user }) => {
    useEffect(() => {
        window.onReady(function onReady() {
            window.game.onload();
        });
    }, [])
    return (
        <div style={{ width: "100%" }}>
            {(!isGuest || (user && user.userId)) && <div style={{
                width: "max-content",
                margin: "10px auto",
                fontSize: "16px",
                textDecoration: "none",
                backgroundImage: `linear-gradient(90deg, red, darkblue)`,
                padding: "10px"
            }}><Link style={{ color: "white" }} to="/leaderboard">GO TO LEADERBOARD</Link></div>}
            <div style={{ maxWidth: "768px", margin: "0 auto" }} id="screen"></div>
        </div >
    );
}
const showBanner = false
const showLanguageBar = isGuest ? false : true
export default connect(state => ({ user: state.user }))(withLayout(GameZone, showBanner, showLanguageBar));