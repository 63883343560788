import React from 'react';

import MobileBanner from "../MobileBanner";
import Carousel from "react-grid-carousel";
import MyDot from "../customizedDots";
import { connect } from "react-redux";
import { openModal } from '../../redux/helpers/modal';
import { PLAY_WITH_OR_WITHOUT_SIGN_IN_MODAL } from '../../constant/modal-type';


const GameCarousel = ({ content }) => {
    const { userId = null } = content
    const handlePlay = () => {
        localStorage.getItem("me.save.topSteps") && localStorage.removeItem("me.save.topSteps")
        localStorage.getItem("me.save.score") && localStorage.removeItem("me.save.score")
        localStorage.getItem("me.save") && localStorage.removeItem("me.save")
        localStorage.getItem("me.save.steps") && localStorage.removeItem("me.save.steps")
        const isGuest = sessionStorage.getItem("guestUser")
        return (!userId && !isGuest) ? openModal(PLAY_WITH_OR_WITHOUT_SIGN_IN_MODAL) : window.location.replace(window.location.origin + "/gamezone")
    };
    return (
        <div>
            <div id="playnow" className="playnow-modal">
                <span className="playnow-close" onClick={() => handlePlay()}></span>
            </div>
            <div className="row dots-bottom">
                <MobileBanner>
                    
                    {/* <Carousel loop autoplay={5000} showDots={false} hideArrow={true} dot={MyDot}> */}
                        {/* <Carousel.Item> */}
                            {/* <div style={{ cursor: "pointer", height:"200px" }} className="img-banner"> */}
                                {/* <img className="bannerImage" src={require("../../Assets/playnow1.png")} onClick={() => handlePlay("show")} alt="banner" /> */}
                            {/* </div> */}
                        {/* </Carousel.Item> */}
                        {/* <Carousel.Item> */}
                            {/* <div style={{ cursor: "pointer",height:"200px"  }} className="img-banner"> */}
                                {/* <img className="bannerImage" src={require("../../Assets/playnow1.png")} onClick={() => handlePlay("show")} alt="banner" /> */}
                            {/* </div> */}
                        {/* </Carousel.Item> */}
                        {/* <Carousel.Item> */}
                            {/* <div style={{  cursor: "pointer",height:"200px"  }} className="img-banner"> */}
                                {/* <img className="bannerImage" src={require("../../Assets/playnow1.png")} onClick={() => handlePlay("show")} alt="banner" /> */}
                            {/* </div> */}
                        {/* </Carousel.Item>
                    </Carousel> */}
                </MobileBanner>
            </div>
        </div>
    );
}

export default connect((state) => ({ content: state.user }))(GameCarousel);