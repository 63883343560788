import React, { Component, createRef, Fragment } from "react";
import { Link } from "react-router-dom";
import routes from "./routes";
import { openModal } from "../../redux/helpers/modal";
import { SIGN_IN_MODAL } from "../../constant/modal-type";
import { ArrowDown, Close, Search, Hamburger, SearchBig } from "../../Assets/icons";
import SearchModule from "../../Components/searchModule";
import Axios from 'axios';
import ApiPart from "../../util/apiPart"

import "./style.scss";

class HeaderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: localStorage.getItem("userName") || "SignIn/ Register",
      changScroll: 188,
      currenPath: (window && window.location && window.location.pathname) || "/",
      listActive: false,
      searchData: [],
    };
    this.headerReff = createRef();
    this.inputReff = createRef();
  }
  showNavList = () => {
    if (this.state.listActive == false) {
      this.setState({ listActive: true });
      document.getElementsByClassName("icon")[0].style.transform = "rotate(180deg)";
    }
  };
  handleClick = event => {
    event.preventDefault();
    this.setState({ open: true });
  };
  handleSubmit = event => {
    event.preventDefault();
  };
  handleChange = async (event) => {
    // this.setState({ value: event.target.value });
    document.getElementsByClassName("search-modal")[0].style.display = "block";
    document.getElementsByClassName("search-modal")[0].children[0].style.display = "block";
    this.setState(state => ({ ...state, searchData: [] }))

    if (event.target.value.length > 1) {
      await Axios({
        method: 'GET',
        url: `${ApiPart[0].firstPart}/getdata?search=${event.target.value}`,
      }).then(res => {
        this.setState(state => ({ ...state, searchData: res.data.data }))
        if (this.state.searchData.length === 0) document.getElementsByClassName("no-search-results")[0].style.display = "block";
        document.getElementsByClassName("search-modal")[0].children[0].style.display = "none";
      });
    }
    else {
      document.getElementsByClassName("search-modal")[0].style.display = "none";
    }
  };
  handleCloseSearch = () => {
    this.setState({ open: false, value: "" });
    this.inputReff.current.value = "";
    document.body.style.overflowY = "scroll";
    document.getElementsByClassName("search-modal")[0].style.display = "none";
  };
  handleMenuToggle = () => {
    this.setState({ openMenu: !this.state.openMenu });
  };
  showLogin = () => {
    openModal(SIGN_IN_MODAL);
  };
  handleScroll = () => {
    const node = this.headerReff.current;
    try {
      if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
        node.style.height = "54px";
      } else {
        node.style.height = "74px";
      }
    } catch (e) {
      console.log(e);
    }
  };
  // handleClickOutside=()=>{
  //   console.log(this,"this");
  //   this.setState({ listActive: false });
  //   document.getElementsByClassName("icon")[0].style.transform="rotate(0deg)";

  // }
  // componentDidMount() {
  // window.addEventListener("scroll", this.handleScroll);
  // let dom=document.body.children[1].children[0].children[0].children;
  // for(let x=0; x <dom.length;x++){
  //   if(x!=3)
  //     dom[x].addEventListener("mousedown", this.handleClickOutside);
  // }
  // window.onscroll = this.handleScroll;
  // this.setState({ currenPath: window.location.pathname });
  // }
  // componentWillUnmount() {
  //   let dom=document.body.children[1].children[0].children[0].children;
  //   for(let x=0; x <dom.length;x++){
  //     if(x!=3)
  //       dom[x].addEventListener("mousedown", this.handleClickOutside);
  //   }
  // }
  render() {
    const navigationItems = [];
    return (
      <div className="cricview-header">
        <nav className="cricview-navigation-desktop" ref={this.headerReff}>
          <div className="cricview-branding-container">
            <div className="branding-partner">
              <a href="https://nexgeniots.com/" target="__blank" rel="noopener noreferrer">
                <img className="nexg" src={require("../../Assets/nexgen-white.png")} alt="" />
              </a>
            </div>
            <div className="slash-line"></div>
            <Link to="/">
              <img className="header-image" alt="CRICVIEW" src={require("../../Assets/cricview-logo-redesign.png")} />
            </Link>
          </div>
          <div className={`cricview-navigation-container ${this.state.open ? "deactivate" : ""}`}>
            <ul className="cricview-navigation-list">
              {routes.map(({ label, path, navigationItems = null }, i) => (
                <Fragment key={i}>
                  <Link to={path}>
                    <li
                      className="cricview-navigation-list-item"
                      onClick={() => this.showNavList()}
                      style={{ backgroundColor: this.state.currenPath === path ? "#ff3333" : "" }}
                    >
                      {label}
                      {navigationItems && (
                        <Fragment>
                          <ArrowDown />
                        </Fragment>
                      )}
                    </li>
                  </Link>
                </Fragment>
              ))}
            </ul>
            {navigationItems && navigationItems.length > 0 && (
              <div className={this.state.listActive ? "navigation__drop-down showList" : "navigation__drop-down"}>
                <ul>
                  {navigationItems.map((navItem, j) => (
                    <Fragment key={j}>
                      <li className="hover-list">
                        <a href={navItem.path} className=" navigation__drop-down-link">
                          {navItem.label}
                        </a>
                      </li>
                    </Fragment>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className={`cricview-search-container ${this.state.open ? "close" : ""}`} onClick={event => this.handleClick(event)}>
            <SearchBig width="36px" height="36px" />
          </div>
          <form className={`cricview-search-container-open ${this.state.open ? "is-active" : ""}`} onSubmit={this.handleSubmit}>
            <div className="search-open-container">
              <div className="search-input-box">
                <input
                  type="text"
                  className="input"
                  ref={this.inputReff}
                  placeholder="Type to search in cricview..."
                  onChange={event => this.handleChange(event)}
                ></input>
              </div>
              <button className="search-button-go" type="submit">
                <Search />
              </button>
              <div className="search-button-close" onClick={this.handleCloseSearch}>
                <Close />
              </div>
            </div>
          </form>
        </nav>
        <nav className="cricview-navigation-mobile">
          <ul className={`cricview-navigation-mobile-list`}>
            <li className="cricview-navigation-mobile-list-item">
              <div className="menu-icon" onClick={this.handleMenuToggle}>
                <Hamburger />
              </div>
            </li>
            <li className="cricview-navigation-mobile-list-item">
              <div className="cricview-branding-container">
                <div className="branding-partner">
                  <a rel="noopener noreferrer" href="https://nexgeniots.com/">
                    <img className="nexg" src={require("../../Assets/nexgen-white.png")} alt="" />
                  </a>
                </div>
                <div className="slash-line"></div>
                <a href="/">
                  <img className="header-image" alt="CRICVIEW" src={require("../../Assets/cricview-logo-redesign.png")} />
                </a>
              </div>
            </li>
            <li className="cricview-navigation-mobile-list-item">
              <div className={`cricview-search-container ${this.state.open ? "close" : ""}`} onClick={event => this.handleClick(event)}>
                <SearchBig width="24px" height="24px"></SearchBig>
              </div>
            </li>
          </ul>
          <form className={`cricview-search-container-open ${this.state.open ? "is-active" : ""}`} onSubmit={this.handleSubmit}>
            <div className="search-open-container">
              <div className="search-input-box">
                <input
                  type="text"
                  className="input"
                  value={this.state.value}
                  placeholder="Type to search in cricview..."
                  onChange={event => this.handleChange(event)}
                ></input>
              </div>
              <button className="search-button-go" type="submit">
                <Search />
              </button>
              <div className="search-button-close" onClick={this.handleCloseSearch}>
                <Close />
              </div>
            </div>
          </form>
          <div className={`cricview-navigation-mobilemenu-container ${this.state.openMenu ? "openMenu" : ""}`}>
            <ul className="cricview-navigation-mobilemenu-list">
              {routes.map(({ label, path }, i) => (
                <Fragment key={i}>
                  <Link to={path}>
                    <li className="cricview-navigation-mobilemenu-list-item" style={{ backgroundColor: this.state.currenPath === path ? "#ff3333" : "" }}>
                      {label}
                    </li>
                  </Link>
                </Fragment>
              ))}
              {/* <li className="cricview-navigation-mobilemenu-list-item" onClick={() => this.showLogin()}>
                <span className="displayCountry">{this.state.loggedIn}</span>
              </li> */}
            </ul>
          </div>
        </nav>
        <div className="search-modal">
          <div className="loader-container">
            <div id="loader">
            </div>
          </div>

          {(this.state.searchData.length > 0) ?
            (this.state.searchData.map((content) => (
              <SearchModule
                data={content}
                enableShowMore={true}
                showMoreNavigate={content && content.articles ? "/blogs" : "/videos"}
                styles={true}
              />
            ))
            ) : (<div className="no-search-results">
              No Results
            </div>
            )}
        </div>
      </div>
    );
  }
}

export default HeaderComponent;
