const cplTeam = [
    {
        "name" : "Delhi Kings",
        "flag" : require("../Assets/cplFLagNew/delhi.png"),
        "id" : "Delhi"
    },
    {
        "name" : "Mumbai Master Blasters",
        "flag" :  require("../Assets/cplFLagNew/mumbai.png"),
        "id" : "Mumbai"
    },
    {
        "name" : "Royal Strikers Bangalore",
        "flag" :  require("../Assets/cplFLagNew/beng.png"),
        "id" : "Bangalore"
    },
    {
        "name" : "Kolkata Tigers",
        "flag" :  require("../Assets/cplFLagNew/kolkatta.png"),
        "id" : "Kolkata"
    },
    {
        "name" : "Hyderabad Hurricanes",
        "flag" :  require("../Assets/cplFLagNew/new.png"),
        "id" : "Hyderabad"
    },
    {
        "name" : "Wolves XI Punjab",
        "flag" :  require("../Assets/cplFLagNew/punjab.png"),
        "id" : "Punjab"
    },
    {
        "name" : "Rajasthan Warriors",
        "flag" :  require("../Assets/cplFLagNew/rajasthan.png"),
        "id" : "Rajasthan"
    },
    {
        "name" : "Chennai Lions",
        "flag" :  require("../Assets/cplFLagNew/chennai.png"),
        "id" : "Chennai"
    }
    
]

export default cplTeam;