const saying = [
    {
        "line":"No dream is ever chased alone",
        "author":"Sachin Tendulkar"
    },
    {
        "line":"I have failed at times, but I never stop trying",
        "author":"Rahul Dravid"
    },
    
    // {
    //     "line":"The era of playing aggressive cricket and to have the mid-on up is gone. You now try to read the mindset of a batsman.",
    //     "author":"Mahendra Singh Dhoni"
    // },
    
    // {
    //     "line":"No cricket team in the world depends on one or two players. The team always plays to win.",
    //     "author":"Virat Kohli"
    // },
    
    {
        "line":"Far from all resort of mirth, Save the Cricket on the hearth.",
        "author":"John milton"
    },
    
    {
        "line":"I tend to believe that Cricket is the greatest thing that God ever created on earth.",
        "author":"Harold Pinter"
    },
]

export default   saying;