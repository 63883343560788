import React, { Component } from 'react';
import './style.scss'
import Axios from 'axios';
// import LiveCard from "../LiveCard/LiveCard"

import { Link } from "react-router-dom";
// import FacebookLogin from 'react-facebook-login';

class Live extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image1: "https://wearetesting.s3.ap-south-1.amazonaws.com/live11.png",
            image2: "https://wearetesting.s3.ap-south-1.amazonaws.com/live22.png"
        }
    }

    async componentDidMount() {
        this.interval = setInterval(() => {
            let x = Math.floor(Math.random() * 1000000000000);
            this.setState({
                image1: "https://wearetesting.s3.ap-south-1.amazonaws.com/live11.png?x=" + x,
                image2: "https://wearetesting.s3.ap-south-1.amazonaws.com/live22.png?x=" + x
            })
        }, 4000);

    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    render() {
        return ( null
            // <div className="livematch">
            // <div className="card_1"><LiveCard /></div>
            // </div>
            // <div className="row">
            // <div className="livematch">
            //     <div className="boxOne setMargin">
            //         <div className="imageBoxCric">
            //             <img src={require("../../Assets/cricview_logo-192x192.png")} />
            //         </div>
            //         <div className="imageBox borderCornerOne">
            //             <Link to="/fullScore"><img src={this.state.image1}></img></Link>
            //         </div>
            //     </div>
            //     <div className="boxOne ">

            //         <div className="imageBoxCric">
            //             <img src={require("../../Assets/cricview_logo-192x192.png")} />
            //         </div>
            //         <div className="imageBox  borderCornerTwo">
            //             <img src={this.state.image2}></img>
            //         </div>
            //     </div>
            //     {/* </div> */}
            // </div>
            )
    }
        }

export default Live;