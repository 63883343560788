const countryData = [
    {
        "name": "Afghanistan",
        "code": "af",
        "dial_code": "+93",
        "flag": require("../Assets/flags/flag-of-Afghanistan.png")
    },
    {
        "name": "Albania",
        "code": "al",
        "dial_code": "+355",
        "flag": require("../Assets/flags/flag-of-Albania.png")
    },
    {
        "name": "Algeria",
        "code": "dz",
        "dial_code": "+213",
        "flag": require("../Assets/flags/flag-of-Algeria.png")
    },
    {
        "name": "Andorra",
        "code": "ad",
        "dial_code": "+376",
        "flag": require("../Assets/flags/flag-of-Andorra.png")
    },
    {
        "name": "Angola",
        "code": "ao",
        "dial_code": "+244",
        "flag": require("../Assets/flags/flag-of-Angola.png")
    },
    {
        "name": "Anguilla",
        "code": "ai",
        "dial_code": "+1264",
        "flag": require("../Assets/flags/flag_of_Anguilla.png")
    },
    {
        "name": "Antigua and Barbuda",
        "code": "ag",
        "dial_code": "+1268",
        "flag": require("../Assets/flags/flag-of-Antigua.png")
    },
    {
        "name": "Argentina",
        "code": "ar",
        "dial_code": "+54",
        "flag": require("../Assets/flags/flag-of-Argentina.png")
    },
    {
        "name": "Armenia",
        "code": "am",
        "dial_code": "+374",
        "flag": require("../Assets/flags/flag-of-Armenia.png")
    },
    {
        "name": "Aruba",
        "code": "aw",
        "dial_code": "+297",
        "flag": require("../Assets/flags/flag-of-Aruba.png")
    },
    {
        "name": "Australia",
        "code": "au",
        "dial_code": "+61",
        "flag": require("../Assets/flags/flag-of-Australia.png")
    },
    {
        "name": "Austria",
        "code": "at",
        "dial_code": "+43",
        "flag": require("../Assets/flags/flag-of-Austria.png")
    },
    {
        "name": "Azerbaijan",
        "code": "az",
        "dial_code": "+994",
        "flag": require("../Assets/flags/flag-of-Azerbaijan.png")
    },
    {
        "name": "Bahamas",
        "code": "bs",
        "dial_code": "+1242",
        "flag": require("../Assets/flags/flag-of-Bahamas.png")
    },
    {
        "name": "Bahrain",
        "code": "bh",
        "dial_code": "+973",
        "flag": require("../Assets/flags/flag-of-Bahrain.png")
    },
    {
        "name": "Bangladesh",
        "code": "bd",
        "dial_code": "+880",
        "flag": require("../Assets/flags/flag-of-Bangladesh.png")
    },
    {
        "name": "Barbados",
        "code": "bb",
        "dial_code": "+1246",
        "flag": require("../Assets/flags/flag-of-Barbados.png")
    },
    {
        "name": "Belarus",
        "code": "by",
        "dial_code": "+375",
        "flag": require("../Assets/flags/flag-of-Belarus.png")
    },
    {
        "name": "Belgium",
        "code": "be",
        "dial_code": "+32",
        "flag": require("../Assets/flags/flag-of-Belgium.png")
    },
    {
        "name": "Belize",
        "code": "bz",
        "dial_code": "+501",
        "flag": require("../Assets/flags/flag-of-Belize.png")
    },
    {
        "name": "Benin",
        "code": "bj",
        "dial_code": "+229",
        "flag": require("../Assets/flags/flag-of-Benin.png")
    },
    {
        "name": "Bermuda",
        "code": "bm",
        "dial_code": "+1441",
        "flag": require("../Assets/flags/flag-of-Bermuda.png")
    },
    {
        "name": "Bhutan",
        "code": "bt",
        "dial_code": "+975",
        "flag": require("../Assets/flags/flag-of-Bhutan.png")
    },
    {
        "name": "Bolivia",
        "code": "bo",
        "dial_code": "+591",
        "flag": require("../Assets/flags/flag-of-Bolivia.png")
    },
    {
        "name": "Bosnia and Herzegovina",
        "code": "ba",
        "dial_code": "+387",
        "flag": require("../Assets/flags/flag-of-Bosnia-Herzegovina.png")
    },
    {
        "name": "Botswana",
        "code": "bw",
        "dial_code": "+267",
        "flag": require("../Assets/flags/flag-of-Botswana.png")
    },
    {
        "name": "Brazil",
        "code": "br",
        "dial_code": "+55",
        "flag": require("../Assets/flags/flag-of-Brazil.png")
    },
    {
        "name": "Brunei Darussalam",
        "code": "bn",
        "dial_code": "+673",
        "flag": require("../Assets/flags/flag-of-Brunei.png")
    },
    {
        "name": "Bulgaria",
        "code": "bg",
        "dial_code": "+359",
        "flag": require("../Assets/flags/flag-of-Bulgaria.png")
    },
    {
        "name": "Burkina Faso",
        "code": "bf",
        "dial_code": "+226",
        "flag": require("../Assets/flags/flag-of-Burkina-Faso.png")
    },
    {
        "name": "Burundi",
        "code": "bi",
        "dial_code": "+257",
        "flag": require("../Assets/flags/flag-of-Burundi.png")
    },
    {
        "name": "Cambodia",
        "code": "kh",
        "dial_code": "+855",
        "flag": require("../Assets/flags/flag-of-Cambodia.png")
    },
    {
        "name": "Cameroon",
        "code": "cm",
        "dial_code": "+237",
        "flag": require("../Assets/flags/flag-of-Cameroon.png")
    },
    {
        "name": "Canada",
        "code": "ca",
        "dial_code": "+1",
        "flag": require("../Assets/flags/flag-of-Canada.png")
    },
    {
        "name": "Cape Verde",
        "code": "cv",
        "dial_code": "+238",
        "flag": require("../Assets/flags/flag-of-Cabo-Verde.png")
    },
    {
        "name": "Cayman Islands",
        "code": "ky",
        "dial_code": "+ 345",
        "flag": require("../Assets/flags/flag-of-Cayman-Islands.png")
    },
    {
        "name": "Central African Republic",
        "code": "cf",
        "dial_code": "+236",
        "flag": require("../Assets/flags/flag-of-Central-African-Republic.png")
    },
    {
        "name": "Chad",
        "code": "td",
        "dial_code": "+235",
        "flag": require("../Assets/flags/flag-of-Chad.png")
    },
    {
        "name": "Chile",
        "code": "cl",
        "dial_code": "+56",
        "flag": require("../Assets/flags/flag-of-Chile.png")
    },
    {
        "name": "China",
        "code": "cn",
        "dial_code": "+86",
        "flag": require("../Assets/flags/flag-of-China.png")
    },
    {
        "name": "Colombia",
        "code": "co",
        "dial_code": "+57",
        "flag": require("../Assets/flags/flag-of-Colombia.png")
    },
    {
        "name": "Comoros",
        "code": "km",
        "dial_code": "+269",
        "flag": require("../Assets/flags/flag-of-Comoros.png")
    },
    {
        "name": "Congo",
        "code": "cg",
        "dial_code": "+242",
        "flag": require("../Assets/flags/flag-of-Congo.png")
    },
    {
        "name": "Cook Islands",
        "code": "ck",
        "dial_code": "+682",
        "flag": require("../Assets/flags/flag-of-Cook-Islands.png")
    },
    {
        "name": "Costa Rica",
        "code": "cr",
        "dial_code": "+506",
        "flag": require("../Assets/flags/flag-of-Costa-Rica.png")
    },
    {
        "name": "Cote D'Ivoire",
        "code": "ci",
        "dial_code": "+225",
        "flag": require("../Assets/flags/flag-of-Cote-d-Ivoire.png")
    },
    {
        "name": "Croatia",
        "code": "hr",
        "dial_code": "+385",
        "flag": require("../Assets/flags/flag-of-Croatia.png")
    },
    {
        "name": "Cuba",
        "code": "cu",
        "dial_code": "+53",
        "flag": require("../Assets/flags/flag-of-Cuba.png")
    },
    {
        "name": "Cyprus",
        "code": "cy",
        "dial_code": "+357",
        "flag": require("../Assets/flags/flag-of-Cyprus.png")
    },
    {
        "name": "Czech Republic",
        "code": "cz",
        "dial_code": "+420",
        "flag": require("../Assets/flags/flag-of-Czech-Republic.png")
    },
    {
        "name": "Denmark",
        "code": "dk",
        "dial_code": "+45",
        "flag": require("../Assets/flags/flag-of-Denmark.png")
    },
    {
        "name": "Djibouti",
        "code": "dj",
        "dial_code": "+253",
        "flag": require("../Assets/flags/flag-of-Djibouti.png")
    },
    {
        "name": "Dominica",
        "code": "dm",
        "dial_code": "+1767",
        "flag": require("../Assets/flags/flag-of-Dominica.png")
    },
    {
        "name": "Dominican Republic",
        "code": "do",
        "dial_code": "+1849",
        "flag": require("../Assets/flags/flag-of-Dominican-Republic.png")
    },
    {
        "name": "Ecuador",
        "code": "ec",
        "dial_code": "+593",
        "flag": require("../Assets/flags/flag-of-Ecuador.png")
    },
    {
        "name": "Egypt",
        "code": "eg",
        "dial_code": "+20",
        "flag": require("../Assets/flags/flag-of-Egypt.png")
    },
    {
        "name": "El Salvador",
        "code": "sv",
        "dial_code": "+503",
        "flag": require("../Assets/flags/flag-of-El-Salvador.png")
    },
    {
        "name": "Equatorial Guinea",
        "code": "gq",
        "dial_code": "+240",
        "flag": require("../Assets/flags/flag-of-Equatorial-Guinea.png")
    },
    {
        "name": "Eritrea",
        "code": "er",
        "dial_code": "+291",
        "flag": require("../Assets/flags/flag-of-Eritrea.png")
    },
    {
        "name": "Estonia",
        "code": "ee",
        "dial_code": "+372",
        "flag": require("../Assets/flags/flag-of-Estonia.png")
    },
    {
        "name": "Ethiopia",
        "code": "et",
        "dial_code": "+251",
        "flag": require("../Assets/flags/flag-of-Ethiopia.png")
    },
    {
        "name": "Fiji",
        "code": "fj",
        "dial_code": "+679",
        "flag": require("../Assets/flags/flag-of-Fiji.png")
    },
    {
        "name": "Finland",
        "code": "fi",
        "dial_code": "+358",
        "flag": require("../Assets/flags/flag-of-Finland.png")
    },
    {
        "name": "France",
        "code": "fr",
        "dial_code": "+33",
        "flag": require("../Assets/flags/flag-of-France.png")
    },

    {
        "name": "Gabon",
        "code": "ga",
        "dial_code": "+241",
        "flag": require("../Assets/flags/flag-of-Gabon.png")
    },
    {
        "name": "Gambia",
        "code": "gm",
        "dial_code": "+220",
        "flag": require("../Assets/flags/flag-of-Gambia.png")
    },
    {
        "name": "Georgia",
        "code": "ge",
        "dial_code": "+995",
        "flag": require("../Assets/flags/flag-of-Georgia.png")
    },
    {
        "name": "Germany",
        "code": "de",
        "dial_code": "+49",
        "flag": require("../Assets/flags/flag-of-Germany.png")
    },
    {
        "name": "Ghana",
        "code": "gh",
        "dial_code": "+233",
        "flag": require("../Assets/flags/flag-of-Ghana.png")
    },
    {
        "name": "Greece",
        "code": "gr",
        "dial_code": "+30",
        "flag": require("../Assets/flags/flag-of-Greece.png")
    },
    {
        "name": "Greenland",
        "code": "gl",
        "dial_code": "+299",
        "flag": require("../Assets/flags/flag-of-Greenland.png")
    },
    {
        "name": "Grenada",
        "code": "gd",
        "dial_code": "+1473",
        "flag": require("../Assets/flags/flag-of-Grenada.png")
    },
    {
        "name": "Guadeloupe",
        "code": "gp",
        "dial_code": "+590",
        "flag": require("../Assets/flags/flag-of-Guadeloupe.png")
    },
    {
        "name": "Guatemala",
        "code": "gt",
        "dial_code": "+502",
        "flag": require("../Assets/flags/flag-of-Guatemala.png")
    },
    {
        "name": "Guinea",
        "code": "gn",
        "dial_code": "+224",
        "flag": require("../Assets/flags/flag-of-Guinea.png")
    },
    {
        "name": "Guinea-Bissau",
        "code": "gw",
        "dial_code": "+245",
        "flag": require("../Assets/flags/flag-of-Guinea-Bissau.png")
    },
    {
        "name": "Guyana",
        "code": "gy",
        "dial_code": "+595",
        "flag": require("../Assets/flags/flag-of-Guyana.png")
    },
    {
        "name": "Haiti",
        "code": "ht",
        "dial_code": "+509",
        "flag": require("../Assets/flags/flag-of-Haiti.png")
    },
    {
        "name": "Honduras",
        "code": "hn",
        "dial_code": "+504",
        "flag": require("../Assets/flags/flag-of-Honduras.png")
    },
    {
        "name": "Hong Kong",
        "code": "hk",
        "dial_code": "+852",
        "flag": require("../Assets/flags/flag-of-Hong-Kong.png")
    },
    {
        "name": "Hungary",
        "code": "hu",
        "dial_code": "+36",
        "flag": require("../Assets/flags/flag-of-Hungary.png")
    },
    {
        "name": "Iceland",
        "code": "is",
        "dial_code": "+354",
        "flag": require("../Assets/flags/flag-of-Iceland.png")
    },
    {
        "name": "India",
        "code": "in",
        "dial_code": "+91",
        "flag": require("../Assets/flags/flag-of-India.png")
    },
    {
        "name": "Indonesia",
        "code": "id",
        "dial_code": "+62",
        "flag": require("../Assets/flags/flag-of-Indonesia.png")
    },
    {
        "name": "Iran",
        "code": "ir",
        "dial_code": "+98",
        "flag": require("../Assets/flags/flag-of-Iran.png")
    },
    {
        "name": "Iraq",
        "code": "iq",
        "dial_code": "+964",
        "flag": require("../Assets/flags/flag-of-Iraq.png")
    },
    {
        "name": "Ireland",
        "code": "ie",
        "dial_code": "+353",
        "flag": require("../Assets/flags/flag-of-Ireland.png")
    },
    {
        "name": "Israel",
        "code": "il",
        "dial_code": "+972",
        "flag": require("../Assets/flags/flag-of-Israel.png")
    },
    {
        "name": "Italy",
        "code": "it",
        "dial_code": "+39",
        "flag": require("../Assets/flags/flag-of-Italy.png")
    },
    {
        "name": "Jamaica",
        "code": "jm",
        "dial_code": "+1876",
        "flag": require("../Assets/flags/flag-of-Jamaica.png")
    },
    {
        "name": "Japan",
        "code": "jp",
        "dial_code": "+81",
        "flag": require("../Assets/flags/flag-of-Japan.png")
    },
    {
        "name": "Jordan",
        "code": "jo",
        "dial_code": "+962",
        "flag": require("../Assets/flags/flag-of-Jordan.png")
    },
    {
        "name": "Kazakhstan",
        "code": "kz",
        "dial_code": "+7 7",
        "flag": require("../Assets/flags/flag-of-Kazakhstan.png")
    },
    {
        "name": "Kenya",
        "code": "ke",
        "dial_code": "+254",
        "flag": require("../Assets/flags/flag-of-Kenya.png")
    },
    {
        "name": "Kiribati",
        "code": "ki",
        "dial_code": "+686",
        "flag": require("../Assets/flags/flag-of-Kiribati.png")
    },
    {
        "name": "North Korea",
        "code": "kp",
        "dial_code": "+850",
        "flag": require("../Assets/flags/flag-of-Korea-North.png")
    },
    {
        "name": "South Korea",
        "code": "kr",
        "dial_code": "+82",
        "flag": require("../Assets/flags/flag-of-Korea-South.png")
    },
    {
        "name": "Kuwait",
        "code": "kw",
        "dial_code": "+965",
        "flag": require("../Assets/flags/flag-of-Kuwait.png")
    },
    {
        "name": "Kyrgyzstan",
        "code": "kg",
        "dial_code": "+996",
        "flag": require("../Assets/flags/flag-of-Kyrgyzstan.png")
    },
    {
        "name": "Laos",
        "code": "la",
        "dial_code": "+856",
        "flag": require("../Assets/flags/flag-of-Laos.png")
    },
    {
        "name": "Latvia",
        "code": "lv",
        "dial_code": "+371",
        "flag": require("../Assets/flags/flag-of-Latvia.png")
    },
    {
        "name": "Lebanon",
        "code": "lb",
        "dial_code": "+961",
        "flag": require("../Assets/flags/flag-of-Lebanon.png")
    },
    {
        "name": "Lesotho",
        "code": "ls",
        "dial_code": "+266",
        "flag": require("../Assets/flags/flag-of-Lesotho.png")
    },
    {
        "name": "Liberia",
        "code": "lr",
        "dial_code": "+231",
        "flag": require("../Assets/flags/flag-of-Liberia.png")
    },
    {
        "name": "Libya",
        "code": "ly",
        "dial_code": "+218",
        "flag": require("../Assets/flags/flag-of-Libya.png")
    },
    {
        "name": "Liechtenstein",
        "code": "li",
        "dial_code": "+423",
        "flag": require("../Assets/flags/flag-of-Liechtenstein.png")
    },
    {
        "name": "Lithuania",
        "code": "lt",
        "dial_code": "+370",
        "flag": require("../Assets/flags/flag-of-Lithuania.png")
    },
    {
        "name": "Luxembourg",
        "code": "lu",
        "dial_code": "+352",
        "flag": require("../Assets/flags/flag-of-Luxembourg.png")
    },
    {
        "name": "Madagascar",
        "code": "mg",
        "dial_code": "+261",
        "flag": require("../Assets/flags/flag-of-Madagascar.png")
    },
    {
        "name": "Malawi",
        "code": "mw",
        "dial_code": "+265",
        "flag": require("../Assets/flags/flag-of-Malawi.png")
    },
    {
        "name": "Malaysia",
        "code": "my",
        "dial_code": "+60",
        "flag": require("../Assets/flags/flag-of-Malaysia.png")
    },
    {
        "name": "Maldives",
        "code": "mv",
        "dial_code": "+960",
        "flag": require("../Assets/flags/flag-of-Maldives.png")
    },
    {
        "name": "Mali",
        "code": "ml",
        "dial_code": "+223",
        "flag": require("../Assets/flags/flag-of-Mali.png")
    },
    {
        "name": "Malta",
        "code": "mt",
        "dial_code": "+356",
        "flag": require("../Assets/flags/flag-of-Malta.png")
    },
    {
        "name": "Marshall Islands",
        "code": "mh",
        "dial_code": "+692",
        "flag": require("../Assets/flags/flag-of-Marshall-Islands.png")
    },
    {
        "name": "Mauritania",
        "code": "mr",
        "dial_code": "+222",
        "flag": require("../Assets/flags/flag-of-Mauritania.png")
    },
    {
        "name": "Mauritius",
        "code": "mu",
        "dial_code": "+230",
        "flag": require("../Assets/flags/flag-of-Mauritius.png")
    },
    {
        "name": "Mexico",
        "code": "mx",
        "dial_code": "+52",
        "flag": require("../Assets/flags/flag-of-Mexico.png")
    },
    {
        "name": "Moldova",
        "code": "md",
        "dial_code": "+373",
        "flag": require("../Assets/flags/flag-of-Moldova.png")
    },
    {
        "name": "Monaco",
        "code": "mc",
        "dial_code": "+377",
        "flag": require("../Assets/flags/flag-of-Monaco.png")
    },
    {
        "name": "Mongolia",
        "code": "mn",
        "dial_code": "+976",
        "flag": require("../Assets/flags/flag-of-Mongolia.png")
    },
    {
        "name": "Morocco",
        "code": "ma",
        "dial_code": "+212",
        "flag": require("../Assets/flags/flag-of-Morocco.png")
    },
    {
        "name": "Mozambique",
        "code": "mz",
        "dial_code": "+258",
        "flag": require("../Assets/flags/flag-of-Mozambique.png")
    },
    {
        "name": "Myanmar",
        "code": "mm",
        "dial_code": "+95",
        "flag": require("../Assets/flags/flag-of-Myanmar.png")
    },
    {
        "name": "Namibia",
        "code": "na",
        "dial_code": "+264",
        "flag": require("../Assets/flags/flag-of-Namibia.png")
    },
    {
        "name": "Nauru",
        "code": "nr",
        "dial_code": "+674",
        "flag": require("../Assets/flags/flag-of-Nauru.png")
    },
    {
        "name": "Nepal",
        "code": "np",
        "dial_code": "+977",
        "flag": require("../Assets/flags/flag-of-Nepal.png")
    },
    {
        "name": "Netherlands",
        "code": "nl",
        "dial_code": "+31",
        "flag": require("../Assets/flags/flag-of-Netherlands.png")
    },
    {
        "name": "New Zealand",
        "code": "nz",
        "dial_code": "+64",
        "flag": require("../Assets/flags/flag-of-New-Zealand.png")
    },
    {
        "name": "Nicaragua",
        "code": "ni",
        "dial_code": "+505",
        "flag": require("../Assets/flags/flag-of-Nicaragua.png")
    },
    {
        "name": "Niger",
        "code": "ne",
        "dial_code": "+227",
        "flag": require("../Assets/flags/flag-of-Niger.png")
    },
    {
        "name": "Nigeria",
        "code": "ng",
        "dial_code": "+234",
        "flag": require("../Assets/flags/flag-of-Nigeria.png")
    },
    {
        "name": "North Macedonia",
        "code": "nu",
        "dial_code": "+389",
        "flag": require("../Assets/flags/flag-of-North-Macedonia.png")
    },
    {
        "name": "Norway",
        "code": "no",
        "dial_code": "+47",
        "flag": require("../Assets/flags/flag-of-Norway.png")
    },
    {
        "name": "Oman",
        "code": "om",
        "dial_code": "+968",
        "flag": require("../Assets/flags/flag-of-Oman.png")
    },
    {
        "name": "Pakistan",
        "code": "pk",
        "dial_code": "+92",
        "flag": require("../Assets/flags/flag-of-Pakistan.png")
    },
    {
        "name": "Palau",
        "code": "pw",
        "dial_code": "+680",
        "flag": require("../Assets/flags/flag-of-Palau.png")
    },
    {
        "name": "Palestine",
        "code": "ps",
        "dial_code": "+970",
        "flag": require("../Assets/flags/flag-of-Palestine.png")
    },
    {
        "name": "Panama",
        "code": "pa",
        "dial_code": "+507",
        "flag": require("../Assets/flags/flag-of-Panama.png")
    },
    {
        "name": "Papua New Guinea",
        "code": "pg",
        "dial_code": "+675",
        "flag": require("../Assets/flags/flag-of-Papua-New-Guinea.png")
    },
    {
        "name": "Paraguay",
        "code": "py",
        "dial_code": "+595",
        "flag": require("../Assets/flags/flag-of-Paraguay.png")
    },
    {
        "name": "Peru",
        "code": "pe",
        "dial_code": "+51",
        "flag": require("../Assets/flags/flag-of-Peru.png")
    },
    {
        "name": "Philippines",
        "code": "ph",
        "dial_code": "+63",
        "flag": require("../Assets/flags/flag-of-Philippines.png")
    },
    {
        "name": "Poland",
        "code": "pl",
        "dial_code": "+48",
        "flag": require("../Assets/flags/flag-of-Poland.png")
    },
    {
        "name": "Portugal",
        "code": "pt",
        "dial_code": "+351",
        "flag": require("../Assets/flags/flag-of-Portugal.png")
    },
    {
        "name": "Qatar",
        "code": "qa",
        "dial_code": "+974",
        "flag": require("../Assets/flags/flag-of-Qatar.png")
    },
    {
        "name": "Romania",
        "code": "ro",
        "dial_code": "+40",
        "flag": require("../Assets/flags/flag-of-Romania.png")
    },
    {
        "name": "Russia",
        "code": "ru",
        "dial_code": "+7",
        "flag": require("../Assets/flags/flag-of-Russia.png")
    },
    {
        "name": "Rwanda",
        "code": "rw",
        "dial_code": "+250",
        "flag": require("../Assets/flags/flag-of-Rwanda.png")
    },
    {
        "name": "Saint Kitts and Nevis",
        "code": "kn",
        "dial_code": "+1869",
        "flag": require("../Assets/flags/flag-of-St-Kitts-Nevis.png")
    },
    {
        "name": "Saint Lucia",
        "code": "lc",
        "dial_code": "+1758",
        "flag": require("../Assets/flags/flag-of-St-Lucia.png")
    },
    {
        "name": "Saint Vincent and the Grenadines",
        "code": "vc",
        "dial_code": "+1784",
        "flag": require("../Assets/flags/flag-of-St-Vincent-the-Grenadines.png")
    },
    {
        "name": "Samoa",
        "code": "ws",
        "dial_code": "+685",
        "flag": require("../Assets/flags/flag-of-Samoa.png")
    },
    {
        "name": "San Marino",
        "code": "sm",
        "dial_code": "+378",
        "flag": require("../Assets/flags/flag-of-San-Marino.png")
    },
    {
        "name": "Sao Tome and Principe",
        "code": "st",
        "dial_code": "+239",
        "flag": require("../Assets/flags/flag-of-Sao-Tome-and-Principe.png")
    },
    {
        "name": "Saudi Arabia",
        "code": "sa",
        "dial_code": "+966",
        "flag": require("../Assets/flags/flag-of-Saudi-Arabia.png")
    },
    {
        "name": "Senegal",
        "code": "sn",
        "dial_code": "+221",
        "flag": require("../Assets/flags/flag-of-Andorra.png")
    },
    {
        "name": "Serbia",
        "code": "srb",
        "dial_code": "+381",
        "flag": require("../Assets/flags/flag-of-Serbia.png")
    },
    {
        "name": "Seychelles",
        "code": "sc",
        "dial_code": "+248",
        "flag": require("../Assets/flags/flag-of-Seychelles.png")
    },
    {
        "name": "Sierra Leone",
        "code": "sl",
        "dial_code": "+232",
        "flag": require("../Assets/flags/flag-of-Sierra-Leone.png")
    },
    {
        "name": "Singapore",
        "code": "sg",
        "dial_code": "+65",
        "flag": require("../Assets/flags/flag-of-Singapore.png")
    },
    {
        "name": "Slovakia",
        "code": "sk",
        "dial_code": "+421",
        "flag": require("../Assets/flags/flag-of-Slovakia.png")
    },
    {
        "name": "Slovenia",
        "code": "si",
        "dial_code": "+386",
        "flag": require("../Assets/flags/flag-of-Slovenia.png")
    },
    {
        "name": "Solomon Islands",
        "code": "sb",
        "dial_code": "+677",
        "flag": require("../Assets/flags/flag-of-Solomon-Islands.png")
    },
    {
        "name": "Somalia",
        "code": "so",
        "dial_code": "+252",
        "flag": require("../Assets/flags/flag-of-Somalia.png")
    },
    {
        "name": "South Africa",
        "code": "za",
        "dial_code": "+27",
        "flag": require("../Assets/flags/flag-of-South-Africa.png")
    },
    {
        "name": "South Sudan",
        "code": "ss",
        "dial_code": "+211",
        "flag": require("../Assets/flags/flag-of-South-Sudan.png")
    },
    {
        "name": "Spain",
        "code": "es",
        "dial_code": "+34",
        "flag": require("../Assets/flags/flag-of-Spain.png")
    },
    {
        "name": "Sri Lanka",
        "code": "lk",
        "dial_code": "+94",
        "flag": require("../Assets/flags/flag-of-Sri-Lanka.png")
    },
    {
        "name": "Sudan",
        "code": "sd",
        "dial_code": "+249",
        "flag": require("../Assets/flags/flag-of-Sudan.png")
    },
    {
        "name": "Suriname",
        "code": "sr",
        "dial_code": "+597",
        "flag": require("../Assets/flags/flag-of-Suriname.png")
    },
    {
        "name": "Svalbard and Jan Mayen",
        "code": "sj",
        "dial_code": "+47",
        "flag": require("../Assets/flags/flag-of-Andorra.png")
    },
    {
        "name": "Sweden",
        "code": "se",
        "dial_code": "+46",
        "flag": require("../Assets/flags/flag-of-Sweden.png")
    },
    {
        "name": "Switzerland",
        "code": "ch",
        "dial_code": "+41",
        "flag": require("../Assets/flags/flag-of-Switzerland.png")
    },
    {
        "name": "Syria",
        "code": "sy",
        "dial_code": "+963",
        "flag": require("../Assets/flags/flag-of-Syria.png")
    },
    {
        "name": "Taiwan",
        "code": "tw",
        "dial_code": "+886",
        "flag": require("../Assets/flags/flag-of-Taiwan.png")
    },
    {
        "name": "Tajikistan",
        "code": "tj",
        "dial_code": "+992",
        "flag": require("../Assets/flags/flag-of-Tajikistan.png")
    },
    {
        "name": "Tanzania",
        "code": "tz",
        "dial_code": "+255",
        "flag": require("../Assets/flags/flag-of-Tanzania.png")
    },
    {
        "name": "Thailand",
        "code": "th",
        "dial_code": "+66",
        "flag": require("../Assets/flags/flag-of-Thailand.png")
    },
    {
        "name": "Timor Leste",
        "code": "tl",
        "dial_code": "+670",
        "flag": require("../Assets/flags/flag-of-Timor-Leste.png")
    },
    {
        "name": "Togo",
        "code": "tg",
        "dial_code": "+228",
        "flag": require("../Assets/flags/flag-of-Togo.png")
    },
    {
        "name": "Tonga",
        "code": "to",
        "dial_code": "+676",
        "flag": require("../Assets/flags/flag-of-Tonga.png")
    },
    {
        "name": "Trinidad and Tobago",
        "code": "tt",
        "dial_code": "+1868",
        "flag": require("../Assets/flags/flag-of-Trinidad-and-Tobago.png")
    },
    {
        "name": "Tunisia",
        "code": "tn",
        "dial_code": "+216",
        "flag": require("../Assets/flags/flag-of-Tunisia.png")
    },
    {
        "name": "Turkey",
        "code": "tr",
        "dial_code": "+90",
        "flag": require("../Assets/flags/flag-of-Turkey.png")
    },
    {
        "name": "Turkmenistan",
        "code": "tm",
        "dial_code": "+993",
        "flag": require("../Assets/flags/flag-of-Turkmenistan.png")
    },
    {
        "name": "Tuvalu",
        "code": "tv",
        "dial_code": "+688",
        "flag": require("../Assets/flags/flag-of-Tuvalu.png")
    },
    {
        "name": "Uganda",
        "code": "ug",
        "dial_code": "+256",
        "flag": require("../Assets/flags/flag-of-Uganda.png")
    },
    {
        "name": "Ukraine",
        "code": "ua",
        "dial_code": "+380",
        "flag": require("../Assets/flags/flag-of-Ukraine.png")
    },
    {
        "name": "United Arab Emirates",
        "code": "ae",
        "dial_code": "+971",
        "flag": require("../Assets/flags/flag-of-United-Arab-Emirates.png")
    },
    {
        "name": "United States of America",
        "code": "us",
        "dial_code": "+1",
        "flag": require("../Assets/flags/flag-of-United-States-of-America.png")
    },
    {
        "name": "Uruguay",
        "code": "uy",
        "dial_code": "+598",
        "flag": require("../Assets/flags/flag-of-Uruguay.png")
    },
    {
        "name": "Uzbekistan",
        "code": "uz",
        "dial_code": "+998",
        "flag": require("../Assets/flags/flag-of-Uzbekistan.png")
    },
    {
        "name": "Vanuatu",
        "code": "vu",
        "dial_code": "+678",
        "flag": require("../Assets/flags/flag-of-Vanuatu.png")
    },
    {
        "name": "Vatican City",
        "code": "va",
        "dial_code": "+379",
        "flag": require("../Assets/flags/flag-of-Vanuatu.png")
    },
    {
        "name": "Venezuela",
        "code": "ve",
        "dial_code": "+58",
        "flag": require("../Assets/flags/flag-of-Venezuela.png")
    },
    {
        "name": "Viet Nam",
        "code": "vn",
        "dial_code": "+84",
        "flag": require("../Assets/flags/flag-of-Vietnam.png")
    },
    {
        "name": "Yemen",
        "code": "ye",
        "dial_code": "+967",
        "flag": require("../Assets/flags/flag-of-Yemen.png")
    },
    {
        "name": "Zambia",
        "code": "zm",
        "dial_code": "+260",
        "flag": require("../Assets/flags/flag-of-Zambia.png")
    },
    {
        "name": "Zimbabwe",
        "code": "zw",
        "dial_code": "+263",
        "flag": require("../Assets/flags/flag-of-Zimbabwe.png")
    }
]

export default countryData;