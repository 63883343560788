import React, { useState, Fragment, useEffect } from "react";
import {withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios"

import { parseDate, linkProps } from "../../util";
import { PlayIcon } from "../../Icons/icons";
import "./style.scss";

const InterviewModule = ({contentData = [],moduleName, showPlayIcon, contentTitle, showMoreNavigate, showMoreBtnText = "Load More", rowCount = [0, 4, 8], interviewCategory = 'popularInterviews' }) => {
  const [results, setResults] = useState({ start: [...rowCount], length: 4 });
  const [latestPost, setLatestPost] = useState([])
  let len = contentData.length
  const slicedItems = window.innerWidth < 769 && window.innerWidth > 425 ? len - (len % 2) : len - (len % 4) - 1
  const handleClick = () => {
    if (showMoreNavigate && results.start[results.start.length - 1] + results.length >= contentData.length) {
      window.open(showMoreNavigate, "_blank");
    } else {
      setResults(state => ({ ...state, start: [...state.start, state.start[state.start.length - 1] + results.length] }));
      setResults(state => ({ ...state, start: [...state.start, state.start[state.start.length - 1] + results.length] }));
    }
  };
  // const postsURL = `https://0uzpgeids9.execute-api.ap-south-1.amazonaws.com/prod/getInstaPost`
  // const postsURL = `https://2rs3myucbd.execute-api.ap-south-1.amazonaws.com/dev/insta/posts`
  const postsURL = `https://2rs3myucbd.execute-api.ap-south-1.amazonaws.com/dev/youtube/playlist`
  ////////////
  useEffect(() => {
    function latestPosts(interviewCategory) {
      const res = axios.get(postsURL).then((res) => {
        if(interviewCategory === 'popularInterviews') {
          setLatestPost([...res.data.result.popularInterviews])
          return res.data.result.popularInterviews
        } else {
          setLatestPost([...res.data.result.allInterviews])
          return res.data.result.allInterviews
        }

        // setLatestPost([...res.data.latestPosts])
        // return res.data
      });
    }
    latestPosts(interviewCategory);
  },[])

  const moduleTitle = contentTitle ? contentTitle.split(" ") : (moduleName && moduleName.split(" ")) || "";
  console.log('--results--', results)
  console.log('--latestPost--', latestPost)
  return (
    <Fragment>
      <div id="mustread" className="border1" />
      <div className="border1" />
      <div className="border1" />
      <div id="mustread" className="cricview-interviews-container">
        <div className="cricview-interviews-wrapper">
          <header className="interviews-header">
            <h3 className="interviews-header-title">
              {moduleTitle && (
                <div>
                  {moduleTitle[0]} <span>{moduleTitle.splice(1, moduleTitle.length).join(" ")}</span>
                </div>
              )}
            </h3>
          </header>
          {
            <div className="interviews">
              <section className="interviews-grid">
                {/* <div className="interviews-flex"> */}
                {latestPost.slice(0, 3).map((item, i) => (
                  <Fragment key={i}>
                    <div className="interviews-item-medium">
                      {/* <Link to={{ pathname: item.url }} target="_blank"> */}
                      <Link to={{ pathname: 'https://www.youtube.com/watch?v='+ item.videoId}} target="_blank">
                        <div className="image-container-medium" style={{ backgroundImage: `url(${item.thumbnailUrl})`, backgroundRepeat: "round" }}>
                          {showPlayIcon && (
                            <div className="play-button">
                              <PlayIcon className="play-image" />
                            </div>
                          )}
                          {/* <img src={item.urlToImage} className="image" alt="" /> */}
                        </div>
                        <div className="title-container-medium">
                          <div className="title">{item.title}</div>
                        </div>
                        </Link>
                      {/* </Link> */}
                    </div>
                  </Fragment>
                ))}
                {results.start.map((start, j) => (
                  <Fragment key={j}>
                    {(latestPost.slice(3, slicedItems)).slice(start, start + results.length).map((item, i) => (
                      <Fragment key={i}>
                        <div className="interviews-item-small">
                          <Link to={{ pathname: 'https://www.youtube.com/watch?v='+ item.videoId}} target="_blank">
                            <div className="image-container-small"  style={{ backgroundImage: `url(${item.thumbnailUrl})`, backgroundRepeat: "round" }}>
                              {showPlayIcon && (
                                <div className="play-button">
                                  <PlayIcon className="play-image" />
                                </div>
                              )}
                              {/* <img src={item.urlToImage} className="image" alt="" /> */}
                            </div>
                            <div className="title-container-small">
                              <div className="title-small">{item.title}</div>
                            </div>
                          </Link>
                        </div>
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
                {/* </div> */}

                {showMoreNavigate || results.start[results.start.length - 1] + results.length < contentData.length ? (
                  <div className="viewall-container">
                    <button className="viewall-button" onClick={handleClick}>
                      {showMoreBtnText}
                    </button>
                  </div>
                ) : (
                    <Fragment />
                  )}
              </section>
            </div>
          }
        </div>
      </div>
    </Fragment>
  );
};

const Art = withRouter(InterviewModule);
export default connect(state => ({ content: state.content }))(Art);
