import React, { Fragment, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import CKEditor from "ckeditor4-react";

import { postEditorData } from "../../util/api";
import ImageCarousel from "../../Components/Image-Carousel";
import withLayout from "../../layout/default";
import "./style.scss";
import { connect } from "react-redux";
import categoriesOptions from "./data"

import uuidGen from "../../util/uuidGen";
CKEditor.editorConfig = function (config) {
  config.language = "fr";
  config.uiColor = "#2b2c26";
};
//   Id	URL	cricview_title	cric_date	cricview_content	urlToImage	status
const getSearchParams = () => {
  const query = useLocation().search;
  const parsedQuery = new URLSearchParams(query);
  const params = ["id", "url", "cricview_title", "cric_date", "cricview_content", "urlToImage", "status"];
  const response = {};
  for (let i in params) {
    let key = params[i];
    let value = parsedQuery.get(key);
    if (key === "cricview_content" && value) value = value.replace(/ /g, "+");
    response[key] = value;
  }
  return response;
};
const Editor = (props) => {
  const { content, editContent, match } = props
  let id = "", url = "", cricview_title = "", cric_date = "", cricview_content = "", urlToImage = "", status = "", category = ""
  if (match && match.params && match.params.edit) {
    id = (editContent.source && editContent.source.id) || (editContent.id && editContent.id) || ""
    url = (editContent.url && editContent.url) || ""
    cricview_title = (editContent.title && editContent.title) || ""
    cric_date = (editContent.publishedAt && editContent.publishedAt) || ""
    cricview_content = (editContent.content && editContent.content) || ""
    urlToImage = (editContent.urlToImage && editContent.urlToImage) || ""
    category = (editContent.category && editContent.category) || ""
  } else {
    const { id: ParamId = "", url: ParamUrl = "", cricview_title: ParamTitle = "", cric_date: ParamDate = "", cricview_content: ParamContent = "", urlToImage: ParamImage = "", status: ParamStatus = "" } = getSearchParams();
    id = ParamId || ""
    url = ParamUrl || ""
    cricview_title = ParamTitle || ""
    cric_date = ParamDate || ""
    cricview_content = ParamContent ? atob(ParamContent.substring(1, ParamContent.length - 1)) : ""
    urlToImage = ParamImage || ""
    status = ParamStatus || ""
  }
  const [isArticleApproved, setIsArticleApproved] = useState(false);
  const [approvalInprocess, setApprovalInprocess] = useState(false);
  const [imageUrlList, setImageUrlList] = useState(urlToImage.length > 0 ? [urlToImage] : [])
  const [editorData, seteditorData] = useState({
    urlToImage: urlToImage,
    cricview_content: cricview_content,
    cricview_title,
    author: content && content.name,
    cric_date,
    status,
    url,
    id,
    category
  });
  const [selectedImage, setSelectedImage] = useState("");
  const [error, setError] = useState("")
  const [getOptions, setGetOtions] = useState({
    oData: categoriesOptions
  });

  const handleData = data => {
    setImageUrlList([...data])
  };
  const handleDesc = data => {
    const temp = data.editor.getData();

    seteditorData(state => ({ ...state, cricview_content: temp }));
  };
  const handleInput = async event => {
    event.preventDefault();
    const { value, name } = event.target;
    seteditorData(state => ({ ...state, [name]: value }));
  };

  const handleSelect = (action, item) => {
    switch (action) {
      case "select":
        setSelectedImage(item);
        break;
      case "unselect":
        setSelectedImage("");
        break;
      default:
        break;
    }
  };
  const handleSubmit = async e => {
    e && e.preventDefault();
    setApprovalInprocess(true);
    editorData["urlToImage"] = selectedImage;
    editorData["status"] = "approved";
    editorData["cric_date"] = new Date().toISOString();
    if (!editorData["id"]) {
      editorData["id"] = uuidGen();
    }
    if (!editorData["url"]) {
      editorData["url"] = "";
    }
    if (selectedImage.length === 0) {
      alert('Please Add and Select an Image')
      setApprovalInprocess(false);
      return
    }
    if (editorData.cricview_title.length === 0) {
      alert('Please fill the Title')
      setApprovalInprocess(false);
      return
    }
    if (editorData.category.length === 0 || editorData.category === "Select a Category") {
      alert('Please fill the category')
      setApprovalInprocess(false);
      return
    }
    if (editorData.author && editorData.author.length === 0) {
      alert('Please fill the name of Author')
      setApprovalInprocess(false);
      return
    }
    if (editorData.cricview_content.length === 0) {
      alert('Please fill the Data in Editor')
      setApprovalInprocess(false);
      return
    }
    await postEditorData({ ...editorData })
      .then((_) => {
        setApprovalInprocess(false)
        setIsArticleApproved(true)
      }
      ).catch((err) => {
        console.log(err)
        setApprovalInprocess(false)
        alertApi("An error Occured. Please try Again")
        setSelectedImage("")
      })
  };
  const alert = msg => {
    setError(msg);
    setTimeout(() => {
      setError(null);
    }, 3000);
  }
  const alertApi = msg => {
    setError(msg);
    setTimeout(() => {
      setError(null);
    }, 3000);
  }
  return !isArticleApproved && !approvalInprocess ? (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <div className="row row_1">
          <div className="row">
            <div className="col10">
              <ImageCarousel getImageData={handleData} imageArray={[...imageUrlList]} handleSelect={handleSelect} />
            </div>
          </div>
          <div className="row">
            <div className="col10 title_style">
              <label>Title</label>
              <input value={editorData.cricview_title} type="text" name="cricview_title" onChange={event => handleInput(event)} />
            </div>
          </div>
          <div className="row">
            <div className="col10 title_style">
              <label>Category</label>
              <select name="category" id="cricViewCategories" className="categories" value={editorData.category} onChange={event => handleInput(event)} >
                {getOptions.oData.map((item, index) => <option key={index} value={item.name}>{item.name}</option>)}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col10 title_style">
              <label>Author</label>
              <input value={editorData.author} type="text" name="author" onChange={event => handleInput(event)} />
            </div>
          </div>
          <div className="row" style={{ margin: "5% auto" }}>
            <div className="col10">
              <CKEditor
                data={editorData.cricview_content}
                config={{
                  autoGrow_minHeight: 800,
                  height: 400,
                }}
                onChange={data => {
                  handleDesc(data);
                }}
              />
            </div>
            <div className="container2">
              <div className="butn col-5">
                <input
                  type="submit"
                  name="action"
                  className="inputButton"
                  id="accept"
                  value="Approved"
                />
              </div>
              {error && <div className="errorApi"  >
                {error}</div>}
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  ) : approvalInprocess ? (
    <Fragment>
      <div className="container" style={{ minHeight: "61vh", alignItems: "center", display: "flex", justifyContent: "center" }}>
        <div className="loader"></div>
      </div>
    </Fragment>
  ) : (
        <Fragment>
          <div className="container" style={{ minHeight: "50vh" }}>
            <div className="row center" style={{ textAlign: "center", marginTop: "10%" }}>
              <div className="col-12">
                <h1>Article Approved</h1>
              </div>
            </div>
          </div>
        </Fragment>
      )
};
const mapStateToProps = (state) => ({ content: state.user, editContent: state.editContent })
export default connect(mapStateToProps)(withLayout(withRouter(Editor)));
