import React, { Component } from 'react';
import './style.scss'
import Axios from 'axios';
import { store } from '../../redux/store';
import { signIn } from '../../redux/helpers/user';
import { closeModal } from "../../redux/helpers/modal";
import { connect } from "react-redux";
import { ArrowDown } from "../../Assets/icons"
import ApiPart from "../../util/apiPart"
// import FacebookLogin from 'react-facebook-login';
import Cookies from "js-cookie";

class UpdateName extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
        }
    }
    handleInput = (event) => {
        event.preventDefault();
        const { value, name } = event.target;
        this.setState({ [name]: value })
    }

    
    enterLogin = async () => {
        this.setState({ processing: true })
        if ((this.state.name).length === 0) {
            this.alert('Please enter name')
            this.setState({ processing: false })
            return
        }
      
        await Axios({
            url: `${ApiPart[0].firstPart}/updateGuestStatus`,
            method: 'POST',
            data: {
                id: Cookies.get("browserId"),
                name:this.state.name
            }
        }).then((response) => {
                if (response.status === 200) {
                    this.setState({ processing: false })
                }
                closeModal()

            }).catch((error) => {
                console.log(error);
                this.setState({ processing: false })
                this.alert("Please Enter the Correct Credentials")
            })
    }

    

    alert(msg) {
        this.setState({ error: msg })
        setTimeout(_ => this.setState({ error: null }), 3000)
    }
    render() {
        const roleOpen = this.state.showRoleMenu ? "show" : "hide"
        return (this.state.processing ? <div className="loader"></div> : <div className="cricview-register-container">
            <div className="cricview-register-wrapper">
                <div className={`login-wrapper ${!this.state.showOtpScreen ? "show" : "hide"}`}>
                    <div className="welcome-class">Welcome!</div>
                    <div className="welcome-class"><img src={require("../../Assets/cricview-logo-redesign.png")}></img></div>
                    <div className="form-class"></div>
                    
                    <div className="emailForm">
                           <form>
                                <div className="input-cont">
                                    <label>Name</label>
                                    <input type="text" name="name" value={this.state.name} onChange={(event) => this.handleInput(event)} autoComplete="new-password"/>
                                </div>
        
                                {this.state.error && <div className="error centerError">
                                {this.state.error}
                                </div>}
                                <div className="emailLog" onClick={() =>this.enterLogin()}>
                                    Enter Name
                                </div>
                                {/* <div className="forgetPassword">
                                <span>Forget password ?</span>
                                </div> */}
                         </form>
                    </div>
                </div>
               
            </div>
        </div >);
    }
}

export default connect(state => ({ content: state.user }))(UpdateName);