const logo =[
    {
        "name" : require("../../Assets/sponsorLogos/3zero.png"),
        "id" : "onelogo"
    },
    {
        "name" : require("../../Assets/Time-for.png"),
        "id" : "twologo"
    },
    {
        "name" : require("../../Assets/logo.png"),
        "id" : "threelogo"
    },
    {
        "name" : require("../../Assets/JJ-Tax.png"),
        "id" : "fourlogo"
    },
    {
        "name" : require("../../Assets/cricketDome.png"),
        "id" : "fivelogo"
    },
    {
        "name" : require("../../Assets/sponsorLogos/GTlogo.png"),
        "id" : "sixlogo"
    },
    {
        "name" : require("../../Assets/sponsorLogos/PRIDE.png"),
        "id" : "sevenlogo"
    },
    {
        "name" : require("../../Assets/sponsorLogos/wolfer.png"),
        "id" : "eightlogo"
    },
    {
        "name" : require("../../Assets/sponsorLogos/yufSports.png"),
        "id" : "ninelogo"
    },
]

export default logo;
