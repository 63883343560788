module.exports = [
    {
        title: "India's Biggest Cricket Talent Hunt",
        description:"If you have the will, then let us show you the way.Cricview Talent Hunt is committed to finding and nurturing gifted cricketers, wherever they are on Indian soil. And you too have a chance to write your destiny, today",
        starDec:"FREE* for applicants from low-income group",
        url: require("../../Assets/Cricketalenthunt-images/main-slider-img1.png")
    },
    {
        title: "Free** Cricket Talent Hunt",
        starDec:"FREE* for applicants from low-income group",
        description:"If you can dream big, you can achieve big too..Cricview Talent Hunt is truly and selflessly committed to promoting tomorrow’s cricketing geniuses, from every part of the country.Success is now a step closer.",
        url: require("../../Assets/Cricketalenthunt-images/main-slider-img2.jpg")
    }, {
        title: "#KhelegaIndia",
        description:"If you aspire to be India’s finest, you need an academy that matches up.Cricview Talent Hunt  – a promising academy to fuel your fire where talent is genuinely recognised regardless of your status/background.Enrol to get the ball rolling.",
        starDec:"FREE* for applicants from low-income group",
        url: require("../../Assets/Cricketalenthunt-images/main-slider-img3.png")
    }
]