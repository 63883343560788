import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import withLayout from "../../layout/default";
// import ArticleModule from "../../Module/ArticleModule";
import InterviewModule from "../../Module/InterviewModule";
import { addYoutubeVideosToStore } from "../../redux/helpers/content";
import { getModuleData } from "../../util/getModuleData";

import "../../Components/FeaturedNews/style.scss";

const Interviews = ({ content }) => {
  useEffect(() => {
    addYoutubeVideosToStore();
  }, []);
  const { Instagram = {}, interviews = [] } = content || {};
  const { topVideos = [] } = Instagram;
  const interviewVideos = [...topVideos].filter(item => item && item.title && item.title.toLowerCase().includes("#interview"));
  return (
    <Fragment>
      {interviewVideos && interviewVideos.length > 0 && (
        <div className="row row_1">
          <InterviewModule
            contentData={[...interviewVideos]}
            contentTitle={"Trending Interviews"}
            showMoreNavigate={"https://www.instagram.com/cricview.in/"}
            showPlayIcon={true}
          />
        </div>
      )}
      {content && content.interviews && [...content.interviews].length > 0 && (
        <div className="row row_1">
          <InterviewModule
            contentData={content && content.interviews ? [...content.interviews].slice(0, 11) : []}
            contentTitle={"Popular Interviews"}
            showPlayIcon={true}
            interviewCategory={"popularInterviews"}
          />
        </div>
      )}
      {content && content.interviews && [...content.interviews].length > 11 && (
        <div className="row row_1">
          <InterviewModule
            contentData={content && content.interviews ? [...content.interviews].slice(11, content.interviews.length) : []}
            contentTitle={"All Interviews"}
            showMoreNavigate="https://www.youtube.com/channel/UCUidaN_HjsKobb8LQ3hxr6w"
            showPlayIcon={true}
            interviewCategory={"allInterviews"}
          />
        </div>
      )}
    </Fragment>
  );
};

const TEMP = withLayout(Interviews);
export default connect(state => ({ content: state.content }))(TEMP);
