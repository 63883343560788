import React, { useState, useEffect, Fragment } from "react";
import withLayout from "../../layout/default";
import "./style.scss";
import Axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ApiPart from "../../util/apiPart"

const AllMatches = () => {
    const [matchData, setMatchData] = useState([]);
    const [liveMatchData, setLiveMatchData] = useState([])
    const [scheduledMatchData, setScheduledMatchData] = useState([])
    const [endMatchData, setEndMatchData] = useState([])
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState("");
    const [apiError, setApiError] = useState(false);
    const [showPop,setShowPop] = useState(false);
    const [popUpData,setPopUpData] =useState({});
    const [votesForPlayerOne,setVotesForPlayerOne] = useState([])
    const [votesForPlayerTwo,setVotesForPlayerTwo] = useState([])
    useEffect(() => {
        getAllMatches();
    }, [])


    const getAllMatches = () => {
        setProcessing(true);
        Axios({
            url: `${ApiPart[0].firstPart}/getAllMatchData`,
            method: "GET"
        })
            .then((response) => {
                const oldMatchData = response.data.data.items;
                const newMatchData = response.data.data.items.filter(item => { return item.matchStatus === "live" })
                const notStarted = response.data.data.items.filter(item => { return item.matchStatus === "notstarted" })
                const enddata = response.data.data.items.filter(item => { return item.matchStatus === "ended" })
                setLiveMatchData(newMatchData);
                setScheduledMatchData(notStarted)
                setEndMatchData(enddata)
                setMatchData(oldMatchData)
                setProcessing(false);
            }).catch((error) => {
                console.log(error)
                setApiError(true)
                setProcessing(false);
            })
    }
    const alert = msg => {
        setError(msg)
        setTimeout(_ => setError(null), 3000)
    }
    const handleVotes = e => {
        setProcessing(true);
        Axios({
            url: `${ApiPart[0].firstPart}/getMatchDetails?matchId=${e.target.id}`,
            method: "GET",
          }).then((response) => {
            setPopUpData(response.data.data)
            setVotesForPlayerOne(response.data.data.matchUser1.votedUsers)
            setVotesForPlayerTwo(response.data.data.matchUser2.votedUsers)
            console.log(response.data.data.matchUser1.votedUsers)
        setShowPop(true)
            setProcessing(false);
          }).catch((error) => {
            console.log(error);
            const erData = error && error.response && error.response.data.data.message;
            setError(erData);
            setProcessing(false);
          })
    }
    const closeVote = e => {
        setShowPop(false)
    }
    
    const handleChange = e => {
        let status;
        const id = e.target.id;
        matchData.map((item, index) => {
            if (item.id === id) {
                status = "ended"
                confirmAlert({
                    title: `${"Do you want to Delete this Match "}`,
                    buttons: [
                        {
                            label: 'Yes',
                            id: 'yes',
                            onClick: () => {
                                setProcessing(true);
                                Axios({
                                    url: `${ApiPart[0].firstPart}/updateMatchStatus`,
                                    method: "POST",
                                    data: {
                                        "matchId": id,
                                        "matchStatus": status
                                    }
                                })
                                    .then((response) => {
                                        getAllMatches();
                                        setProcessing(false);


                                    }).catch((error) => {
                                        console.log(error)
                                        alert("An Error Occured. Please try again.")
                                        setProcessing(false);
                                        return
                                    })
                            }
                        },
                        {
                            label: 'No',
                            id: 'no',
                            onClick: () => {
                                return false
                            }
                        }
                    ]
                });
            }
        })
    }
    var matchName = `<div class="matchNamePopup">${Object.entries(popUpData).length > 0 ? popUpData.matchName : "Match Title"}</div>`
    return (
        <Fragment>
            {error && <div className="errorApi">
                {error}</div>}
            <div className="row row_1">
                <div className="allMatches-container">
                    <div style={{ display: processing ? "block" : "none" }} className="loader-container-new">
                        <div id="loader-new">
                        </div>
                    </div>
                    
            <div style={{ display: showPop ? "block" : "none" }} className="loader-container-new ">
             
                <div className="popUp-container centerPop">
                <div className="closeVote" onClick={e => closeVote(e)}><img src={require("../../Assets/closeVote.png")}/></div>
                <div className="tablevote">
                < table className="customer-table" id="liveMatchTable">
                <caption  dangerouslySetInnerHTML={{ __html: `${matchName}` }}></caption>
                        <thead>
                            <tr>
                                <th>Votes of {Object.entries(popUpData).length > 0 ? popUpData.matchUser1.name : "Player 1"} ({Object.entries(popUpData).length > 0 ? popUpData.matchUser1.vote: 0})</th>
                                <th>Votes of {Object.entries(popUpData).length > 0 ? popUpData.matchUser2.name : "Player 2"} ({Object.entries(popUpData).length > 0 ? popUpData.matchUser2.vote: 0})</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            {Object.entries(popUpData).length > 0 &&
                            <Fragment>
                                <td>
                                   {votesForPlayerOne.length > 0 ? 
                            votesForPlayerOne.map((item,index) => {
                                return(
                                    <div style={{borderBottom:"1px solid grey"}} key = {index}>
                                        {item && item.Items[0] && item.Items[0].name}
                                    </div> )
                                  }):  <div style={{borderBottom:"1px solid grey",color:"red"}} >no one voted yet</div>}
                                  </td>
                                  <td>
                            {votesForPlayerTwo.length > 0 ? 
                            votesForPlayerTwo.map((item,index) => {
                                return(
                                    <div style={{borderBottom:"1px solid grey"}}   key = {index}>
                                        {item && item.Items[0] && item.Items[0].name}
                                    </div> )
                                  }):  <div style={{borderBottom:"1px solid grey",color:"red"}} >no one voted yet</div>}
                                </td>
                                </Fragment>
                            }  
                            </tr>

                          </tbody>
                    </table>
                    </div>
                </div>
            </div>
                    <div className="notStartedLive">
                        <div className="table">
                    {matchData.length > 0 ? < table className="customer-table" id="liveMatchTable">
                        <caption>Cricview Scheduled Matches</caption>
                        <thead>
                            <tr>
                                <th>Sr No.</th>
                                <th>Match Name</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {scheduledMatchData.length > 0 ?
                                <Fragment>
                                    {scheduledMatchData.map((item, index) => {
                                          var matchName = `<div class="matchName">${item.matchName}</div>`
                                        return (
                                            <tr key={index} className={item.id} >
                                                <th>{index + 1}</th>
                                                <th dangerouslySetInnerHTML={{ __html: `${matchName}` }}></th>
                                                <td>
                                                    <input className="delete" type="button" id={item.id} name={item.matchName} onClick={(e) => handleChange(e)} value="Delete" />
                                                </td>
                                                <td>
                                                    <input className="go-to-match" type="button" id={item.id} name={item.matchName} onClick={() => {
                                                        window.location.assign(window.location.origin + "/match/" + item.id);
                                                    }} value="Go To Match" />
                                                </td>
                                            </tr>
                                        )
                                    })}</Fragment>
                                : <tr><td colSpan="6"><div className="noLiveData">There is No Scheduled Match to Display</div></td></tr>}

                        </tbody>
                    </table>
                        : <div style={{ display: processing ? "none" : "flex" }} className="noData">
                            {apiError ? "An Error occured. Please Try Again." : "There is no Match"}
                        </div>}
                        </div>
                        <div className="table">
                    {matchData.length > 0 ? < table className="customer-table" id="liveMatchTable">
                        <caption>Cricview Live Matches</caption>
                        <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Match Name</th>
                                <th>Match Status</th>
                                <th>Total Votes</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {liveMatchData.length > 0 ?
                                <Fragment>
                                    {liveMatchData.map((item, index) => {
                                           var matchName = `<div class="matchName">${item.matchName}</div>`
                                        return (
                                            <tr key={index} className={item.id} >
                                                <th>{index + 1}</th>
                                                <th dangerouslySetInnerHTML={{ __html: `${matchName}` }}></th>
                                                <th style={{textTransform:"uppercase"}}>{item.matchStatus}</th>
                                                <th>{item.voteNum}</th>
                                                <td>
                                                    <input className="delete showVotes" type="button" id={item.id} name={item.matchName} onClick={(e) => handleVotes(e)} value="Show Votes" />
                                                </td>
                                                <td>
                                                    <input className="delete" type="button" id={item.id} name={item.matchName} onClick={(e) => handleChange(e)} value="Delete" />
                                                </td>
                                                <td>
                                                    <input className="go-to-match" type="button" id={item.id} name={item.matchName} onClick={() => {
                                                        window.location.assign(window.location.origin + "/match/" + item.id);
                                                    }} value="Go To Match" />
                                                </td>
                                            </tr>
                                        )
                                    })}</Fragment>
                                : <tr><td colSpan="6"><div className="noLiveData">There is No Live Match to Display</div></td></tr>}

                        </tbody>
                    </table>
                        : <div style={{ display: processing ? "none" : "flex" }} className="noData">
                            {apiError ? "An Error occured. Please Try Again." : "There is no Match"}
                        </div>}
                        </div>
                        <div className="table">
                    {matchData.length > 0 ? < table className="customer-table" id="liveMatchTable">
                        <caption>Cricview Ended Matches</caption>
                        <thead>
                            <tr>
                                <th>Sr No.</th>
                                <th>Match Name</th>
                                <th>Match Status</th>
                                <th>Total Votes</th>
                                <th></th>
                                
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {endMatchData.length > 0 ?
                                <Fragment>
                                    {endMatchData.map((item, index) => {
                                           var matchName = `<div class="matchName">${item.matchName}</div>`
                                        return (
                                            <tr key={index} className={item.id} >
                                                <th>{index + 1}</th>
                                                <th dangerouslySetInnerHTML={{ __html: `${matchName}` }}></th>
                                                <th style={{textTransform:"uppercase"}}>{item.matchStatus}</th>
                                                <th>{item.voteNum}</th>
                                                <td>
                                                    <input className="delete showVotes" type="button" id={item.id} name={item.matchName} onClick={(e) => handleVotes(e)} value="Show Votes" />
                                                </td>
                                                <td>
                                                    <input className="go-to-match" type="button" id={item.id} name={item.matchName} onClick={() => {
                                                        window.location.assign(window.location.origin + "/match/" + item.id);
                                                    }} value="Go To Match" />
                                                </td>
                                            </tr>
                                        )
                                    })}</Fragment>
                                : <tr><td colSpan="6"><div className="noLiveData">There is No Live Match to Display</div></td></tr>}

                        </tbody>
                    </table>
                        : <div style={{ display: processing ? "none" : "flex" }} className="noData">
                            {apiError ? "An Error occured. Please Try Again." : "There is no Match"}
                        </div>}
                        </div>
                        </div>
                </div >
            </div>
        </Fragment>
    )
}
export default withLayout(AllMatches);