import React, { useState } from "react"
import withLayout from "../../layout/default"
import data from "./dummy"
import "./style.scss"

const UserProfile = () => {

    const [userInfo, setUserInfo] = useState({
        userData: data
    })
    return (
        <div className="userProfile-container">
            <div className="userImage">
                <img src={userInfo.userData.image} alt="userImage" />
            </div>
            <div className="userInfo">
                <div className="userInfo-One">
                    <div className="userName">{userInfo.userData.name}</div>
                    <div className="userId">{userInfo.userData.id}</div>
                </div>
                <div className="userInfor-two">
                    <div style={{ textAlign: "start" }} className="userCountry basicc"><div>Country</div><div className="basiccTwo">{userInfo.userData.country}</div></div>
                    <div className="userStatus basicc"><div>Status</div><div className="basiccTwo">{userInfo.userData.status}</div></div>
                    <div className="userCategory basicc"><div>Category</div><div className="basiccTwo">{userInfo.userData.category}</div></div>
                    <div className="userSkills basicc"><div>Skilss</div><div className="basiccTwo">{userInfo.userData.skills}</div></div>
                    <div className="userMobile basicc" style={{ borderRight: "none" }}><div>Mobile Number</div><div className="basiccTwo">{userInfo.userData.mobile}</div></div>
                </div>
            </div>
        </div>
    )
}

export default UserProfile;