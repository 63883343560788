import React, { useEffect, useState, Fragment } from "react";
import parse from "html-react-parser";
import { connect } from "react-redux";
import withLayout from "../../layout/default";
import { parseDate } from "../../util";

import "./style.scss";
import ItemCarousel from "../../Components/ItemCarousel";
import Axios from "axios";
import { editContent } from "../../redux/helpers/editContent";
import { Link } from "react-router-dom";
import ApiPart from "../../util/apiPart"

const DetailPage = (props) => {
  const { id = "" } = props.match.params
  const [data, setData] = useState({});
  const [videos, setVideos] = useState([]);
  const options = {
    replace: ({ attribs, children }) => {
      if (!attribs) return;
      if (attribs.href && attribs.href.includes("twitter")) {
        const id = attribs.href.split("/status/");
        id && id[1] && setVideos(state => [...state, id[1]]);
        return <Fragment />;
      }
      children.length > 0 && children.map((item) => {
        if (item.data && item.data.includes("https://twitter.com")) {
          let id = item.data.split("/status/");
          id = id[1].slice(0, 19)
          setVideos(state => [...state, id])
          return <Fragment />;
        }
      })
    },
  };
  useEffect(() => {
    Axios({
      url: `${ApiPart[0].firstPart}/getItem?id=${id}`,
      method: 'GET'
    }).then((response) => {
      if (response.status === 200) {
        const { article = {} } = response.data.data
        const { description = "" } = article
        setData({ ...article, description: parse(description, options) });
        editContent(article)
      }
    })

  }, []);

  const { urlToImage, title = null, author, description, publishedAt, source } = data || {};
  const external = source && source.name && !source.name.toLowerCase().includes("cricview") ? true : false;
  const userInfo = props.user
  return Object.keys(data).length > 0 ? (
    <Fragment>
      <div className="app appcms">
        <div role="main" className="modules">
          <div className="module">
            {(  (localStorage.getItem("userRole") &&localStorage.getItem("userRole").toLowerCase()=="admin") || (localStorage.getItem("userRole") && localStorage.getItem("userRole").toLowerCase()=="author")) && < div className="editArticle"><Link style={{ textDecoration: "none", color: "white" }} to={`/editor/${id}/true`}>EDIT ARTICLE</Link></div>}
            <div className="infinite-article-container">
              <div className="article-container">
                <div className="article__header-container bg-img">
                  <img src={urlToImage || ""} alt="Article Title goes here lorem ipsum dolor sit amet 4"></img>
                </div>
                <div className="article__header ">
                  <div className="title">{title}</div>
                  <div className="details">
                    <div className="details-left">
                      <div className="author-container">
                        <span className="by-label">By </span>
                        <span className="author">{author}</span>
                      </div>
                      <div>{publishedAt && parseDate(publishedAt)}</div>
                    </div>
                  </div>
                  <div className="divider site-background-color" />
                </div>
                <div className={!external ? "article__body" : "article__body-external"}>
                  <div className={!external ? "content" : "content-external"}>{description}</div>
                </div>
                {videos && [...videos].length > 0 && (
                  <div className="article__body">
                    <ItemCarousel contentTitle="Related Videos" contentData={[...videos]} />
                  </div>
                )}
                <div className="sharing-footer">
                  <div className="share-prompt">
                    <div className="text">SHARE THIS ARTICLE</div>
                    <div className="line site-background-color" />
                  </div>
                  <div className="share-btns">
                    <div className="share-btn-container">
                      <a href={` http://twitter.com/share?text=${title}&url=${window.location.href}`} target="_blank" rel="noopener noreferrer">
                        <i className="">
                          <img className="icon" src={require("../../Assets/twitter@3x.png")} alt="" />
                        </i>
                      </a>
                      <div className="underline site-background-color" />
                    </div>
                    <div className="share-btn-container">
                      <a href={`http://www.facebook.com/sharer.php?u=${window.location.href}`} target="_blank" rel="noopener noreferrer">
                        <i className="ion-social-facebook">
                          <img className="icon" src={require("../../Assets/fb@3x.png")} alt="" />
                        </i>
                      </a>
                      <div className="underline site-background-color" />
                    </div>
                    <div className="share-btn-container">
                      <a href={`whatsapp://send?&text=${window.location.href}`} target="_blank" rel="noopener noreferrer">
                        <i className="ion-ios-email">
                          <img style={{ width: "45px" }} className="icon" src={require("../../Assets/WhatsApp.png")} alt="" />
                        </i>
                      </a>
                      <div className="underline site-background-color" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment >
  ) : (
      <Fragment>
        <div className="row row_1" style={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: "768px" }}>
          <div className="loader"></div>
        </div>
      </Fragment>
    );
};
const Temp = withLayout(DetailPage);
export default connect(state => ({ user: state.user }))(Temp);
