const data =
{
    "image": "https://i.ytimg.com/vi/TnLFAE-Nh_Y/maxresdefault.jpg",
    "name": "Rohit",
    "country": "India",
    "id": "009234",
    "status": "live",
    "category": "Admin",
    "skills": "Bowling",
    "mobile": "987654321"
}


export default data;