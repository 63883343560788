import React, { Fragment, useState, useEffect, useRef } from "react";
import S3 from 'aws-s3';
import "./style.scss";
import aws from "../../util/keys";

const config = {
  bucketName: 'wearetesting',
  region: aws.region,
  accessKeyId: aws.accessKeyId,
  secretAccessKey: aws.secretAccessKey,
}
const S3Client = new S3(config);


const ImageCarousel = props => {
  const inputUpload = useRef();
  const [imagesData, setimagesData] = useState([...props.imageArray]);
  const [checkBox, setcheckBox] = useState({ currCheck: 0, checkLimit: 1 });
  const [Data, setData] = useState([]);
  const [checkedIndex, setChecked] = useState({});
  const [stylee, setStylee] = useState({ width: "100%" });
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState("")
  useEffect(() => {
    let imageWidth = (window.innerWidth * 83.33) / 100 / 4;
    setStylee({ width: imageWidth + "px" });
    const itemWidth = imagesData.length <= 3 ? 27.67 * imagesData.length : 100;
    document.getElementById("increase_width").style.width = `${itemWidth}%`;
  }, [imagesData.length]);

  useEffect(() => {
    setData([]);
    for (const property in checkedIndex) {
      if (checkedIndex[property]) {
        setData(state => [...state, imagesData[property]]);
      }
    }
  }, [checkBox]);


  const callFunction = event => {
    const index = event.target.getAttribute("data-index");
    if (event.target.checked) {
      setcheckBox(state => ({ ...state, currCheck: state.currCheck + 1 }));
      setChecked(state => ({ ...state, [index]: true }));
      props.handleSelect("select", imagesData[index]);
    } else {
      setcheckBox(state => ({ ...state, currCheck: state.currCheck - 1 }));
      setChecked(state => ({ ...state, [index]: undefined }));
      props.handleSelect("unselect", imagesData[index]);
    }
  };
  const alertImageUpload = (msg) => {
    setError(msg);
    setTimeout(_ => setError(null), 3000)
  }

  const onFileChange = event => {
    event.preventDefault();
    setProcessing(true);
    const file = event.target.files[0];
    const alterFileName = 'IMG-' + new Date().getTime();

    S3Client
      .uploadFile(file, alterFileName)
      .then(res => {
        let fileUrl = res.location;
        setimagesData([fileUrl, ...imagesData]);
        props.getImageData([fileUrl, ...imagesData])
        setProcessing(false)
      })
      .catch(err => {
        console.error(err)
        setProcessing(false)
                alertImageUpload("Image Uploading Failed. Please Try Again...")
      })

  };
  const triggerUpload = () => {
    inputUpload.current.click();
  };

  return (
    <Fragment>
      {error && <div className="errorApi" > {error}</div>}
      <div className="editor-Images-carousel">
        <div style={{ display: processing ? "block" : "none" }} className="loader-container-new">
          <div id="loader-new">
          </div>
        </div>
        <div className="full">

          <div className="container1">
            <div className="rowww" id="increase_width">
              <div className="rowww__inner">
                {imagesData.length > 0 ? (
                  imagesData.map((item, index) => {
                    return item ? (
                      <div key={index} className="tile grid-item" id="grid-item" style={stylee}>
                        <label
                          style={
                            checkBox.currCheck < checkBox.checkLimit
                              ? { cursor: "pointer" }
                              : checkedIndex[index]
                                ? { cursor: "pointer" }
                                : { cursor: "not-allowed" }
                          }
                        >
                          <div className="tile__media">
                            <img className="tile__img" src={item} alt="image" />
                          </div>
                          <div className="hid">
                            <input
                              type="checkbox"
                              key={index}
                              data-index={index}
                              disabled={checkBox.currCheck < checkBox.checkLimit ? false : checkedIndex[index] ? false : true}
                              onChange={(event) => callFunction(event)}
                            />
                            <span className="checkmark"></span>
                          </div>
                        </label>
                      </div>
                    ) : (
                        <Fragment />
                      );
                  })
                ) : (
                    <div>No data to show </div>
                  )}
              </div>
            </div>
            <div className="plus">
              <div style={{ cursor: "pointer" }} onClick={(event) => triggerUpload(event)}>
                <input
                  ref={inputUpload}
                  name="uploadImg"
                  type="file"
                  onChange={(event) => onFileChange(event)}
                  onClick = {event => event.target.value = null}
                  className="uploadImg"
                  id="imgInp"
                  required=""
                  accept="image/gif, image/jpeg, image/png"
                  style={{ display: "none" }}
                />
                <img src={require("../../Assets/addImage.png")} alt="add" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ImageCarousel;
