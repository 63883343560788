module.exports = {
  globalObjects: {
    tweets: {
      "1277195132312465408": {
        created_at: "Sun Jun 28 11:00:23 +0000 2020",
        id_str: "1277195132312465408",
        full_text: "Innovation 💡\n\nWho comes to mind? 🤔\n\nVote here: https://t.co/Hg4ZNRFjV5\n\n@EnglandCricket x @BBCSport https://t.co/eP9davZOlF",
        display_text_range: [0, 99],
        entities: {
          user_mentions: [
            {
              screen_name: "englandcricket",
              name: "England Cricket",
              id_str: "3017241909",
              indices: [72, 87],
            },
            {
              screen_name: "BBCSport",
              name: "BBC Sport",
              id_str: "265902729",
              indices: [90, 99],
            },
          ],
          urls: [
            {
              url: "https://t.co/Hg4ZNRFjV5",
              expanded_url: "http://ms.spr.ly/6018Tlk8I",
              display_url: "ms.spr.ly/6018Tlk8I",
              indices: [47, 70],
            },
          ],
          media: [
            {
              id_str: "1277195046144610310",
              indices: [100, 123],
              media_url: "http://pbs.twimg.com/media/EbmCM3BXsAAyQOG.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbmCM3BXsAAyQOG.jpg",
              url: "https://t.co/eP9davZOlF",
              display_url: "pic.twitter.com/eP9davZOlF",
              expanded_url: "https://twitter.com/englandcricket/status/1277195132312465408/video/1",
              type: "photo",
              original_info: {
                width: 1080,
                height: 1080,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
                large: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
              },
            },
          ],
        },
        extended_entities: {
          media: [
            {
              id_str: "1277195046144610310",
              indices: [100, 123],
              media_url: "http://pbs.twimg.com/media/EbmCM3BXsAAyQOG.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbmCM3BXsAAyQOG.jpg",
              url: "https://t.co/eP9davZOlF",
              display_url: "pic.twitter.com/eP9davZOlF",
              expanded_url: "https://twitter.com/englandcricket/status/1277195132312465408/video/1",
              type: "video",
              original_info: {
                width: 1080,
                height: 1080,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
                large: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
              },
              video_info: {
                aspect_ratio: [1, 1],
                duration_millis: 53480,
                variants: [
                  {
                    bitrate: 432000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1277195046144610310/vid/320x320/E8oA2Mv2sittumL1.mp4?tag=13",
                  },
                  {
                    content_type: "application/x-mpegURL",
                    url: "https://video.twimg.com/amplify_video/1277195046144610310/pl/vFEcjrdufN2_w0PS.m3u8?tag=13",
                  },
                  {
                    bitrate: 832000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1277195046144610310/vid/480x480/7NjrY1FgKCUVcUHW.mp4?tag=13",
                  },
                  {
                    bitrate: 1280000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1277195046144610310/vid/720x720/ffmf-WCP-d3XQLec.mp4?tag=13",
                  },
                ],
              },
              media_key: "13_1277195046144610310",
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext_media_color: null,
              ext: {
                mediaStats: {
                  r: {
                    ok: {
                      viewCount: "8069",
                    },
                  },
                  ttl: -1,
                },
              },
              additional_media_info: {
                title: "Ultimate England Cricketers - Innovation",
                description: "",
                monetizable: false,
              },
            },
          ],
        },
        source: '<a href="https://prod1.sprinklr.com" rel="nofollow">Sprinklr Publishing</a>',
        user_id_str: "3017241909",
        retweet_count: 28,
        favorite_count: 265,
        reply_count: 9,
        quote_count: 2,
        conversation_id_str: "1277195132312465408",
        possibly_sensitive_editable: true,
        lang: "en",
      },
      "1277270634461003776": {
        created_at: "Sun Jun 28 16:00:24 +0000 2020",
        id_str: "1277270634461003776",
        full_text:
          "Clipped. Whipped. Nudged 🏏\n\nUltimate England Men's Cricketers: https://t.co/UwBZVZZHo6\n\n@EnglandCricket x @BBCSport https://t.co/kcnlSGERLM",
        display_text_range: [0, 115],
        entities: {
          user_mentions: [
            {
              screen_name: "englandcricket",
              name: "England Cricket",
              id_str: "3017241909",
              indices: [88, 103],
            },
            {
              screen_name: "BBCSport",
              name: "BBC Sport",
              id_str: "265902729",
              indices: [106, 115],
            },
          ],
          urls: [
            {
              url: "https://t.co/UwBZVZZHo6",
              expanded_url: "http://ms.spr.ly/6019TlwbD",
              display_url: "ms.spr.ly/6019TlwbD",
              indices: [63, 86],
            },
          ],
          media: [
            {
              id_str: "1277270548410753028",
              indices: [116, 139],
              media_url: "http://pbs.twimg.com/media/EbnG3npWsAAf6PY.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbnG3npWsAAf6PY.jpg",
              url: "https://t.co/kcnlSGERLM",
              display_url: "pic.twitter.com/kcnlSGERLM",
              expanded_url: "https://twitter.com/englandcricket/status/1277270634461003776/video/1",
              type: "photo",
              original_info: {
                width: 1080,
                height: 1080,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
                large: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
              },
            },
          ],
        },
        extended_entities: {
          media: [
            {
              id_str: "1277270548410753028",
              indices: [116, 139],
              media_url: "http://pbs.twimg.com/media/EbnG3npWsAAf6PY.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbnG3npWsAAf6PY.jpg",
              url: "https://t.co/kcnlSGERLM",
              display_url: "pic.twitter.com/kcnlSGERLM",
              expanded_url: "https://twitter.com/englandcricket/status/1277270634461003776/video/1",
              type: "video",
              original_info: {
                width: 1080,
                height: 1080,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
                large: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
              },
              video_info: {
                aspect_ratio: [1, 1],
                duration_millis: 49800,
                variants: [
                  {
                    bitrate: 432000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1277270548410753028/vid/320x320/9MGI7_NeFdIx5sl-.mp4?tag=13",
                  },
                  {
                    bitrate: 832000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1277270548410753028/vid/480x480/4wDTfF9B7PtqxCoi.mp4?tag=13",
                  },
                  {
                    content_type: "application/x-mpegURL",
                    url: "https://video.twimg.com/amplify_video/1277270548410753028/pl/LTXgMbI-qbbsQ2YA.m3u8?tag=13",
                  },
                  {
                    bitrate: 1280000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1277270548410753028/vid/720x720/XMH5BiFScXBrL329.mp4?tag=13",
                  },
                ],
              },
              media_key: "13_1277270548410753028",
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext_media_color: null,
              ext: {
                mediaStats: {
                  r: {
                    ok: {
                      viewCount: "8919",
                    },
                  },
                  ttl: -1,
                },
              },
              additional_media_info: {
                title: "Ultimate England Men's Cricketers - Off the Pads",
                description: "",
                monetizable: false,
              },
            },
          ],
        },
        source: '<a href="https://prod1.sprinklr.com" rel="nofollow">Sprinklr Publishing</a>',
        user_id_str: "3017241909",
        retweet_count: 27,
        favorite_count: 218,
        reply_count: 12,
        quote_count: 2,
        conversation_id_str: "1277270634461003776",
        possibly_sensitive_editable: true,
        lang: "en",
      },
      "1276192222703243264": {
        created_at: "Thu Jun 25 16:35:11 +0000 2020",
        id_str: "1276192222703243264",
        full_text: "The lads reckon @chriswoakes has been inspired by @SergioRamos! https://t.co/D1V0dnCWOL",
        display_text_range: [0, 63],
        entities: {
          user_mentions: [
            {
              screen_name: "chriswoakes",
              name: "Chris Woakes",
              id_str: "254783152",
              indices: [16, 28],
            },
            {
              screen_name: "SergioRamos",
              name: "Sergio Ramos",
              id_str: "151123001",
              indices: [50, 62],
            },
          ],
          media: [
            {
              id_str: "1276190771725598721",
              indices: [64, 87],
              media_url: "http://pbs.twimg.com/media/EbXwvs5WAAEYaww.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbXwvs5WAAEYaww.jpg",
              url: "https://t.co/D1V0dnCWOL",
              display_url: "pic.twitter.com/D1V0dnCWOL",
              expanded_url: "https://twitter.com/englandcricket/status/1276192222703243264/photo/1",
              type: "photo",
              original_info: {
                width: 1200,
                height: 1200,
                focus_rects: [
                  {
                    x: 0,
                    y: 0,
                    h: 672,
                    w: 1200,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1200,
                    w: 1200,
                  },
                  {
                    x: 147,
                    y: 0,
                    h: 1200,
                    w: 1053,
                  },
                  {
                    x: 600,
                    y: 0,
                    h: 1200,
                    w: 600,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1200,
                    w: 1200,
                  },
                ],
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                large: {
                  w: 1200,
                  h: 1200,
                  resize: "fit",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1200,
                  h: 1200,
                  resize: "fit",
                },
              },
              features: {
                large: {
                  faces: [
                    {
                      x: 582,
                      y: 706,
                      h: 63,
                      w: 63,
                    },
                    {
                      x: 835,
                      y: 133,
                      h: 228,
                      w: 228,
                    },
                    {
                      x: 181,
                      y: 196,
                      h: 199,
                      w: 199,
                    },
                  ],
                },
                small: {
                  faces: [
                    {
                      x: 329,
                      y: 400,
                      h: 35,
                      w: 35,
                    },
                    {
                      x: 473,
                      y: 75,
                      h: 129,
                      w: 129,
                    },
                    {
                      x: 102,
                      y: 111,
                      h: 112,
                      w: 112,
                    },
                  ],
                },
                medium: {
                  faces: [
                    {
                      x: 582,
                      y: 706,
                      h: 63,
                      w: 63,
                    },
                    {
                      x: 835,
                      y: 133,
                      h: 228,
                      w: 228,
                    },
                    {
                      x: 181,
                      y: 196,
                      h: 199,
                      w: 199,
                    },
                  ],
                },
                orig: {
                  faces: [
                    {
                      x: 582,
                      y: 706,
                      h: 63,
                      w: 63,
                    },
                    {
                      x: 835,
                      y: 133,
                      h: 228,
                      w: 228,
                    },
                    {
                      x: 181,
                      y: 196,
                      h: 199,
                      w: 199,
                    },
                  ],
                },
              },
            },
          ],
        },
        extended_entities: {
          media: [
            {
              id_str: "1276190771725598721",
              indices: [64, 87],
              media_url: "http://pbs.twimg.com/media/EbXwvs5WAAEYaww.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbXwvs5WAAEYaww.jpg",
              url: "https://t.co/D1V0dnCWOL",
              display_url: "pic.twitter.com/D1V0dnCWOL",
              expanded_url: "https://twitter.com/englandcricket/status/1276192222703243264/photo/1",
              type: "photo",
              original_info: {
                width: 1200,
                height: 1200,
                focus_rects: [
                  {
                    x: 0,
                    y: 0,
                    h: 672,
                    w: 1200,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1200,
                    w: 1200,
                  },
                  {
                    x: 147,
                    y: 0,
                    h: 1200,
                    w: 1053,
                  },
                  {
                    x: 600,
                    y: 0,
                    h: 1200,
                    w: 600,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1200,
                    w: 1200,
                  },
                ],
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                large: {
                  w: 1200,
                  h: 1200,
                  resize: "fit",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1200,
                  h: 1200,
                  resize: "fit",
                },
              },
              features: {
                large: {
                  faces: [
                    {
                      x: 582,
                      y: 706,
                      h: 63,
                      w: 63,
                    },
                    {
                      x: 835,
                      y: 133,
                      h: 228,
                      w: 228,
                    },
                    {
                      x: 181,
                      y: 196,
                      h: 199,
                      w: 199,
                    },
                  ],
                },
                small: {
                  faces: [
                    {
                      x: 329,
                      y: 400,
                      h: 35,
                      w: 35,
                    },
                    {
                      x: 473,
                      y: 75,
                      h: 129,
                      w: 129,
                    },
                    {
                      x: 102,
                      y: 111,
                      h: 112,
                      w: 112,
                    },
                  ],
                },
                medium: {
                  faces: [
                    {
                      x: 582,
                      y: 706,
                      h: 63,
                      w: 63,
                    },
                    {
                      x: 835,
                      y: 133,
                      h: 228,
                      w: 228,
                    },
                    {
                      x: 181,
                      y: 196,
                      h: 199,
                      w: 199,
                    },
                  ],
                },
                orig: {
                  faces: [
                    {
                      x: 582,
                      y: 706,
                      h: 63,
                      w: 63,
                    },
                    {
                      x: 835,
                      y: 133,
                      h: 228,
                      w: 228,
                    },
                    {
                      x: 181,
                      y: 196,
                      h: 199,
                      w: 199,
                    },
                  ],
                },
              },
              media_key: "3_1276190771725598721",
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext_media_color: {
                palette: [
                  {
                    rgb: {
                      red: 29,
                      green: 30,
                      blue: 34,
                    },
                    percentage: 32.92,
                  },
                  {
                    rgb: {
                      red: 190,
                      green: 201,
                      blue: 212,
                    },
                    percentage: 21.11,
                  },
                  {
                    rgb: {
                      red: 170,
                      green: 191,
                      blue: 88,
                    },
                    percentage: 7.34,
                  },
                  {
                    rgb: {
                      red: 90,
                      green: 81,
                      blue: 56,
                    },
                    percentage: 6.98,
                  },
                  {
                    rgb: {
                      red: 5,
                      green: 28,
                      blue: 51,
                    },
                    percentage: 4.53,
                  },
                ],
              },
              ext: {
                mediaStats: {
                  r: "Missing",
                  ttl: -1,
                },
              },
            },
          ],
        },
        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
        in_reply_to_status_id_str: "1276190703786352643",
        in_reply_to_user_id_str: "3017241909",
        in_reply_to_screen_name: "englandcricket",
        user_id_str: "3017241909",
        retweet_count: 12,
        favorite_count: 289,
        reply_count: 7,
        quote_count: 1,
        conversation_id_str: "1276190703786352643",
        possibly_sensitive_editable: true,
        lang: "en",
        self_thread: {
          id_str: "1276190703786352643",
        },
      },
      "1276908252165541893": {
        created_at: "Sat Jun 27 16:00:25 +0000 2020",
        id_str: "1276908252165541893",
        full_text:
          "The Straight Drive 🏏\n\nIt's a beautiful sight 🙌\n\nVote here: https://t.co/hmVD6wUGoE\n\n@EnglandCricket x @BBCSport https://t.co/13cDY4iIQY",
        display_text_range: [0, 111],
        entities: {
          user_mentions: [
            {
              screen_name: "englandcricket",
              name: "England Cricket",
              id_str: "3017241909",
              indices: [84, 99],
            },
            {
              screen_name: "BBCSport",
              name: "BBC Sport",
              id_str: "265902729",
              indices: [102, 111],
            },
          ],
          urls: [
            {
              url: "https://t.co/hmVD6wUGoE",
              expanded_url: "http://ms.spr.ly/6010Tlugs",
              display_url: "ms.spr.ly/6010Tlugs",
              indices: [59, 82],
            },
          ],
          media: [
            {
              id_str: "1276908162881400832",
              indices: [112, 135],
              media_url: "http://pbs.twimg.com/media/Ebh9SMPXYAMjDYA.jpg",
              media_url_https: "https://pbs.twimg.com/media/Ebh9SMPXYAMjDYA.jpg",
              url: "https://t.co/13cDY4iIQY",
              display_url: "pic.twitter.com/13cDY4iIQY",
              expanded_url: "https://twitter.com/englandcricket/status/1276908252165541893/video/1",
              type: "photo",
              original_info: {
                width: 1080,
                height: 1080,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
                large: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
              },
            },
          ],
        },
        extended_entities: {
          media: [
            {
              id_str: "1276908162881400832",
              indices: [112, 135],
              media_url: "http://pbs.twimg.com/media/Ebh9SMPXYAMjDYA.jpg",
              media_url_https: "https://pbs.twimg.com/media/Ebh9SMPXYAMjDYA.jpg",
              url: "https://t.co/13cDY4iIQY",
              display_url: "pic.twitter.com/13cDY4iIQY",
              expanded_url: "https://twitter.com/englandcricket/status/1276908252165541893/video/1",
              type: "video",
              original_info: {
                width: 1080,
                height: 1080,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
                large: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
              },
              video_info: {
                aspect_ratio: [1, 1],
                duration_millis: 50640,
                variants: [
                  {
                    bitrate: 1280000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276908162881400832/vid/720x720/lkaAEtsDPkHmlgPj.mp4?tag=13",
                  },
                  {
                    bitrate: 432000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276908162881400832/vid/320x320/nD9OyclRwNQWzhA-.mp4?tag=13",
                  },
                  {
                    content_type: "application/x-mpegURL",
                    url: "https://video.twimg.com/amplify_video/1276908162881400832/pl/wBZhDexgH3zcvlNf.m3u8?tag=13",
                  },
                  {
                    bitrate: 832000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276908162881400832/vid/480x480/IxuRp4DGLD9Codfy.mp4?tag=13",
                  },
                ],
              },
              media_key: "13_1276908162881400832",
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext_media_color: null,
              ext: {
                mediaStats: {
                  r: {
                    ok: {
                      viewCount: "9423",
                    },
                  },
                  ttl: -1,
                },
              },
              additional_media_info: {
                title: "Ultimate England Cricketer - Straight Drive",
                description: "",
                monetizable: false,
              },
            },
          ],
        },
        source: '<a href="https://prod1.sprinklr.com" rel="nofollow">Sprinklr Publishing</a>',
        user_id_str: "3017241909",
        retweet_count: 31,
        favorite_count: 370,
        reply_count: 20,
        quote_count: 2,
        conversation_id_str: "1276908252165541893",
        possibly_sensitive_editable: true,
        lang: "en",
      },
      "1276190703786352643": {
        created_at: "Thu Jun 25 16:29:08 +0000 2020",
        id_str: "1276190703786352643",
        full_text: "Locks-down 💇‍♂️\n\nHeadbands seem to be the new fashion accessory for our bowlers! https://t.co/YPpIoakQZ0",
        display_text_range: [0, 80],
        entities: {
          media: [
            {
              id_str: "1276190676674318336",
              indices: [81, 104],
              media_url: "http://pbs.twimg.com/media/EbXwqKzWkAAKmuQ.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbXwqKzWkAAKmuQ.jpg",
              url: "https://t.co/YPpIoakQZ0",
              display_url: "pic.twitter.com/YPpIoakQZ0",
              expanded_url: "https://twitter.com/englandcricket/status/1276190703786352643/photo/1",
              type: "photo",
              original_info: {
                width: 973,
                height: 973,
                focus_rects: [
                  {
                    x: 0,
                    y: 0,
                    h: 545,
                    w: 973,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 973,
                    w: 973,
                  },
                  {
                    x: 83,
                    y: 0,
                    h: 973,
                    w: 854,
                  },
                  {
                    x: 267,
                    y: 0,
                    h: 973,
                    w: 487,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 973,
                    w: 973,
                  },
                ],
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                medium: {
                  w: 973,
                  h: 973,
                  resize: "fit",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                large: {
                  w: 973,
                  h: 973,
                  resize: "fit",
                },
              },
              features: {
                medium: {
                  faces: [],
                },
                small: {
                  faces: [],
                },
                orig: {
                  faces: [],
                },
                large: {
                  faces: [],
                },
              },
            },
            {
              id_str: "1276190683691352072",
              indices: [81, 104],
              media_url: "http://pbs.twimg.com/media/EbXwqk8WAAg5ci2.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbXwqk8WAAg5ci2.jpg",
              url: "https://t.co/YPpIoakQZ0",
              display_url: "pic.twitter.com/YPpIoakQZ0",
              expanded_url: "https://twitter.com/englandcricket/status/1276190703786352643/photo/1",
              type: "photo",
              original_info: {
                width: 1024,
                height: 1024,
                focus_rects: [
                  {
                    x: 0,
                    y: 0,
                    h: 573,
                    w: 1024,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                  {
                    x: 88,
                    y: 0,
                    h: 1024,
                    w: 898,
                  },
                  {
                    x: 281,
                    y: 0,
                    h: 1024,
                    w: 512,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                ],
              },
              sizes: {
                medium: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                large: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
              },
              features: {
                medium: {
                  faces: [
                    {
                      x: 459,
                      y: 149,
                      h: 162,
                      w: 162,
                    },
                  ],
                },
                orig: {
                  faces: [
                    {
                      x: 459,
                      y: 149,
                      h: 162,
                      w: 162,
                    },
                  ],
                },
                large: {
                  faces: [
                    {
                      x: 459,
                      y: 149,
                      h: 162,
                      w: 162,
                    },
                  ],
                },
                small: {
                  faces: [
                    {
                      x: 304,
                      y: 98,
                      h: 107,
                      w: 107,
                    },
                  ],
                },
              },
            },
            {
              id_str: "1276190691597697026",
              indices: [81, 104],
              media_url: "http://pbs.twimg.com/media/EbXwrCZXQAIlQTd.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbXwrCZXQAIlQTd.jpg",
              url: "https://t.co/YPpIoakQZ0",
              display_url: "pic.twitter.com/YPpIoakQZ0",
              expanded_url: "https://twitter.com/englandcricket/status/1276190703786352643/photo/1",
              type: "photo",
              original_info: {
                width: 1024,
                height: 1024,
                focus_rects: [
                  {
                    x: 0,
                    y: 0,
                    h: 573,
                    w: 1024,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                  {
                    x: 88,
                    y: 0,
                    h: 1024,
                    w: 898,
                  },
                  {
                    x: 281,
                    y: 0,
                    h: 1024,
                    w: 512,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                ],
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                large: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                medium: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
              },
              features: {
                orig: {
                  faces: [
                    {
                      x: 427,
                      y: 118,
                      h: 185,
                      w: 185,
                    },
                  ],
                },
                large: {
                  faces: [
                    {
                      x: 427,
                      y: 118,
                      h: 185,
                      w: 185,
                    },
                  ],
                },
                medium: {
                  faces: [
                    {
                      x: 427,
                      y: 118,
                      h: 185,
                      w: 185,
                    },
                  ],
                },
                small: {
                  faces: [
                    {
                      x: 283,
                      y: 78,
                      h: 122,
                      w: 122,
                    },
                  ],
                },
              },
            },
            {
              id_str: "1276190699667574784",
              indices: [81, 104],
              media_url: "http://pbs.twimg.com/media/EbXwrgdX0AAclzZ.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbXwrgdX0AAclzZ.jpg",
              url: "https://t.co/YPpIoakQZ0",
              display_url: "pic.twitter.com/YPpIoakQZ0",
              expanded_url: "https://twitter.com/englandcricket/status/1276190703786352643/photo/1",
              type: "photo",
              original_info: {
                width: 1024,
                height: 1024,
                focus_rects: [
                  {
                    x: 0,
                    y: 354,
                    h: 573,
                    w: 1024,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                  {
                    x: 88,
                    y: 0,
                    h: 1024,
                    w: 898,
                  },
                  {
                    x: 281,
                    y: 0,
                    h: 1024,
                    w: 512,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                ],
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                large: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
              },
              features: {
                orig: {
                  faces: [
                    {
                      x: 759,
                      y: 798,
                      h: 55,
                      w: 55,
                    },
                    {
                      x: 385,
                      y: 121,
                      h: 224,
                      w: 224,
                    },
                  ],
                },
                small: {
                  faces: [
                    {
                      x: 504,
                      y: 529,
                      h: 36,
                      w: 36,
                    },
                    {
                      x: 255,
                      y: 80,
                      h: 148,
                      w: 148,
                    },
                  ],
                },
                medium: {
                  faces: [
                    {
                      x: 759,
                      y: 798,
                      h: 55,
                      w: 55,
                    },
                    {
                      x: 385,
                      y: 121,
                      h: 224,
                      w: 224,
                    },
                  ],
                },
                large: {
                  faces: [
                    {
                      x: 759,
                      y: 798,
                      h: 55,
                      w: 55,
                    },
                    {
                      x: 385,
                      y: 121,
                      h: 224,
                      w: 224,
                    },
                  ],
                },
              },
            },
          ],
        },
        extended_entities: {
          media: [
            {
              id_str: "1276190676674318336",
              indices: [81, 104],
              media_url: "http://pbs.twimg.com/media/EbXwqKzWkAAKmuQ.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbXwqKzWkAAKmuQ.jpg",
              url: "https://t.co/YPpIoakQZ0",
              display_url: "pic.twitter.com/YPpIoakQZ0",
              expanded_url: "https://twitter.com/englandcricket/status/1276190703786352643/photo/1",
              type: "photo",
              original_info: {
                width: 973,
                height: 973,
                focus_rects: [
                  {
                    x: 0,
                    y: 0,
                    h: 545,
                    w: 973,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 973,
                    w: 973,
                  },
                  {
                    x: 83,
                    y: 0,
                    h: 973,
                    w: 854,
                  },
                  {
                    x: 267,
                    y: 0,
                    h: 973,
                    w: 487,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 973,
                    w: 973,
                  },
                ],
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                medium: {
                  w: 973,
                  h: 973,
                  resize: "fit",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                large: {
                  w: 973,
                  h: 973,
                  resize: "fit",
                },
              },
              features: {
                medium: {
                  faces: [],
                },
                small: {
                  faces: [],
                },
                orig: {
                  faces: [],
                },
                large: {
                  faces: [],
                },
              },
              media_key: "3_1276190676674318336",
              ext_media_color: {
                palette: [
                  {
                    rgb: {
                      red: 120,
                      green: 134,
                      blue: 47,
                    },
                    percentage: 72.16,
                  },
                  {
                    rgb: {
                      red: 194,
                      green: 149,
                      blue: 125,
                    },
                    percentage: 9.49,
                  },
                  {
                    rgb: {
                      red: 33,
                      green: 45,
                      blue: 62,
                    },
                    percentage: 7.87,
                  },
                  {
                    rgb: {
                      red: 69,
                      green: 66,
                      blue: 34,
                    },
                    percentage: 2.44,
                  },
                  {
                    rgb: {
                      red: 65,
                      green: 34,
                      blue: 52,
                    },
                    percentage: 0.87,
                  },
                ],
              },
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext: {
                mediaStats: {
                  r: "Missing",
                  ttl: -1,
                },
              },
            },
            {
              id_str: "1276190683691352072",
              indices: [81, 104],
              media_url: "http://pbs.twimg.com/media/EbXwqk8WAAg5ci2.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbXwqk8WAAg5ci2.jpg",
              url: "https://t.co/YPpIoakQZ0",
              display_url: "pic.twitter.com/YPpIoakQZ0",
              expanded_url: "https://twitter.com/englandcricket/status/1276190703786352643/photo/1",
              type: "photo",
              original_info: {
                width: 1024,
                height: 1024,
                focus_rects: [
                  {
                    x: 0,
                    y: 0,
                    h: 573,
                    w: 1024,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                  {
                    x: 88,
                    y: 0,
                    h: 1024,
                    w: 898,
                  },
                  {
                    x: 281,
                    y: 0,
                    h: 1024,
                    w: 512,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                ],
              },
              sizes: {
                medium: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                large: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
              },
              features: {
                medium: {
                  faces: [
                    {
                      x: 459,
                      y: 149,
                      h: 162,
                      w: 162,
                    },
                  ],
                },
                orig: {
                  faces: [
                    {
                      x: 459,
                      y: 149,
                      h: 162,
                      w: 162,
                    },
                  ],
                },
                large: {
                  faces: [
                    {
                      x: 459,
                      y: 149,
                      h: 162,
                      w: 162,
                    },
                  ],
                },
                small: {
                  faces: [
                    {
                      x: 304,
                      y: 98,
                      h: 107,
                      w: 107,
                    },
                  ],
                },
              },
              media_key: "3_1276190683691352072",
              ext_media_color: {
                palette: [
                  {
                    rgb: {
                      red: 117,
                      green: 132,
                      blue: 47,
                    },
                    percentage: 49.9,
                  },
                  {
                    rgb: {
                      red: 22,
                      green: 27,
                      blue: 33,
                    },
                    percentage: 18.42,
                  },
                  {
                    rgb: {
                      red: 49,
                      green: 55,
                      blue: 21,
                    },
                    percentage: 6.35,
                  },
                  {
                    rgb: {
                      red: 172,
                      green: 114,
                      blue: 92,
                    },
                    percentage: 5.51,
                  },
                  {
                    rgb: {
                      red: 179,
                      green: 183,
                      blue: 184,
                    },
                    percentage: 3.68,
                  },
                ],
              },
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext: {
                mediaStats: {
                  r: "Missing",
                  ttl: -1,
                },
              },
            },
            {
              id_str: "1276190691597697026",
              indices: [81, 104],
              media_url: "http://pbs.twimg.com/media/EbXwrCZXQAIlQTd.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbXwrCZXQAIlQTd.jpg",
              url: "https://t.co/YPpIoakQZ0",
              display_url: "pic.twitter.com/YPpIoakQZ0",
              expanded_url: "https://twitter.com/englandcricket/status/1276190703786352643/photo/1",
              type: "photo",
              original_info: {
                width: 1024,
                height: 1024,
                focus_rects: [
                  {
                    x: 0,
                    y: 0,
                    h: 573,
                    w: 1024,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                  {
                    x: 88,
                    y: 0,
                    h: 1024,
                    w: 898,
                  },
                  {
                    x: 281,
                    y: 0,
                    h: 1024,
                    w: 512,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                ],
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                large: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                medium: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
              },
              features: {
                orig: {
                  faces: [
                    {
                      x: 427,
                      y: 118,
                      h: 185,
                      w: 185,
                    },
                  ],
                },
                large: {
                  faces: [
                    {
                      x: 427,
                      y: 118,
                      h: 185,
                      w: 185,
                    },
                  ],
                },
                medium: {
                  faces: [
                    {
                      x: 427,
                      y: 118,
                      h: 185,
                      w: 185,
                    },
                  ],
                },
                small: {
                  faces: [
                    {
                      x: 283,
                      y: 78,
                      h: 122,
                      w: 122,
                    },
                  ],
                },
              },
              media_key: "3_1276190691597697026",
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext_media_color: {
                palette: [
                  {
                    rgb: {
                      red: 166,
                      green: 187,
                      blue: 82,
                    },
                    percentage: 33.38,
                  },
                  {
                    rgb: {
                      red: 11,
                      green: 25,
                      blue: 38,
                    },
                    percentage: 24.91,
                  },
                  {
                    rgb: {
                      red: 99,
                      green: 90,
                      blue: 75,
                    },
                    percentage: 12.63,
                  },
                  {
                    rgb: {
                      red: 48,
                      green: 91,
                      blue: 146,
                    },
                    percentage: 6.19,
                  },
                  {
                    rgb: {
                      red: 155,
                      green: 143,
                      blue: 101,
                    },
                    percentage: 5.55,
                  },
                ],
              },
              ext: {
                mediaStats: {
                  r: "Missing",
                  ttl: -1,
                },
              },
            },
            {
              id_str: "1276190699667574784",
              indices: [81, 104],
              media_url: "http://pbs.twimg.com/media/EbXwrgdX0AAclzZ.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbXwrgdX0AAclzZ.jpg",
              url: "https://t.co/YPpIoakQZ0",
              display_url: "pic.twitter.com/YPpIoakQZ0",
              expanded_url: "https://twitter.com/englandcricket/status/1276190703786352643/photo/1",
              type: "photo",
              original_info: {
                width: 1024,
                height: 1024,
                focus_rects: [
                  {
                    x: 0,
                    y: 354,
                    h: 573,
                    w: 1024,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                  {
                    x: 88,
                    y: 0,
                    h: 1024,
                    w: 898,
                  },
                  {
                    x: 281,
                    y: 0,
                    h: 1024,
                    w: 512,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                ],
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                large: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
              },
              features: {
                orig: {
                  faces: [
                    {
                      x: 759,
                      y: 798,
                      h: 55,
                      w: 55,
                    },
                    {
                      x: 385,
                      y: 121,
                      h: 224,
                      w: 224,
                    },
                  ],
                },
                small: {
                  faces: [
                    {
                      x: 504,
                      y: 529,
                      h: 36,
                      w: 36,
                    },
                    {
                      x: 255,
                      y: 80,
                      h: 148,
                      w: 148,
                    },
                  ],
                },
                medium: {
                  faces: [
                    {
                      x: 759,
                      y: 798,
                      h: 55,
                      w: 55,
                    },
                    {
                      x: 385,
                      y: 121,
                      h: 224,
                      w: 224,
                    },
                  ],
                },
                large: {
                  faces: [
                    {
                      x: 759,
                      y: 798,
                      h: 55,
                      w: 55,
                    },
                    {
                      x: 385,
                      y: 121,
                      h: 224,
                      w: 224,
                    },
                  ],
                },
              },
              media_key: "3_1276190699667574784",
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext_media_color: {
                palette: [
                  {
                    rgb: {
                      red: 101,
                      green: 109,
                      blue: 50,
                    },
                    percentage: 28.04,
                  },
                  {
                    rgb: {
                      red: 29,
                      green: 22,
                      blue: 38,
                    },
                    percentage: 22.59,
                  },
                  {
                    rgb: {
                      red: 102,
                      green: 168,
                      blue: 229,
                    },
                    percentage: 15.56,
                  },
                  {
                    rgb: {
                      red: 72,
                      green: 65,
                      blue: 46,
                    },
                    percentage: 8.72,
                  },
                  {
                    rgb: {
                      red: 208,
                      green: 214,
                      blue: 212,
                    },
                    percentage: 6.9,
                  },
                ],
              },
              ext: {
                mediaStats: {
                  r: "Missing",
                  ttl: -1,
                },
              },
            },
          ],
        },
        source: '<a href="https://prod1.sprinklr.com" rel="nofollow">Sprinklr Publishing</a>',
        user_id_str: "3017241909",
        retweet_count: 52,
        favorite_count: 1102,
        reply_count: 26,
        quote_count: 18,
        conversation_id_str: "1276190703786352643",
        possibly_sensitive_editable: true,
        lang: "en",
        self_thread: {
          id_str: "1276190703786352643",
        },
      },
      "1277300835328950272": {
        created_at: "Sun Jun 28 18:00:24 +0000 2020",
        id_str: "1277300835328950272",
        full_text:
          "Who has the best defensive game? ⛔\n\nUltimate England Men's Cricketers: https://t.co/1CBDAtZfWc\n\n@EnglandCricket x @BBCSport https://t.co/MQeVGGRyvD",
        display_text_range: [0, 123],
        entities: {
          user_mentions: [
            {
              screen_name: "englandcricket",
              name: "England Cricket",
              id_str: "3017241909",
              indices: [96, 111],
            },
            {
              screen_name: "BBCSport",
              name: "BBC Sport",
              id_str: "265902729",
              indices: [114, 123],
            },
          ],
          urls: [
            {
              url: "https://t.co/1CBDAtZfWc",
              expanded_url: "http://ms.spr.ly/6010TlwbE",
              display_url: "ms.spr.ly/6010TlwbE",
              indices: [71, 94],
            },
          ],
          media: [
            {
              id_str: "1277300747814699009",
              indices: [124, 147],
              media_url: "http://pbs.twimg.com/media/EbniVjXWsAAMoU3.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbniVjXWsAAMoU3.jpg",
              url: "https://t.co/MQeVGGRyvD",
              display_url: "pic.twitter.com/MQeVGGRyvD",
              expanded_url: "https://twitter.com/englandcricket/status/1277300835328950272/video/1",
              type: "photo",
              original_info: {
                width: 1080,
                height: 1080,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
                large: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
              },
            },
          ],
        },
        extended_entities: {
          media: [
            {
              id_str: "1277300747814699009",
              indices: [124, 147],
              media_url: "http://pbs.twimg.com/media/EbniVjXWsAAMoU3.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbniVjXWsAAMoU3.jpg",
              url: "https://t.co/MQeVGGRyvD",
              display_url: "pic.twitter.com/MQeVGGRyvD",
              expanded_url: "https://twitter.com/englandcricket/status/1277300835328950272/video/1",
              type: "video",
              original_info: {
                width: 1080,
                height: 1080,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
                large: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
              },
              video_info: {
                aspect_ratio: [1, 1],
                duration_millis: 50800,
                variants: [
                  {
                    bitrate: 432000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1277300747814699009/vid/320x320/gaOdWwNn3PIaQzNW.mp4?tag=13",
                  },
                  {
                    bitrate: 1280000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1277300747814699009/vid/720x720/_wl7WSoZp6lJm_fd.mp4?tag=13",
                  },
                  {
                    content_type: "application/x-mpegURL",
                    url: "https://video.twimg.com/amplify_video/1277300747814699009/pl/x3Bog3bOHOUHYFYe.m3u8?tag=13",
                  },
                  {
                    bitrate: 832000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1277300747814699009/vid/480x480/cRg9qGnC7AsXAN22.mp4?tag=13",
                  },
                ],
              },
              media_key: "13_1277300747814699009",
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext_media_color: null,
              ext: {
                mediaStats: {
                  r: {
                    ok: {
                      viewCount: "13849",
                    },
                  },
                  ttl: -1,
                },
              },
              additional_media_info: {
                title: "Ultimate England Men's Cricketers - Defence",
                description: "",
                monetizable: false,
              },
            },
          ],
        },
        source: '<a href="https://prod1.sprinklr.com" rel="nofollow">Sprinklr Publishing</a>',
        user_id_str: "3017241909",
        retweet_count: 25,
        favorite_count: 279,
        reply_count: 36,
        quote_count: 6,
        conversation_id_str: "1277300835328950272",
        possibly_sensitive_editable: true,
        lang: "en",
      },
      "1277542413263872001": {
        created_at: "Mon Jun 29 10:00:21 +0000 2020",
        id_str: "1277542413263872001",
        full_text: "Four @JasonRoy20 boundaries from his 162 against Sri Lanka #OnThisDay in 2016 💪 \n\nWhich shot is your favourite? https://t.co/ChzywWz5ph",
        display_text_range: [0, 111],
        entities: {
          hashtags: [
            {
              text: "OnThisDay",
              indices: [59, 69],
            },
          ],
          user_mentions: [
            {
              screen_name: "JasonRoy20",
              name: "Jason Roy",
              id_str: "343512172",
              indices: [5, 16],
            },
          ],
          media: [
            {
              id_str: "1277542336298455041",
              indices: [112, 135],
              media_url: "http://pbs.twimg.com/media/Ebq-DXNXQAAtFQo.jpg",
              media_url_https: "https://pbs.twimg.com/media/Ebq-DXNXQAAtFQo.jpg",
              url: "https://t.co/ChzywWz5ph",
              display_url: "pic.twitter.com/ChzywWz5ph",
              expanded_url: "https://twitter.com/englandcricket/status/1277542413263872001/video/1",
              type: "photo",
              original_info: {
                width: 720,
                height: 582,
              },
              sizes: {
                medium: {
                  w: 720,
                  h: 582,
                  resize: "fit",
                },
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                large: {
                  w: 720,
                  h: 582,
                  resize: "fit",
                },
                small: {
                  w: 680,
                  h: 550,
                  resize: "fit",
                },
              },
            },
          ],
        },
        extended_entities: {
          media: [
            {
              id_str: "1277542336298455041",
              indices: [112, 135],
              media_url: "http://pbs.twimg.com/media/Ebq-DXNXQAAtFQo.jpg",
              media_url_https: "https://pbs.twimg.com/media/Ebq-DXNXQAAtFQo.jpg",
              url: "https://t.co/ChzywWz5ph",
              display_url: "pic.twitter.com/ChzywWz5ph",
              expanded_url: "https://twitter.com/englandcricket/status/1277542413263872001/video/1",
              type: "video",
              original_info: {
                width: 720,
                height: 582,
              },
              sizes: {
                medium: {
                  w: 720,
                  h: 582,
                  resize: "fit",
                },
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                large: {
                  w: 720,
                  h: 582,
                  resize: "fit",
                },
                small: {
                  w: 680,
                  h: 550,
                  resize: "fit",
                },
              },
              video_info: {
                aspect_ratio: [1, 1],
                duration_millis: 51426,
                variants: [
                  {
                    bitrate: 832000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1277542336298455041/vid/480x480/75qxw07cx5uXFoMt.mp4?tag=13",
                  },
                  {
                    bitrate: 432000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1277542336298455041/vid/320x320/-E8HzPobe0cqU3QG.mp4?tag=13",
                  },
                  {
                    content_type: "application/x-mpegURL",
                    url: "https://video.twimg.com/amplify_video/1277542336298455041/pl/D_A80714xGJMnSEw.m3u8?tag=13",
                  },
                  {
                    bitrate: 1280000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1277542336298455041/vid/720x720/x8gzCXy4BR49jVds.mp4?tag=13",
                  },
                ],
              },
              media_key: "13_1277542336298455041",
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext_media_color: null,
              ext: {
                mediaStats: {
                  r: {
                    ok: {
                      viewCount: "9219",
                    },
                  },
                  ttl: -1,
                },
              },
              additional_media_info: {
                title: "Jason Roy huge hits",
                description: "",
                monetizable: false,
              },
            },
          ],
        },
        source: '<a href="https://prod1.sprinklr.com" rel="nofollow">Sprinklr Publishing</a>',
        user_id_str: "3017241909",
        retweet_count: 24,
        favorite_count: 314,
        reply_count: 10,
        quote_count: 4,
        conversation_id_str: "1277542413263872001",
        possibly_sensitive_editable: true,
        lang: "en",
      },
      "1276501167732862978": {
        created_at: "Fri Jun 26 13:02:49 +0000 2020",
        id_str: "1276501167732862978",
        full_text:
          "Poetry in motion ❤\n\nGood luck choosing just one 🙈\n\nVote here: https://t.co/SEN2U2xH1G\n\n@EnglandCricket x @BBCSport https://t.co/ngC9UR2m1I",
        display_text_range: [0, 114],
        entities: {
          user_mentions: [
            {
              screen_name: "englandcricket",
              name: "England Cricket",
              id_str: "3017241909",
              indices: [87, 102],
            },
            {
              screen_name: "BBCSport",
              name: "BBC Sport",
              id_str: "265902729",
              indices: [105, 114],
            },
          ],
          urls: [
            {
              url: "https://t.co/SEN2U2xH1G",
              expanded_url: "http://ms.spr.ly/6014TluDQ",
              display_url: "ms.spr.ly/6014TluDQ",
              indices: [62, 85],
            },
          ],
          media: [
            {
              id_str: "1276501078301949953",
              indices: [115, 138],
              media_url: "http://pbs.twimg.com/media/EbcLCu1XkAEvzc6.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbcLCu1XkAEvzc6.jpg",
              url: "https://t.co/ngC9UR2m1I",
              display_url: "pic.twitter.com/ngC9UR2m1I",
              expanded_url: "https://twitter.com/englandcricket/status/1276501167732862978/video/1",
              type: "photo",
              original_info: {
                width: 1080,
                height: 1080,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
                large: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
              },
            },
          ],
        },
        extended_entities: {
          media: [
            {
              id_str: "1276501078301949953",
              indices: [115, 138],
              media_url: "http://pbs.twimg.com/media/EbcLCu1XkAEvzc6.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbcLCu1XkAEvzc6.jpg",
              url: "https://t.co/ngC9UR2m1I",
              display_url: "pic.twitter.com/ngC9UR2m1I",
              expanded_url: "https://twitter.com/englandcricket/status/1276501167732862978/video/1",
              type: "video",
              original_info: {
                width: 1080,
                height: 1080,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
                large: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
              },
              video_info: {
                aspect_ratio: [1, 1],
                duration_millis: 46960,
                variants: [
                  {
                    bitrate: 832000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276501078301949953/vid/480x480/hWuT5j6M6tzPc1Xl.mp4?tag=13",
                  },
                  {
                    bitrate: 432000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276501078301949953/vid/320x320/fsu5EXii2nWynqe5.mp4?tag=13",
                  },
                  {
                    content_type: "application/x-mpegURL",
                    url: "https://video.twimg.com/amplify_video/1276501078301949953/pl/WNn9a4yayInTcr6C.m3u8?tag=13",
                  },
                  {
                    bitrate: 1280000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276501078301949953/vid/720x720/BJqn1qsy67IAuxrt.mp4?tag=13",
                  },
                ],
              },
              media_key: "13_1276501078301949953",
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext_media_color: null,
              ext: {
                mediaStats: {
                  r: {
                    ok: {
                      viewCount: "15272",
                    },
                  },
                  ttl: -1,
                },
              },
              additional_media_info: {
                title: "Ultimate England Cricketers - Cover Drive",
                description: "",
                monetizable: false,
              },
            },
          ],
        },
        source: '<a href="https://prod1.sprinklr.com" rel="nofollow">Sprinklr Publishing</a>',
        user_id_str: "3017241909",
        retweet_count: 23,
        favorite_count: 292,
        reply_count: 26,
        quote_count: 7,
        conversation_id_str: "1276501167732862978",
        possibly_sensitive_editable: true,
        lang: "en",
      },
      "1276832743322779648": {
        created_at: "Sat Jun 27 11:00:23 +0000 2020",
        id_str: "1276832743322779648",
        full_text:
          "Three captains past and present 🏴󠁧󠁢󠁥󠁮󠁧󠁿\n\nVote &amp; help us build the Ultimate England Cricketers: https://t.co/SEN2U2xH1G\n\n@EnglandCricket x @BBCSport https://t.co/kFpX3Ypm1P",
        display_text_range: [0, 151],
        entities: {
          user_mentions: [
            {
              screen_name: "englandcricket",
              name: "England Cricket",
              id_str: "3017241909",
              indices: [124, 139],
            },
            {
              screen_name: "BBCSport",
              name: "BBC Sport",
              id_str: "265902729",
              indices: [142, 151],
            },
          ],
          urls: [
            {
              url: "https://t.co/SEN2U2xH1G",
              expanded_url: "http://ms.spr.ly/6014TluDQ",
              display_url: "ms.spr.ly/6014TluDQ",
              indices: [99, 122],
            },
          ],
          media: [
            {
              id_str: "1276832661630324738",
              indices: [152, 175],
              media_url: "http://pbs.twimg.com/media/Ebg4nDEXkAAeEAa.jpg",
              media_url_https: "https://pbs.twimg.com/media/Ebg4nDEXkAAeEAa.jpg",
              url: "https://t.co/kFpX3Ypm1P",
              display_url: "pic.twitter.com/kFpX3Ypm1P",
              expanded_url: "https://twitter.com/englandcricket/status/1276832743322779648/video/1",
              type: "photo",
              original_info: {
                width: 1080,
                height: 1080,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
                large: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
              },
            },
          ],
        },
        extended_entities: {
          media: [
            {
              id_str: "1276832661630324738",
              indices: [152, 175],
              media_url: "http://pbs.twimg.com/media/Ebg4nDEXkAAeEAa.jpg",
              media_url_https: "https://pbs.twimg.com/media/Ebg4nDEXkAAeEAa.jpg",
              url: "https://t.co/kFpX3Ypm1P",
              display_url: "pic.twitter.com/kFpX3Ypm1P",
              expanded_url: "https://twitter.com/englandcricket/status/1276832743322779648/video/1",
              type: "video",
              original_info: {
                width: 1080,
                height: 1080,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
                large: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
              },
              video_info: {
                aspect_ratio: [1, 1],
                duration_millis: 50320,
                variants: [
                  {
                    content_type: "application/x-mpegURL",
                    url: "https://video.twimg.com/amplify_video/1276832661630324738/pl/R0Nvh8Nf4eO3Tl00.m3u8?tag=13",
                  },
                  {
                    bitrate: 1280000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276832661630324738/vid/720x720/IIfjDQ2lsMLRBQlq.mp4?tag=13",
                  },
                  {
                    bitrate: 832000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276832661630324738/vid/480x480/eTG03yCtrFwqxx2B.mp4?tag=13",
                  },
                  {
                    bitrate: 432000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276832661630324738/vid/320x320/90QOCD0fCTdILYS3.mp4?tag=13",
                  },
                ],
              },
              media_key: "13_1276832661630324738",
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext_media_color: null,
              ext: {
                mediaStats: {
                  r: {
                    ok: {
                      viewCount: "5980",
                    },
                  },
                  ttl: -1,
                },
              },
              additional_media_info: {
                title: "Ultimate England Cricketer - Cut",
                description: "",
                monetizable: false,
              },
            },
          ],
        },
        source: '<a href="https://prod1.sprinklr.com" rel="nofollow">Sprinklr Publishing</a>',
        user_id_str: "3017241909",
        retweet_count: 21,
        favorite_count: 140,
        reply_count: 6,
        quote_count: 0,
        conversation_id_str: "1276832743322779648",
        possibly_sensitive_editable: true,
        lang: "en",
      },
      "1276938444867022849": {
        created_at: "Sat Jun 27 18:00:24 +0000 2020",
        id_str: "1276938444867022849",
        full_text: "Power 💪💥\n\nThree of the best 🙌\n\nVote here: https://t.co/DpnkIXYg9I\n\n@EnglandCricket x @BBCSport https://t.co/Meoxbe5RVs",
        display_text_range: [0, 94],
        entities: {
          user_mentions: [
            {
              screen_name: "englandcricket",
              name: "England Cricket",
              id_str: "3017241909",
              indices: [67, 82],
            },
            {
              screen_name: "BBCSport",
              name: "BBC Sport",
              id_str: "265902729",
              indices: [85, 94],
            },
          ],
          urls: [
            {
              url: "https://t.co/DpnkIXYg9I",
              expanded_url: "http://ms.spr.ly/6016TlRTn",
              display_url: "ms.spr.ly/6016TlRTn",
              indices: [42, 65],
            },
          ],
          media: [
            {
              id_str: "1276938360553160704",
              indices: [95, 118],
              media_url: "http://pbs.twimg.com/media/EbiYvoCXsAEr3fE.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbiYvoCXsAEr3fE.jpg",
              url: "https://t.co/Meoxbe5RVs",
              display_url: "pic.twitter.com/Meoxbe5RVs",
              expanded_url: "https://twitter.com/englandcricket/status/1276938444867022849/video/1",
              type: "photo",
              original_info: {
                width: 1080,
                height: 1080,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
                large: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
              },
            },
          ],
        },
        extended_entities: {
          media: [
            {
              id_str: "1276938360553160704",
              indices: [95, 118],
              media_url: "http://pbs.twimg.com/media/EbiYvoCXsAEr3fE.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbiYvoCXsAEr3fE.jpg",
              url: "https://t.co/Meoxbe5RVs",
              display_url: "pic.twitter.com/Meoxbe5RVs",
              expanded_url: "https://twitter.com/englandcricket/status/1276938444867022849/video/1",
              type: "video",
              original_info: {
                width: 1080,
                height: 1080,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
                large: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
              },
              video_info: {
                aspect_ratio: [1, 1],
                duration_millis: 52040,
                variants: [
                  {
                    bitrate: 1280000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276938360553160704/vid/720x720/nhnOT1la85g7gNpS.mp4?tag=13",
                  },
                  {
                    bitrate: 832000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276938360553160704/vid/480x480/yH_KChZwCu01EXmp.mp4?tag=13",
                  },
                  {
                    bitrate: 432000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276938360553160704/vid/320x320/BXZ2qniDpTLCCc1f.mp4?tag=13",
                  },
                  {
                    content_type: "application/x-mpegURL",
                    url: "https://video.twimg.com/amplify_video/1276938360553160704/pl/oCESFGC2T2am42U7.m3u8?tag=13",
                  },
                ],
              },
              media_key: "13_1276938360553160704",
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext_media_color: null,
              ext: {
                mediaStats: {
                  r: {
                    ok: {
                      viewCount: "7912",
                    },
                  },
                  ttl: -1,
                },
              },
              additional_media_info: {
                title: "Ultimate England Cricketers - Power",
                description: "",
                monetizable: false,
              },
            },
          ],
        },
        source: '<a href="https://prod1.sprinklr.com" rel="nofollow">Sprinklr Publishing</a>',
        user_id_str: "3017241909",
        retweet_count: 29,
        favorite_count: 265,
        reply_count: 9,
        quote_count: 2,
        conversation_id_str: "1276938444867022849",
        possibly_sensitive_editable: true,
        lang: "en",
      },
      "1276779799642505217": {
        created_at: "Sat Jun 27 07:30:00 +0000 2020",
        id_str: "1276779799642505217",
        full_text: "Happy birthday @kp24 🎂\n\nWhat was your favourite KP innings in an England shirt? https://t.co/SG8IqHsisK",
        display_text_range: [0, 79],
        entities: {
          user_mentions: [
            {
              screen_name: "KP24",
              name: "Kevin Pietersen🦏",
              id_str: "171117515",
              indices: [15, 20],
            },
          ],
          media: [
            {
              id_str: "1276124427839852544",
              indices: [80, 103],
              media_url: "http://pbs.twimg.com/media/EbW2c3-XYAADLmJ.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbW2c3-XYAADLmJ.jpg",
              url: "https://t.co/SG8IqHsisK",
              display_url: "pic.twitter.com/SG8IqHsisK",
              expanded_url: "https://twitter.com/englandcricket/status/1276779799642505217/video/1",
              type: "photo",
              original_info: {
                width: 1957,
                height: 2200,
              },
              sizes: {
                small: {
                  w: 605,
                  h: 680,
                  resize: "fit",
                },
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                medium: {
                  w: 1067,
                  h: 1200,
                  resize: "fit",
                },
                large: {
                  w: 1822,
                  h: 2048,
                  resize: "fit",
                },
              },
            },
          ],
        },
        extended_entities: {
          media: [
            {
              id_str: "1276124427839852544",
              indices: [80, 103],
              media_url: "http://pbs.twimg.com/media/EbW2c3-XYAADLmJ.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbW2c3-XYAADLmJ.jpg",
              url: "https://t.co/SG8IqHsisK",
              display_url: "pic.twitter.com/SG8IqHsisK",
              expanded_url: "https://twitter.com/englandcricket/status/1276779799642505217/video/1",
              type: "video",
              original_info: {
                width: 1957,
                height: 2200,
              },
              sizes: {
                small: {
                  w: 605,
                  h: 680,
                  resize: "fit",
                },
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                medium: {
                  w: 1067,
                  h: 1200,
                  resize: "fit",
                },
                large: {
                  w: 1822,
                  h: 2048,
                  resize: "fit",
                },
              },
              video_info: {
                aspect_ratio: [427, 240],
                duration_millis: 237233,
                variants: [
                  {
                    bitrate: 288000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276124427839852544/vid/480x270/TxVWqqNBQ-L71yRy.mp4?tag=13",
                  },
                  {
                    content_type: "application/x-mpegURL",
                    url: "https://video.twimg.com/amplify_video/1276124427839852544/pl/l7EG4QwNZ_JuLdgo.m3u8?tag=13",
                  },
                  {
                    bitrate: 2176000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276124427839852544/vid/854x480/oh053ts3R24RDkJv.mp4?tag=13",
                  },
                  {
                    bitrate: 832000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276124427839852544/vid/640x360/2lMM1NHqU7i1UW6B.mp4?tag=13",
                  },
                ],
              },
              media_key: "13_1276124427839852544",
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext_media_color: null,
              ext: {
                mediaStats: {
                  r: {
                    ok: {
                      viewCount: "45379",
                    },
                  },
                  ttl: -1,
                },
              },
              additional_media_info: {
                title: "Kevin Pietersen maiden Test 100",
                description: "",
                embeddable: true,
                monetizable: false,
              },
            },
          ],
        },
        source: '<a href="https://studio.twitter.com" rel="nofollow">Twitter Media Studio</a>',
        user_id_str: "3017241909",
        retweet_count: 196,
        favorite_count: 1619,
        reply_count: 94,
        quote_count: 37,
        conversation_id_str: "1276779799642505217",
        possibly_sensitive_editable: true,
        lang: "en",
      },
      "1276583768912953346": {
        created_at: "Fri Jun 26 18:31:02 +0000 2020",
        id_str: "1276583768912953346",
        full_text: "These two @LFC fans have been wearing their kits all day! 🥳 https://t.co/cBpu9Lx7mT",
        display_text_range: [0, 59],
        entities: {
          user_mentions: [
            {
              screen_name: "LFC",
              name: "Liverpool FC (Premier League Champions 🏆)",
              id_str: "19583545",
              indices: [10, 14],
            },
          ],
          media: [
            {
              id_str: "1276583764622217219",
              indices: [60, 83],
              media_url: "http://pbs.twimg.com/media/EbdWK5hWkAMOnmg.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbdWK5hWkAMOnmg.jpg",
              url: "https://t.co/cBpu9Lx7mT",
              display_url: "pic.twitter.com/cBpu9Lx7mT",
              expanded_url: "https://twitter.com/englandcricket/status/1276583768912953346/photo/1",
              type: "photo",
              original_info: {
                width: 1024,
                height: 1024,
                focus_rects: [
                  {
                    x: 0,
                    y: 0,
                    h: 573,
                    w: 1024,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 898,
                  },
                  {
                    x: 76,
                    y: 0,
                    h: 1024,
                    w: 512,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                ],
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                medium: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                large: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
              },
              features: {
                orig: {
                  faces: [
                    {
                      x: 662,
                      y: 90,
                      h: 93,
                      w: 93,
                    },
                    {
                      x: 280,
                      y: 72,
                      h: 103,
                      w: 103,
                    },
                  ],
                },
                medium: {
                  faces: [
                    {
                      x: 662,
                      y: 90,
                      h: 93,
                      w: 93,
                    },
                    {
                      x: 280,
                      y: 72,
                      h: 103,
                      w: 103,
                    },
                  ],
                },
                large: {
                  faces: [
                    {
                      x: 662,
                      y: 90,
                      h: 93,
                      w: 93,
                    },
                    {
                      x: 280,
                      y: 72,
                      h: 103,
                      w: 103,
                    },
                  ],
                },
                small: {
                  faces: [
                    {
                      x: 439,
                      y: 59,
                      h: 61,
                      w: 61,
                    },
                    {
                      x: 185,
                      y: 47,
                      h: 68,
                      w: 68,
                    },
                  ],
                },
              },
            },
          ],
        },
        extended_entities: {
          media: [
            {
              id_str: "1276583764622217219",
              indices: [60, 83],
              media_url: "http://pbs.twimg.com/media/EbdWK5hWkAMOnmg.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbdWK5hWkAMOnmg.jpg",
              url: "https://t.co/cBpu9Lx7mT",
              display_url: "pic.twitter.com/cBpu9Lx7mT",
              expanded_url: "https://twitter.com/englandcricket/status/1276583768912953346/photo/1",
              type: "photo",
              original_info: {
                width: 1024,
                height: 1024,
                focus_rects: [
                  {
                    x: 0,
                    y: 0,
                    h: 573,
                    w: 1024,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 898,
                  },
                  {
                    x: 76,
                    y: 0,
                    h: 1024,
                    w: 512,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                ],
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                medium: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                large: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
              },
              features: {
                orig: {
                  faces: [
                    {
                      x: 662,
                      y: 90,
                      h: 93,
                      w: 93,
                    },
                    {
                      x: 280,
                      y: 72,
                      h: 103,
                      w: 103,
                    },
                  ],
                },
                medium: {
                  faces: [
                    {
                      x: 662,
                      y: 90,
                      h: 93,
                      w: 93,
                    },
                    {
                      x: 280,
                      y: 72,
                      h: 103,
                      w: 103,
                    },
                  ],
                },
                large: {
                  faces: [
                    {
                      x: 662,
                      y: 90,
                      h: 93,
                      w: 93,
                    },
                    {
                      x: 280,
                      y: 72,
                      h: 103,
                      w: 103,
                    },
                  ],
                },
                small: {
                  faces: [
                    {
                      x: 439,
                      y: 59,
                      h: 61,
                      w: 61,
                    },
                    {
                      x: 185,
                      y: 47,
                      h: 68,
                      w: 68,
                    },
                  ],
                },
              },
              media_key: "3_1276583764622217219",
              ext_media_color: {
                palette: [
                  {
                    rgb: {
                      red: 65,
                      green: 57,
                      blue: 44,
                    },
                    percentage: 30.33,
                  },
                  {
                    rgb: {
                      red: 128,
                      green: 102,
                      blue: 65,
                    },
                    percentage: 26.54,
                  },
                  {
                    rgb: {
                      red: 128,
                      green: 115,
                      blue: 107,
                    },
                    percentage: 14.99,
                  },
                  {
                    rgb: {
                      red: 158,
                      green: 20,
                      blue: 45,
                    },
                    percentage: 5.88,
                  },
                  {
                    rgb: {
                      red: 190,
                      green: 155,
                      blue: 99,
                    },
                    percentage: 5.54,
                  },
                ],
              },
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext: {
                mediaStats: {
                  r: "Missing",
                  ttl: -1,
                },
              },
            },
          ],
        },
        source: '<a href="https://prod1.sprinklr.com" rel="nofollow">Sprinklr Publishing</a>',
        user_id_str: "3017241909",
        retweet_count: 764,
        favorite_count: 9040,
        reply_count: 98,
        quote_count: 40,
        conversation_id_str: "1276583768912953346",
        possibly_sensitive_editable: true,
        lang: "en",
      },
      "1276141747941388289": {
        created_at: "Thu Jun 25 13:14:36 +0000 2020",
        id_str: "1276141747941388289",
        full_text:
          "UPDATE: @JofraArcher has tested negative for COVID-19. He will join the England camp at Ageas Bowl later today and will be free to start training tomorrow with the rest of the group. https://t.co/tLcGA2i3js",
        display_text_range: [0, 182],
        entities: {
          user_mentions: [
            {
              screen_name: "JofraArcher",
              name: "Jofra Archer",
              id_str: "290153781",
              indices: [8, 20],
            },
          ],
          media: [
            {
              id_str: "1276141710880448512",
              indices: [183, 206],
              media_url: "http://pbs.twimg.com/media/EbXEH_AWoAAaA40.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbXEH_AWoAAaA40.jpg",
              url: "https://t.co/tLcGA2i3js",
              display_url: "pic.twitter.com/tLcGA2i3js",
              expanded_url: "https://twitter.com/englandcricket/status/1276141747941388289/photo/1",
              type: "photo",
              original_info: {
                width: 1200,
                height: 1200,
                focus_rects: [
                  {
                    x: 0,
                    y: 0,
                    h: 672,
                    w: 1200,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1200,
                    w: 1200,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1200,
                    w: 1053,
                  },
                  {
                    x: 210,
                    y: 0,
                    h: 1200,
                    w: 600,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1200,
                    w: 1200,
                  },
                ],
              },
              sizes: {
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                large: {
                  w: 1200,
                  h: 1200,
                  resize: "fit",
                },
                medium: {
                  w: 1200,
                  h: 1200,
                  resize: "fit",
                },
              },
              features: {
                small: {
                  faces: [],
                },
                orig: {
                  faces: [],
                },
                large: {
                  faces: [],
                },
                medium: {
                  faces: [],
                },
              },
            },
          ],
        },
        extended_entities: {
          media: [
            {
              id_str: "1276141710880448512",
              indices: [183, 206],
              media_url: "http://pbs.twimg.com/media/EbXEH_AWoAAaA40.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbXEH_AWoAAaA40.jpg",
              url: "https://t.co/tLcGA2i3js",
              display_url: "pic.twitter.com/tLcGA2i3js",
              expanded_url: "https://twitter.com/englandcricket/status/1276141747941388289/photo/1",
              type: "photo",
              original_info: {
                width: 1200,
                height: 1200,
                focus_rects: [
                  {
                    x: 0,
                    y: 0,
                    h: 672,
                    w: 1200,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1200,
                    w: 1200,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1200,
                    w: 1053,
                  },
                  {
                    x: 210,
                    y: 0,
                    h: 1200,
                    w: 600,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1200,
                    w: 1200,
                  },
                ],
              },
              sizes: {
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                large: {
                  w: 1200,
                  h: 1200,
                  resize: "fit",
                },
                medium: {
                  w: 1200,
                  h: 1200,
                  resize: "fit",
                },
              },
              features: {
                small: {
                  faces: [],
                },
                orig: {
                  faces: [],
                },
                large: {
                  faces: [],
                },
                medium: {
                  faces: [],
                },
              },
              media_key: "3_1276141710880448512",
              ext_media_color: {
                palette: [
                  {
                    rgb: {
                      red: 199,
                      green: 209,
                      blue: 220,
                    },
                    percentage: 66.26,
                  },
                  {
                    rgb: {
                      red: 28,
                      green: 32,
                      blue: 38,
                    },
                    percentage: 22.34,
                  },
                  {
                    rgb: {
                      red: 123,
                      green: 102,
                      blue: 102,
                    },
                    percentage: 2.1,
                  },
                  {
                    rgb: {
                      red: 54,
                      green: 76,
                      blue: 107,
                    },
                    percentage: 1.76,
                  },
                  {
                    rgb: {
                      red: 75,
                      green: 55,
                      blue: 42,
                    },
                    percentage: 1.68,
                  },
                ],
              },
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext: {
                mediaStats: {
                  r: "Missing",
                  ttl: -1,
                },
              },
            },
          ],
        },
        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
        in_reply_to_status_id_str: "1275490057399545868",
        in_reply_to_user_id_str: "3017241909",
        in_reply_to_screen_name: "englandcricket",
        user_id_str: "3017241909",
        retweet_count: 27,
        favorite_count: 516,
        reply_count: 9,
        quote_count: 6,
        conversation_id_str: "1275490057399545868",
        possibly_sensitive_editable: true,
        lang: "en",
        self_thread: {
          id_str: "1275490057399545868",
        },
      },
      "1276802557826854918": {
        created_at: "Sat Jun 27 09:00:26 +0000 2020",
        id_str: "1276802557826854918",
        full_text: "Pietersen at his very best 💪 https://t.co/PGeE3rT5t8",
        display_text_range: [0, 28],
        entities: {
          media: [
            {
              id_str: "1276802467016126464",
              indices: [29, 52],
              media_url: "http://pbs.twimg.com/media/EbgdKBmXQAU2fPC.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbgdKBmXQAU2fPC.jpg",
              url: "https://t.co/PGeE3rT5t8",
              display_url: "pic.twitter.com/PGeE3rT5t8",
              expanded_url: "https://twitter.com/englandcricket/status/1276802557826854918/video/1",
              type: "photo",
              original_info: {
                width: 1080,
                height: 1080,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
                large: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
              },
            },
          ],
        },
        extended_entities: {
          media: [
            {
              id_str: "1276802467016126464",
              indices: [29, 52],
              media_url: "http://pbs.twimg.com/media/EbgdKBmXQAU2fPC.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbgdKBmXQAU2fPC.jpg",
              url: "https://t.co/PGeE3rT5t8",
              display_url: "pic.twitter.com/PGeE3rT5t8",
              expanded_url: "https://twitter.com/englandcricket/status/1276802557826854918/video/1",
              type: "video",
              original_info: {
                width: 1080,
                height: 1080,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
                large: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
              },
              video_info: {
                aspect_ratio: [1, 1],
                duration_millis: 59960,
                variants: [
                  {
                    bitrate: 432000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276802467016126464/vid/320x320/Uc5m8FrGsp8hDEX1.mp4?tag=13",
                  },
                  {
                    bitrate: 832000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276802467016126464/vid/480x480/Nb7_bfpfkqb-hu92.mp4?tag=13",
                  },
                  {
                    bitrate: 1280000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276802467016126464/vid/720x720/jzSnW01eis0nQfVm.mp4?tag=13",
                  },
                  {
                    content_type: "application/x-mpegURL",
                    url: "https://video.twimg.com/amplify_video/1276802467016126464/pl/mLw7-G3zdRltYfOc.m3u8?tag=13",
                  },
                ],
              },
              media_key: "13_1276802467016126464",
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext_media_color: null,
              ext: {
                mediaStats: {
                  r: {
                    ok: {
                      viewCount: "235810",
                    },
                  },
                  ttl: -1,
                },
              },
              additional_media_info: {
                title: "Pietersen v India - 2011",
                description: "",
                monetizable: false,
              },
            },
          ],
        },
        source: '<a href="https://prod1.sprinklr.com" rel="nofollow">Sprinklr Publishing</a>',
        user_id_str: "3017241909",
        retweet_count: 179,
        favorite_count: 1826,
        reply_count: 35,
        quote_count: 26,
        conversation_id_str: "1276802557826854918",
        possibly_sensitive_editable: true,
        lang: "en",
      },
      "1275824842575577089": {
        created_at: "Wed Jun 24 16:15:20 +0000 2020",
        id_str: "1275824842575577089",
        full_text: "#OnThisDay in 2018 👀 \n\nAn incredible 💯 from @josbuttler and a 5-0 series victory over Australia 😍 https://t.co/qcG2PAPPT7",
        display_text_range: [0, 97],
        entities: {
          hashtags: [
            {
              text: "OnThisDay",
              indices: [0, 10],
            },
          ],
          user_mentions: [
            {
              screen_name: "josbuttler",
              name: "Jos Buttler",
              id_str: "263800151",
              indices: [44, 55],
            },
          ],
          media: [
            {
              id_str: "1275824766667161601",
              indices: [98, 121],
              media_url: "http://pbs.twimg.com/media/EbSj7ikWsAQOQvb.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbSj7ikWsAQOQvb.jpg",
              url: "https://t.co/qcG2PAPPT7",
              display_url: "pic.twitter.com/qcG2PAPPT7",
              expanded_url: "https://twitter.com/englandcricket/status/1275824842575577089/video/1",
              type: "photo",
              original_info: {
                width: 1080,
                height: 1080,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
                large: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
              },
            },
          ],
        },
        extended_entities: {
          media: [
            {
              id_str: "1275824766667161601",
              indices: [98, 121],
              media_url: "http://pbs.twimg.com/media/EbSj7ikWsAQOQvb.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbSj7ikWsAQOQvb.jpg",
              url: "https://t.co/qcG2PAPPT7",
              display_url: "pic.twitter.com/qcG2PAPPT7",
              expanded_url: "https://twitter.com/englandcricket/status/1275824842575577089/video/1",
              type: "video",
              original_info: {
                width: 1080,
                height: 1080,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
                large: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
              },
              video_info: {
                aspect_ratio: [1, 1],
                duration_millis: 58800,
                variants: [
                  {
                    bitrate: 832000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1275824766667161601/vid/480x480/99_LRG7bHnHApEVo.mp4?tag=13",
                  },
                  {
                    bitrate: 1280000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1275824766667161601/vid/720x720/YmxSD_LGmB0-ODFA.mp4?tag=13",
                  },
                  {
                    bitrate: 432000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1275824766667161601/vid/320x320/YkzV9F2vaSezFrBp.mp4?tag=13",
                  },
                  {
                    content_type: "application/x-mpegURL",
                    url: "https://video.twimg.com/amplify_video/1275824766667161601/pl/OpwMzmge03D0_FZ8.m3u8?tag=13",
                  },
                ],
              },
              media_key: "13_1275824766667161601",
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext_media_color: null,
              ext: {
                mediaStats: {
                  r: {
                    ok: {
                      viewCount: "30293",
                    },
                  },
                  ttl: -1,
                },
              },
              additional_media_info: {
                title: "Jos Buttler Century v Australia at Old Trafford",
                description: "",
                monetizable: false,
              },
            },
          ],
        },
        source: '<a href="https://prod1.sprinklr.com" rel="nofollow">Sprinklr Publishing</a>',
        user_id_str: "3017241909",
        retweet_count: 89,
        favorite_count: 724,
        reply_count: 7,
        quote_count: 6,
        conversation_id_str: "1275824842575577089",
        possibly_sensitive_editable: true,
        lang: "en",
      },
      "1276545853965115392": {
        created_at: "Fri Jun 26 16:00:23 +0000 2020",
        id_str: "1276545853965115392",
        full_text:
          "Style. Dominance. Flair 🏏🛠\n\nVote and help create the Ultimate England Batsman: https://t.co/SEN2U2xH1G\n\n@EnglandCricket x @BBCSport https://t.co/JBqjsFzAyr",
        display_text_range: [0, 131],
        entities: {
          user_mentions: [
            {
              screen_name: "englandcricket",
              name: "England Cricket",
              id_str: "3017241909",
              indices: [104, 119],
            },
            {
              screen_name: "BBCSport",
              name: "BBC Sport",
              id_str: "265902729",
              indices: [122, 131],
            },
          ],
          urls: [
            {
              url: "https://t.co/SEN2U2xH1G",
              expanded_url: "http://ms.spr.ly/6014TluDQ",
              display_url: "ms.spr.ly/6014TluDQ",
              indices: [79, 102],
            },
          ],
          media: [
            {
              id_str: "1276545770494275586",
              indices: [132, 155],
              media_url: "http://pbs.twimg.com/media/Ebczr5pWsAgWIib.jpg",
              media_url_https: "https://pbs.twimg.com/media/Ebczr5pWsAgWIib.jpg",
              url: "https://t.co/JBqjsFzAyr",
              display_url: "pic.twitter.com/JBqjsFzAyr",
              expanded_url: "https://twitter.com/englandcricket/status/1276545853965115392/video/1",
              type: "photo",
              original_info: {
                width: 1080,
                height: 1080,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
                large: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
              },
            },
          ],
        },
        extended_entities: {
          media: [
            {
              id_str: "1276545770494275586",
              indices: [132, 155],
              media_url: "http://pbs.twimg.com/media/Ebczr5pWsAgWIib.jpg",
              media_url_https: "https://pbs.twimg.com/media/Ebczr5pWsAgWIib.jpg",
              url: "https://t.co/JBqjsFzAyr",
              display_url: "pic.twitter.com/JBqjsFzAyr",
              expanded_url: "https://twitter.com/englandcricket/status/1276545853965115392/video/1",
              type: "video",
              original_info: {
                width: 1080,
                height: 1080,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
                large: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
              },
              video_info: {
                aspect_ratio: [1, 1],
                duration_millis: 48520,
                variants: [
                  {
                    bitrate: 1280000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276545770494275586/vid/720x720/Fbl501p5tYB4RSbj.mp4?tag=13",
                  },
                  {
                    bitrate: 832000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276545770494275586/vid/480x480/T259BS2ietATjCZr.mp4?tag=13",
                  },
                  {
                    bitrate: 432000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276545770494275586/vid/320x320/o-2ts-Utqe7nhBJk.mp4?tag=13",
                  },
                  {
                    content_type: "application/x-mpegURL",
                    url: "https://video.twimg.com/amplify_video/1276545770494275586/pl/L_uQXfPoP0H7P-K_.m3u8?tag=13",
                  },
                ],
              },
              media_key: "13_1276545770494275586",
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext_media_color: null,
              ext: {
                mediaStats: {
                  r: {
                    ok: {
                      viewCount: "6986",
                    },
                  },
                  ttl: -1,
                },
              },
              additional_media_info: {
                title: "Ultimate England Cricketer - Pull",
                description: "",
                monetizable: false,
              },
            },
          ],
        },
        source: '<a href="https://prod1.sprinklr.com" rel="nofollow">Sprinklr Publishing</a>',
        user_id_str: "3017241909",
        retweet_count: 20,
        favorite_count: 167,
        reply_count: 7,
        quote_count: 3,
        conversation_id_str: "1276545853965115392",
        possibly_sensitive_editable: true,
        lang: "en",
      },
      "1275839934641983488": {
        created_at: "Wed Jun 24 17:15:19 +0000 2020",
        id_str: "1275839934641983488",
        full_text: "The skipper is back in the nets, and she looks in great form 🔥 https://t.co/pcEfQZYeao",
        display_text_range: [0, 62],
        entities: {
          media: [
            {
              id_str: "1275839863997304832",
              indices: [63, 86],
              media_url: "http://pbs.twimg.com/media/EbSxp30XYAkdX0C.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbSxp30XYAkdX0C.jpg",
              url: "https://t.co/pcEfQZYeao",
              display_url: "pic.twitter.com/pcEfQZYeao",
              expanded_url: "https://twitter.com/englandcricket/status/1275839934641983488/video/1",
              type: "photo",
              original_info: {
                width: 1080,
                height: 1080,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
                large: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
              },
            },
          ],
        },
        extended_entities: {
          media: [
            {
              id_str: "1275839863997304832",
              indices: [63, 86],
              media_url: "http://pbs.twimg.com/media/EbSxp30XYAkdX0C.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbSxp30XYAkdX0C.jpg",
              url: "https://t.co/pcEfQZYeao",
              display_url: "pic.twitter.com/pcEfQZYeao",
              expanded_url: "https://twitter.com/englandcricket/status/1275839934641983488/video/1",
              type: "video",
              original_info: {
                width: 1080,
                height: 1080,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
                large: {
                  w: 1080,
                  h: 1080,
                  resize: "fit",
                },
              },
              video_info: {
                aspect_ratio: [1, 1],
                duration_millis: 31700,
                variants: [
                  {
                    bitrate: 432000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1275839863997304832/vid/320x320/zigCq1ppuzYE9HDg.mp4?tag=13",
                  },
                  {
                    bitrate: 1280000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1275839863997304832/vid/720x720/rXFXuPK9xB83ZeET.mp4?tag=13",
                  },
                  {
                    content_type: "application/x-mpegURL",
                    url: "https://video.twimg.com/amplify_video/1275839863997304832/pl/CexVL0foKISvvFHR.m3u8?tag=13",
                  },
                  {
                    bitrate: 832000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1275839863997304832/vid/480x480/tT9dCwBb1IsReIWo.mp4?tag=13",
                  },
                ],
              },
              media_key: "13_1275839863997304832",
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext_media_color: null,
              ext: {
                mediaStats: {
                  r: {
                    ok: {
                      viewCount: "28696",
                    },
                  },
                  ttl: -1,
                },
              },
              additional_media_info: {
                title: "Heather Knight returns to the nets",
                description: "",
                monetizable: false,
              },
            },
          ],
        },
        source: '<a href="https://prod1.sprinklr.com" rel="nofollow">Sprinklr Publishing</a>',
        user_id_str: "3017241909",
        retweet_count: 57,
        favorite_count: 853,
        reply_count: 13,
        quote_count: 5,
        conversation_id_str: "1275839934641983488",
        possibly_sensitive_editable: true,
        lang: "en",
      },
      "1276047753244741638": {
        created_at: "Thu Jun 25 07:01:06 +0000 2020",
        id_str: "1276047753244741638",
        full_text: "Life in the bubble begins 🧪😷 https://t.co/xwisWunmu4",
        display_text_range: [0, 28],
        entities: {
          media: [
            {
              id_str: "1276047730696126465",
              indices: [29, 52],
              media_url: "http://pbs.twimg.com/media/EbVupniWAAEGgSs.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbVupniWAAEGgSs.jpg",
              url: "https://t.co/xwisWunmu4",
              display_url: "pic.twitter.com/xwisWunmu4",
              expanded_url: "https://twitter.com/englandcricket/status/1276047753244741638/photo/1",
              type: "photo",
              original_info: {
                width: 1024,
                height: 1024,
                focus_rects: [
                  {
                    x: 0,
                    y: 46,
                    h: 573,
                    w: 1024,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 898,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 512,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                ],
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                large: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                medium: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
              },
              features: {
                small: {
                  faces: [
                    {
                      x: 458,
                      y: 525,
                      h: 33,
                      w: 33,
                    },
                    {
                      x: 336,
                      y: 110,
                      h: 87,
                      w: 87,
                    },
                  ],
                },
                large: {
                  faces: [
                    {
                      x: 691,
                      y: 791,
                      h: 51,
                      w: 51,
                    },
                    {
                      x: 506,
                      y: 167,
                      h: 132,
                      w: 132,
                    },
                  ],
                },
                orig: {
                  faces: [
                    {
                      x: 691,
                      y: 791,
                      h: 51,
                      w: 51,
                    },
                    {
                      x: 506,
                      y: 167,
                      h: 132,
                      w: 132,
                    },
                  ],
                },
                medium: {
                  faces: [
                    {
                      x: 691,
                      y: 791,
                      h: 51,
                      w: 51,
                    },
                    {
                      x: 506,
                      y: 167,
                      h: 132,
                      w: 132,
                    },
                  ],
                },
              },
            },
            {
              id_str: "1276047736996089856",
              indices: [29, 52],
              media_url: "http://pbs.twimg.com/media/EbVup_AX0AA8voE.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbVup_AX0AA8voE.jpg",
              url: "https://t.co/xwisWunmu4",
              display_url: "pic.twitter.com/xwisWunmu4",
              expanded_url: "https://twitter.com/englandcricket/status/1276047753244741638/photo/1",
              type: "photo",
              original_info: {
                width: 1024,
                height: 1024,
                focus_rects: [
                  {
                    x: 0,
                    y: 0,
                    h: 573,
                    w: 1024,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 898,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 512,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                ],
              },
              sizes: {
                large: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
              },
              features: {
                large: {
                  faces: [
                    {
                      x: 478,
                      y: 105,
                      h: 147,
                      w: 147,
                    },
                  ],
                },
                small: {
                  faces: [
                    {
                      x: 317,
                      y: 69,
                      h: 97,
                      w: 97,
                    },
                  ],
                },
                orig: {
                  faces: [
                    {
                      x: 478,
                      y: 105,
                      h: 147,
                      w: 147,
                    },
                  ],
                },
                medium: {
                  faces: [
                    {
                      x: 478,
                      y: 105,
                      h: 147,
                      w: 147,
                    },
                  ],
                },
              },
            },
            {
              id_str: "1276047743195271168",
              indices: [29, 52],
              media_url: "http://pbs.twimg.com/media/EbVuqWGX0AAwagb.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbVuqWGX0AAwagb.jpg",
              url: "https://t.co/xwisWunmu4",
              display_url: "pic.twitter.com/xwisWunmu4",
              expanded_url: "https://twitter.com/englandcricket/status/1276047753244741638/photo/1",
              type: "photo",
              original_info: {
                width: 1024,
                height: 1024,
                focus_rects: [
                  {
                    x: 0,
                    y: 149,
                    h: 573,
                    w: 1024,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                  {
                    x: 88,
                    y: 0,
                    h: 1024,
                    w: 898,
                  },
                  {
                    x: 281,
                    y: 0,
                    h: 1024,
                    w: 512,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                ],
              },
              sizes: {
                large: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
              },
              features: {
                large: {
                  faces: [
                    {
                      x: 475,
                      y: 283,
                      h: 147,
                      w: 147,
                    },
                  ],
                },
                orig: {
                  faces: [
                    {
                      x: 475,
                      y: 283,
                      h: 147,
                      w: 147,
                    },
                  ],
                },
                small: {
                  faces: [
                    {
                      x: 315,
                      y: 187,
                      h: 97,
                      w: 97,
                    },
                  ],
                },
                medium: {
                  faces: [
                    {
                      x: 475,
                      y: 283,
                      h: 147,
                      w: 147,
                    },
                  ],
                },
              },
            },
            {
              id_str: "1276047749763432448",
              indices: [29, 52],
              media_url: "http://pbs.twimg.com/media/EbVuqukWAAAEWpG.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbVuqukWAAAEWpG.jpg",
              url: "https://t.co/xwisWunmu4",
              display_url: "pic.twitter.com/xwisWunmu4",
              expanded_url: "https://twitter.com/englandcricket/status/1276047753244741638/photo/1",
              type: "photo",
              original_info: {
                width: 1024,
                height: 1024,
                focus_rects: [
                  {
                    x: 0,
                    y: 46,
                    h: 573,
                    w: 1024,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                  {
                    x: 126,
                    y: 0,
                    h: 1024,
                    w: 898,
                  },
                  {
                    x: 332,
                    y: 0,
                    h: 1024,
                    w: 512,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                ],
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                medium: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                large: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
              },
              features: {
                medium: {
                  faces: [
                    {
                      x: 469,
                      y: 242,
                      h: 205,
                      w: 205,
                    },
                  ],
                },
                large: {
                  faces: [
                    {
                      x: 469,
                      y: 242,
                      h: 205,
                      w: 205,
                    },
                  ],
                },
                small: {
                  faces: [
                    {
                      x: 311,
                      y: 160,
                      h: 136,
                      w: 136,
                    },
                  ],
                },
                orig: {
                  faces: [
                    {
                      x: 469,
                      y: 242,
                      h: 205,
                      w: 205,
                    },
                  ],
                },
              },
            },
          ],
        },
        extended_entities: {
          media: [
            {
              id_str: "1276047730696126465",
              indices: [29, 52],
              media_url: "http://pbs.twimg.com/media/EbVupniWAAEGgSs.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbVupniWAAEGgSs.jpg",
              url: "https://t.co/xwisWunmu4",
              display_url: "pic.twitter.com/xwisWunmu4",
              expanded_url: "https://twitter.com/englandcricket/status/1276047753244741638/photo/1",
              type: "photo",
              original_info: {
                width: 1024,
                height: 1024,
                focus_rects: [
                  {
                    x: 0,
                    y: 46,
                    h: 573,
                    w: 1024,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 898,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 512,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                ],
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                large: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                medium: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
              },
              features: {
                small: {
                  faces: [
                    {
                      x: 458,
                      y: 525,
                      h: 33,
                      w: 33,
                    },
                    {
                      x: 336,
                      y: 110,
                      h: 87,
                      w: 87,
                    },
                  ],
                },
                large: {
                  faces: [
                    {
                      x: 691,
                      y: 791,
                      h: 51,
                      w: 51,
                    },
                    {
                      x: 506,
                      y: 167,
                      h: 132,
                      w: 132,
                    },
                  ],
                },
                orig: {
                  faces: [
                    {
                      x: 691,
                      y: 791,
                      h: 51,
                      w: 51,
                    },
                    {
                      x: 506,
                      y: 167,
                      h: 132,
                      w: 132,
                    },
                  ],
                },
                medium: {
                  faces: [
                    {
                      x: 691,
                      y: 791,
                      h: 51,
                      w: 51,
                    },
                    {
                      x: 506,
                      y: 167,
                      h: 132,
                      w: 132,
                    },
                  ],
                },
              },
              media_key: "3_1276047730696126465",
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext_media_color: {
                palette: [
                  {
                    rgb: {
                      red: 55,
                      green: 60,
                      blue: 54,
                    },
                    percentage: 36.46,
                  },
                  {
                    rgb: {
                      red: 136,
                      green: 120,
                      blue: 107,
                    },
                    percentage: 23.15,
                  },
                  {
                    rgb: {
                      red: 149,
                      green: 173,
                      blue: 201,
                    },
                    percentage: 20.02,
                  },
                  {
                    rgb: {
                      red: 90,
                      green: 62,
                      blue: 48,
                    },
                    percentage: 2.58,
                  },
                  {
                    rgb: {
                      red: 142,
                      green: 185,
                      blue: 228,
                    },
                    percentage: 2.13,
                  },
                ],
              },
              ext: {
                mediaStats: {
                  r: "Missing",
                  ttl: -1,
                },
              },
            },
            {
              id_str: "1276047736996089856",
              indices: [29, 52],
              media_url: "http://pbs.twimg.com/media/EbVup_AX0AA8voE.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbVup_AX0AA8voE.jpg",
              url: "https://t.co/xwisWunmu4",
              display_url: "pic.twitter.com/xwisWunmu4",
              expanded_url: "https://twitter.com/englandcricket/status/1276047753244741638/photo/1",
              type: "photo",
              original_info: {
                width: 1024,
                height: 1024,
                focus_rects: [
                  {
                    x: 0,
                    y: 0,
                    h: 573,
                    w: 1024,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 898,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 512,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                ],
              },
              sizes: {
                large: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
              },
              features: {
                large: {
                  faces: [
                    {
                      x: 478,
                      y: 105,
                      h: 147,
                      w: 147,
                    },
                  ],
                },
                small: {
                  faces: [
                    {
                      x: 317,
                      y: 69,
                      h: 97,
                      w: 97,
                    },
                  ],
                },
                orig: {
                  faces: [
                    {
                      x: 478,
                      y: 105,
                      h: 147,
                      w: 147,
                    },
                  ],
                },
                medium: {
                  faces: [
                    {
                      x: 478,
                      y: 105,
                      h: 147,
                      w: 147,
                    },
                  ],
                },
              },
              media_key: "3_1276047736996089856",
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext_media_color: {
                palette: [
                  {
                    rgb: {
                      red: 40,
                      green: 46,
                      blue: 46,
                    },
                    percentage: 31.81,
                  },
                  {
                    rgb: {
                      red: 134,
                      green: 163,
                      blue: 197,
                    },
                    percentage: 24.76,
                  },
                  {
                    rgb: {
                      red: 142,
                      green: 122,
                      blue: 113,
                    },
                    percentage: 22.28,
                  },
                  {
                    rgb: {
                      red: 88,
                      green: 61,
                      blue: 50,
                    },
                    percentage: 3.53,
                  },
                  {
                    rgb: {
                      red: 28,
                      green: 32,
                      blue: 57,
                    },
                    percentage: 2.03,
                  },
                ],
              },
              ext: {
                mediaStats: {
                  r: "Missing",
                  ttl: -1,
                },
              },
            },
            {
              id_str: "1276047743195271168",
              indices: [29, 52],
              media_url: "http://pbs.twimg.com/media/EbVuqWGX0AAwagb.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbVuqWGX0AAwagb.jpg",
              url: "https://t.co/xwisWunmu4",
              display_url: "pic.twitter.com/xwisWunmu4",
              expanded_url: "https://twitter.com/englandcricket/status/1276047753244741638/photo/1",
              type: "photo",
              original_info: {
                width: 1024,
                height: 1024,
                focus_rects: [
                  {
                    x: 0,
                    y: 149,
                    h: 573,
                    w: 1024,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                  {
                    x: 88,
                    y: 0,
                    h: 1024,
                    w: 898,
                  },
                  {
                    x: 281,
                    y: 0,
                    h: 1024,
                    w: 512,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                ],
              },
              sizes: {
                large: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
              },
              features: {
                large: {
                  faces: [
                    {
                      x: 475,
                      y: 283,
                      h: 147,
                      w: 147,
                    },
                  ],
                },
                orig: {
                  faces: [
                    {
                      x: 475,
                      y: 283,
                      h: 147,
                      w: 147,
                    },
                  ],
                },
                small: {
                  faces: [
                    {
                      x: 315,
                      y: 187,
                      h: 97,
                      w: 97,
                    },
                  ],
                },
                medium: {
                  faces: [
                    {
                      x: 475,
                      y: 283,
                      h: 147,
                      w: 147,
                    },
                  ],
                },
              },
              media_key: "3_1276047743195271168",
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext_media_color: {
                palette: [
                  {
                    rgb: {
                      red: 83,
                      green: 82,
                      blue: 80,
                    },
                    percentage: 35.71,
                  },
                  {
                    rgb: {
                      red: 133,
                      green: 167,
                      blue: 205,
                    },
                    percentage: 28.77,
                  },
                  {
                    rgb: {
                      red: 147,
                      green: 136,
                      blue: 130,
                    },
                    percentage: 18.26,
                  },
                  {
                    rgb: {
                      red: 124,
                      green: 89,
                      blue: 113,
                    },
                    percentage: 6.33,
                  },
                  {
                    rgb: {
                      red: 102,
                      green: 70,
                      blue: 55,
                    },
                    percentage: 3.69,
                  },
                ],
              },
              ext: {
                mediaStats: {
                  r: "Missing",
                  ttl: -1,
                },
              },
            },
            {
              id_str: "1276047749763432448",
              indices: [29, 52],
              media_url: "http://pbs.twimg.com/media/EbVuqukWAAAEWpG.jpg",
              media_url_https: "https://pbs.twimg.com/media/EbVuqukWAAAEWpG.jpg",
              url: "https://t.co/xwisWunmu4",
              display_url: "pic.twitter.com/xwisWunmu4",
              expanded_url: "https://twitter.com/englandcricket/status/1276047753244741638/photo/1",
              type: "photo",
              original_info: {
                width: 1024,
                height: 1024,
                focus_rects: [
                  {
                    x: 0,
                    y: 46,
                    h: 573,
                    w: 1024,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                  {
                    x: 126,
                    y: 0,
                    h: 1024,
                    w: 898,
                  },
                  {
                    x: 332,
                    y: 0,
                    h: 1024,
                    w: 512,
                  },
                  {
                    x: 0,
                    y: 0,
                    h: 1024,
                    w: 1024,
                  },
                ],
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                medium: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                large: {
                  w: 1024,
                  h: 1024,
                  resize: "fit",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
              },
              features: {
                medium: {
                  faces: [
                    {
                      x: 469,
                      y: 242,
                      h: 205,
                      w: 205,
                    },
                  ],
                },
                large: {
                  faces: [
                    {
                      x: 469,
                      y: 242,
                      h: 205,
                      w: 205,
                    },
                  ],
                },
                small: {
                  faces: [
                    {
                      x: 311,
                      y: 160,
                      h: 136,
                      w: 136,
                    },
                  ],
                },
                orig: {
                  faces: [
                    {
                      x: 469,
                      y: 242,
                      h: 205,
                      w: 205,
                    },
                  ],
                },
              },
              media_key: "3_1276047749763432448",
              ext_media_availability: {
                status: "available",
              },
              ext_media_color: {
                palette: [
                  {
                    rgb: {
                      red: 139,
                      green: 171,
                      blue: 194,
                    },
                    percentage: 31.57,
                  },
                  {
                    rgb: {
                      red: 45,
                      green: 54,
                      blue: 51,
                    },
                    percentage: 25.49,
                  },
                  {
                    rgb: {
                      red: 139,
                      green: 127,
                      blue: 113,
                    },
                    percentage: 18.78,
                  },
                  {
                    rgb: {
                      red: 92,
                      green: 64,
                      blue: 50,
                    },
                    percentage: 2.22,
                  },
                  {
                    rgb: {
                      red: 204,
                      green: 211,
                      blue: 217,
                    },
                    percentage: 1.51,
                  },
                ],
              },
              ext_alt_text: null,
              ext: {
                mediaStats: {
                  r: "Missing",
                  ttl: -1,
                },
              },
            },
          ],
        },
        source: '<a href="https://prod1.sprinklr.com" rel="nofollow">Sprinklr Publishing</a>',
        user_id_str: "3017241909",
        retweet_count: 307,
        favorite_count: 5195,
        reply_count: 101,
        quote_count: 91,
        conversation_id_str: "1276047753244741638",
        possibly_sensitive_editable: true,
        lang: "en",
      },
      "1276477533337399297": {
        created_at: "Fri Jun 26 11:28:54 +0000 2020",
        id_str: "1276477533337399297",
        full_text:
          "Cover Drive, Pull, Cut, Defence, Power, Innovation, Straight Drive and Off the Pads 🛠🏏\n\nThe Batting nominees are being revealed...\n\nFollow ➡ https://t.co/5CS9aMnoox https://t.co/oVQ5CLS1sW",
        display_text_range: [0, 164],
        entities: {
          urls: [
            {
              url: "https://t.co/5CS9aMnoox",
              expanded_url: "http://ms.spr.ly/6017TlPtx",
              display_url: "ms.spr.ly/6017TlPtx",
              indices: [141, 164],
            },
          ],
          media: [
            {
              id_str: "1276477457026224129",
              indices: [165, 188],
              media_url: "http://pbs.twimg.com/media/Ebb1jL9X0AEa-yy.jpg",
              media_url_https: "https://pbs.twimg.com/media/Ebb1jL9X0AEa-yy.jpg",
              url: "https://t.co/oVQ5CLS1sW",
              display_url: "pic.twitter.com/oVQ5CLS1sW",
              expanded_url: "https://twitter.com/englandcricket/status/1276477533337399297/video/1",
              type: "photo",
              original_info: {
                width: 720,
                height: 720,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 720,
                  h: 720,
                  resize: "fit",
                },
                large: {
                  w: 720,
                  h: 720,
                  resize: "fit",
                },
              },
            },
          ],
        },
        extended_entities: {
          media: [
            {
              id_str: "1276477457026224129",
              indices: [165, 188],
              media_url: "http://pbs.twimg.com/media/Ebb1jL9X0AEa-yy.jpg",
              media_url_https: "https://pbs.twimg.com/media/Ebb1jL9X0AEa-yy.jpg",
              url: "https://t.co/oVQ5CLS1sW",
              display_url: "pic.twitter.com/oVQ5CLS1sW",
              expanded_url: "https://twitter.com/englandcricket/status/1276477533337399297/video/1",
              type: "video",
              original_info: {
                width: 720,
                height: 720,
              },
              sizes: {
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                medium: {
                  w: 720,
                  h: 720,
                  resize: "fit",
                },
                large: {
                  w: 720,
                  h: 720,
                  resize: "fit",
                },
              },
              video_info: {
                aspect_ratio: [1, 1],
                duration_millis: 60000,
                variants: [
                  {
                    bitrate: 432000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276477457026224129/vid/320x320/myoTda3z8CGmQ0Zd.mp4?tag=13",
                  },
                  {
                    content_type: "application/x-mpegURL",
                    url: "https://video.twimg.com/amplify_video/1276477457026224129/pl/2GqqJFdtNVc2MNB5.m3u8?tag=13",
                  },
                  {
                    bitrate: 1280000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276477457026224129/vid/720x720/-TFNEuqG2kgvIrCZ.mp4?tag=13",
                  },
                  {
                    bitrate: 832000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276477457026224129/vid/480x480/YEXqYNxxCjlL2qo1.mp4?tag=13",
                  },
                ],
              },
              media_key: "13_1276477457026224129",
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext_media_color: null,
              ext: {
                mediaStats: {
                  r: {
                    ok: {
                      viewCount: "7761",
                    },
                  },
                  ttl: -1,
                },
              },
              additional_media_info: {
                title: "The Ultimate England Cricketers",
                description: "",
                monetizable: false,
              },
            },
          ],
        },
        source: '<a href="https://prod1.sprinklr.com" rel="nofollow">Sprinklr Publishing</a>',
        user_id_str: "3017241909",
        retweet_count: 34,
        favorite_count: 221,
        reply_count: 5,
        quote_count: 2,
        conversation_id_str: "1276477533337399297",
        possibly_sensitive_editable: true,
        lang: "en",
      },
      "1276817641492156416": {
        created_at: "Sat Jun 27 10:00:22 +0000 2020",
        id_str: "1276817641492156416",
        full_text: "An incredible innings 🔥 https://t.co/lmOQDJJqWz",
        display_text_range: [0, 23],
        entities: {
          media: [
            {
              id_str: "1276817562446299137",
              indices: [24, 47],
              media_url: "http://pbs.twimg.com/media/Ebgq3_MWsAEWsN3.jpg",
              media_url_https: "https://pbs.twimg.com/media/Ebgq3_MWsAEWsN3.jpg",
              url: "https://t.co/lmOQDJJqWz",
              display_url: "pic.twitter.com/lmOQDJJqWz",
              expanded_url: "https://twitter.com/englandcricket/status/1276817641492156416/video/1",
              type: "photo",
              original_info: {
                width: 932,
                height: 932,
              },
              sizes: {
                medium: {
                  w: 932,
                  h: 932,
                  resize: "fit",
                },
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                large: {
                  w: 932,
                  h: 932,
                  resize: "fit",
                },
              },
            },
          ],
        },
        extended_entities: {
          media: [
            {
              id_str: "1276817562446299137",
              indices: [24, 47],
              media_url: "http://pbs.twimg.com/media/Ebgq3_MWsAEWsN3.jpg",
              media_url_https: "https://pbs.twimg.com/media/Ebgq3_MWsAEWsN3.jpg",
              url: "https://t.co/lmOQDJJqWz",
              display_url: "pic.twitter.com/lmOQDJJqWz",
              expanded_url: "https://twitter.com/englandcricket/status/1276817641492156416/video/1",
              type: "video",
              original_info: {
                width: 932,
                height: 932,
              },
              sizes: {
                medium: {
                  w: 932,
                  h: 932,
                  resize: "fit",
                },
                thumb: {
                  w: 150,
                  h: 150,
                  resize: "crop",
                },
                small: {
                  w: 680,
                  h: 680,
                  resize: "fit",
                },
                large: {
                  w: 932,
                  h: 932,
                  resize: "fit",
                },
              },
              video_info: {
                aspect_ratio: [1, 1],
                duration_millis: 36600,
                variants: [
                  {
                    content_type: "application/x-mpegURL",
                    url: "https://video.twimg.com/amplify_video/1276817562446299137/pl/3N2VF3kS9PTDSLQm.m3u8?tag=13",
                  },
                  {
                    bitrate: 432000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276817562446299137/vid/320x320/L4K8UmeQ7D9OmsZQ.mp4?tag=13",
                  },
                  {
                    bitrate: 1280000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276817562446299137/vid/720x720/mt2kXwElFnSNXDGm.mp4?tag=13",
                  },
                  {
                    bitrate: 832000,
                    content_type: "video/mp4",
                    url: "https://video.twimg.com/amplify_video/1276817562446299137/vid/480x480/F1m_NjxiuFVLbw-k.mp4?tag=13",
                  },
                ],
              },
              media_key: "13_1276817562446299137",
              ext_media_availability: {
                status: "available",
              },
              ext_alt_text: null,
              ext_media_color: null,
              ext: {
                mediaStats: {
                  r: {
                    ok: {
                      viewCount: "200876",
                    },
                  },
                  ttl: -1,
                },
              },
              additional_media_info: {
                title: "Pietersen v South Africa - 2012",
                description: "",
                monetizable: false,
              },
            },
          ],
        },
        source: '<a href="https://prod1.sprinklr.com" rel="nofollow">Sprinklr Publishing</a>',
        user_id_str: "3017241909",
        retweet_count: 204,
        favorite_count: 2340,
        reply_count: 43,
        quote_count: 59,
        conversation_id_str: "1276817641492156416",
        possibly_sensitive_editable: true,
        lang: "en",
      },
    },
    users: {
      "3017241909": {
        id_str: "3017241909",
        name: "England Cricket",
        screen_name: "englandcricket",
        location: "",
        description: "We Are England Cricket. The official home of all of the England Cricket teams on Twitter.",
        url: "https://t.co/nnNwhuJK8R",
        entities: {
          url: {
            urls: [
              {
                url: "https://t.co/nnNwhuJK8R",
                expanded_url: "http://www.ecb.co.uk",
                display_url: "ecb.co.uk",
                indices: [0, 23],
              },
            ],
          },
          description: {},
        },
        followers_count: 856359,
        fast_followers_count: 0,
        normal_followers_count: 856359,
        friends_count: 131,
        listed_count: 1228,
        created_at: "Wed Feb 04 11:51:07 +0000 2015",
        favourites_count: 3198,
        geo_enabled: true,
        verified: true,
        statuses_count: 23120,
        media_count: 17544,
        profile_image_url_https: "https://pbs.twimg.com/profile_images/1273527775908020224/UeGQEmh3_normal.jpg",
        profile_banner_url: "https://pbs.twimg.com/profile_banners/3017241909/1590999614",
        profile_image_extensions_alt_text: null,
        profile_image_extensions_media_availability: null,
        profile_image_extensions_media_color: {
          palette: [
            {
              rgb: {
                red: 228,
                green: 6,
                blue: 21,
              },
              percentage: 13.09,
            },
            {
              rgb: {
                red: 247,
                green: 248,
                blue: 240,
              },
              percentage: 12.46,
            },
            {
              rgb: {
                red: 215,
                green: 11,
                blue: 82,
              },
              percentage: 12.3,
            },
            {
              rgb: {
                red: 254,
                green: 233,
                blue: 20,
              },
              percentage: 11,
            },
            {
              rgb: {
                red: 149,
                green: 193,
                blue: 34,
              },
              percentage: 10.24,
            },
          ],
        },
        profile_image_extensions: {
          mediaStats: {
            r: {
              missing: null,
            },
            ttl: -1,
          },
        },
        profile_banner_extensions_alt_text: null,
        profile_banner_extensions_media_availability: null,
        profile_banner_extensions_media_color: {
          palette: [
            {
              rgb: {
                red: 207,
                green: 214,
                blue: 218,
              },
              percentage: 26.53,
            },
            {
              rgb: {
                red: 123,
                green: 200,
                blue: 251,
              },
              percentage: 14.99,
            },
            {
              rgb: {
                red: 198,
                green: 195,
                blue: 156,
              },
              percentage: 8.27,
            },
            {
              rgb: {
                red: 73,
                green: 154,
                blue: 235,
              },
              percentage: 7.3,
            },
            {
              rgb: {
                red: 85,
                green: 67,
                blue: 197,
              },
              percentage: 5.05,
            },
          ],
        },
        profile_banner_extensions: {
          mediaStats: {
            r: {
              missing: null,
            },
            ttl: -1,
          },
        },
        profile_link_color: "002D5B",
        pinned_tweet_ids: [],
        pinned_tweet_ids_str: [],
        has_custom_timelines: true,
        can_media_tag: true,
        advertiser_account_type: "promotable_user",
        advertiser_account_service_levels: ["media_studio", "mms", "smb"],
        profile_interstitial_type: "",
        business_profile_state: "none",
        translator_type: "none",
        ext: {
          highlightedLabel: {
            r: {
              ok: {},
            },
            ttl: -1,
          },
        },
      },
    },
    moments: {},
    cards: {},
    places: {},
    media: {},
    broadcasts: {},
    topics: {},
    lists: {},
  },
};
