import React, { useEffect, useState, Fragment } from "react";
import { parseDate, linkProps } from "../../util";
import { PlayIcon } from "../../Icons/icons";
import { Link } from "react-router-dom";
import "./style.scss";

const SearchModule = ({data:propsData, showMoreBtnText = "View All", enableShowMore, showMoreNavigate = "/",styles = false}) => {
  const scrollRef = React.createRef();
  const gridRef = React.createRef();
  const videoItemRef = React.createRef();
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollRight, setScrollRight] = useState(false);
  const [isvideo, setIsvideo] = useState(false);
  const [LatestVideoData, SetLatestVideoData] = useState([]);
  
  useEffect(() => {
    if(LatestVideoData.length>0){
      const variableWidth = window.innerWidth > 786 ? (LatestVideoData.length * 100) / 4 :(window.innerWidth === 768?  (LatestVideoData.length * 100)/2:LatestVideoData.length * 100);
      gridRef.current.style.width = variableWidth + "%";
      const element = scrollRef.current;
      element.addEventListener("scroll", event => {
        setScrollLeft(event.target.scrollLeft);
        const totalItem = LatestVideoData.length;
        const newScroll = event.target.scrollWidth - event.target.scrollLeft;
        const scrollValue = Math.round((event.target.scrollWidth / totalItem) * (window.innerWidth > 786 ? 4 :window.innerWidth == 768? 2:1));
        if (scrollValue - newScroll >= -1 && scrollValue - newScroll <= 1) setScrollRight(true);
        else setScrollRight(false);
      });
    }
  }, [gridRef, LatestVideoData.length]);

  useEffect(() => {
    let VideoData=propsData && Object.keys(propsData).length> 0 ? propsData:null;
    if (VideoData.articles) {
      SetLatestVideoData(VideoData.articles);
      // setModuleData(VideoData.module.split(' '));
    }
    else if (VideoData.videos || VideoData.videos.latest || VideoData.videos.top){
        const videoContent=VideoData.videos>0? VideoData.videos: VideoData.videos.latest>0? VideoData.videos.latest: VideoData.videos.top;
        setIsvideo(true);
        SetLatestVideoData(videoContent);
        // setModuleData(VideoData.module.split(' '));
    }
    
  }, [propsData]);

  const handleClick = direction => () => {
    const { offsetLeft, clientWidth } = videoItemRef.current;
    const elementsToScroll = window.innerWidth <= 786 ? 1 : 3;
    if (direction === "left") {
      scrollRef.current.scrollLeft -= (clientWidth + offsetLeft) * elementsToScroll;
    } else if (direction === "right") {
      scrollRef.current.scrollLeft += (clientWidth + offsetLeft) * elementsToScroll;
    }
  };
  const moduleData =propsData && Object.keys(propsData).length> 0 && propsData.articles ? propsData.module.split(' '): propsData.videos && propsData.module.split(' ');

  return  LatestVideoData.length > 0 && (
    <div className="search-module-conatiner row row_1"> 
    <div className="cricview-latestvideo-container withHeader">
      <div className="cricview-latestvideo-wrapper">
        <header className="latestvideo-header">
          {moduleData && (  
            <h3 className="latestvideo-header-title">
              {moduleData[0]} <span> {moduleData.splice(1, moduleData.length).join(" ")}</span>
            </h3>
          )}
         
        </header>
        <div className="arrow-flex">
          {LatestVideoData.length > 0 ? 
          (<Fragment>
          <div className="arrow-left" style={{ opacity: scrollLeft === 0 ? "0.5" : "1" }} onClick={handleClick("left")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="#fff"
              style={{ backgroundColor: "#ff3333", borderRadius: "5px", }}
              width="36px"
              height="36px"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
            </svg>
          </div>
          <section className="latestvideos" ref={scrollRef}>
            <div className="latestvideos-grid" ref={gridRef}>
              {LatestVideoData.map((item, i) => (
                <div className="latestvideo-item" ref={i === 0 ? videoItemRef : null} key={i}>
                  <Link {...linkProps(item)}>
                    <div className="image-container" style={{ backgroundImage: `url(${item.urlToImage})` }}>
                      <div style={isvideo?{display:"block"}:{display:"none"}}className="play-button">
                        <PlayIcon className="play-image" />
                      </div>
                      {/* <img src={item.urlToImage} className="video-image" alt="video-thumbnail" /> */}
                    </div>
                    <div className="time-container">
                      <div className="time" style={{color: styles ? "white" : "black"}}>{item.length}</div>
                    </div>
                    <div className="title-container">
                      <div className="publishDate" style={{color: styles ? "white" : "black"}}>{parseDate(item.publishedAt)}</div>
                      <div className="title" style={{color: styles ? "white" : "black"}}>{item.title}</div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </section>

          <div className="arrow-right" style={{ opacity: scrollRight ? "0.5" : "1" }} onClick={handleClick("right")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="#fff"
              style={{ backgroundColor: "#ff3333", borderRadius: "5px" }}
              width="36px"
              height="36px"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
            </svg>
          </div>
        
          </Fragment>) :
          (
            <div>
                No Result
            </div>
          )
          }
        </div>
        { LatestVideoData.length > 0 && enableShowMore && (
          <div className="showmore_button_container">
            <div className="showmore_button_wrapper">
              <Link to={showMoreNavigate}>
                <div className="showmore_button">{showMoreBtnText}</div>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
    </div>
  );
  
};

export default SearchModule;
