const AhemdabadData = [
  {
    name: "DR. RAJESH RANJAN",  
    verifyDate: "05-04-2021",
    type:  "ICU/NORMAL BEDS",
    phone_Number: "8800784476",
    location: "NIIMS, GREATER NOIDA",
    avaibility: "NA",
    remark: "AS OF NOW ALL BEDS ARE FULL"
  },
{
  name: "KETAN",  
  verifyDate: "07-04-2021",
  type:  "OXYGEN CONCENTRATOR",
  phone_Number: "9818256591, 9891432509",
  location: "DELHI",
  avaibility: "A",
  remark: "AT 65000/- 7 LTRS IN DELHI"
},
{
  name: "GEN BHATIA",  
  verifyDate: "05-04-2021",
  type:  "ICU BEDS",
  phone_Number: "9654895961",
  location: "DELHI, ARMY HOSPITAL",
  avaibility: "NA",
  remark: "NOT RESPONIDING YET TO BE VERIFY"
},
{
  name: "DR. CHUG",  
  verifyDate: "05-04-2021",
  type:  "ICU BEDS",
  phone_Number: "9868100678",
  location: "DELHI, ARMY HOSPITAL",
  avaibility: "NA",
  remark: "NOT RESPONIDING YET TO BE VERIFY"
},
{
  name: "EEMA OXYGEN",  
  verifyDate: "05-04-2021",
  type:  "OXYGEN CYLINDER/REFILL",
  phone_Number: "9873325428",
  location: "MUNDKA,ROHINI,MAYAPURI",
  avaibility: "A",
  remark: "CYLINDERS AND REFIL BOTH FACILITY"
},
{
  name: "EEMA OXYGEN",  
  verifyDate: "05-04-2021",
  type:  "OXYGEN CYLINDER/REFILL",
  phone_Number: "8126654666",
  location: "SALASANGAR,SUKHBIRNAGAR",
  avaibility: "A",
  remark: "CYLINDERS AND REFIL BOTH FACILITY"
},
{
  name: "ARVIND JHA",  
  verifyDate: "05-04-2021",
  type:  "ICU/NORMAL BEDS",
  phone_Number: "9810810772",
  location: "DELHI",
  avaibility: "NA",
  remark: "WILL BE AVAILABLE IN 3 OR 4 DAYS"
},
{
  name: "LG RESPICARE",  
  verifyDate: "05-04-2021",
  type:  "BIPAP MACHINE",
  phone_Number: "9810306003",
  location: "AKSHARDHAM METRO, DELHI",
  avaibility: "A",
  remark: "ON RENT BASIS (15 K FOR 15 DAYS)"
},
{
name: "HARSH",  
verifyDate: "05-04-2021",
type:  "OXYGEN CONCENTRATOR",
phone_Number: "9711344021",
location: "DELHI",
avaibility: "A",
remark: "PROVIDING ON RENT BASIS"
},
{
name: "BLOOD TEST",  
verifyDate: "05-04-2021",
type:  "LAB BLOOD TEST",
phone_Number: "9773838681",
location: "DELHI",
avaibility: "A",
remark: "ALL TYPE OF BLOOD TEST WILL BE DONE HERE"
},
{
name: "WAZIB KHAN",  
verifyDate: "05-04-2021",
type:  "OXYGEN REGULATOR & FLOW METER",
phone_Number: "9811289586",
location: "LUCKNOW",
avaibility: "A",
remark: "DELIVERING EVERYWHERE 2800/-"
},
{
name: "SVSU COVID HELPLINE",  
verifyDate: "05-05-2021",
type:  "BED,OXYGEN CYLINDER,AMBULANCE",
phone_Number: "18001800147",
location: "DELHI & NCR",
avaibility: "A",
remark: "AVAILABLE"
},
{
name: "COVID HELP",  
verifyDate: "05-05-2021",
type:  "OXYGEN CYLINDER",
phone_Number: "8860216746",
location: "DELHI(MORBAND)",
avaibility: "A",
remark: "12 LTR IN 800/-"
},
{
name: "PARAMJIT SINGH",  
verifyDate: "05-05-2021",
type:  "OXYGEN CYLINDER",
phone_Number: "9810810772",
location: "DELHI",
avaibility: "A",
remark: "2,3 HRS OXYGEN ONLY IF OXYGEN LEVEL IS VERY LOW"
},
{
  name: "HOME ICU",  
  verifyDate: "05-05-2021",
  type:  "ICU NURSE",
  phone_Number: "8826911383",
  location: "DELHI",
  avaibility: "A",
  remark: "24*7 NURSE AVAILBLE FOR ICU SETUP"
},
{
name: "REFIL",  
verifyDate: "05-05-2021",
type:  "OXYGEN REFIL",
phone_Number: "8130184210",
location: "PANCHSHEEL PARK, DELHI",
avaibility: "A",
remark: "REFILLING AT FREE OF COST"
},
{
name: "OXYGEN",  
verifyDate: "05-05-2021",
type:  "OXYGEN CONCENTRATOR",
phone_Number: "9560976288",
location: "DELHI & NCR",
avaibility: "A",
remark: "OXYGEN CONCENTRATOR AVAILABLE"
},
{
name: "MEDIC EQUIP",  
verifyDate: "05-05-2021",
type:  "HUMIDIFIER & FLOWMETER",
phone_Number: "8107230447",
location: "DELHI & NCR",
avaibility: "A",
remark: "DELIVERING IN DELHI NCR AT 3500/-"
},
{
name: "REFIL",  
verifyDate: "05-04-2021",
type:  "OXYGEN REFIL",
phone_Number: "9810125132",
location: "TURKMAN GATE,HAJ MANJIL DELHI",
avaibility: "A",
remark: "REFILLING START AT 8 PM"
},
{
name: "BED",  
verifyDate: "05-05-2021",
type:  "BEDS AVAILABLE",
phone_Number: "9818073527",
location: "GURGAON",
avaibility: "A",
remark: "BEDS AVAILABLE"
},
{
name: "OXYGEN",  
verifyDate: "05-05-2021",
type:  "OXYGEN CYLINDER",
phone_Number: "6291651074",
location: "DELHI",
avaibility: "A",
remark: "HOME DELIVERY AVAILABLE"
},
{
name: "OXYGEN",  
verifyDate: "05-05-2021",
type:  "OXYGEN CYLINDER",
phone_Number: "8653286281",
location: "NEHRU PLACE, DELHI",
avaibility: "A",
remark: "55 LTR 7500/"
},
{
name: "COVID EQUIP",  
verifyDate: "05-05-2021",
type:  "OXYGEN CONCENTRATOR",
phone_Number: "7021400602",
location: "RAJORI GARDEN, DELHI",
avaibility: "A",
remark: "75,000 EACH"
},
{
name: "MEDIC EQUIP",  
verifyDate: "05-05-2021",
type:  "MEDICAL EQUIPMENTS",
phone_Number: "9560976288",
location: "1ST FLOOR BUILDING NEAR LAZIZ PIZZA, DELHI",
avaibility: "A",
remark: "ALL TYPE OF BLOOD TEST WILL BE DONE HERE"
},
{
name: "OXYGEN LIFE",  
verifyDate: "05-04-2021",
type:  "OXYGEN REGULATOR & FLOW METER",
phone_Number: "9811289586",
location: "LUCKNOW",
avaibility: "A",
remark: "DELIVERING EVERYWHERE 2800/-"
},
{
name: "SVSU COVID HELPLINE",  
verifyDate: "05-05-2021",
type:  "OXYGEN CONCENTRATOR",
phone_Number: "9818331756",
location: "VAISHALI, ANAND VIHAR",
avaibility: "A",
remark: "CONTACT FOR OXYGEN CONCENTRATOR"
},
{
name: "OXYGEN",  
verifyDate: "05-05-2021",
type:  "OXYGEN CONCENTRATOR",
phone_Number: "8860687002",
location: "NOT KNOWN",
avaibility: "A",
remark: "60,000 EACH"
},
{
name: "OXYGEN",  
verifyDate: "05-05-2021",
type:  "OXYGEN CYLINDER",
phone_Number: "9818798980",
location: "DWARKA SECTOR 28, DELHI",
avaibility: "A",
remark: "18 LTR CYLINDER IN 3500/-"
},
/////////////


{
  name: "CYLINDER EQUIP",  
  verifyDate: "05-05-2021",
  type:  "OXYGEN REGULATOR ",
  phone_Number: "8107230447",
  location: "BIWADI, RAJSTHAN",
  avaibility: "A",
  remark: "AVAILABLE FOR 3200/-"
},
{
name: "AMBULANCE",  
verifyDate: "05-05-2021",
type:  "AMBULANCE WITH OXYGEN CYLINDER",
phone_Number: "6378494759",
location: "DELHI",
avaibility: "A",
remark: "AVAILABLE AMBULANCE WITH OXYGEN FACILITY"
},
{
name: "ISKCON",  
verifyDate: "05-05-2021",
type:  "FOOD FOR COVID PATIENT",
phone_Number: "8130326501",
location: "GURGAON",
avaibility: "A",
remark: "bit.ly/covidfoodaid TRY THIS LINK OR GIVEN NO. FOR FOOD FOR COVID PATIENT"
},
{
name: "NIKHIL MEDICAL EQUIPMENT",  
verifyDate: "05-05-2021",
type:  "ICU SETUP",
phone_Number: "9871582633",
location: "DELHI",
avaibility: "A",
remark: "ICU SETUP FOR HOME"
},
{
name: "REFIL",  
verifyDate: "05-05-2021",
type:  "OXYGEN REFIL",
phone_Number: "9868827510",
location: "MAYAPURI",
avaibility: "A",
remark: "OXYGEN REFILLING IS AVAILABLE ONLY FOR SMALL CYLINDER"
},
{
name: "REFIL",  
verifyDate: "05-05-2021",
type:  "OXYGEN REFIL",
phone_Number: "7290951034",
location: "SASHTRI PARK DELHI",
avaibility: "A",
remark: "OXYGEN REFILLING IS AVAILABLE ONLY FOR UPTO 10 LTR CYLINDER"
},
{
name: "AMBULANCE",  
verifyDate: "05-04-2021",
type:  "AMBULANCE WITH OXYGEN CYLINDER",
phone_Number: "999947990",
location: "DELHI NCR, GURGAON, GHAZIABAD",
avaibility: "A",
remark: "AMBULANCE WITH OXYGEN CYLINDER"
},

///////////////
{
name: "CYLINDER EQUIP",  
verifyDate: "05-04-2021",
type:  "OXYGEN REGULATOR",
phone_Number: "8107230447",
location: "BIWADI, RAJSTHAN",
avaibility: "A",
remark: "AVAILABLE FOR 3200/-"
},
{
name: "AMBULANCE",  
verifyDate: "05-05-2021",
type:  "AMBULANCE WITH OXYGEN CYLINDER",
phone_Number: "6378494759",
location: "DELHI",
avaibility: "A",
remark: "AVAILABLE AMBULANCE WITH OXYGEN FACILITY"
},
{
name: "ISKCON",  
verifyDate: "05-05-2021",
type:  "FOOD FOR COVID PATIENT",
phone_Number: "8130326501",
location: "GURGAON",
avaibility: "A",
remark: "bit.ly/covidfoodaid TRY THIS LINK OR GIVEN NO. FOR FOOD FOR COVID PATIENT"
},
{
name: "NIKHIL MEDICAL EQUIPMENT",  
verifyDate: "05-06-2021",
type:  "ICU SETUP",
phone_Number: "9871582633",
location: "DELHI",
avaibility: "A",
remark: "ICU SETUP FOR HOME"
},
{
name: "REFIL",  
verifyDate: "05-06-2021",
type:  "BED,OXYGEN CYLINDER,AMBULANCE",
phone_Number: "9868827510",
location: "MAYAPURI",
avaibility: "A",
remark: "OXYGEN REFILLING IS AVAILABLE ONLY FOR SMALL CYLINDER"
},
{
name: "REFIL",  
verifyDate: "05-06-2021",
type:  "OXYGEN REFIL",
phone_Number: "7290951034",
location: "SASHTRI PARK DELHI",
avaibility: "A",
remark: "OXYGEN REFILLING IS AVAILABLE ONLY FOR UPTO 10 LTR CYLINDER"
},
{
name: "AMBULANCE",  
verifyDate: "05-06-2021",
type:  "AMBULANCE WITH OXYGEN CYLINDER",
phone_Number: "999947990",
location: "DELHI NCR, GURGAON, GHAZIABAD",
avaibility: "A",
remark: "AMBULANCE WITH OXYGEN CYLINDER"
},
{
name: "PRA AMBULANCE",  
verifyDate: "05-06-2021",
type:  "AMBULANCE WITH OXYGEN CYLINDER",
phone_Number: "9873934133",
location: "NOIDA, GURGAON",
avaibility: "A",
remark: "AMBULANCE WITH OXYGEN CYLINDER"
},
{
name: "FORTIS BLOOD BANK",  
verifyDate: "05-06-2021",
type:  "PLASMA DONOR",
phone_Number: "9643200547",
location: "NOIDA",
avaibility: "A",
remark: "PLASMA DONOR AVAILABLE 24 HRS"
},
{
name: "OXYGEN",  
verifyDate: "05-06-2021",
type:  "OXYGEN CYLINDER",
phone_Number: "8707347003",
location: "LUCKNOW",
avaibility: "A",
remark: "OXYGEN CYLINDERS AND REFIL AVAILABLE"
},
{
name: "CHARITABLE BANK",  
verifyDate: "05-06-2021",
type:  "PLASMA DONOR",
phone_Number: "7042840641",
location: "NOIDA",
avaibility: "A",
remark: "PLASMA DONOR AVAILABLE 24 HRS"
},
{
  name: "CHARITABLE BANK",  
  verifyDate: "05-06-2021",
  type:  "PLASMA DONOR",
  phone_Number: "7042840641",
  location: "NOIDA",
  avaibility: "A",
  remark: "PLASMA DONOR AVAILABLE 24 HRS"
  },
  {
    name: "HINDU RAO HOSPITAL",  
    verifyDate: "05-06-2021",
    type:  "PLASMA DONOR",
    phone_Number: "011-23919476",
    location: "DELHI",
    avaibility: "A",
    remark: "PLASMA DONOR AVAILABLE 24 HRS"
    },
    {
      name: "BLOOD BANK",  
      verifyDate: "05-06-2021",
      type:  "PLASMA DONOR",
      phone_Number: "011-25730773",
      location: "DELHI",
      avaibility: "A",
      remark: "PLASMA DONOR AVAILABLE 24 HRS"
      },
      {
        name: "BHAGWAN BUDHA BLOOD BANK",  
        verifyDate: "05-06-2021",
        type:  "PLASMA DONOR",
        phone_Number: "0120-4356147",
        location: "NOT KNOWN",
        avaibility: "A",
        remark: "PLASMA DONOR AVAILABLE 24 HRS"
        },
        {
          name: "LIONS BLOOD BANK",  
          verifyDate: "05-06-2021",
          type:  "PLASMA DONOR",
          phone_Number: "77982832970",
          location: "GURGAON",
          avaibility: "A",
          remark: "PLASMA DONOR AVAILABLE 24 HRS"
          },
          {
            name: "BLOOD BANK",  
            verifyDate: "05-06-2021",
            type:  "PLASMA DONOR",
            phone_Number: "0120-477010",
            location: "GHAZIABAD",
            avaibility: "A",
            remark: "PLASMA DONOR AVAILABLE 24 HRS"
            },
            {
              name: "COLUMBIA HOSPITAL",  
              verifyDate: "05-06-2021",
              type:  "PLASMA DONOR",
              phone_Number: "0124-3989896",
              location: "NOT KNOWN",
              avaibility: "A",
              remark: "PLASMA DONOR AVAILABLE 24 HRS"
              },
              {
                name: "LIONS BLOOD BANK",  
                verifyDate: "05-06-2021",
                type:  "PLASMA DONOR",
                phone_Number: "9717897500",
                location: "DELHI",
                avaibility: "A",
                remark: "PLASMA DONOR AVAILABLE 24 HRS"
                },
                {
                  name: "SWAMI DAYANAND HOSPITAL",  
                  verifyDate: "05-06-2021",
                  type:  "PLASMA DONOR",
                  phone_Number: "011-22581031",
                  location: "DELHI",
                  avaibility: "A",
                  remark: "PLASMA DONOR AVAILABLE 24 HRS"
                  },
                  {
                    name: "NATIONAL HEART INSTITUTE ",  
                    verifyDate: "05-06-2021",
                    type:  "PLASMA DONOR",
                    phone_Number: "011-26414156",
                    location: "DELHI",
                    avaibility: "A",
                    remark: "PLASMA DONOR AVAILABLE 24 HRS"
                    },
                    {
                      name: "KARNAL BLOD BANK",  
                      verifyDate: "05-06-2021",
                      type:  "PLASMA DONOR",
                      phone_Number: "9896666672",
                      location: "KARNAL, HARYANA",
                      avaibility: "A",
                      remark: "PLASMA DONOR AVAILABLE 24 HRS"
                      },
                      {
                        name: "NABH BLOOD CENTRE",  
                        verifyDate: "05-06-2021",
                        type:  "PLASMA DONOR",
                        phone_Number: "8130597597",
                        location: "ATAMPUR",
                        avaibility: "A",
                        remark: "PLASMA DONOR AVAILABLE 24 HRS"
                        },
                        {
                          name: "LBS HOSPITAL",  
                          verifyDate: "05-06-2021",
                          type:  "PLASMA DONOR",
                          phone_Number: "011-22783633",
                          location: "DELHI",
                          avaibility: "A",
                          remark: "PLASMA DONOR AVAILABLE 24 HRS"
                          },
                          {
                            name: "OXYGEN",  
                            verifyDate: "05-06-2021",
                            type:  "OXYGEN CONCENTRATOR",
                            phone_Number: "9971997291",
                            location: "DELHI/NCR",
                            avaibility: "A",
                            remark: "ON RENT BASIS (2000 per DAY)"
                            },
                            {
                              name: "OXYGEN",  
                              verifyDate: "05-07-2021",
                              type:  "OXYGEN CONCENTRATOR",
                              phone_Number: "9267999854",
                              location: "DELHI",
                              avaibility: "A",
                              remark: "10 LTRS 39900/- DELIVERS ALL OVER DELHI"
                              },
                              {
                                name: "CHIRANJEET",  
                                verifyDate: "05-07-2021",
                                type:  "PLASMA DONOR",
                                phone_Number: "9650632806",
                                location: "DELHI",
                                avaibility: "A",
                                remark: "PLASMA DONOR AVAILABLE FOR HOSPITALS ONLY"
                                },
                                {
                                  name: "KAPIL SAIGHAL",  
                                  verifyDate: "05-07-2021",
                                  type:  "PLASMA DONOR",
                                  phone_Number: "9717260991",
                                  location: "DELHI",
                                  avaibility: "A",
                                  remark: "PLASMA DONOR AVAILABLE"
                                  },
                                  {
                                    name: "DONOR",  
                                    verifyDate: "05-07-2021",
                                    type:  "PLASMA DONOR",
                                    phone_Number: "8010066066",
                                    location: "DELHI",
                                    avaibility: "A",
                                    remark: "YOU CAN REGISTER HERE, THEY WILL CALL ONCE DONOR IS AVAILBLE"
                                    },
                                    {
                                      name: "SANYAM & SHIVAM",  
                                      verifyDate: "05-07-2021",
                                      type:  "FOOD FOR COVID PATIENT",
                                      phone_Number: "9953122142, 9560225050",
                                      location: "EAST DELHI",
                                      avaibility: "A",
                                      remark: "PFREE FOOD FOR COVID PATIENTS"
                                      },


]

export default AhemdabadData;