import React, { useEffect, Fragment, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect, useHistory } from "react-router-dom";
import ModalVideo from "react-modal-video";
import { connect } from "react-redux";
import Modal from "react-modal";
import InstagramEmbed from "react-instagram-embed";
import TalentPay from "./Views/talentPay";

import routes from "./routes";
import Register from "./Components/Register";
import UpdateName from "./Components/UpdateName";

import PopPlay from "./Components/PlayGamesWIthOrWithOutLogin"
import { closeModal, openModal } from "./redux/helpers/modal";
import { fetchContent, fetchYoutubeContent } from "./redux/helpers/content";
import { closeYtModal } from "./redux/helpers/youtubeModal";
import { TwitterVideoEmbed } from "react-twitter-embed";
import { INSTA_MODAL, SIGN_IN_MODAL, DOODLE_CRICKET_MODAL, TWITTER_MODAL, PLAY_WITH_OR_WITHOUT_SIGN_IN_MODAL, UPDATE_NAME_MODAL } from "./constant/modal-type";
import { Close } from "./Assets/icons";
import "react-modal-video/scss/modal-video.scss";
import "./Styles/grid.css";
import Cookies from "js-cookie";
import { uuid } from 'uuidv4';


const customStyles = {
  content: {
    padding: 0,
    background: "none",
    border: "none",
    position: "unset",
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
};
Modal.setAppElement(document.getElementById("root"));

function App(props) {
  if(!Cookies.get("browserId")){
    //alert(Cookies.get("browserId"));
    Cookies.set("browserId", uuid(), { expires: 30});
  }else{
    //alert(Cookies.get("browserId"));
  }
  function onCloseModal() {
    closeModal();
  }
  useEffect(() => {
    async function fetchStoreContent() {
      fetchContent();
      fetchYoutubeContent();
    }
    fetchStoreContent();
  }, []);
  useEffect(() => {
    if (props.user && props.user.userId && sessionStorage.getItem("guestUser")) {
      sessionStorage.removeItem("guestUser")
    }
  }, [props.user])
 

  const PrivateRoutes = ({ component: C, path, needAuth, adminAccess, authorAccess, registrationClosed }) => {
    // const isAuth = props.user && props.user.userId && true;
    // const isAdminRoleNeeded = Object.entries(props.user).length > 0 && (props.user.role).toLowerCase() === "admin" && true;
    // const isAuthorRoleNeeded = Object.entries(props.user).length > 0 && (props.user.role).toLowerCase() === "author" && true;

    const isAuth = localStorage.getItem("userId");
    const isAdminRoleNeeded = localStorage.getItem("userRole")=="admin" ? true : false;
    const isAuthorRoleNeeded = localStorage.getItem("userRole")=="author" ? true : false;
    const isGuest = sessionStorage.getItem("guestUser")


    let url=window.location.href;
    localStorage.setItem("url",url);

    if (path === "/gamezone" && isGuest) {
      return <Route render={(props) => <C {...props} registrationClosed={registrationClosed}></C>} path={path}></Route>
    }

    if (isAuth && needAuth && adminAccess && isAdminRoleNeeded) {
      return <Route render={(props) => <C {...props} registrationClosed={registrationClosed}></C>} path={path} ></Route>
    } else if (isAuth && needAuth && authorAccess && isAuthorRoleNeeded) {
      return <Route render={(props) => <C {...props} registrationClosed={registrationClosed}></C>} path={path} ></Route>
    } else if (isAuth && needAuth && !adminAccess) {
      return <Route render={(props) => <C {...props} registrationClosed={registrationClosed}></C>} path={path} ></Route>
    } else {
      !isAuth && openModal(SIGN_IN_MODAL)
      return (
        <Redirect to="/" />
      )
    }
  }
  const Routes = ({ component: C, path, registrationClosed }) => {
    return <Route path={path} render={(props) => <C {...props} registrationClosed={registrationClosed}></C>}></Route>
  }
  const getModalChild = () => {
    const { instagramUrl, twitterUrl } = props && props.modal && props.modal.payload ? props.modal.payload : {};
    switch (props.modal.type) {
      case INSTA_MODAL:
        return (
          <Fragment>
            <div className="instaa">
              <InstagramEmbed url={instagramUrl} maxWidth={640} hideCaption={true} containerTagName="div" protocol="" injectScript />
            </div>
          </Fragment>
        );
      case SIGN_IN_MODAL:
        return (
          <Fragment>
            <Register />
          </Fragment>
        );
      case UPDATE_NAME_MODAL:
        return (
          <Fragment>
            <UpdateName />
          </Fragment>
        );
      case DOODLE_CRICKET_MODAL:
        return (
          <Fragment>
            <div className="playnow-modal-content">
              <div id="screen"></div>
            </div>
          </Fragment>
        );
      case PLAY_WITH_OR_WITHOUT_SIGN_IN_MODAL:
        return (
          <Fragment>
            <PopPlay />
          </Fragment>
        );
      case TWITTER_MODAL:
        return (
          <Fragment>
            <TwitterVideoEmbed id={"1278687688485371907"} />
          </Fragment>
        );
      default:
        return <Fragment />;
    }
  };
  return (
    <BrowserRouter history={useHistory()}>
      <div>
        <ModalVideo
          channel="youtube"
          isOpen={props.youtubeModal.isYtModalOpen || false}
          videoId={props.youtubeModal.videoId || null}
          onClose={() => closeYtModal()}
        />
        <Modal isOpen={props && props.modal ? props.modal.isModalOpen : false} onRequestClose={onCloseModal} style={customStyles}>
          <div>
            <div style={{ position: "relative", left: "92%", cursor: "pointer" }} onClick={() => onCloseModal()}>
              <Close />
            </div>
            {props && props.modal && props.modal.isModalOpen && getModalChild()}
          </div>
        </Modal>
        
        <Switch>
          {routes.map(({ Component, path, needAuth = false, adminAccess = false, authorAccess = false, registrationClosed = false }, index) => (
            needAuth ? <PrivateRoutes path={path} component={Component} key={index} needAuth={needAuth} adminAccess={adminAccess} authorAccess={authorAccess} registrationClosed={registrationClosed} /> : <Routes path={path} key={index} component={Component} registrationClosed={registrationClosed}></Routes>
          ))}
          <Redirect to="/" />
        </Switch>
      </div>
    </BrowserRouter>
  );
}
function mapStateToProps(state) {
  const { youtubeModal, modal, user } = state;
  return { youtubeModal, modal, user };
}
export default connect(mapStateToProps)(App);
