import React, { Fragment, useEffect, useState } from "react";
import ArticleModule from "../../Module/ArticleModule";
import TopSearch from "../../Module/TopSearchesModule";
import FeaturedNews from "../../Components/FeaturedNews";
import LatestVideo from "../../Module/LatestVideoModule";
import { connect } from "react-redux";
import { addYoutubeVideosToStore } from "../../redux/helpers/content";
import withLayout from "../../layout/default";
import LiveTray from "../../Components/LiveTray";
import Live from "../../Components/Live"
import Cookies from "js-cookie";
import { uuid } from 'uuidv4';


function Home({ content }) {

  const [contentProp, setContentProp] = useState({});
  console.log(contentProp)

  useEffect(() => {
    if (Object.keys(content).length > 0) {
      setContentProp(content);
    }
  }, [content]);
  useEffect(() => {
    addYoutubeVideosToStore();
    addYoutubeVideosToStore("website");
  }, []);

  const { Articles = {}, Instagram = {}, Twitter = {}, articles = [], youtube = {} } = contentProp || {};
  const { top = [], latest = [] } = youtube;
  const { latestPosts = [], latestVideos = [], topVideos = [] } = Instagram;
  const { cricviewCrawler = [], latestCricview = [], topCricview = [], worldcup = [] } = Articles;

  const newCrawler = cricviewCrawler.concat(latestCricview)
  console.log(newCrawler)

  let evenContentData = []
  for (let i=0; i<=newCrawler.length; i+=2){
    evenContentData.push(newCrawler[i])
  }
  console.log(evenContentData)

  let oddContentData = []
  for(let i=1; i<=newCrawler.length; i+=2){
    oddContentData.push(newCrawler[i])
  }
  console.log(oddContentData)

  return (
    <Fragment>
      <div>
        <div className="row row_1">
          <TopSearch contentData={[...[...evenContentData].slice(0, 21)]} moduleName={"Top Search"} />
        </div>
        <div className="row row_1">
          <FeaturedNews
            contentData={[...oddContentData, ...topCricview, ...worldcup]}
            contentTitle={"Latest News"}
            rowCount={[0]}
            contentSubHeading="The sporting activities in the world has come to a standstill."
          />
        </div>
        <div className="row row_1" style={{"marginBottom": "0.5%"}}>
          <LiveTray/>
        </div>
      {/* 
        <div className="row row_1">
          <Live></Live>
        </div> */}

        {/* <div className="row row_1">
          <LatestVideo
            contentData={[...worldcup]}
            moduleName={"Cricview World Cup"}
            enableShowMore={true}
            showMoreNavigate="/videos"
            styles={true}
            showPlayIcon={false}
          />
        </div> */}
       <div className="row row_1">
          <LatestVideo 
            contentData={top && [...top.slice(0, 8)]} 
            showMoreBtnText="View All" 
            enableShowMore={true} 
            showMoreNavigate="/videos" 
            styles={true} 
          />
        </div>
        <div id="featurenews" className="row ads-banner-container">
          {/* <img className="add-banner-1" src={require("../../Assets/banner.png")} alt="" /> */}
        </div>
        <div className="row row_1">
          <ArticleModule 
            contentData={[...latestPosts]} 
            contentTitle={"Latest on Instagram"} 
          />
        </div>
        <div className="row row_1">
          <LatestVideo
            contentData={[...topVideos]}
            moduleName={"Most Happening on Talent Hunt"}
            enableShowMore={true}
            showMoreNavigate="/interviews"
            styles={true}
          />
        </div>
      </div>
      ;
    </Fragment>
  );
}

export default connect(state => ({ content: state.content }))(withLayout(Home));
