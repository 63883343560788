import { openModal } from "../redux/helpers/modal";
import { INSTA_MODAL, TWITTER_MODAL } from "../constant/modal-type";
import { openYtModal } from "../redux/helpers/youtubeModal";

export function isEquivalent(a, b) {
  // Create arrays of property names
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);

  if (aProps.length !== bProps.length) {
    return false;
  }
  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];
    if (a[propName] !== b[propName]) {
      return false;
    }
  }
  return true;
}

export const parseDate = oldDate => {
  if (`${oldDate}`.includes("Z")) {
    let months = ["JAN", "FEB", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUG", "SEPT", "OCT", "NOV", "DEC"];
    let dateObj = new Date(oldDate);
    let month = months[dateObj.getUTCMonth()];
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();
    let newdate = day + " " + month + " " + year;
    return newdate;
  }
  return oldDate;
};

export const removeTags = str => {
  if (str === null || str === "") return false;
  else str = str.toString();
  return str.replace(/(<([^>]+)>)/gi, "");
};

export const navigateTo = (data = null) => {
  const { url, title = "", source = null, id = null } = data;
  let uniqueId = ""
  if (source && source.id) {
    uniqueId = source.id
  } else {
    uniqueId = id
  }
  var itemTitle = title;
  itemTitle = itemTitle.replace(/\s+/g, '-').toLowerCase();
  itemTitle = itemTitle.replace(/[.!@#$%^&*()_+=,:;"]/g, '').toLowerCase();
  let redirectUrl = url;
  redirectUrl = `/article/${uniqueId}/${itemTitle}`;
  return redirectUrl;
};

export function parseYoutubeResponse(data = {}) {
  const { items = [] } = data;
  const response = [];
  if (items.length > 0) {
    try {
      items.map(item => {
        const { id, snippet } = item || {};
        const { title, description, thumbnails, resourceId, publishedAt } = snippet;

        let videoId = null;
        videoId = id && id.videoId ? id.videoId : videoId;
        videoId = resourceId && resourceId.videoId ? resourceId.videoId : videoId;
        if (videoId && title && description && thumbnails && publishedAt && thumbnails["high"] && thumbnails["high"].url)
          response.push({
            title,
            description,
            urlToImage: thumbnails["high"].url,
            videoId: videoId,
            publishedAt,
            source: { id: null, name: "youtube" },
          });
        return null;
      });
    } catch (e) {
      console.log(e);
    }
    return response;
  } else return {};
}

export const parseContentData = async (contentData = []) => {
  // contentData must be an array
  contentData = [...contentData];
  console.log(contentData)
  const response = { articles: [], videos: [], data: [] };
  contentData.map(item => {
    const { articles = [], videos = [] } = item;
    if (response.articles && Array.isArray(articles)) response.articles = [...response.articles, ...articles];

    if (response.videos && Array.isArray(videos)) response.videos = [...response.videos, ...videos];
    else {
      const { top = [], latest = [] } = videos;
      response.videos = [...response.videos, ...top, ...latest];
    }
    response.data.push(item);
    return null;
  });
  return response;
};

export const caluclateReadTime = text => {
  //300 words per minute
  const totalWords = text.split(" ");
};

const clickProp = item => {
  const sourceName = item.source.name.toLowerCase();
  switch (sourceName) {
    case "youtube":
      return openYtModal(item.videoId);
    case "instagram":
      return openModal(INSTA_MODAL, { instagramUrl: item.url });
    case "twitter":
      return openModal(TWITTER_MODAL, { twitterUrl: item.url });
    default:
      return null;
  }
};
export const linkProps = item => {
  const currUrl = window.location.pathname + window.location.search;
  const response =
    item &&
      item.source &&
      item.source.name &&
      (item.source.name === "youtube" || item.source.name.toLowerCase() === "instagram" || item.source.name.toLowerCase() === "twitter")
      ? { to: `${currUrl}#!`, onClick: e => clickProp(item) }
      : { to: navigateTo({ ...item }) || `${currUrl}#!` };
  return response;
};

export const removeDuplicateObjectFromArray = (array, key) => {
  var check = new Set();
  return array.filter(obj => !check.has(obj[key]) && check.add(obj[key]));
};
