import axios from "axios";
import ApiPart from "../../util/apiPart"

export const getData = async lang => {
  return axios({
    method: "GET",
    url: `${ApiPart[0].firstPart}/getdata?x=${Math.round(new Date().getTime() / 1000)}&languageCode=${lang}`,
  });
};

export const postEditorData = async bodyParams => {
  return axios({
    method: "POST",
    data: { ...bodyParams },
    url: `${ApiPart[0].firstPart}/saveData`,
  });
};
