const FETCH_CONTENT = "FETCH_CONTENT";

const OPEN_YT_MODAL = "OPEN_YT_MODAL";
const CLOSE_YT_MODAL = "CLOSE_YT_MODAL";

const OPEN_MODAL = "OPEN_MODAL";
const CLOSE_MODAL = "CLOSE_MODAL";

const SIGN_IN = "SIGN_IN";

const UPDATE_NAME = "UPDATE_NAME";


const EDIT_CONTENT = "EDIT_CONTENT";
const CLEAR_CONTENT = "CLEAR_CONTENT";
export { FETCH_CONTENT, OPEN_YT_MODAL, CLOSE_YT_MODAL, OPEN_MODAL, CLOSE_MODAL, SIGN_IN, EDIT_CONTENT, CLEAR_CONTENT, UPDATE_NAME };
