import React, { Fragment, useState, useEffect } from "react";
import TalentHuntFooter from "../../Components/Talenthunt-Footer";
import TalentHuntHeader from "../../Components/Talenthunt-Header";
import { Redirect } from "react-router-dom";
import withLayout from "../../layout/default";
import "./style.scss";

const mailBSrc = require("../../Assets/mail-black.png");
const phoneBSrc = require("../../Assets/phone-black.png");
const homeSrc = require("../../Assets/home-black.png");
const whatsappBSrc = require("../../Assets/whatsapp-black.png");

const TalentContact = () => {
  const [formdata, setData] = useState({
    name: "",
    age: 0,
    phone: 0,
    email: "",
    city: "",
    aadharid: "",
    tshirtsize: "S",
    skills: "Bowling",
    income: "Moderate",
    profile: {},
  });
  const [radioState, setradioState] = useState({ radio1: true, radio2: false, radio3: false, radio4: false, radio5: false, radio6: false });
  const [errorContent, setError] = useState({ error: "", errbool: false });

  const handleInput = event => {
    event.preventDefault();
    const { value, name } = event.target;
    setData(state => ({ ...state, [name]: value }));
    setError(state => ({ ...state, error: "" }));
  };
  const setToActive = event => {
    event.preventDefault();

    for (const property in radioState) {
      if (property === event.target.id) {
        setradioState(state => ({ ...state, [property]: true }));
      } else {
        setradioState(state => ({ ...state, [property]: false }));
      }
    }
    const name = event.target.children[0].name;
    const value = event.target.children[0].value;
    setData(state => ({ ...state, [name]: value }));
  };

  const verifyform = () => {
    setError(state => ({ ...state, error: "" }));
    if (formdata.phone.length !== 10 && !Number(formdata.phone)) {
      setError(state => ({ ...state, error: '"Please enter valid number.!!"' }));
      return;
    }
    if (formdata.age == 0 && !Number(formdata.age)) {
      setError(state => ({ ...state, error: '"Please enter valid age.!!"' }));
      return;
    }
    // let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (formdata.email.length == 0) {
      setError(state => ({ ...state, error: '"Invalid Email Address!!"' }));
      return;
    }
    if (formdata.name.length === 0) {
      setError(state => ({ ...state, error: '"Please enter the name.!!"' }));
      return;
    }
    if (formdata.city.length === 0) {
      setError(state => ({ ...state, error: '"Please enter the city .!!"' }));
      return;
    }
    if (formdata.aadharid.length !== 16 && !Number(formdata.aadharid)) {
      setError(state => ({ ...state, error: '"Please enter valid aadharid.!!"' }));
      return;
    }
    if (Object.keys(formdata.profile).length === 0) {
      setError(state => ({ ...state, error: '"Please Upload Your Photo.!!"' }));
      return;
    }
    if (errorContent.error.length === 0) {
      setError(state => ({ ...state, errbool: true }));
    }
  };
  const onFileChange = event => {
    let fileData = { data: event.target.files[0], src: URL.createObjectURL(event.target.files[0]) };
    setData(state => ({ ...state, profile: fileData }));
  };
  return (
    <div className="cricview-talnet-contact">
      <TalentHuntHeader />
      <Fragment>
        <section id="contant" className="contant main-heading team">
          <div className="row">
            <div className="container">
              <div className="contact">
                <div className="col-md-6">
                  <div className="contact-us">
                    <h2>Registration is open</h2>
                    <form className="comments-form" id="contactform">
                      <br />
                      <ul>
                        <li>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="required"
                            placeholder="Name *"
                            onChange={event => {
                              handleInput(event);
                            }}
                          />
                        </li>
                        <li>
                          <input
                            type="number"
                            id="age"
                            name="age"
                            className="required"
                            placeholder="Age *"
                            onChange={event => {
                              handleInput(event);
                            }}
                          />
                        </li>
                        <li>
                          <input
                            type="number"
                            id="phone"
                            name="phone"
                            className="required phone"
                            minlength="10"
                            maxlength="10"
                            required=""
                            placeholder="Phone * (10 digits phone number)"
                            onChange={event => {
                              handleInput(event);
                            }}
                          />
                        </li>
                        <li>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className="required email"
                            placeholder="Email *"
                            onChange={event => {
                              handleInput(event);
                            }}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            name="city"
                            id="city"
                            required=""
                            placeholder="City *"
                            onChange={event => {
                              handleInput(event);
                            }}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            id="aadharid"
                            name="aadharid"
                            className="required phone"
                            placeholder="Aadhar Id Number *"
                            onChange={event => {
                              handleInput(event);
                            }}
                          />
                        </li>
                        <li>
                          <div className="form-group">
                            <label for="skills" className="pull-left">
                              Skills
                            </label>
                            <select
                              className="form-control required"
                              id="skills"
                              name="skills"
                              onChange={event => {
                                handleInput(event);
                              }}
                            >
                              <option>Bowling</option>
                              <option>Batting</option>
                              <option>Wicketkeeper/Batsman</option>
                              <option>All Rounder</option>
                            </select>
                          </div>
                        </li>
                        <li>
                          <div className="input-group">
                            <label for="tshirtsize" className="pull-left">
                              Tshirt Size
                            </label>
                            <br />
                            <div id="radioBtn" className="btn-group btnR">
                              <a
                                id="radio1"
                                onClick={event => setToActive(event)}
                                className={radioState.radio1 ? "btn btn-primary btn-sm active" : "btn btn-primary btn-sm notActive"}
                              >
                                S
                                <input type="hidden" name="tshirtsize" id="tshirtsize" value="S" />
                              </a>
                              <a
                                id="radio2"
                                onClick={event => setToActive(event)}
                                className={radioState.radio2 ? "btn btn-primary btn-sm active" : "btn btn-primary btn-sm notActive"}
                              >
                                XS
                                <input type="hidden" name="tshirtsize" id="tshirtsize" value="XS" />
                              </a>
                              <a
                                id="radio3"
                                onClick={event => setToActive(event)}
                                className={radioState.radio3 ? "btn btn-primary btn-sm active" : "btn btn-primary btn-sm notActive"}
                              >
                                M
                                <input type="hidden" name="tshirtsize" id="tshirtsize" value="M" />
                              </a>
                              <a
                                id="radio4"
                                onClick={event => setToActive(event)}
                                className={radioState.radio4 ? "btn btn-primary btn-sm active" : "btn btn-primary btn-sm notActive"}
                              >
                                L
                                <input type="hidden" name="tshirtsize" id="tshirtsize" value="L" />
                              </a>
                              <a
                                id="radio5"
                                onClick={event => setToActive(event)}
                                className={radioState.radio5 ? "btn btn-primary btn-sm active" : "btn btn-primary btn-sm notActive"}
                              >
                                XL
                                <input type="hidden" name="tshirtsize" id="tshirtsize" value="XL" />
                              </a>
                              <a
                                id="radio6"
                                onClick={event => setToActive(event)}
                                className={radioState.radio6 ? "btn btn-primary btn-sm active" : "btn btn-primary btn-sm notActive"}
                              >
                                XXL
                                <input type="hidden" name="tshirtsize" id="tshirtsize" value="XXL" />
                              </a>
                            </div>
                          </div>
                          <br />
                        </li>
                        <li>
                          <div className="input-group">
                            <label for="income" className="pull-left">
                              Income
                            </label>
                            <br />
                            <div id="radioBtn" className="btn-group income_radio">
                              <a className="btn btn-primary btn-sm  active">Moderate</a>
                            </div>
                            <input type="hidden" name="income" id="income" value="Moderate" />
                          </div>
                          <br />
                        </li>
                        <li className="UploadProfile">
                          <div className="form-group">
                            <label className="pull-left">Upload Your Photo</label>
                            <div className="input-group pull-left">
                              <span className="input-group-btn">
                                <span className="btn btn-default btn-file">
                                  Choose…{" "}
                                  <input
                                    name="profile"
                                    type="file"
                                    onChange={event => onFileChange(event)}
                                    className="required"
                                    id="imgInp"
                                    required=""
                                    accept="image/gif, image/jpeg, image/png"
                                  />
                                </span>
                              </span>
                              <br />
                              <input type="text" className="required" value={formdata.profile.data ? formdata.profile.data.name : ""} readonly="" />
                            </div>
                            <img id="img-upload" src={formdata.profile ? formdata.profile.src : ""} />
                          </div>
                        </li>
                        <li>{errorContent.error && <div className="error">{errorContent.error}</div>}</li>
                        <li>
                          <div
                            className="thbg-color"
                            onClick={() => {
                              verifyform();
                            }}
                            value="Register"
                          >
                            Register
                          </div>
                          {errorContent.errbool ? (
                            <Redirect
                              to={{
                                pathname: "/talentPay",
                                state: { formdata },
                              }}
                            />
                          ) : (
                            <div></div>
                          )}
                        </li>
                      </ul>
                    </form>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="contact-info">
                    <div className="kode-section-title">
                      <h3>Contact Info</h3>
                    </div>
                    <div className="kode-forminfo">
                      <p>Register with us.</p>
                      <ul className="kode-form-list">
                        <li>
                          <img src={homeSrc} />
                          <p>
                            <strong>Address:</strong> Wework, 32 Milestone, Gurgaon
                          </p>
                        </li>
                        <li>
                          <img src={phoneBSrc} />
                          <p>
                            <strong>Phone:</strong> +91 82874 44901 and +91 96505 19854
                          </p>
                        </li>
                        <li>
                          <img src={mailBSrc} />
                          <p>
                            <strong>Email:</strong> cricview15@gmail.com
                          </p>
                        </li>
                        <li>
                          <img src={whatsappBSrc} />
                          <p>
                            <strong>Join WhatsApp group for manual registration and other queries:</strong>
                            <a href="https://chat.whatsapp.com/JwHYkifsExo6sldOE14FBk">Click here to join</a>
                          </p>
                        </li>

                        <li>
                          <hr />
                          <h3>For FREE Registrations</h3>{" "}
                          <p style={{ color: "red" }}>
                            <b>
                              {" "}
                              please contact us via Instagram: <a href="https://www.instagram.com/talenthuntcricview">@TalentHuntCricview</a>
                            </b>{" "}
                            or email: <a href="mailto:cricview15@gmail.com">cricview15@gmail.com</a>
                          </p>
                          <br />
                          <br />
                        </li>
                        <li>
                          <hr />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    </div>
  );
};

export default withLayout(TalentContact);
