export default [
  {
    label: "Home",
    path: "/",
    // navigationItems: [
    //   {
    //     label: "Top Searches",
    //     path: "/#topsearch",
    //   },
    //   {
    //     label: "Feature News",
    //     path: "/#featurenews",
    //   },
    //   {
    //     label: "Must Read",
    //     path: "/#mustread",
    //   },
    // ],
  },
  {
    label: "Blogs",
    path: "/blogs",
  },
  {
    label: "Videos",
    path: "/videos",
  },
  // {
  //   label: "Women Cricket",
  //   path: "/womencricket",
  // },
  {
    label: "Interviews",
    path: "/interviews",
  },
  {
    label: "Talent Hunt",
    path: "/talenthunt",
  },
  {
    label: "Online Talent Hunt",
    path: "/cricketonlinetalenthunt",
  },
  // {
  //   label: "Live Score",
  //   path: "/LiveDetails",
  // }
{
  label: "Covid HelpLine",
  path: "/CovidDetails"
}

];

