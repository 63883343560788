import React from 'react';
import './style.scss'

export  const ArrowLeft = () => {
    return (  
        <span className="arrowLeft"></span>
    );
}


export const ArrowRight = () => {
    return (  
        <span className="arrowRight"></span>
    );
}
 
