import React, { useState, useEffect, Fragment } from "react";
import "./style.scss";

const Mute = (props) => {
  const toggleClass = (e) => {
    e.preventDefault();
        let element = document.getElementById("mute");
        element.classList.toggle("mute");
        props.muteFunc()
      }
    return(
        <div  className="muteContainer">
            <a onClick = {(e) =>toggleClass(e)} id = "mute" href="#" className="speaker">
                <span></span>
            </a>
        </div>
    )
}
export const MuteIos = (props) => {
  const toggleClass = (e) => {
    e.preventDefault();
        let element = document.getElementById("mute");
        element.classList.toggle("mute");
        props.muteFunc()
      }
    return(
        <div  className="muteContainer">
            <a onClick = {(e) =>toggleClass(e)} id = "mute" href="#" className="speaker mute">
                <span></span>
            </a>
        </div>
    )
}


export default Mute;
 