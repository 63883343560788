import { OPEN_YT_MODAL, CLOSE_YT_MODAL } from "../actions/types";
const initialState = {
  isYtModalOpen: false,
  videoId: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OPEN_YT_MODAL:
      return {
        isYtModalOpen: true,
        videoId: action.payload.videoId,
      };
    case CLOSE_YT_MODAL:
      return initialState;
    default:
      return state;
  }
}
