import React, { Component } from 'react';
import './style.scss'
import Axios from 'axios';
import { store } from '../../redux/store';
import { signIn } from '../../redux/helpers/user';
import { closeModal } from "../../redux/helpers/modal";
import { connect } from "react-redux";
import { ArrowDown } from "../../Assets/icons"
import ApiPart from "../../util/apiPart"
// import FacebookLogin from 'react-facebook-login';

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: "",
            countrycode: "91",
            name: '',
            role: "User",
            showRoleMenu: false,
            error: '',
            showOtpScreen: false,
            showRegister: false,
            showEmailForm:false,
            emailId:"",
            password:"",
            emailName:"",
        }
    }
    handleInput = (event) => {
        event.preventDefault();
        const { value, name } = event.target;
        this.setState({ [name]: value })
    }

    responseFacebook = async() => {
        window.FB.login( async(response) => {
            this.testAPI(response);
        }, { scope: 'email, public_profile' })        
    }
    ShowEmailForm = () =>{
        this.setState({showEmailForm : true})
    }

    enterLogin = async () => {
        this.setState({ processing: true })
        if ((this.state.emailName).length === 0) {
            this.alert('Please enter name')
            this.setState({ processing: false })
            return
        }
        if ((this.state.emailId).length === 0) {
            this.alert('Please enter the Email Id.')
            this.setState({ processing: false })
            return
        }
        if ((this.state.password).length === 0) {
            this.alert('Please enter the password.')
            this.setState({ processing: false })
            return
        }
        let profile = {
            email: this.state.emailId,
            password: this.state.password,
            name: this.state.emailName,
            phone: '',
            role : 'user'
        }
        await Axios({
            url: `${ApiPart[0].firstPart}/identity`,
            method: 'POST',
            data: profile
        }).then((response) => {
                if (response.status === 200) {
                    localStorage.setItem("userId", response.data.data.userId)
                    localStorage.setItem("phoneNumber", response.data.data.phone)
                    localStorage.setItem("name", response.data.data.name)
                    localStorage.setItem("userRole", (response.data.data.role).toLowerCase())
                    this.setState({ processing: false })
                    signIn(response.data.data)
                    closeModal()
                    setTimeout(() =>{
                        window.location.href = localStorage.getItem("url");
                        localStorage.removeItem("url")
                    },1000) 
                }
            }).catch((error) => {
                console.log(error);
                this.setState({ processing: false })
                this.alert("Please Enter the Correct Credentials")
            })
    }

    testAPI=async(response) =>{                      // Testing Graph API after login.  See statusChangeCallback() for when this call is made.
        window.FB.api('/me', {fields: 'name,email'}, async function(response) {
            let profile = {
                email: response.email,
                name: response.name,
                phone: '',
                role : 'user'
            }
    
            await Axios({
                url: `${ApiPart[0].firstPart}/identity`,
                method: 'POST',
                data: profile
            })
                .then((response) => {
                    if (response.status === 200) {
                        localStorage.setItem("userId", response.data.data.userId)
                        localStorage.setItem("phoneNumber", response.data.data.phone)
                        localStorage.setItem("name", response.data.data.name)
                        localStorage.setItem("userRole", (response.data.data.role).toLowerCase())
                        signIn(response.data.data)
                        closeModal()
                        setTimeout(() =>{
                            window.location.href = localStorage.getItem("url");
                            localStorage.removeItem("url")
                        },1000) 
                    }
                }).catch((error) => {
                    console.log(error);
                    this.setState({ processing: false })
                    this.alert("Please Register Yourself Before Signin. Click on Register Button Below")
                })


        });
      }
    
    AttemptLogin = async () => {
        this.setState({ processing: true })
        if ((this.state.countrycode.length < 1) || !Number(this.state.countrycode)) {
            this.alert('Please enter valid country code.')
            this.setState({ processing: false })
            return
        }
        if (((this.state.phone).length !== 10) || !Number(this.state.phone)) {
            this.alert('Please enter valid number.')
            this.setState({ processing: false })
            return
        }
        if (this.state.showRegister && this.state.name.length === 0) {
            this.alert('Please enter the name.')
            this.setState({ processing: false })
            return
        }
        let data = this.state.showRegister ? {
            phone: `+${this.state.countrycode}${this.state.phone}`,
            name: this.state.name,
            role: (this.state.role).toLowerCase(),
            requestType: 'send'
        } : {
                phone: `+${this.state.countrycode}${this.state.phone}`,
                requestType: 'send'
            }
        await Axios({
            url: `${ApiPart[0].firstPart}/identity`,
            method: 'POST',
            data: data
        })
            .then((response) => {
                if (response.status === 200) {
                    this.setState({ showOtpScreen: true })
                    this.setState({ processing: false })
                }
            }).catch((error) => {
                this.setState({ processing: false })
                this.alert("Please Register Yourself Before Signin. Click on Register Button Below")
            })
    }
    showRoleMenu = () => {
        this.setState({ showRoleMenu: !this.state.showRoleMenu })
    }
    handleRoleSelect = (event) => {
        const { value } = event.target.dataset;
        this.setState({ role: value })
    }
    handleCountryCodeSelect = (event) => {
        const { value } = event.target;
        this.setState({ countrycode: value })
    }
    verifyOtp = async () => {
        this.setState({ processing: true })
        let otp = "" + this.phoneCodeOne.value + this.phoneCodeTwo.value + this.phoneCodeThree.value + this.phoneCodeFour.value;
        if ((otp.length !== 4) || !Number(otp)) {
            this.alert('Incorrect OTP')
            this.setState({ processing: false })
            return
        }
        let data = this.state.showRegister ? {
            phone: `+${this.state.countrycode}${this.state.phone}`,
            name: this.state.name,
            role: (this.state.role).toLowerCase(),
            requestType: 'verify',
            otpValue: `${otp}`
        } : {
                phone: `+${this.state.countrycode}${this.state.phone}`,
                requestType: 'verify',
                otpValue: `${otp}`
            }
        await Axios({
            url: `${ApiPart[0].firstPart}/identity`,
            method: 'POST',
            data: data
        }).then((response) => {
            if (response.status === 200) {
                this.setState({ processing: false })
                localStorage.setItem("userId", response.data.data.userId)
                localStorage.setItem("phoneNumber", response.data.data.phone)
                localStorage.setItem("name", response.data.data.name)
                localStorage.setItem("userRole", (response.data.data.role).toLowerCase())
                signIn(response.data.data)
                closeModal()
                
                setTimeout(() =>{
                window.location.href = localStorage.getItem("url");
                localStorage.removeItem("url")
                },1000) 
            }
            else {
                this.setState({ processing: false })
                this.alert('An error Occured. Please try again.')
            }
        }).catch((err) => {
            this.setState({ processing: false })
            this.alert('Invalid OTP')
        })
    }
    otpTextChange = (input) => {
        let classValueInput = input.target.id;
        switch (classValueInput) {
            case "otp1":
                this.phoneCodeTwo.focus();
                break;
            case "otp2":
                this.phoneCodeThree.focus();
                break;
            case "otp3":
                this.phoneCodeFour.focus();
                break;
            case "otp3":
                this.phoneCodeOne.focus();
                break;
            default:
                break;
        }
    }
    handleRegisterAndLogin = () => {
        this.setState({ error: "" })
        this.setState({ showRegister: !(this.state.showRegister) })
    }
    alert(msg) {
        this.setState({ error: msg })
        setTimeout(_ => this.setState({ error: null }), 3000)
    }
    render() {
        const roleOpen = this.state.showRoleMenu ? "show" : "hide"
        return (this.state.processing ? <div className="loader"></div> : <div className="cricview-register-container">
            <div className="cricview-register-wrapper">
                <div className={`login-wrapper ${!this.state.showOtpScreen ? "show" : "hide"}`}>
                    <div className="welcome-class">Welcome!</div>
                    <div className="welcome-class"><img src={require("../../Assets/cricview-logo-redesign.png")}></img></div>
                    <div className="form-class"></div>
                    {this.state.showEmailForm ? 
                    <div className="emailForm">
                           <form>
                                <div className="input-cont">
                                    <label>Name</label>
                                    <input type="text" name="emailName" value={this.state.emailName} onChange={(event) => this.handleInput(event)} autoComplete="new-password"/>
                                </div>
                                <div className="input-cont">
                                    <label>Email Id</label>
                                    <input type="email" name="emailId" value={this.state.emailId} onChange={(event) => this.handleInput(event)} autoComplete="new-password"/>
                                </div>
                                <div className="input-cont">
                                    <label>Password</label>
                                    <input type="password" name="password" value={this.state.password} onChange={(event) => this.handleInput(event)} autoComplete="new-password"/>
                                </div>
                                {this.state.error && <div className="error centerError">
                            {this.state.error}
                        </div>}
                                <div className="emailLog" onClick={() =>this.enterLogin()}>
                                    Log In/Sign Up
                                </div>
                                {/* <div className="forgetPassword">
                                <span>Forget password ?</span>
                                </div> */}
                         </form>
                    </div>
                    :
                    <form className="register-login-form">
                        <div className="input-container">
                            <div className="dropdown countrycode">
                                <div className="dropbtn">{this.state.countrycode} <ArrowDown></ArrowDown></div>
                                <ul className="dropdown-content">
                                    <li value="91" onClick={(event) => this.handleCountryCodeSelect(event)}>IN</li>
                                    <li value="1" onClick={(event) => this.handleCountryCodeSelect(event)}>US</li>
                                </ul>
                            </div>
                            <input className="input mobilenumber" type="tel" id="phone" name="phone" value={this.state.phone} placeholder="Enter Mobile Number" pattern="[0-9]" maxLength="10" onChange={(event) => this.handleInput(event)}></input>
                        </div>
                        {this.state.showRegister && <div className="input-container">
                            <input className="input" type="text" id="name" name="name" value={this.state.name} placeholder="Enter Name" onChange={(event) => this.handleInput(event)}></input>
                        </div>}
                        {this.state.showRegister && <div className="role-dropdown">
                            <span className="role">Role: </span>
                            <div className="role-value" onClick={() => this.showRoleMenu()}><span>{this.state.role}</span>
                                <ul className={`role-list ${roleOpen}`}>
                                    <li data-value="User" className="role-list-item" onClick={(e) => this.handleRoleSelect(e)}>User</li>
                                </ul>
                            </div>
                        </div>}
                        {this.state.error && <div className="error">
                            {this.state.error}
                        </div>}
                        <div className="login-button" style={{ pointerEvents: this.state.processing ? "none" : "auto" }} onClick={() => this.AttemptLogin()}>{this.state.showRegister ? "Register" : "Log In"}</div>
                        <div style={{ height: "50px" }}></div>
                        <div className="account">{this.state.showRegister ? "Already have account ? " : "Don't have account  ? "}<div className="account-change" onClick={() => this.handleRegisterAndLogin()}>{this.state.showRegister ? "Login" : "Register"}</div></div>
                    </form>
                }
                  {!this.state.showEmailForm && 
                                  <div className="loginWith">
                                  <div className="fbb-login-button" style={{background:'#4267B2'}}  onClick={this.responseFacebook}>
                                      LOGIN WITH FACEBOOK
                                  </div>
                                  <div className="textOr">OR</div>
                                  <div className="emailLogin" onClick={this.ShowEmailForm}>
                                      LOGIN WITH EMAIL
                                  </div>
                              </div>
                  }
                </div>
                <div className={`otp-wrapper ${this.state.showOtpScreen ? 'show' : 'hide'}`}>
                    <div className="otp-message">Please enter your otp recieved on {this.state.phone}</div>
                    <div className="otp-div">
                        <input type="tel" id="otp1" maxLength="1" name="digit1" placeholder="" className="input-otp" autoFocus onChange={this.otpTextChange} ref={i => { this.phoneCodeOne = i }}></input>-
                        <input type="tel" id="otp2" maxLength="1" name="digit2" placeholder="" className="input-otp" onChange={this.otpTextChange} ref={i => { this.phoneCodeTwo = i }}></input>-
                        <input type="tel" id="otp3" maxLength="1" name="digit3" placeholder="" className="input-otp" onChange={this.otpTextChange} ref={i => { this.phoneCodeThree = i }}></input>-
                        <input type="tel" id="otp4" maxLength="1" name="digit4" placeholder="" className="input-otp" onChange={this.otpTextChange} ref={i => { this.phoneCodeFour = i }}></input>
                    </div>
                    {this.state.error && <div className="error">
                        {this.state.error}
                    </div>}
                    <div className="verify-otp" style={{ pointerEvents: this.state.processing ? "none" : "auto" }} onClick={() => this.verifyOtp()}>Submit</div>
                </div>
            </div>
        </div >);
    }
}

export default connect(state => ({ content: state.user }))(Register);