import React, { Fragment, useState, useEffect } from "react";
import TalentHuntFooter from "../../Components/Talenthunt-Footer";
import TalentHuntHeader from "../../Components/Talenthunt-Header";
import { Redirect } from "react-router-dom";
import withLayout from "../../layout/default";
import "./style.scss";
import axios from "axios";
import LatestVideo from "../../Module/LatestVideoModule";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert";

const mailBSrc = require("../../Assets/mail-black.png");
const phoneBSrc = require("../../Assets/phone-black.png");
const homeSrc = require("../../Assets/home-black.png");
const whatsappBSrc = require("../../Assets/whatsapp-black.png");

const TalentOnline = ({ content }) => {
  const [formdata, setData] = useState({
    name: "",
    age: 0,
    phone: 0,
    email: "",
    insta: "",
    rolemodel:"",
    city: "",
    tshirtsize: "S",
    skills: "Bowling",
    income: "Moderate",
    profile: {},
  });
  const [contentProp, setContentProp] = useState({});

  const { Articles = {}, Instagram = {}, Twitter = {}, articles = [], youtube = {} } = contentProp || {};
  const { top = [], latest = [] } = youtube;
  const { latestPosts = [], latestVideos = [], topVideos = [] } = Instagram;
  const { cricviewCrawler = [], latestCricview = [], topCricview = [] } = Articles;
  const [radioState, setradioState] = useState({ radio1: true, radio2: false, radio3: false, radio4: false, radio5: false, radio6: false });
  const [errorContent, setError] = useState({ error: "", errbool: false });
  const [loader, setLoader] = useState(false)
  useEffect(() => {
    if (Object.keys(content).length > 0) {
      setContentProp(content);
    }
  }, [content]);
  const handleInput = event => {
    event.preventDefault();
    const { value, name } = event.target;
    setData(state => ({ ...state, [name]: value }));
    setError(state => ({ ...state, error: "" }));
  };
  const setToActive = event => {
    event.preventDefault();

    for (const property in radioState) {
      if (property === event.target.id) {
        setradioState(state => ({ ...state, [property]: true }));
      } else {
        setradioState(state => ({ ...state, [property]: false }));
      }
    }
    const name = event.target.children[0].name;
    const value = event.target.children[0].value;
    setData(state => ({ ...state, [name]: value }));
  };

  let razorPayBtn = React.createRef();

  const verifyform = async () => {
    if (formdata.name.length === 0) {
      setError(state => ({ ...state, error: '"Please enter the name.!!"' }));
      return;
    }
    if (formdata.emaill.length === 0) {
      setError(state => ({ ...state, error: '"Please enter Email Address!!"' }));
      return;
    }
    if (formdata.insta.length === 0) {
      setError(state => ({ ...state, error: '"Please enter Insta Id .!!"' }));
      return;
    }
    if (formdata.age == 0 && !Number(formdata.age)) {
      setError(state => ({ ...state, error: '"Please enter valid age.!!"' }));
      return;
    }
       if (formdata.phone.length !== 10 ) {
    setError(state => ({ ...state, error: '"Please add Phone number.!!"' }));
    return;
     }
 
    if (formdata.city.length === 0) {
      setError(state => ({ ...state, error: '"Please enter the city .!!"' }));
      return;
    }
    if (formdata.rolemodel.length === 0) {
      setError(state => ({ ...state, error: '"Please enter the roleModel .!!"' }));
      return;
    }
       if (Object.keys(formdata.profile).length === 0) {
      setError(state => ({ ...state, error: '"Please Upload Your Video.!!"' }));
      return;
    }

    setLoader(true)
    setTimeout(() => {
      setLoader(false)
      confirmAlert({
        title: `${"Your payment and video both are under process. We will revert you once verified."}`,
        buttons: [
          {
            label: 'OK',
            id: 'OK',
            onClick: () => {
              return false
            }
          }
        ]
      });
    }, 10000)
    // setError(state => ({ ...state, error: "" }));
    // if (formdata.phone.length !== 10 && !Number(formdata.phone)) {
    //   setError(state => ({ ...state, error: '"Please enter valid number.!!"' }));
    //   return;
    // }
    // if (formdata.age == 0 && !Number(formdata.age)) {
    //   setError(state => ({ ...state, error: '"Please enter valid age.!!"' }));
    //   return;
    // }
    // // let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    // if (formdata.email.length == 0) {
    //   setError(state => ({ ...state, error: '"Invalid Email Address!!"' }));
    //   return;
    // }
    // if (formdata.name.length === 0) {
    //   setError(state => ({ ...state, error: '"Please enter the name.!!"' }));
    //   return;
    // }
    // if (formdata.city.length === 0) {
    //   setError(state => ({ ...state, error: '"Please enter the city .!!"' }));
    //   return;
    // }

    // if (Object.keys(formdata.profile).length === 0) {
    //   setError(state => ({ ...state, error: '"Please Upload Your Video.!!"' }));
    //   return;
    // }
    // if (errorContent.error.length === 0) {
    //   setError(state => ({ ...state, errbool: true }));
    // }

    // const headers = {
    //   'Access-Control-Allow-Origin': '*',
    //   'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    // }

    // await axios.post(`https://api.razorpay.com/v1/orders`,{}, headers, {
    //   auth: {
    //     username: "rzp_test_NA5tfgNfnxPaPd",
    //     password: "rckAuTx0oio5neQVrFbg23Mm"
    //   }
    // }
    // ).then(res => {
    //   console.log(res);

    // }); 

    // var options = {
    //   "key": "rzp_test_NA5tfgNfnxPaPd", // Enter the Key ID generated from the Dashboard
    //   "amount": "10000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    //   "currency": "INR",
    //   "name": "CricView Online Talent Hunt",
    //   "description": "NexgenIOT Solutions",
    //   "image": "https://www.cricview.in/wp-content/uploads/2019/03/cricview-logo-redesign.png",
    //   "order_id": "order_FJMMsZYH9BXUyo", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    //   "handler": function (response) {
    //     alert(response.razorpay_payment_id);
    //     alert(response.razorpay_order_id);
    //     alert(response.razorpay_signature)
    //   },
    //   "prefill": {
    //     "name": "Piyush Aggarwal",
    //     "email": "piyush2official@gmail.com",
    //     "contact": "9711593110"
    //   },
    //   "notes": {
    //     "address": "NexgenIOT Solutions"
    //   },
    //   "theme": {
    //     "color": "#F37254"
    //   }
    // };

    // var rzp1 = new window.Razorpay(options);
    // rzp1.open();
  };
  const onFileChange = event => {
    let fileData = { data: event.target.files[0], src: URL.createObjectURL(event.target.files[0]) };
    setData(state => ({ ...state, profile: fileData }));
  };
  if (loader) {
    return <div style={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: "400px", width: "100%" }}><div className="loader"></div></div>
  }
  return (
    <Fragment>
      <div className="row row_1 marginT">
        <LatestVideo
          contentData={[...topVideos]}
          moduleName={"Most Happening on Talent Hunt"}
          enableShowMore={true}
          showMoreNavigate="/interviews"
          styles={true}
        />
      </div>
      <div className="cricview-talnet-contact">
        {/* <TalentHuntHeader /> */}
        <Fragment>
          <section id="contant" className="contant main-heading team">
            <div className="row">
              <div className="container">
                <div className="contact">
                  <div className="col-md-12">
                    <div className="contact-us">
                      <h2>Online Registration is open</h2>
                      <form className="comments-form" id="contactform">
                        <br />
                        <ul>
                          <li>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              className="required"
                              placeholder="Name *"
                              onChange={event => {
                                handleInput(event);
                              }}
                            />
                          </li>
                          <li>
                            <input
                              type="text"
                              id="emaill"
                              name="emaill"
                              className="required"
                              placeholder="Email *"
                              onChange={event => {
                                handleInput(event);
                              }}
                            />
                          </li>
                          <li>
                            <input
                              type="text"
                              id="insta"
                              name="insta"
                              className="required"
                              placeholder="Instagram Id *"
                              onChange={event => {
                                handleInput(event);
                              }}
                            />
                          </li>
                          <li>
                            <input
                              type="number"
                              id="age"
                              name="age"
                              className="required"
                              placeholder="Age *"
                              onChange={event => {
                                handleInput(event);
                              }}
                            />
                          </li>
                          <li>
                            <input
                              type="number"
                              id="phone"
                              name="phone"
                              className="required phone"
                              minlength="10"
                              maxlength="10"
                              required=""
                              placeholder="Phone * (10 digits phone number)"
                              onChange={event => {
                                handleInput(event);
                              }}
                            />
                          </li>

                          <li>
                            <input
                              type="text"
                              name="city"
                              id="city"
                              required=""
                              placeholder="City *"
                              onChange={event => {
                                handleInput(event);
                              }}
                            />
                          </li>

                          <li>
                            <input
                              type="text"
                              id="rolemodel"
                              name="rolemodel"
                              className="required"
                              placeholder="Role Model in Cricket *"
                              onChange={event => {
                                handleInput(event);
                              }}
                            />
                          </li>

                          <li>
                            <div className="form-group">
                              <label for="skills" className="pull-left">
                                Skills
                            </label>
                              <select
                                className="form-control required"
                                id="skills"
                                name="skills"
                                onChange={event => {
                                  handleInput(event);
                                }}
                              >
                                <option>Bowling</option>
                                <option>Batting</option>
                                <option>Wicketkeeper/Batsman</option>
                                <option>All Rounder</option>
                              </select>
                            </div>
                          </li>




                          <li>
                            <div className="form-group">
                              <label for="skills" className="pull-left">
                                Select Category You are Selected In
                            </label>
                              <select
                                className="form-control required"
                                id="Category "
                                name="Category "
                                onChange={event => {
                                  handleInput(event);
                                }}
                              >
                                <option>100 Rs - Win Upto 2000</option>
                                <option>200 Rs - Win Upto 4000</option>
                                <option>500 Rs - Win Upto 6000</option>
                                <option>1000 Rs - Win Upto 10000</option>
                                <option>2000 Rs - Win Upto 30000</option>
                                <option>5000 Rs - Win Upto 75000</option>
                                <option>15000 Rs - Win Upto 10 Lakh</option>
                              </select>
                            </div>
                          </li>

                          <li>
                            <div className="form-group">
                              <label for="skills" className="pull-left">
                                If you are caught cheating by increasing your views by fake mechanism, we will disqualify you with no refunds. Agree? *
                            </label>
                              <select
                                className="form-control required"
                                id="Category "
                                name="Category "
                                onChange={event => {
                                  handleInput(event);
                                }}
                              >
                                <option>YES</option>
                              </select>
                            </div>
                          </li>

                          <li className="UploadProfile">
                            <div className="form-group">
                              <label className="pull-left">Upload Your Video</label>
                              <div className="input-group pull-left">
                                <span className="input-group-btn">
                                  <span className="btn btn-default btn-file">
                                    Choose…{" "}
                                    <input
                                      name="profile"
                                      type="file"
                                      onChange={event => onFileChange(event)}
                                      className="required"
                                      id="imgInp"
                                      required=""
                                      accept="video/*"
                                    />
                                  </span>
                                </span>
                                <br />
                                <input type="text" className="required" value={formdata.profile.data ? formdata.profile.data.name : ""} readonly="" />
                              </div>
                              {/* <img id="img-upload" src={formdata.profile ? formdata.profile.src : ""} /> */}
                            </div>
                          </li>

                          <li>{errorContent.error && <div className="error">{errorContent.error}</div>}</li>

                          <li>
                            <div
                              className="thbg-color"
                              id="rzp-button1"
                              onClick={() => {
                                verifyform();
                              }}
                              ref={razorPayBtn}
                              value="Register"
                            >
                              Register and Pay
                          </div>
                            {

                            }
                          </li>
                        </ul>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Fragment>
      </div>
    </Fragment>

  );
};

export default connect(state => ({ content: state.content }))(withLayout(TalentOnline));
