import React from "react"
import withLayout from "../../layout/default"
import "./style.scss"

const UploadVideo = () => {
    return (
        <div className="upload-container">
            <div className="uploadText"><span style={{ color: "#070799" }}>ADD </span><span style={{ color: "#33d9ff" }}>VIDEOS</span></div>
            <div className="uploadImage"><img src={require("../../Assets/uploadVideo.png")}></img></div>
            <div className="uploadButton"><button>Upload Now</button></div>
        </div>
    )
}

export default UploadVideo;