import React, { Fragment, useEffect } from "react";
import Header from "../Module/Header";
import LanguageBar from "../Components/LanguageBar";
import Sticky from "../Components/Sticky";
import Footer from "../Components/Footers";
import { useLocation } from "react-router-dom";
import { openModal } from "../redux/helpers/modal";
import { DOODLE_CRICKET_MODAL } from "../constant/modal-type";
import MobileBanner from "../Components/MobileBanner";
import Carousel from "react-grid-carousel";
import MyDot from "../Components/customizedDots";
import GameCarousel from "../Components/GameBanner";

const withLayout = (Component, showBanner = true, showLanguageBar = true) => props => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Fragment>
      {showBanner && <GameCarousel></GameCarousel>}
      <Sticky>
        <div className="row">
          {showLanguageBar && <LanguageBar />}
          <Header />
        </div>
      </Sticky >
      <div style={{minHeight: "calc(100vh - 280px)"}}>
      <Component {...props} />
      </div>
      <div className="row">
        <Footer />
      </div>
    </Fragment >
  );
};

export default withLayout;
