import React, { Fragment, useEffect, useRef, useState } from "react";
import "./sticky.css";

export default props => {
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const handleScroll = () => {
    try {
      if (ref.current) {
        setSticky(ref.current.getBoundingClientRect().top <= 0);
      }
      var header = document.getElementById("header");
      var sticky = header.offsetTop;
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky-wrapper");
      } else {
        header.classList.remove("sticky-wrapper");
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  return (
    <Fragment>
      <div id="header" ref={ref}>
        {props.children}
      </div>
    </Fragment>
  );
};
