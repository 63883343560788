import React from 'react';
const MyDot = ({ isActive }) => (
    <span
        style={{
            display: 'inline-block',
            height: isActive ? '11px' : '8px',
            width: isActive ? '11px' : '8px',
            background: isActive ? '#ffffff' : "#ff3333",
            borderRadius: "50%"

        }}
    ></span>
)
export default MyDot;