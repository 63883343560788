import React, { Fragment } from "react";
import "./languagebar.scss";
import { ExitApp, ArrowDown } from "../../Assets/icons";
import { openModal } from "../../redux/helpers/modal";
import { SIGN_IN_MODAL } from "../../constant/modal-type";
import { fetchContent } from "../../redux/helpers/content";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class LanguageBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTeam: "Australia",
      isLangVisible: false,
      selectedCountry: "India",
      isCountryVisible: false,
      isTeamVisible: false,
      active: localStorage.getItem("myLanguage") || "en",

    };
    this.Reflang = React.createRef(null);
    this.RefCntry = React.createRef(null);
    this.RefTeam = React.createRef(null);
  }
  showLogin = () => {
    openModal(SIGN_IN_MODAL);
  };
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  changeLanguageToEng = () => {
    if (this.state.active !== "en") {
      this.setState({ active: "en" });
      document.getElementsByClassName("loader-container")[0].style.display = "block";
      fetchContent("en");
    }
  };
  changeLanguageToHindi = () => {
    if (this.state.active !== "hi") {

      this.setState({ active: "hi" });
      document.getElementsByClassName("loader-container")[0].style.display = "block";
      fetchContent("hi");
    }
  };
  handleExit = () => {
    localStorage.clear();
    window.FB.logout(function(response) {
      // user is now logged out
    });
    window.location.replace(window.location.origin);
  }
  render() {
    const { content } = this.props;
    // const { name = null, role = "user" } = content;
    const name = localStorage.getItem("name");
    const role = localStorage.getItem("userRole");

    return (
      <Fragment>
        <div className="loader-container">
          <div id="loader">
          </div>
        </div>
        <div className="language-bar-container no-padding ">
          <div className="tab-container">
            <div className="dropdown" >
              <div style={{ display: "flex", alignItems: "center" }} onClick={() => !name && this.showLogin()}>
                <img src={require("../../Assets/profiile.png")} alt="" />
                <div style={{ margin: "0 10px", cursor: name ? "auto" : "pointer" }}>{name ? name : "Log In"}</div>
                {name && <ArrowDown></ArrowDown>}
              </div>
              {name && <ul className="dropdown-content">
                {((role.toLowerCase() === "admin") || (role.toLowerCase() === "author")) && <li><Link style={{ textDecoration: "none", color: "black" }} to="/editor">Create Article</Link></li>}
                {role.toLowerCase() === "admin" && <li><Link style={{ textDecoration: "none", color: "black" }} to="/createMatch">Create Match</Link></li>}
                {role.toLowerCase() === "admin" && <li><Link style={{ textDecoration: "none", color: "black" }} to="/allmatches">All Matches</Link></li>}
                {name && <li style={{ cursor: "pointer" }} onClick={() => this.handleExit()}>Logout</li>}
              </ul>}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default connect(state => ({ content: state.user }))(LanguageBar);