import React, { Fragment, useState, useEffect } from "react"
import "./style.scss";
import Axios from "axios";
import Carousel from "react-grid-carousel";
import ApiPart from "../../util/apiPart"

const LiveTray = () => {
    const [liveMatchData, setLiveMatchData] = useState([]);
    useEffect(() => {
        Axios({
            url: `${ApiPart[0].firstPart}/getAllMatchData`,
            method: "GET"
        })
            .then((response) => {
                const newMatchData = response.data.data.items.filter(item => { return item.matchStatus != "ended" })
                setLiveMatchData(newMatchData);
            }).catch((error) => {
                console.log(error)
            })
    }, [])
    const responsive = [
        {
            breakpoint: 1024,
            cols: 4,
        },
        {
            breakpoint: 768,
            cols: 3,
        }
    ];
    const stylee = {
        width: `${liveMatchData.length && liveMatchData.length * 260}px`
    }
    const StartTimeMatch = (props) => {
        let fullTime = parseInt(props.startTime);
        let d = new Date(fullTime);
        let date = d.toLocaleDateString();
        let time = d.toLocaleTimeString('en-US', { hour12: true });
             return (
            <Fragment>
                <div className="endText">Match will Start at :</div><div> {time}</div> <div>On</div><div>{date}</div>
            </Fragment>
        )
    }
    const EndTime = (props) => {
        let fullTime = parseInt(props.duration);
        let d = new Date(fullTime);
        let date = d.toLocaleDateString();
        let time = d.toLocaleTimeString('en-US', { hour12: true });
             return (
            <Fragment>
                <div className="endText">Match will End at :</div><div> {time}</div> <div>On</div><div>{date}</div>
            </Fragment>
        )
    }
    return ( null
        // <Fragment>
        //     <div className="liveTrayBackground">

        //         <div className="liveTray-container">
        //             <div className="liveTray">
        //                 <div>CricView Talent Hunt</div>
        //                 <div className="liveGif">
        //                     <img src={require("../../Assets/live3.gif")} alt="LIVE" />
        //                 </div>
        //                 <div>Matches</div>
        //             </div>
        //             <div className="carousel-container">
        //                 <Carousel
        //                     cols={5}
        //                     rows={1}
        //                     loop
        //                     showDots={false}
        //                     gap={10}
        //                     responsiveLayout={responsive}
        //                     autoplay={3000}
        //                     dot={false}
        //                     hideArrow={true}
        //                 >

        //                     {liveMatchData.length > 0 ? liveMatchData.map((item, index) => {
        //                          var matchName = `<div class="matchName">${item.matchName}</div>`
        //                         return (
        //                             <Carousel.Item key={index}>
        //                                 <div className="card" key={index} onClick={() => window.location.assign(window.location.origin + "/match/" + item.id)}>
        //                                     <div className="cricView"><img src={require("../../Assets/cricview_logo-192x192.png")} /></div>
        //                                     <div className="title" dangerouslySetInnerHTML={{ __html: `${matchName}` }}></div>
        //                                     {/* <div className="score" >75% - 25%</div> */}
        //                                     <div className="countDown">{item.matchStatus==="notstarted" ? <StartTimeMatch startTime={item.startTime}/> : <EndTime duration={item.endTime} /> }</div>
        //                                     <div style={{fontSize: "15px"}}>👁️ <span style={{ color: "white", fontSize: "15px", fontWeight: "700", letterSpacing: "1px" }}>{item.totalCount*5} </span></div>
        //                                     <div className="sharing-footer">
        //                                         <div className="share-btns">
        //                                             <div className="share-btn-container">
        //                                             <a onClick = {(event) => {  event.stopPropagation();}} href={` http://twitter.com/share?text&url=${window.location.origin}/match/${item.id}`} target="_blank" rel="noopener noreferrer">
        //                                                 <i className="">
        //                                                 <img  style={{ width: "25px" }} className="icon" src={require("../../Assets/twitter@3x.png")} alt="" />
        //                                                 </i>
        //                                             </a>
        //                                             <div className="underline site-background-color" />
        //                                             </div>
        //                                             <div className="share-btn-container">
        //                                             <a onClick = {(event) => {  event.stopPropagation();}} href={`http://www.facebook.com/sharer.php?u=${window.location.origin + "/match/" + item.id}`} target="_blank" rel="noopener noreferrer">
        //                                                 <i className="ion-social-facebook">
        //                                                 <img  style={{ width: "25px" }} className="icon" src={require("../../Assets/fb@3x.png")} alt="" />
        //                                                 </i>
        //                                             </a>
        //                                             <div className="underline site-background-color" />
        //                                             </div>
        //                                             <div className="share-btn-container">
        //                                             <a onClick = {(event) => {  event.stopPropagation();}} id="whatsApforDesktop" href={`https://web.whatsapp.com/send?&text=`+ encodeURIComponent(window.location.origin+"/match/" + item.id)}   target="_blank" rel="noopener noreferrer">
        //                                                 <i className="ion-ios-email">
        //                                                 <img style={{ width: "29px" }} className="icon" src={require("../../Assets/WhatsApp.png")} alt="" />
        //                                                 </i>
        //                                             </a>
        //                                             <a onClick = {(event) => {  event.stopPropagation();}} id="whatsApforMobile"  href={`whatsapp://send?&text=${window.location.origin+"/match/" + item.id}`} target="_blank" rel="noopener noreferrer">
        //                                                 <i className="ion-ios-email">
        //                                                 <img style={{ width: "29px" }} className="icon" src={require("../../Assets/WhatsApp.png")} alt="" />
        //                                                 </i>
        //                                             </a>
        //                                             <div className="underline site-background-color" />
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                 </div>

        //                             </Carousel.Item>
        //                         )
        //                     }) :
        //                         <Fragment> There is no Match for now. Please wait for new Matches</Fragment>}
        //                 </Carousel>
        //             </div>
        //             <div className="voteText"><div className="vote"><img src={require("../../Assets/voteNow.gif")} /></div><div className="now">NOW</div><div className="ex">!</div></div>
        //         </div>



        //     </div>
        //     <div className="border1"></div>
        //     <div className="border1"></div>
        //     <div className="border1"></div>
        // </Fragment>
    )
}

export default LiveTray;