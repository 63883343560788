import React, { Fragment, useState, useEffect } from "react";
import TalentHuntFooter from "../../Components/Talenthunt-Footer";
import TalentHuntHeader from "../../Components/Talenthunt-Header";
import { Redirect } from "react-router-dom";
import withLayout from "../../layout/default";

import "./style.scss";

const TalentPay = ({ data: propsData }) => {
  const [ContentData, setcontent] = useState({});
  const [isRedirect, setRedirect] = useState(false);
  useEffect(() => {
    const data = propsData.location.state ? { ...propsData.location.state.formdata } : false;
    if (!data) {
      setRedirect(true);
    }
    setcontent(data);
  }, []);

  return (
    <div className="row row1">
      <div className="cricview-talnet-pay">
        <TalentHuntHeader />
        <Fragment>
          {isRedirect ? (
            <Redirect
              to={{
                pathname: "/talentContact",
              }}
            />
          ) : (
            <div></div>
          )}
          <div className="candidate_details">
            {ContentData ? (
              <table>
                <tbody>
                  <tr>
                    <td width="200">Name: </td>
                    <td>{ContentData.name}</td>
                  </tr>
                  <tr>
                    <td>Age: </td>
                    <td>{ContentData.age}</td>
                  </tr>
                  <tr>
                    <td>Phone: </td>
                    <td>{ContentData.phone}</td>
                  </tr>
                  <tr>
                    <td>Email: </td>
                    <td>{ContentData.email}</td>
                  </tr>
                  <tr>
                    <td>City: </td>
                    <td>{ContentData.city}</td>
                  </tr>
                  <tr>
                    <td>AadharID: </td>
                    <td>{ContentData.aadharid}</td>
                  </tr>
                  <tr>
                    <td>Skill: </td>
                    <td>{ContentData.skills}</td>
                  </tr>
                  <tr>
                    <td>Tshirt Size: </td>
                    <td>{ContentData.tshirtsize}</td>
                  </tr>
                  <tr>
                    <td>Amount: </td>
                    <td>Rs.2000</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>(Rs.1650 + GST + Processing Fees)</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div>NO Data</div>
            )}
          </div>
          <form className="razorpay_form">
            <input type="submit" value="Pay Now" className="razorpay-payment-button" />
          </form>
        </Fragment>
      </div>
    </div>
  );
};

export default withLayout(TalentPay);
