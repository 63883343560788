import React, { Fragment, useState, useEffect } from "react";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

import Carousel from "react-grid-carousel";
import { connect } from "react-redux";
import { Pause, Play } from "../../Assets/icons";
import { parseDate, navigateTo, linkProps } from "../../util";
import withLayout from "../../layout/default";
import { getModuleData } from "../../util/getModuleData";
import TalentHuntFooter from "../../Components/Talenthunt-Footer";
import talenthuntData from "./constant";

import "./style.scss";
import { Link } from "react-router-dom";
import MyDot from "../../Components/customizedDots";
import { ArrowLeft, ArrowRight } from '../../Components/customizedArrows';

const youtubeSrc = require("../../Assets/you@3x.png");
const instaSrc = require("../../Assets/insta@3x.png");
const facebookSrc = require("../../Assets/fb@3x.png");
const logoSrc = require("../../Assets/cricketalenthunt-logo.png");

const TalentHunt = ({ content, registrationClosed }) => {
  let moduleName = null;
  let contentData = null;

  const [contentProp, setContentProp] = useState({});
  let topSearchData = [];
  let topSearchModuleName = "";
  if (contentProp && contentProp.data) {
    const { articles: tsData, module: tsName } = getModuleData(contentProp.data, "Top Articles") || {}; //Cricview  Latest articles
    topSearchData = tsData;
    moduleName = tsName;
    contentData = [...topSearchData.slice(0, 6)];
  }
  useEffect(() => {
    if (Object.keys(content).length > 0) {
      setContentProp(content);
    }
  }, [content]);
  useEffect(() => {
    registrationClosed && confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui extra'>
            <h2>Registrations for 2nd Cricview Talent Hunt for Academies is Closed for Year 2020.</h2>
            <p className="ui-text">Please Email us for Further Queries.</p>
            <p className="ui-email"><b>Email: Cricview15@gmail.com</b></p>
            <div className="ui-separator"><hr /></div>

            {/* <div className="ui-process">
              <h3>2nd Cricview Talent Hunt Trials Process : </h3>
              <div className="ui-rounds">
                <p><strong>Round 1:</strong> Video Based Trials on Cricview Website.</p>

                <p><strong>Round 2:</strong> Those who Clear First Round will be called at Ground for Field Trials.</p>
              </div>
              <p class="comingSoon">Dates will be Announced Soon...</p>
            </div> */}
            <div className="ui-button-container">
              <button className="ui-button" onClick={onClose}>Ok</button>
            </div>

          </div>
        );
      }
    });
  }, [])
  const [marqueeStatus, setMarqueeStatus] = useState("stop");
  const [paused, setPaused] = useState(false);
  const marqueeRef = React.createRef();
  const marqueeToggle = () => {
    setPaused(!paused);
    marqueeRef.current[marqueeStatus]();
    setMarqueeStatus(marqueeStatus === "stop" ? "start" : "stop");
  };
  const moduleTitle = moduleName && moduleName.split(" ");

  return (
    <div className="row row_1">
      <div className="cricview-talnet-page">
        <section>
          <header>
            <div className="container-talnet">
              <div className="header-top">
                <div className="row-talnet">
                  <div className="col-md-6">
                    <div className="full-talnet">
                      <div className="logo">
                        <a href="index.html">
                          <img src={logoSrc} alt="#"></img>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <br />
                    <div className="right_top_section" style={{ marginTop: "-10px" }}>
                      <ul className="social-icons pull-left">
                        <li>
                          <a className="facebook" href="https://www.facebook.com/cricviewFB/">
                            <img className="icon" src={facebookSrc} alt="" />
                          </a>
                        </li>
                        <li>
                          <a className="instagram" href="https://www.instagram.com/cricview.in/">
                            <img className="icon" src={instaSrc} alt="" />
                          </a>
                        </li>
                        <li>
                          <a className="youtube" href="https://www.youtube.com/cricviewmedia">
                            {/* <i className="fa fa-youtube-play"></i> */}
                            <img className="icon" src={youtubeSrc} alt="" />
                          </a>
                        </li>
                      </ul>

                      <ul className="login">
                        <li className="login-modal">
                          {!registrationClosed && <Link to="/regmethods" className="login-reg">Register Now
                          {/* <button style={{ border: "none" }} className="login-reg" onClick={handlePopUp}>Register Now</button> */}
                          Register Now
                          </Link>}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <div className="full-slider">
            <div id="carousel-example-generic" className="carousel slide" data-ride="carousel">
              <div className="carousel-inner" role="listbox">
                <Carousel showDots={true} loop dotColorActive="#ffffff" dotColorInactive="#ff3333" dot={MyDot} arrowLeft={ArrowLeft} arrowRight={ArrowRight}>
                  {talenthuntData.map(item => {
                    const { title, description, starDec, url } = item || {};
                    return (
                      <Carousel.Item style={{ margin: "0px" }}>
                        <div className="item deepskyblue">
                          <img className="item-image" src={url}></img>
                          <div className="carousel-caption">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"></div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                              <div className="slider-contant" data-animation="animated fadeInRight">
                                <h3>
                                  <span className="color-yellow">{title}</span>
                                </h3>
                                <p>
                                  {/* If you have the will, then let us show you the way. */}
                                  <br />
                                  {/* <b style={{ color: "#ffcb05" }}>Cricview Talent Hunt</b> */}
                                  {description}
                                </p>

                                {!registrationClosed && <Link to={"/regmethods"} className="btn-talent">
                                  {/* <button style={{ border: "none" }} className="btn-talent" onClick={handlePopUp}>Register Now</button> */}
                                Register Now
                                </Link>}
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <h4 className="pull-left color-yellow">{starDec}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </div>
          <div id="topsearch" className="topsearches-marquee">
            <div className="marquee-title-container">
              {moduleTitle && (
                <div className="title-text">
                  {moduleTitle[0]} <span> {moduleTitle.splice(1, moduleTitle.length).join(" ")}</span>
                </div>
              )}
              <div className="title-image" onClick={marqueeToggle}>
                {paused ? <Play fill={"#fff"} /> : <Pause fill={"#fff"} />}
              </div>
            </div>
            <marquee ref={marqueeRef} className="marquee">
              {contentData &&
                contentData.map(item => {
                  const { url = null, urlToImage, publishedAt, title } = item || {};
                  return url ? (
                    <span className="margin-span">
                      <span style={{ backgroundColor: "white", color: "white", borderRadius: "50%", fontSize: "8px", verticalAlign: "middle" }}>◯</span>{" "}
                      &nbsp;&nbsp;
                      <a href="#!" onClick={_ => (window.location.href = navigateTo({ url, urlToImage, publishedAt, title, module }))}>
                        {title}
                      </a>
                    </span>
                  ) : (
                      <Fragment />
                    );
                })}
            </marquee>
          </div>
          <br />
          <div className="container">
            <div className="feature-post small-blog">
              <div className="col-md-5">
                <div className="feature-img">
                  <img src={require("../../Assets/Cricketalenthunt-images/venueimage.jpg")} className="img-responsive" alt="#"></img>
                </div>
              </div>
              <div className="col-md-7">
                <div className="feature-cont">
                  <div className="post-heading">
                    <h2>2nd Cricview Talent Hunt Trials Process : </h2>
                    <h3><strong>Round 1:</strong> Video Based Trials on Cricview Website.</h3>
                    <h3><strong>Round 2:</strong> Those who Clear First Round will be called at Ground for Field Trials.</h3>
                    <p class="comingSoon">Dates will be Announced Soon...</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
const Temp = withLayout(TalentHunt);
export default connect(state => ({ content: state.content }))(Temp);
