import React, { Fragment, Component } from "react";

import "./style.scss";
import { Link } from "react-router-dom";    
const youtubeSrc=require("../../Assets/you@3x.png");
const instaSrc=require("../../Assets/insta@3x.png");
const facebookSrc=require("../../Assets/fb@3x.png");
const logoSrc=require("../../Assets/cricketalenthunt-logo.png");
const aboutSrc=require("../../Assets/Cricketalenthunt-images/aboutbg.png");
const TalentHuntHeader = () => {
    return (
    <div className="cricview-talnet-header">
        <section>
        <header>
          <div className="container-talnet">
            <div className="header-top">
              <div className="row-talnet">
                <div className="col-md-6">
                  <div className="full-talnet">
                    <div className="logo">
                      <Link to="/talenthunt">
                        <img src={logoSrc} alt="#"></img>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <br />
                  <div className="right_top_section" style={{ marginTop: "-10px" }}>
                    <ul className="social-icons pull-left">
                      <li>
                        <a className="facebook" href="https://www.facebook.com/cricviewFB/">
                          <img className="icon" src={facebookSrc} alt />

                        </a>
                      </li>
                      <li>
                        <a className="instagram" href="https://www.instagram.com/cricview.in/">
                          <img className="icon" src={instaSrc} alt />

                        </a>
                      </li>
                      <li>
                        <a className="youtube" href="https://www.youtube.com/cricviewmedia">
                           <img className="icon" src={youtubeSrc} alt />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
         <div className="inner-page-banner" style={{backgroundImage: `url(${aboutSrc})` }}>
            <div className="container-talnet">
            </div>
         </div>
         <div className="inner-information-text">
            <div className="container-talnet">
               <h3 className="heading">Register Now</h3>
               <ul className="breadcrumb">

                  <li><Link to="/talenthunt"><h4>Home &nbsp;/&nbsp;</h4></Link></li>
                  <li className="red"><h4>&nbsp;Register Now</h4></li>
               </ul>
            </div>
         </div>
      </section>
    
    </div>
    );
}
export default TalentHuntHeader;
