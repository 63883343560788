import { combineReducers } from "redux";
import modalReducer from "../reducers/modal";
import contentReducer from "../reducers/content";
import youtubeModalReducer from "../reducers/youtubeModal";
import signInReducer from "../reducers/user";
import editContentReducer from "../reducers/editContent";
export default combineReducers({
  modal: modalReducer,
  content: contentReducer,
  youtubeModal: youtubeModalReducer,
  user: signInReducer,
  editContent: editContentReducer
});
