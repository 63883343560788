import axios from "axios";

const DEFAULT_PART = "snippet,contentDetails";
const CHNANEL_ID = "UCUidaN_HjsKobb8LQ3hxr6w";
const KEYS = [
  "AIzaSyB08_o-5KCcPeF16jEao4GSLW5LTDuHRmY",
  "AIzaSyA3ZXCUNKYCg1ogUSwqaJFrK4CFYmCHol0",
  "AIzaSyAifIaVWyHODQSIW0C7S2TbM5DIebfltvo",
  "AIzaSyDNMAv-kpYA-RNjGJ-ZuIcuGGl6seOYpoU",
  "AIzaSyBDYj4qLi9HsKPqtuzU86uwDFXkwaaIXz4"
];
const CHOOSED_KEY = Math.floor(Math.random() * KEYS.length);
const API_KEY = KEYS[CHOOSED_KEY];
const INTERVIEW_PLAYLIST_ID = {
  kind: "youtube#playlist",
  etag: "RLRhtAqPyQtGFV4bRma0QgayenA",
  id: "PLEHD2FRlZ9VQpmG93K24sQD-SaJowxW1_",
};
const WEBSITE_PLAYLIST_ID = {
  kind: "youtube#playlist",
  etag: "hcbdHHIRTcxyzHqJqfMePfz26Es",
  id: "PLEHD2FRlZ9VQfO6A2ofvD76y6cpWHajrh",
};

export const search = params => {
  return axios.get("https://www.googleapis.com/youtube/v3/search", { params });
};

export const youtubePopularVideos = (order = "viewCount", maxResults = 50) => {
  const params = {
    key: API_KEY,
    channelId: CHNANEL_ID,
    part: "snippet,id",
    maxResults,
    order,
  };
  return axios.get("https://www.googleapis.com/youtube/v3/search", { params });
};

export const fetchYoutubeInterviews = (order = "viewCount", maxResults = 50) => {
  const params = {
    key: API_KEY,
    channelId: CHNANEL_ID,
    playlistId: INTERVIEW_PLAYLIST_ID.id,
    part: "snippet",
    maxResults,
    order,
  };
  return axios.get("https://www.googleapis.com/youtube/v3/playlistItems", { params });
};

export const fetchWebsiteVideos = (order = "date", maxResults = 50) => {
  const params = {
    key: API_KEY,
    channelId: CHNANEL_ID,
    playlistId: WEBSITE_PLAYLIST_ID.id,
    part: "snippet",
    maxResults,
    order,
  };
  return axios.get("https://www.googleapis.com/youtube/v3/playlistItems", { params });
};
