import React, { Fragment } from "react";
import Carousel from "react-grid-carousel";
import { TwitterVideoEmbed } from "react-twitter-embed";
import { ArrowLeft, ArrowRight } from "../customizedArrows";

import "./style.scss";

import data from "./data";
const responsive = [
  {
    breakpoint: 768,
    cols: 2,
    rows: 1,
    gap: 10,
    loop: true,
    autoplay: 5000,
  },
];
export default function ItemCarousel({ contentData, moduleName, contentTitle = "Cricview Twitter Feeds" }) {
  const moduleTitle = contentTitle ? contentTitle.split(" ") : moduleName.split(" ");
  return (
    <Fragment>
      <div className="carousel-container" style={{ width: "100%" }}>
        <div className="carousel-header-title" style={{ margin: "1% 3%" }}>
          {moduleTitle[0] || ""} <span>{moduleTitle.splice(1, moduleTitle.length).join(" ") || " "}</span>
        </div>
        <div className="carousel-section">
          <Carousel
            cols={3}
            rows={1}
            loop={true}
            showDots={true}
            gap={1}
            responsiveLayout={responsive}
            autoplay={5000}
            dot={MyDot}
            arrowRight={ArrowRight}
            arrowLeft={ArrowLeft}
          >
            {contentData.map((item, index) => {
              return (
                <Carousel.Item key={index}>
                  <Fragment>
                    <div className="centerContent">
                      <div className="selfCenter spaceBetween">
                        <TwitterVideoEmbed
                          id={item}
                          placeholder={
                            <div className="placeholder">
                              <img src={require("../../Assets/cricview-logo-redesign.png")} alt="ph" />
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </Fragment>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      </div>
    </Fragment>
  );
}
const MyDot = ({ isActive }) => (
  <span
    style={{
      display: "inline-block",
      height: isActive ? "11px" : "8px",
      width: isActive ? "11px" : "8px",
      background: isActive ? "#232882" : "#ff3333",
      borderRadius: "50%",
    }}
  ></span>
);
