import React, { Fragment } from "react";
import { closeModal, openModal } from "../../redux/helpers/modal";
import "./style.scss";
import { SIGN_IN } from "../../redux/actions/types";

const PopPlay = () => {
    const handleGuest = () => {
        sessionStorage.setItem("guestUser", true)
        closeModal()
    }
    const handleLogin = () => {
        openModal(SIGN_IN)
    }
    return (
        <Fragment>
            <div className="play-box">
                <div className="welcome">
                    <div className="welcomme-inner1"><img src={require("../../Assets/trophy.png")} /></div>
                    <div className="welcomme-inner2">
                        <div className="part1"><h1>WELCOME TO</h1></div>
                        <div className="part1"><img src={require("../../Assets/cricview-logo-redesign.png")} /></div>
                    </div>
                </div>
                <div className="flexx">
                    <div className="Play1-box">
                        <a href="/gamezone" className="btn blue" id="guest" onClick={() => handleGuest()}>Play as a Guest</a>
                    </div>
                    <div className="play-or">
                        <span>OR</span>
                    </div>
                    <div className="play-2">
                        <a className="btn blue image" id="withLogIn" onClick={() => handleLogin()}>Log In</a>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PopPlay;