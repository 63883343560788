import { store } from "../store";
import { openYtModalAction, closeYtModalAction } from "../actions/youtubeModal";

export const openYtModal = async videoId => {
  store.dispatch(openYtModalAction(videoId));
};

export const closeYtModal = async () => {
  store.dispatch(closeYtModalAction());
};
