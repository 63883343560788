import React from "react"
import MaterialTable, { MTableToolbar } from "material-table";
import MedicData from "../CovidData/Groups/MedicData"
import { TablePagination } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';

import "../styles.css"

const useStyles = makeStyles({
  toolbarWrapper: {
    '& .MuiToolbar-regular': {
      paddingLeft: 0,
      paddingBottom: "5px",
      minHeight: '5rem'
    },
  },
});

const CovidHelpLines = () => {

  const classes = useStyles();

    const colGroup = [
      {title: "NAME", field: "name",cellStyle: {
        fontSize: '1.2rem',
        padding: "5px",
        fontWeight: "bold"
      },},
      {title: "VERIFIED AT", field: "verifyDate",cellStyle: {
        fontSize: '1.2rem',
        padding: "5px",
        fontWeight: "bold"
      },},
      {title: "TYPE OF MEDICAL AVAILABILITY", field: "type",cellStyle: {
        fontSize: '1.2rem',
        padding: "5px",
        fontWeight: "bold"
      },},
      {title: "CONTACT NO", field: "phone_Number",cellStyle: {
        fontSize: '1.2rem',
        padding: "5px",
        fontWeight: "bold"
      },},
      {title: "LOCATION", field: "location",cellStyle: {
        fontSize: '1.2rem',
        padding: "5px",
        fontWeight: "bold"
      },},
      {title: "AVAILABILITY", field: "avaibility",cellStyle: {
        fontSize: '1.2rem',
        padding: "5px",
        fontWeight: "bold"
      }},
      {title: "REMARKS", field: "remark",cellStyle: {
        fontSize: '1.2rem',
        padding: "5px",
        fontWeight: "bold"
      },},
      

    ]
    return (<>
    <div className="componentStyling">
    <div className="round">
    <h1>CRICVIEW CARE-COVID LEADS</h1>
    <hr></hr>
    <MaterialTable
    title={""}
    components={{
      Toolbar: props => (
        <div className={classes.toolbarWrapper}><MTableToolbar {...props} /></div>
      ),
      Pagination: props => (
        <TablePagination
          {...props}
          labelRowsPerPage={<div style={{fontSize: 20}}>{props.labelRowsPerPage}</div>}
          labelDisplayedRows={row => <div style={{fontSize: 20}}>{props.labelDisplayedRows(row)}</div>}
          SelectProps={{
            style:{
              fontSize: 14,
            
            }
          }}
        />
      )
    }}
    data={MedicData}
    columns={colGroup}
    options={
        {
            search: true,
            // maxBodyHeight: "500px",
            showTitle: false,
            searchFieldStyle: {
              fontSize: '1.5rem'
            },
            sorting: false,
            headerStyle: {
                backgroundColor: '#c60f5e',
                color: '#FFF',
                fontSize: "1.4rem",
                lineHeight: '1.5rem',
                padding: "5px",
                fontWeight: "bold"
            }
        }
    }
    />
    </div>
    </div>
    </>)
}
export default CovidHelpLines;