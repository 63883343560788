const groupData = [
    {
        name: "RAVI HOSPITAL",  
        verifyDate: "05/06/2021",
        type:  "BEDS (NORMAL/ICU)",
        phone_Number: "9837244600, 991709222",
        location: "AGRA",
        avaibility: "A",
        remark: "BEDS AVAILABLE"
      },
      {
        name: "NIYATI HOSPITAL",  
        verifyDate: "05/06/2021",
        type:  "BEDS (NORMAL/ICU)",
        phone_Number: "9811233140",
        location: "(SIKANDRA) AGRA",
        avaibility: "A",
        remark: "BEDS AVAILABLE"
      },
      {
        name: "VIMS HOSPITAL",  
        verifyDate: "05/06/2021",
        type:  "BEDS (NORMAL/ICU)",
        phone_Number: "9837094694",
        location: "MG ROAD , AGRA",
        avaibility: "A",
        remark: "BEDS AVAILABLE"
      },
      {
        name: "SHRI PARSH HOSPITAL",  
        verifyDate: "05/06/2021",
        type:  "BEDS (NORMAL/ICU)",
        phone_Number: "8171131766",
        location: "BHAGWAAN TALKIES , AGRA",
        avaibility: "A",
        remark: "BEDS AVAILABLE"
      },
      {
        name: "BLOSSOM HOSPITAL",  
        verifyDate: "05/06/2021",
        type:  "BEDS (NORMAL/ICU)",
        phone_Number: "7500977770",
        location: "MASTER PLAN ROAD , AGRA",
        avaibility: "A",
        remark: "BEDS AVAILABLE"
      },
      {
        name: "PALS HOSPITAL",  
        verifyDate: "05/06/2021",
        type:  "BEDS (NORMAL/ICU)",
        phone_Number: "8445282484",
        location: "JAWAHAR NAGAR , AGRA",
        avaibility: "A",
        remark: "BEDS AVAILABLE"
      },
      {
        name: "JD HOSPITAL",  
        verifyDate: "05/06/2021",
        type:  "BEDS (NORMAL/ICU)",
        phone_Number: "6398941887",
        location: "TRANSYAMUNA COLONY PHASE-2 , AGRA",
        avaibility: "A",
        remark: "BEDS AVAILABLE"
      },
      {
        name: "YESHVANT HOSPITAL",  
        verifyDate: "05/06/2021",
        type:  "BEDS (NORMAL/ICU)",
        phone_Number: "9411261011",
        location: "NEW AGRA COLONY",
        avaibility: "A",
        remark: "BEDS AVAILABLE"
      },
      {
        name: "CHAHAR HOSPITAL",  
        verifyDate: "05/06/2021",
        type:  "BEDS (NORMAL/ICU)",
        phone_Number: "7500181343",
        location: "ARJUN NAGAR , AGRA",
        avaibility: "A",
        remark: "BEDS AVAILABLE"
      },
      {
        name: "SR HOSPITAL",  
        verifyDate: "05/06/2021",
        type:  "BEDS (NORMAL/ICU)",
        phone_Number: "8477088884",
        location: "NAMNAR , AGRA",
        avaibility: "A",
        remark: "BEDS AVAILABLE"
      },
      {
        name: "SHRI KRISHN HOSPITAL",  
        verifyDate: "05/06/2021",
        type:  "BEDS (NORMAL/ICU)",
        phone_Number: "9412257292",
        location: "RAMBAGH TVC PHASE-2",
        avaibility: "A",
        remark: "BEDS AVAILABLE"
      },
      {
        name: "RASHMI MEDICARE HOSPITAL",  
        verifyDate: "05/06/2021",
        type:  "BEDS (NORMAL/ICU)",
        phone_Number: "9837006916",
        location: "KAMALANAGAR , AGRA",
        avaibility: "A",
        remark: "BEDS AVAILABLE"
      },
      {
        name: "SINGHAL HOSPITAL",  
        verifyDate: "05/06/2021",
        type:  "BEDS (NORMAL/ICU)",
        phone_Number: "7834979991",
        location: "SADH NAGAR , PALAM DELHI",
        avaibility: "A",
        remark: "BEDS AVAILABLE WITH OXYGEN FACILITY"
      },
      {
        name: "SANT NIRANKARI COVID CENTRE",  
        verifyDate: "05/06/2021",
        type:  "BEDS (NORMAL)",
        phone_Number: "9625565752",
        location: "DELHI",
        avaibility: "A",
        remark: "BEDS AVAILABLE WITH OXYGEN FACILITY"
      },
      {
        name: "BRIG GC GULATI",  
        verifyDate: "05/06/2021",
        type:  "BEDS (NORMAL/ICU)",
        phone_Number: "9519109253",
        location: "ATAL VIHARI VAJPAYE HOSPITAL, LUCKNOW",
        avaibility: "A",
        remark: "AN ADMIT COVID PATIENT IN THIS HOSPITAL BEDS ARE AVAILABLE"
      },
      {
        name: "COL SAMEER MALHOTRA",  
        verifyDate: "05/06/2021",
        type:  "BEDS (NORMAL/ICU)",
        phone_Number: "9519109283",
        location: "ATAL VIHARI VAJPAYE HOSPITAL, LUCKNOW",
        avaibility: "A",
        remark: "AN ADMIT COVID PATIENT IN THIS HOSPITAL BEDS ARE AVAILABLE"
      },
      {
        name: "AMIR",  
        verifyDate: "05/06/2021",
        type:  "BED ICU",
        phone_Number: "9696236565",
        location: "LUCKNOW",
        avaibility: "A",
        remark: "ANY KIND OF MDICAL HELP IN LUCKNOW"
      },
      {
        name: "SRI SAI LIFE HOSPITAL",  
        verifyDate: "05/06/2021",
        type:  "BEDS",
        phone_Number: "9450407843",
        location: "KALLI PASCHIM,LUCKNOW",
        avaibility: "A",
        remark: "BEDS AVAILABLE WITH OXYGEN FACILITY"
      },
      {
        name: "ROCKLAND HOSPITAL",  
        verifyDate: "05/06/2021",
        type:  "BEDS",
        phone_Number: "9235004999",
        location: "THAKURGANJ , LUCKNOW",
        avaibility: "A",
        remark: "BEDS AVAILABLE WITH OXYGEN FACILITY"
      },
      {
        name: "SUN HOSPITAL",  
        verifyDate: "05/06/2021",
        type:  "BEDS (NORMAL/ICU)",
        phone_Number: "8960286288",
        location: "VAIBHAV KHAND, LUCKNOW",
        avaibility: "A",
        remark: "BEDS AVAILABLE WITH OXYGEN , VENTILATOR FACILITY"
      },
      {
        name: "AGVA HEALTH CARE",  
        verifyDate: "05/06/2021",
        type:  "BEDS ICU",
        phone_Number: "9999569841",
        location: "LAKSHMINAGAR , DELHI",
        avaibility: "A",
        remark: "BEDS AVAILABLE WITH OXYGEN, MEDICINE 17000/NIGHT"
      },
      {
        name: "HOLY FAMILY HOSPITAL",  
        verifyDate: "05/06/2021",
        type:  "BEDS ICU",
        phone_Number: "",
        location: "OKHLA , DELHI",
        avaibility: "NA",
        remark: "17 BEDS AVAILABLE WITH OXYGEN"
      }
]
export default groupData;