import React, { Fragment, useState, useEffect } from "react";
import withLayout from "../../layout/default";
import './style.scss';
import Axios from "axios"
import ApiPart from "../../util/apiPart"
const LeaderBoard = () => {
  const [getAllUsers, setAllUsers] = useState({});
  useEffect(() => {
    Axios({
      url: `${ApiPart[0].firstPart}/getAllUsers`,
      method: 'GET',
    }).then((response) => {
      if (response.status === 200) {
        setAllUsers(response.data.data.items);
      }
    })
  }, []);
  return (
    <div className="leaderboard-container">
      <div className="main">

        <div className="center">
          <div className="leaderboard-name"><span>CRICVIEW&nbsp;&nbsp; LEADERBOARD</span></div>
          <div className="top3">
            <div className="two item">
              <div className="pos">2</div>
              <div className="pic" style={{ backgroundImage: `url(${require("../../Assets/cricview_logo-192x192.png")})` }}></div>
              <div className="name">
                {getAllUsers && getAllUsers[1] && getAllUsers[1].name}
              </div>
              <div className="score">
                {getAllUsers && getAllUsers[1] && getAllUsers[1].score}
              </div>
            </div>
            <div className="one item">
              <div className="pos">1</div>
              <div className="pic" style={{ backgroundImage: `url(${require("../../Assets/cricview_logo-192x192.png")})` }}></div>
              <div className="name">
                {getAllUsers && getAllUsers[0] && getAllUsers[0].name}
              </div>
              <div className="score">
                {getAllUsers && getAllUsers[0] && getAllUsers[0].score}
              </div>
            </div>
            <div className="three item">
              <div className="pos">3</div>
              <div className="pic" style={{ backgroundImage: `url(${require("../../Assets/cricview_logo-192x192.png")})` }}></div>
              <div className="name">
                {getAllUsers && getAllUsers[2] && getAllUsers[2].name}
              </div>
              <div className="score">
                {getAllUsers && getAllUsers[2] && getAllUsers[2].score}
              </div>
            </div>
          </div>
          <div className="list">
            {
              getAllUsers.length > 0 && getAllUsers.slice(3).map((item, index) => {
                return (
                  <Fragment key={index}>
                    <div className="item" >
                      <div className="pos">{index + 4}</div>
                      <div className="pic" style={{ backgroundImage: `url(${require("../../Assets/cricview_logo-192x192.png")})` }}></div>
                      <div className="name">
                        {item.name}
                      </div>
                      <div className="score">
                        {item.score}
                      </div>
                    </div>
                  </Fragment>
                )
              })
            }
          </div>
          {/* <div className="pagination">
            <div href="#">❮</div>
            <div href="#">❯</div>
          </div> */}
        </div>

      </div>
    </div>
  );
};

export default withLayout(LeaderBoard);