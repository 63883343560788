import React from "react"
import UploadVideo from "../../Components/uploadVideo"
import UserProfile from "../../Components/UserProfile"
import LatestVideo from "../../Module/LatestVideoModule"
import withLayout from "../../layout/default"
import "./style.scss"

const AllProfile = () => {
    return (
        <div className="profile-container">
            <div className="partOne"><UserProfile /></div>
            <div className="partTwo">
                <div className="uploadV"> <UploadVideo /></div>
                <div className="uploadL">
                    <h2>Total Videos Uploaded</h2>
                    <p>All Video (45)</p>
                    <LatestVideo />
                </div>
            </div>
        </div>
    )
}

export default withLayout(AllProfile)