import React, { Fragment, useEffect, useState } from "react";
import ArticleModule from "../../Module/ArticleModule";
import TopSearch from "../../Module/TopSearchesModule";
import FeaturedNews from "../../Components/FeaturedNews";
import LatestVideo from "../../Module/LatestVideoModule";
import { connect } from "react-redux";
import { addYoutubeVideosToStore } from "../../redux/helpers/content";
import withLayout from "../../layout/default";
import LiveTray from "../../Components/LiveTray";
import LiveScore from "../../Components/LiveScoreCard"
function HomeClone({ content }) {
  const [contentProp, setContentProp] = useState({});
  useEffect(() => {
    if (Object.keys(content).length > 0) {
      setContentProp(content);
    }
  }, [content]);
  useEffect(() => {
    addYoutubeVideosToStore();
    addYoutubeVideosToStore("website");
  }, []);

  const { Articles = {}, Instagram = {}, Twitter = {}, articles = [], youtube = {} } = contentProp || {};
  const { top = [], latest = [] } = youtube;
  const { latestPosts = [], latestVideos = [], topVideos = [] } = Instagram;
  const { cricviewCrawler = [], latestCricview = [], topCricview = [], worldcup = [] } = Articles;
  const cricViewData = [...[...cricviewCrawler].slice(0, 21)];
  cricViewData.length > 0 && cricViewData.splice(3, 0, { type: "ad" });
  cricViewData.length > 0 && cricViewData.splice(11, 0, { type: "ad" });
  cricViewData.length > 0 && cricViewData.splice(19, 0, { type: "ad" });
  return (
    <Fragment>
      <div>
        <div className="row row_1">
          <TopSearch contentData={cricViewData} moduleName={"Top Search"} />
        </div>
        <div className="row row_1" style={{ "marginBottom": "0.5%" }}>
          <LiveTray />
        </div>
        <div className="row row_1" style={{"marginBottom": "0.5%"}}>
          <LiveScore/>
        </div>
        <div className="row row_1">
          <LatestVideo
            contentData={[...worldcup]}
            moduleName={"Cricview World Cup"}
            enableShowMore={true}
            showMoreNavigate="/videos"
            styles={true}
            showPlayIcon={false}
          />
        </div>
        <div className="row row_1">
          <LatestVideo contentData={top && [...top.slice(0, 8)]} showMoreBtnText="View All" enableShowMore={true} showMoreNavigate="/videos" styles={true} />
        </div>
        <div id="featurenews" className="row ads-banner-container">
          <img className="add-banner-1" src={require("../../Assets/banner.png")} alt="" />
        </div>
        <div className="row row_1">
          <FeaturedNews
            contentData={[...latestCricview, ...topCricview]}
            contentTitle={"Latest News"}
            rowCount={[0]}
            contentSubHeading="The sporting activities in the world has come to a standstill."
          />
        </div>
        <div className="row row_1">
          <ArticleModule contentData={[...latestPosts]} contentTitle={"Latest on Instagram"} />
        </div>
        <div className="row row_1">
          <LatestVideo
            contentData={[...topVideos]}
            moduleName={"Most Happening on Talent Hunt"}
            enableShowMore={true}
            showMoreNavigate="/interviews"
            styles={true}
          />
        </div>
      </div>
      ;
    </Fragment>
  );
}

export default connect(state => ({ content: state.content }))(withLayout(HomeClone));
