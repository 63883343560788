import React, { useState, useEffect, Fragment } from "react";
import withLayout from "../../layout/default";
import "./style.scss";
import countryData from "../../util/countryData";
import S3 from 'aws-s3';
import aws from "../../util/keys";
import axios from "axios";
import { useHistory } from "react-router-dom";
//import { contentState , convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import DateTimePicker from 'react-datetime-picker'
import ApiPart from "../../util/apiPart"
import cplTeam from "../../util/cplTeam"

const config = {
  bucketName: 'wearetesting',
  region: aws.region,
  accessKeyId: aws.accessKeyId,
  secretAccessKey: aws.secretAccessKey,
}
const S3Client = new S3(config);

const CreateMatch = () => {
  const history = useHistory();

  const [video1, setVideo1] = useState({
    player1Video: null,
  });
  const [video2, setVideo2] = useState({
    player2Video: null,
  });
  const [getAllCountries, setGetAllCountries] = useState({
    cData: countryData
  });
  const [getCplTeam, setGetCplteam] = useState({
    cplTeam: cplTeam
  });
  // const cplTeams = ["MUMBAI CPL TEAM", "DELHI CPL TEAM", "KOLKATA CPL TEAMS", "KERALA CPL TEAM" , "CHENNAI DHAMMAL KING"];

  const [audioFile, setAudioFile] = useState({
    audioFileData: null
  })

  const [showPopUp, setShowPopUp] = useState(false);
  const [matchId, setMatchId] = useState("");
  const [userrOneName, setUserrOneName] = useState("");
  const [userrOneCountry, setUserrOneCountry] = useState("Afghanistan");

  const [userrOneTeam, setUserrOneTeam] = useState("Delhi Kings");
  const [userCPL, setUserCPL] = useState(false);
  const [isGuestMatch, setIsGuestMatch] = useState(false);
  const [isShowVoters, setIsShowVoters] = useState(false);

  const [userrTwoTeam, setUserrTwoTeam] = useState("Delhi Kings");

  const [userrOneState, setUserrOneState] = useState("");
  const [userrOneSkill, setUserrOneSkill] = useState("");
  const [userrOneVideoUrl, setUserrOneVideoUrl] = useState("");

  const [userrTwoName, setUserrTwoName] = useState("");
  const [userrTwoCountry, setUserrTwoCountry] = useState("Afghanistan");
  const [userrTwoState, setUserrTwoState] = useState("");
  const [userrTwoSkill, setUserrTwoSkill] = useState("");
  const [userrTwoVideoUrl, setUserrTwoVideoUrl] = useState("");
  const [askQuestion, setAskQuestion] = useState("");
  const [message, setMessage] = useState({
    congo: "Congratulations to player1 and hard luck to player2",
    win: "player1 is winner and good luck to player2 for next match"
  });
  const [status, setStatus] = useState('live');

  const [startT, setStartT] = useState();
  const [endT, setEndT] = useState();
  const handleEndTime = (e) => {
    setEndT(e)

  }
  const handleStartTime = (e) => {
    setStartT(e)
  }


  const handleIsCPLOne = async event => {
    setUserCPL(!userCPL);
  };
  const handleisGuestMatch = async event => {
    setIsGuestMatch(!isGuestMatch);
  };
  const handleisShowVoters = async event => {
    setIsShowVoters(!isShowVoters);
  };
  const handleInputUserOneTeam = async event => {
    event.preventDefault();
    const { value, name } = event.target;
    setUserrOneTeam(value);
  };

  // const handleIsCPLTwo = async event => {
  //   event.preventDefault();
  //   const { value, name } = event.target;
  //   setUserTwoCPL(value);
  // };

  const handleInputUserTwoTeam = async event => {
    event.preventDefault();
    const { value, name } = event.target;
    setUserrTwoTeam(value);
  };


  const handleInputUserOneName = async event => {
    event.preventDefault();
    const { value, name } = event.target;
    setUserrOneName(value);
  };

  const handleInputUserOneCountry = async event => {
    event.preventDefault();
    const { value, name } = event.target;
    setUserrOneCountry(value);
  };

  const handleInputUserOneState = async event => {
    event.preventDefault();
    const { value, name } = event.target;
    setUserrOneState(value);
  };

  const handleInputUserOneSkill = async event => {
    event.preventDefault();
    const { value, name } = event.target;
    setUserrOneSkill(value);
  };

  const handleInputUserTwoName = async event => {
    event.preventDefault();
    const { value, name } = event.target;
    setUserrTwoName(value);
  };

  const handleInputUserTwoCountry = async event => {
    event.preventDefault();
    const { value, name } = event.target;
    setUserrTwoCountry(value);
  };

  const handleInputUserTwoState = async event => {
    event.preventDefault();
    const { value, name } = event.target;
    setUserrTwoState(value);
  };

  const handleInputUserTwoSkill = async event => {
    event.preventDefault();
    const { value, name } = event.target;
    setUserrTwoSkill(value);
  };
  const handleInputAskQuestion = async event => {
    event.preventDefault();
    const { value, name } = event.target;
    setAskQuestion(value);
  };
  const handleMessage = async event => {
    event.preventDefault();
    const { value, name } = event.target;
    setMessage({ ...message, [name]: value });
  }
  const [disabledAudio, setDisabledAudio] = useState();
  const [disabledVideo1, setDisabledVideo1] = useState();
  const [disabledVideo2, setDisabledVideo2] = useState();


  const handleAudioUpload = event => {
    event.preventDefault();
    const audio = event.target.files[0];
    const audioName = event.target.files[0].name;

    const uuidd = new Date().getTime();
    const uniqueAudioname = audioName.replace(audioName, "audio") + "-" + uuidd;

    const alterAudioName = audioName.split(".");
    const audioSize = audio.size;
    const id = event.target.id;


    confirmAlert({
      title: `${"Do you want to Upload " + audioName} ?`,
      buttons: [
        {
          label: 'Yes',
          id: 'yes',
          onClick: () => {
            setProcessing(true);
            S3Client
              .uploadFile(audio, uniqueAudioname)
              .then(res => {
                let audioUrl = res.location;

                setAudioFile({
                  audioFileData: audioUrl
                });
                setProcessing(false);
                const truee = document.getElementById(id).disabled = true;
                setDisabledAudio(truee);
              })
              .catch(err => {
                console.error(err)
                setProcessing(false);
                alert('An error occured. Please try again')
              })
          }
        },
        {
          label: 'No',
          id: 'no',
          onClick: () => {
            document.getElementById(id).value = "";
            return false
          }
        }
      ]
    });


  };


  const handleVideo1Upload = event => {
    event.preventDefault();
    const video = event.target.files[0];
    const videoName = event.target.files[0].name;


    const uuidd = new Date().getTime();
    const uniqueVideoname = videoName.replace(videoName, "video") + "-" + uuidd;

    const alterVideoName = videoName.split(".");
    const videoSize = video.size;
    const id = event.target.id;

    if (videoSize <= 95000000) {
      confirmAlert({
        title: `${"Do you want to Upload " + videoName}?`,
        buttons: [
          {
            label: 'Yes',
            id: 'yes',
            onClick: () => {
              setProcessing(true);
              S3Client
                .uploadFile(video, uniqueVideoname)
                .then(res => {
                  let videoUrl1 = res.location;

                  setVideo1({
                    player1Video: videoUrl1
                  });
                  setProcessing(false);
                  const truee = document.getElementById(id).disabled = true;
                  setDisabledVideo1(truee);
                })
                .catch(err => {
                  console.error(err)
                  setProcessing(false);
                  alert('An error occured. Please try again')
                })
            }
          },
          {
            label: 'No',
            id: 'no',
            onClick: () => {
              document.getElementById(id).value = "";
              return false
            }
          }
        ]
      });


    } else {
      alert("Please upload a video less than 95MB");
    }

  };

  const handleVideo2Upload = event => {
    event.preventDefault();

    const video2 = event.target.files[0];
    const id = event.target.id;
    const videoName2 = video2.name;

    const uuidd = new Date().getTime();
    const uniqueVideoname = videoName2.replace(videoName2, "video") + "-" + uuidd;
    const alterVideoName2 = videoName2.split(".");


    const videoSize2 = video2.size;
    if (videoSize2 <= 95000000) {
      confirmAlert({
        title: `${"Do you want to Upload " + videoName2} ?`,
        buttons: [
          {
            label: 'Yes',
            id: 'yes',
            onClick: () => {
              setProcessing(true);
              S3Client
                .uploadFile(video2, uniqueVideoname)
                .then(res => {
                  let videoUrl2 = res.location;

                  setVideo2({
                    player2Video: videoUrl2
                  });

                  setProcessing(false);
                  const truee = document.getElementById(id).disabled = true;
                  setDisabledVideo2(truee);

                })
                .catch(err => {
                  console.error(err)
                  setProcessing(false);
                  alert('An error occured. Please try again')
                })
            }
          },
          {
            label: 'No',
            id: 'no',
            onClick: () => {
              document.getElementById(id).value = "";
              return false
            }
          }
        ]
      });

    } else {
      alert("Please upload a video less than 95MB");
    }
  };
  // for copied popup
  function myFunction() {
    const popup = document.getElementById("myPopup");
    popup.classList.toggle("show");
  }

  //*****************************************editor start**************************************//
  const [editorFile, setEditorFile] = useState({
    editorState: EditorState.createEmpty(),
  })

  const onEditorStateChange = (editorState) => {

    setEditorFile({
      editorState
    });

  };


  const matchT = editorFile && editorFile.editorState && editorFile.editorState.getCurrentContent();
  const textEditor = convertToRaw(matchT)
  const convertedData = draftToHtml(textEditor);


  //***********************************editor end***********************************//

  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState("");

  const alert = msg => {
    setError(msg);
    setTimeout(() => {
      setError(null);
    }, 3000);
  }
  //*****************************submit function start******************************************//

  const submitCreateMatchData = async () => {
    setProcessing(true);
    const audioFast = "https://wearetesting.s3-ap-south-1.amazonaws.com/audio-1600195594641.mpeg"
    const videoUrll1 = video1.player1Video;
    const videoUrll2 = video2.player2Video;
    const audioUrl = audioFile.audioFileData ? audioFile.audioFileData : audioFast;
    const createMatchInfo = {
      "user1": {
        "name": userrOneName,
        "state": userCPL ? userrOneTeam : userrOneState,
        "country": userrOneCountry,
        "skill": userrOneSkill,
        "videoUrl": videoUrll1
      },
      "user2": {
        "name": userrTwoName,
        "state": userCPL ? userrTwoTeam : userrTwoState,
        "country": userrTwoCountry,
        "skill": userrTwoSkill,
        "videoUrl": videoUrll2
      },
      "winMessage": message.win,
      "cpl": userCPL,
      "isGuestMatch": isGuestMatch,
      "isShowVoters": isShowVoters,
      "congoMessage": message.congo,
      "audioUrl": audioUrl,
      "matchName": convertedData,
      "matchStatus": "live",
      "question": askQuestion,
      "startTime": `${startT ? startT.getTime() : ""}`,
      "endTime": `${endT ? endT.getTime() : ""}`
    }
    if (textEditor.blocks[0].text.length === 0) {
      alert('Please fill the Match Title')
      setProcessing(false);
      return
    }
    if (askQuestion.length === 0) {
      alert('Please Ask a Question')
      setProcessing(false);
      return
    }
    if (message.congo.length === 0) {
      alert('Please fill Congratulation Message')
      setProcessing(false);
      return
    }
    var message1 = message.congo
    var player1 = message1.search("player1");
    if (player1 === -1) {
      alert('Please write player1 in Congratulation Message')
      setProcessing(false);
      return
    }
    var message2 = message.congo
    var player2 = message2.search("player2");
    if (player2 === -1) {
      alert('Please write player2 in Congratulation Message')
      setProcessing(false);
      return
    }
    if (message.win.length === 0) {
      alert('Please fill Win message')
      setProcessing(false);
      return
    }
    var message3 = message.win
    var player3 = message3.search("player1");
    if (player3 === -1) {
      alert('Please write player1 in Win Message')
      setProcessing(false);
      return
    }
    var message4 = message.win
    var player4 = message4.search("player2");
    if (player4 === -1) {
      alert('Please write player2 in Win Message')
      setProcessing(false);
      return
    }
    if (userrOneName.length === 0) {
      alert('Please fill the name of Player One')
      setProcessing(false);
      return
    }
    // if (userrOneState.length === 0) {
    //   alert('Please fill the State of Player One')
    //   setProcessing(false);
    //   return
    // }
    if (userrOneSkill.length === 0) {
      alert('Please fill the skills  of Player One')
      setProcessing(false);
      return
    }
    if (!videoUrll1) {
      alert('Please upload a Video of Player One')
      setProcessing(false);
      return
    }
    if (userrTwoName.length === 0) {
      alert('Please fill the name of Player Two')
      setProcessing(false);
      return
    }
    // if (userrTwoState.length === 0) {
    //   alert('Please fill the State of Player Two')
    //   setProcessing(false);
    //   return
    // }
    if (userrTwoSkill.length === 0) {
      alert('Please fill the skills of Player Two')
      setProcessing(false);
      return
    }
    if (!videoUrll2) {
      alert('Please upload a Video of Player Two')
      setProcessing(false);
      return
    }
    if (!startT) {
      alert('Please fill the Start Time')
      setProcessing(false);
      return
    }
    if (!endT) {
      alert('Please fill the End Time')
      setProcessing(false);
      return
    }
    if ((endT && endT.getTime()) < (startT && startT.getTime())) {
      alert('End Time Should be Greater Than Start Time')
      setProcessing(false);
      return
    }
    await axios.post(`${ApiPart[0].firstPart}/createMatch`, createMatchInfo)
      .then(res => {
        if (res.status === 200) {
          const matchId = res.data.data.matchId;
          setMatchId(matchId);
          setShowPopUp(true);

          setProcessing(false);
        } else {
          alert('Something went wrong ');

          setProcessing(false);
        }
      }).catch(err => {
        console.error(err)
        setProcessing(false);
      }
      );
  };

  //*****************************submit function end******************************************//
  const pathOne = window.location.origin + "/match/" + matchId;
  const pathTwo = "/match/" + matchId;
  const handlePush = () => {
    window.location.replace(pathOne)
  }

  const copyElementText = (e) => {
    const id = e.target.id;
    const text = document.getElementById(id).innerText;
    const elem = document.createElement("textarea");
    document.body.appendChild(elem);
    elem.value = text;
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
    myFunction()

  }


  const { editorState } = editorFile;


  return !showPopUp ? (
    <Fragment>

      <div className="createMatch">
        {processing && <div className="loader-container-new">
          <div id="loader-new">
          </div>
        </div>}
        <form  >
          <div className="header_style">
            <label>Match Title</label>
            <Editor
              editorState={editorState} toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName" editorClassName="editorClassName"
              onEditorStateChange={onEditorStateChange} placeholder="Type here..."
              id="editor1"
            />
          </div>
          <div className="audio-upload">
            <div className={!audioFile.audioFileData ? 'hidden' : 'audio-file'}>
              <audio id="audioPlayer" key={audioFile.audioFileData} controls>
                <source src={audioFile.audioFileData} type="audio/mpeg" />
                <source src={audioFile.audioFileData} type="audio/ogg" />
                        Your browser does not support the audio element.
                    </audio>
            </div>
            <div className="uploadAudio">
              <input type="file" id="audiofile" className="disabledd" accept=".mpeg,audio/*" onChange={handleAudioUpload} />
              <label htmlFor="audiofile" className={!disabledAudio ? "btn-1" : "disabledUploadButton"}>Upload a Audio</label>
            </div>
          </div>
          <div className="question_style">
            <label className="labell">Ask a Question</label>
            <input className="inputt" autoComplete="new-password" type="text" name="name" value={askQuestion} onChange={event => handleInputAskQuestion(event)} />
          </div>
          <div className="question_style">
            <label className="labell">Congratulations Message</label>
            <input className="inputt" autoComplete="new-password" type="text" name="congo" value={message.congo} onChange={event => handleMessage(event)} />
          </div>
          <div className="question_style">
            <label className="labell">Win Message</label>
            <input className="inputt" autoComplete="new-password" type="text" name="win" value={message.win} onChange={event => handleMessage(event)} />
          </div>
          <div className="checkboxes">
            <div className="cpl country widthSame">
              <input type="checkbox" id="isCPL" checked={userCPL} onChange={e => handleIsCPLOne(e)} name="scales" ></input>
              <label htmlFor="isCPL">IS CPL MATCH</label>
            </div>
            <div className="cpl country widthSame">
              <input type="checkbox" id="isGuestMatch" checked={isGuestMatch} onChange={e => handleisGuestMatch(e)} name="scales" ></input>
              <label htmlFor="isGuestMatch">IS GUEST MATCH</label>
            </div>
            <div className="cpl country widthSame">
              <input type="checkbox" id="isShowVoters" checked={isShowVoters} onChange={e => handleisShowVoters(e)} name="scales" ></input>
              <label htmlFor="isShowVoters">IS SHOW VOTERS</label>
            </div>
          </div>
          <div className="vs-box col10">
            <div className="vs-left">
              <div className="player1 widthSame">
                <label className="labell">Name of Player One</label>
                <input className="inputt" autoComplete="new-password" type="text" name="name" value={userrOneName} onChange={event => handleInputUserOneName(event)} />
              </div>
              <div className="country widthSame">
                <label htmlFor="countries1">Choose a Country:</label>
                <select name="countries1" id="countries1" name="country" value={userrOneCountry} onChange={e => handleInputUserOneCountry(e)}>
                  {getAllCountries.cData.map((item, index) => <option key={index} value={item.name}>{item.name}</option>)}
                </select>
              </div>

              {/* <div className="country widthSame">
                <input type="checkbox" id="isCPL" value={userrOneCPL} onChange={e => handleIsCPLOne(e)} name="scales" ></input>
                <label htmlFor="isCPL">IS CPL MATCH</label>
              </div> */}

              <div className="country widthSame">
                <label htmlFor="cplteam1">Choose a CPL Team:</label>
                <select name="cplteam" id="cplteam1" value={userrOneTeam} onChange={e => handleInputUserOneTeam(e)}>
                  {getCplTeam.cplTeam.map((item, index) => <option key={index} value={item.name}>{item.name}</option>)}
                </select>
              </div>

              <div className="state2 widthSame">
                <label className="labell">State</label>
                <input className="inputt" type="text" name="state" value={userrOneState} autoComplete="new-password" onChange={event => handleInputUserOneState(event)} />
              </div>
              <div className="box1 widthSame">
                <h1>Description of Player One</h1>
                <textarea name="comment" id="comment1" autoComplete="new-password" placeholder="Type Skills..." name="skill" value={userrOneSkill} onChange={event => handleInputUserOneSkill(event)} ></textarea>
              </div>
              <div className={!video1.player1Video ? 'hidden' : 'preview'}>
                <video key={video1.player1Video} controls>
                  <source src={video1.player1Video} type="video/mp4" />
                  <source src={video1.player1Video} type="video/ogg" />
                                Your browser does not support HTML video.
                        </video>
              </div>
              <div className="upload">
                <input type="file" id="file1" key={video1.player1Video || ''} accept="video/*" onChange={handleVideo1Upload} />
                <label htmlFor="file1" className={!disabledVideo1 ? "btn-1" : "disabledUploadButton"}>Upload a Video</label>
              </div>
            </div>
            <div className="vs-right">
              <div className="player2 widthSame ">
                <label className="labell">Name of Player Two</label>
                <input className="inputt" type="text" autoComplete="new-password" name="name" value={userrTwoName} onChange={event => handleInputUserTwoName(event)} />
              </div>
              <div className="country widthSame">
                <label htmlFor="countries2">Choose a Country:</label>
                <select id="countries2" name="country" value={userrTwoCountry} onChange={event => handleInputUserTwoCountry(event)}>
                  {getAllCountries.cData.map((item, index) => <option key={index} value={item.name}>{item.name}</option>)}
                </select>
              </div>


              {/* <div className="country widthSame">
                <input type="checkbox" id="isCPLTwo" value={userrTwoCPL} onChange={e => handleIsCPLTwo(e)} name="scales" ></input>
                <label htmlFor="isCPLTwo">IS CPL MATCH</label>
              </div> */}

              <div className="country widthSame">
                <label htmlFor="cplteam2">Choose a CPL Team:</label>
                <select name="cplteam" id="cplteam2" value={userrTwoTeam} onChange={e => handleInputUserTwoTeam(e)}>
                  {getCplTeam.cplTeam.map((item, index) => <option key={index} value={item.name}>{item.name}</option>)}
                </select>
              </div>

              <div className="state2 widthSame">
                <label className="labell">State</label>
                <input className="inputt" type="text" name="state" autoComplete="new-password" value={userrTwoState} onChange={event => handleInputUserTwoState(event)} />
              </div>
              <div className="box1 widthSame">
                <h1>Description of Player Two</h1>
                <textarea name="comment" id="comment2" autoComplete="new-password" placeholder="Type Skills..." name="skill" value={userrTwoSkill} onChange={event => handleInputUserTwoSkill(event)}></textarea>
              </div>
              <div className={!video2.player2Video ? 'hidden' : 'preview-right'}>
                <video key={video2.player2Video} controls>
                  <source src={video2.player2Video} type="video/mp4" />
                  <source src={video2.player2Video} type="video/ogg" />
                                Your browser does not support HTML video.
                        </video>
              </div>
              <div className="upload">
                <input type="file" id="file" accept="video/*" onChange={handleVideo2Upload} />
                <label htmlFor="file" className={!disabledVideo2 ? "btn-1" : "disabledUploadButton"}>Upload a Video</label>
              </div>
            </div>
          </div>
          <div className="timePicker">
            <div className="chooseTime"><div>Start Time: </div><DateTimePicker minDate={new Date()} value={startT} onChange={e => handleStartTime(e)} hourPlaceholder="H" minutePlaceholder="mm" yearPlaceholder="YYYY" dayPlaceholder="DD" monthPlaceholder="MM" /> </div>
            <div className="chooseTime"><div>End Time: </div> <DateTimePicker minDate={new Date()} value={endT} onChange={e => handleEndTime(e)} hourPlaceholder="H" minutePlaceholder="mm" dayPlaceholder="DD" yearPlaceholder="YYYY" monthPlaceholder="MM" /></div>
          </div>

          <div className="submit-button col10">
            <input type="button" name="submit" id="dis" value="Approve" onClick={submitCreateMatchData} />
          </div>
          {error && <div className="errorApi"  >
            {error}</div>}


        </form>

      </div>
    </Fragment>
  ) : (
      <>
        <div className="popUpforMatch">
          <div className="urll popup">
            <p className="copyUrl">Click on this Url to copy: </p>
            <p className="pathOne" id="copyPath" onClick={e => copyElementText(e)}>{pathOne}</p>
            <span className="popuptext" id="myPopup"> URL Copied</span>
          </div>
          <input type="button" value="GO TO MATCH" className="matchButton" onClick={handlePush} />

          <div className="sharing-footer">
            <div className="share-prompt">
              <div className="text">SHARE THIS MATCH</div>
              <div className="line site-background-color" />
            </div>
            <div className="share-btns">
              <div className="share-btn-container">
                <a href={` http://twitter.com/share?&url=${pathOne}`} target="_blank" rel="noopener noreferrer">
                  <i className="">
                    <img className="icon" src={require("../../Assets/twitter@3x.png")} alt="" />
                  </i>
                </a>
                <div className="underline site-background-color" />
              </div>
              <div className="share-btn-container">
                <a href={`http://www.facebook.com/sharer.php?u=${pathOne}`} target="_blank" rel="noopener noreferrer">
                  <i className="ion-social-facebook">
                    <img className="icon" src={require("../../Assets/fb@3x.png")} alt="" />
                  </i>
                </a>
                <div className="underline site-background-color" />
              </div>
              <div className="share-btn-container">
                <a href={`whatsapp://send?&text=${pathOne}`} target="_blank" rel="noopener noreferrer">
                  <i className="ion-ios-email">
                    <img style={{ width: "45px" }} className="icon" src={require("../../Assets/WhatsApp.png")} alt="" />
                  </i>
                </a>
                <div className="underline site-background-color" />
              </div>
            </div>
          </div>
        </div>
      </>
    )
}

export default withLayout(CreateMatch);