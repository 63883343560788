import React from "react"
import Header from "../../Module/Header/index"
import './styles.css';
import MaterialTable, { MTableToolbar } from "material-table";
import { TablePagination } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
// import {Switch,Route} from "react-router-dom";
import Footers from "../../Components/Footers/index"

//Header
// import PlacesHeader from "./PlacesHeader"
//Data
import GroupData from "./CovidData/Groups/Group"
// import Routes from "./Routes"
import CovidHelpLines from "./PlacesComponents/CovidHelplines";

const useStyles = makeStyles({
  toolbarWrapper: {
    '& .MuiToolbar-regular': {
      paddingLeft: 0,
      paddingBottom: "5px",
      minHeight: '5rem'
    },
  },
});

const CovidDetails = () => {

  const classes = useStyles();

    const colGroup = [
        {title: "NAME", field: "name",cellStyle: {
            fontSize: '1.2rem',
            padding: "5px",
            fontWeight: "bold"
          },},
          {title: "VERIFIED AT", field: "verifyDate",cellStyle: {
            fontSize: '1.2rem',
            padding: "5px",
            fontWeight: "bold"
          },},
          {title: "TYPE OF MEDICAL AVAILABILITY", field: "type",cellStyle: {
            fontSize: '1.2rem',
            padding: "5px",
            fontWeight: "bold"
          },},
          {title: "CONTACT NO", field: "phone_Number",cellStyle: {
            fontSize: '1.2rem',
            padding: "5px",
            fontWeight: "bold"
          },},
          {title: "LOCATION", field: "location",cellStyle: {
            fontSize: '1.2rem',
            padding: "5px",
            fontWeight: "bold"
          },},
          {title: "AVAILABILITY", field: "avaibility",cellStyle: {
            fontSize: '1.2rem',
            padding: "5px",
            fontWeight: "bold"
          }},
          {title: "REMARKS", field: "remark",cellStyle: {
            fontSize: '1.2rem',
            padding: "5px",
            fontWeight: "bold"
          },},
    ]

    const handleClick = (event, rowData) => {
        // console.log("[fired]::handleSelectionChange", rowData);
        window.location.href = rowData.url
      }

    return (<>
    <Header />
    <div className="Heading">
    <h1>CRICVIEW CARE FOUNDATIONS</h1>
    <hr></hr>
    <h5>COVID HELPLINES</h5>
    </div>
    <CovidHelpLines />
    {/* <PlacesHeader /> */}
    {/* <Switch>
        {Routes.map(({ Component, path}, index) => (
        <Route path={path} key={index} component={Component}></Route>
        ))}
    </Switch> */}
    <div className="TelegramLinks">
        <h1>CV COVID CARES LEAD</h1>
        <hr></hr>
        {/* <h5>Citywise Telegram public group to share,seek and give info on Covid related matters</h5> */}
    <div className="round">
    <MaterialTable 
    title={""}
    components={{
      Toolbar: props => (
        <div className={classes.toolbarWrapper}><MTableToolbar {...props} /></div>
      ),
      Pagination: props => (
        <TablePagination
          {...props}
          labelRowsPerPage={<div style={{fontSize: 20}}>{props.labelRowsPerPage}</div>}
          labelDisplayedRows={row => <div style={{fontSize: 20}}>{props.labelDisplayedRows(row)}</div>}
          SelectProps={{
            style:{
              fontSize: 14,
            
            }
          }}
        />
      )
    }}
    data={GroupData}
    onRowClick={handleClick}
    columns={colGroup}
    options={
        {
            sorting: false,
            maxBodyHeight: "500px",
            showTitle: false,
            searchFieldStyle: {
              fontSize: '1.5rem',
            },
            headerStyle: {
                backgroundColor: '#01579b',
                color: '#FFF',
                fontSize: "1.4rem",
                lineHeight: '1.5rem',
                padding: "5px",
                fontWeight: "bold"
            }
        }
    }
    />
    </div>
    </div>
    <Footers />
     {/* <iframe className="excel" src='https://docs.google.com/spreadsheets/d/1F7ILLuxiPslbFs0WzDPH8p3xjPabm8mZZQQEAMFOmas/edit#gid=470216955'></iframe>
     <div className="Table"></div> */}
    </>)
}

export default CovidDetails;