import React, { Fragment } from "react";
import TalentHuntHeader from "../../Components/Talenthunt-Header";
import { Link } from "react-router-dom";
import withLayout from "../../layout/default";
import "./style.scss";

const mailBSrc = require("../../Assets/mail-black.png");
const phoneBSrc = require("../../Assets/phone-black.png");
const homeSrc = require("../../Assets/home-black.png");

const RegMethods = () => {
  return (
    <div className="row row1">
      <div className="cricview-talnet-reg">
        <TalentHuntHeader />
        <Fragment>
          <section id="contant" className="contant main-heading team">
            <div className="row">
              <div className="container">
                <div className="contact">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="contact-us">
                          <h2>Using Debit Card &amp; Net Banking</h2>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <center>
                            <Link className=" btn-danger btn-lg text-center" to="/talentContact">
                              Register Now
                            </Link>
                          </center>
                          <br />
                          <br />
                          <h4>
                            <b>Fastest way to get entry tickets</b>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12">
                        <div className="contact-us">
                          <h2>Using Paytm, PhonePe &amp; GooglePay</h2>
                          <div className="row">
                            <hr />
                            <div className="col-md-4">
                              <h3>Paytm</h3>
                              <img className="img-responsive" src={require("../../Assets/Cricketalenthunt-images/cvpaytm.jpg")} alt="#" />
                              <hr />
                            </div>
                            <div className="col-md-4">
                              <h3>PhonePe</h3>
                              <img className="img-responsive" src={require("../../Assets/Cricketalenthunt-images/cvphonepe.jpg")} alt="#" />
                              <hr />
                            </div>
                            <div className="col-md-4">
                              <h3>GooglePay</h3>
                              <img className="img-responsive" src={require("../../Assets/Cricketalenthunt-images/cvgooglepay.jpg")} alt="#" />
                              <hr />
                            </div>
                          </div>
                          <h4>Process to do registration for Paytm, PhonePe and GooglePay payments-</h4>
                          <p>
                            1. Scan QR code of any of the above options and pay <b>₹ 2000</b>
                          </p>
                          <br />
                          <br />
                          <br />
                          <p>2. Take screenshot of payment.</p>
                          <br />
                          <br />
                          <p>
                            3. Send Payment Screenshot, Name, Mobile Number, Email, Age, City, Aadhar ID Number, Skills(Bowling, Batting, Wicketkeeper/Batsman,
                            All Rounder), Photo, T-shirt Size on cricviewmedia@gmail.com.
                          </p>
                          <br />
                          <br />
                          <p>4. You will receive the entry ticket on your email after verification.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="contact-info col-md-7">
                    <div className="kode-section-title">
                      <h3>Contact Info</h3>
                    </div>
                    <div className="kode-forminfo">
                      <p>Registrations are now open. Please fill the details.</p>
                      <ul className="kode-form-list">
                        <li>
                          <img src={homeSrc} />
                          <p>
                            <strong>Address:</strong> Wework, 32 Milestone, Gurgaon
                          </p>
                        </li>
                        <li>
                          <img src={phoneBSrc} />
                          <p>
                            <strong>Phone:</strong> +91 82874 44901 and +91 96505 19854
                          </p>
                        </li>
                        <li>
                          <img src={mailBSrc} />
                          <p>
                            <strong>Email:</strong> cricview15@gmail.com
                          </p>
                        </li>

                        <li>
                          <hr />
                          <h3>For FREE Registrations</h3>{" "}
                          <p style={{ color: "red" }}>
                            <b>
                              {" "}
                              please contact us via Instagram: <a href="https://www.instagram.com/talenthuntcricview">@TalentHuntCricview</a>
                            </b>{" "}
                            or email: <a href="mailto:cricview15@gmail.com">cricview15@gmail.com</a>
                          </p>
                          <br />
                          <br />
                        </li>
                        <li>
                          <hr />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Fragment>
      </div>
    </div>
  );
};

export default withLayout(RegMethods);
