import { OPEN_MODAL, CLOSE_MODAL } from "../actions/types";
const initialState = {
  isModalOpen: false,
  type: "",
  payload: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        isModalOpen: true,
        type: action.payload.modalType,
        payload: action.payload.payload,
      };
    case CLOSE_MODAL:
      return initialState;
    default:
      return state;
  }
}
