import React, { useEffect, useState } from "react";
import { parseDate, linkProps } from "../../util";
import { PlayIcon } from "../../Icons/icons";

import "./style.scss";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import axios from "axios"

const LatestVideo = ({ contentData = [], moduleName, showMoreBtnText = "View All", enableShowMore, showMoreNavigate = "/", styles = false, showPlayIcon = true }) => {
  const scrollRef = React.createRef();
  const gridRef = React.createRef();
  const videoItemRef = React.createRef();
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollRight, setScrollRight] = useState(false);

  //
  // const [latestPost, setLatestPost] = useState([])

  useEffect(() => {
    let dividePost = 4;
    if (contentData.length < 4) {
      dividePost = 1;
      setScrollRight(true);
    }
    let variableWidth = ""
    if (contentData.length > 4) {
      variableWidth = window.innerWidth > 786 ? (contentData.length * 100) / dividePost : (window.innerWidth > 425 && window.innerWidth <= 786 ? (contentData.length * 100) / 2 : contentData.length * 100);
    } else {
      variableWidth = window.innerWidth > 786 ? 100 : (window.innerWidth > 425 && window.innerWidth <= 786 ? (contentData.length * 100) / 2 : contentData.length * 100)
    }
    gridRef.current.style.width = variableWidth + "%";
    const element = scrollRef.current;
    element.addEventListener("scroll", event => {
      setScrollLeft(event.target.scrollLeft);
      const totalItem = contentData.length;
      const newScroll = event.target.scrollWidth - event.target.scrollLeft;
      const scrollValue = Math.round((event.target.scrollWidth / totalItem) * (window.innerWidth > 786 ? 4 : window.innerWidth == 768 ? 2 : 1));
      if (scrollValue - newScroll >= -1 && scrollValue - newScroll <= 1) setScrollRight(true);
      else setScrollRight(false);
    });
  }, [gridRef, contentData.length]);

  const handleClick = direction => () => {
    const { offsetLeft, clientWidth } = videoItemRef.current;
    const elementsToScroll = window.innerWidth <= 786 ? 1 : 3;
    if (direction === "left") {
      scrollRef.current.scrollLeft -= (clientWidth + offsetLeft) * elementsToScroll;
    } else if (direction === "right") {
      scrollRef.current.scrollLeft += (clientWidth + offsetLeft) * elementsToScroll;
    }
  };
  const moduleTitle = moduleName && moduleName.split(" ");

console.log('--contentData--', contentData)

  return (
    <div className={moduleTitle ? "cricview-latestvideo-container withHeader" : "cricview-latestvideo-container withoutHeader"} style={{ backgroundColor: styles ? "#0c237b" : "#e9f6ff" }}>
      <div className="cricview-latestvideo-wrapper">
        <header className="latestvideo-header">
          {moduleTitle && (
            <h3 className="latestvideo-header-title">
              {moduleTitle[0]} <span> {moduleTitle.splice(1, moduleTitle.length).join(" ")}</span>
            </h3>
          )}
        </header>

        <div className="arrow-flex">
          <div className="arrow-left" style={{ opacity: scrollLeft === 0 ? "0.5" : "1" }} onClick={handleClick("left")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="#fff"
              style={{ backgroundColor: "#ff3333", borderRadius: "5px" }}
              width="36px"
              height="36px"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
            </svg>
          </div>
          <section className="latestvideos" ref={scrollRef}>
            <div className="latestvideos-grid" ref={gridRef}>
              {contentData.map((item, i) => (
                <div className="latestvideo-item" ref={i === 0 ? videoItemRef : null} key={i}>
                  <Link {...linkProps(item)} to={{ pathname: item.url }} target="_blank">
                    <div className="image-container" style={{ backgroundImage: `url(${item.urlToImage})` }}>
                      <div className="play-button">
                        {showPlayIcon && <PlayIcon className="play-image" />}
                      </div>
                      {/* <img src={item.urlToImage} className="video-image" alt="video-thumbnail" /> */}
                    </div>
                    <div className="time-container">
                      <div className="time" style={{ color: styles ? "white" : "black" }}>{item.length}</div>
                    </div>
                    <div className="title-container">
                      <div className="publishDate" style={{ color: styles ? "white" : "black" }}>{parseDate(item.publishedAt)}</div>
                      <div className="title" style={{ color: styles ? "white" : "black" }}>{item.title}</div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </section>

          <div className="arrow-right" style={{ opacity: scrollRight ? "0.5" : "1" }} onClick={handleClick("right")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="#fff"
              style={{ backgroundColor: "#ff3333", borderRadius: "5px" }}
              width="36px"
              height="36px"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
            </svg>
          </div>
        </div>
        {enableShowMore && (
          <div className="showmore_button_container">
            <div className="showmore_button_wrapper">
              <Link to={showMoreNavigate}>
                <div className="showmore_button">{showMoreBtnText}</div>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(state => ({ content: state.content }))(LatestVideo);
