const categoriesOptions = [
    {
        name:"Select a Category"
    },
    {
        name:"CricView World Cup"
    },
    {
        name:"CricView IPL"
    },
    {
        name:"CricView Article"
    },
    {
        name:"CricView Interview"
    },
    {
        name:"CricView Cricketer"
    },
    {
        name:"Test Cricket"
    },
    {
        name:"ODI cricket"
    },
    {
        name:"T20I cricket"
    },
    {
        name:"IPL"
    },
    {
        name:"T20 Leagues "
    },
    {
        name:"Ashes"
    },
    {
        name:"India v Australia "
    },
    {
        name:"India v Pakistan "
    },
    {
        name:"India v England"
    },
    {
        name:"Women's Cricket"
    },
    {
        name:"Down Memory Lane"
    },
    {
        name:"Cricketer Birthdays"
    },
    {
        name:"ODI World Cup"
    },
    {
        name:"T20 World Cup"
    },
    {
        name:"Cricview Premier League (CPL)"
    },
    {
        name:"Cricket Others"
    }
]

export default categoriesOptions;