import React from "react";

export const MapIcon = ({ className }) => (
  <svg className={className} role="presentation" viewBox="0 0 496 512">
    <path
      fill="currentColor"
      d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z"
    ></path>
  </svg>
);
export const LanguageIcon = ({ className }) => (
  <svg role="presentation" className={className} viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M152.1 236.2c-3.5-12.1-7.8-33.2-7.8-33.2h-.5s-4.3 21.1-7.8 33.2l-11.1 37.5H163zM616 96H336v320h280c13.3 0 24-10.7 24-24V120c0-13.3-10.7-24-24-24zm-24 120c0 6.6-5.4 12-12 12h-11.4c-6.9 23.6-21.7 47.4-42.7 69.9 8.4 6.4 17.1 12.5 26.1 18 5.5 3.4 7.3 10.5 4.1 16.2l-7.9 13.9c-3.4 5.9-10.9 7.8-16.7 4.3-12.6-7.8-24.5-16.1-35.4-24.9-10.9 8.7-22.7 17.1-35.4 24.9-5.8 3.5-13.3 1.6-16.7-4.3l-7.9-13.9c-3.2-5.6-1.4-12.8 4.2-16.2 9.3-5.7 18-11.7 26.1-18-7.9-8.4-14.9-17-21-25.7-4-5.7-2.2-13.6 3.7-17.1l6.5-3.9 7.3-4.3c5.4-3.2 12.4-1.7 16 3.4 5 7 10.8 14 17.4 20.9 13.5-14.2 23.8-28.9 30-43.2H412c-6.6 0-12-5.4-12-12v-16c0-6.6 5.4-12 12-12h64v-16c0-6.6 5.4-12 12-12h16c6.6 0 12 5.4 12 12v16h64c6.6 0 12 5.4 12 12zM0 120v272c0 13.3 10.7 24 24 24h280V96H24c-13.3 0-24 10.7-24 24zm58.9 216.1L116.4 167c1.7-4.9 6.2-8.1 11.4-8.1h32.5c5.1 0 9.7 3.3 11.4 8.1l57.5 169.1c2.6 7.8-3.1 15.9-11.4 15.9h-22.9a12 12 0 0 1-11.5-8.6l-9.4-31.9h-60.2l-9.1 31.8c-1.5 5.1-6.2 8.7-11.5 8.7H70.3c-8.2 0-14-8.1-11.4-15.9z"
    ></path>
  </svg>
);
export const CloseIcon = ({ className }) => (
  <svg role="presentation" className={className} viewBox="0 0 357 357">
    <path d="M357 35.7L321.3 0 178.5 142.8 35.7 0 0 35.7l142.8 142.8L0 321.3 35.7 357l142.8-142.8L321.3 357l35.7-35.7-142.8-142.8" />
  </svg>
);
export const InstagramIcon = ({ className }) => (
  <svg role="presentation" className={className} viewBox="0 0 512 512">
    <path d="M336 96c21.2 0 41.3 8.4 56.5 23.5S416 154.8 416 176v160c0 21.2-8.4 41.3-23.5 56.5S357.2 416 336 416H176c-21.2 0-41.3-8.4-56.5-23.5S96 357.2 96 336V176c0-21.2 8.4-41.3 23.5-56.5S154.8 96 176 96h160m0-32H176c-61.6 0-112 50.4-112 112v160c0 61.6 50.4 112 112 112h160c61.6 0 112-50.4 112-112V176c0-61.6-50.4-112-112-112z" />
    <path d="M360 176c-13.3 0-24-10.7-24-24s10.7-24 24-24c13.2 0 24 10.7 24 24s-10.8 24-24 24zM256 192c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64m0-32c-53 0-96 43-96 96s43 96 96 96 96-43 96-96-43-96-96-96z" />
  </svg>
);
export const FacebookIcon = ({ className }) => (
  <svg role="presentation" className={className} viewBox="0 0 32 32">
    <path d="M19 6h5v-6h-5c-3.86 0-7 3.14-7 7v3h-4v6h4v16h6v-16h5l1-6h-6v-3c0-0.542 0.458-1 1-1z" />
  </svg>
);
export const TwitterIcon = ({ className }) => (
  <svg role="presentation" className={className} viewBox="0 0 32 32">
    <path d="M32 7.075c-1.175 0.525-2.444 0.875-3.769 1.031 1.356-0.813 2.394-2.1 2.887-3.631-1.269 0.75-2.675 1.3-4.169 1.594-1.2-1.275-2.906-2.069-4.794-2.069-3.625 0-6.563 2.938-6.563 6.563 0 0.512 0.056 1.012 0.169 1.494-5.456-0.275-10.294-2.888-13.531-6.862-0.563 0.969-0.887 2.1-0.887 3.3 0 2.275 1.156 4.287 2.919 5.463-1.075-0.031-2.087-0.331-2.975-0.819 0 0.025 0 0.056 0 0.081 0 3.181 2.263 5.838 5.269 6.437-0.55 0.15-1.131 0.231-1.731 0.231-0.425 0-0.831-0.044-1.237-0.119 0.838 2.606 3.263 4.506 6.131 4.563-2.25 1.762-5.075 2.813-8.156 2.813-0.531 0-1.050-0.031-1.569-0.094 2.913 1.869 6.362 2.95 10.069 2.95 12.075 0 18.681-10.006 18.681-18.681 0-0.287-0.006-0.569-0.019-0.85 1.281-0.919 2.394-2.075 3.275-3.394z" />
  </svg>
);
export const PlayIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" className={className}>
    <g fill="none" fillRule="evenodd">
      <circle cx="27.5" cy="27.5" r="27.5" fill="#FF333D" fillOpacity=".4" />
      <g transform="translate(11 11)">
        <circle cx="17" cy="17" r="17" fill="#FF333D" />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M11.724 12.256v8.248c0 1.295 1.05 2.345 2.345 2.345.42 0 .834-.113 1.196-.328l7.07-4.192c1.114-.66 1.482-2.098.821-3.212-.206-.348-.499-.637-.85-.838l-7.07-4.057c-1.123-.645-2.556-.257-3.2.867-.205.355-.312.757-.312 1.167zm3.04.458l5.577 3.2c.225.129.302.415.174.64-.04.07-.098.129-.168.17L14.77 20.03c-.223.132-.51.059-.643-.164-.043-.073-.066-.155-.066-.24v-6.505c0-.26.21-.47.47-.47.081 0 .162.022.233.063z"
        />
      </g>
    </g>
  </svg>
);
export const CaretDownIcon = ({ className }) => (
  <svg viewBox="0 0 292.362 292.362" id="icn-play-border" className={className}>
    <path d="M286.935,69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952,0-9.233,1.807-12.85,5.424   C1.807,72.998,0,77.279,0,82.228c0,4.948,1.807,9.229,5.424,12.847l127.907,127.907c3.621,3.617,7.902,5.428,12.85,5.428   s9.233-1.811,12.847-5.428L286.935,95.074c3.613-3.617,5.427-7.898,5.427-12.847C292.362,77.279,290.548,72.998,286.935,69.377z" />
  </svg>
);
export const SearchIcon = ({ className }) => (
  <svg role="presentation" className={className} viewBox="0 0 1024 1024">
    <path d="M995.2 902.4l-214.4-214.4c51.2-70.4 76.8-150.4 76.8-243.2 0-230.4-188.8-419.2-419.2-419.2s-422.4 185.6-422.4 416c0 233.6 188.8 422.4 419.2 422.4 96 0 185.6-35.2 259.2-89.6l163.2 163.2c3.2 3.2 3.2 3.2 6.4 3.2l41.6 41.6 89.6-80zM435.2 768c-176 0-320-144-320-320s144-320 320-320c176 0 320 144 320 320 0 73.6-25.6 144-64 195.2-16 19.2-32 35.2-44.8 51.2-57.6 44.8-131.2 73.6-211.2 73.6v0z" />
  </svg>
);
export const LeftArrow = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width="16" height="25" viewBox="0 0 16 25">
      <path
        fill="#000"
        fillOpacity=".729"
        fillRule="nonzero"
        d="M13.723 25a2.4 2.4 0 0 1-1.624-.607L.665 13.924c-.887-.816-.887-2.135 0-2.952L12.099.503c.907-.71 2.26-.663 3.105.11.844.774.896 2.012.12 2.843l-9.811 8.982 9.81 8.982c.657.596.857 1.496.506 2.28-.35.783-1.181 1.296-2.106 1.3z"
      />
    </svg>
  );
};

export const RightArrow = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width="16" height="25" viewBox="0 0 16 25">
      <path
        fill="#000"
        fillOpacity=".729"
        fillRule="nonzero"
        d="M2.277 25a2.4 2.4 0 0 0 1.624-.607l11.434-10.469c.887-.816.887-2.135 0-2.952L3.901.503C2.994-.207 1.64-.16.796.613c-.844.774-.896 2.012-.12 2.843l9.811 8.982-9.81 8.982C.02 22.016-.18 22.916.17 23.7c.35.783 1.181 1.296 2.106 1.3z"
      />
    </svg>
  );
};

export const YouTubeIcon = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 512 512" width="128px" height="128px">
      <path fill="#E04F5F" d="M504.1,256C504.1,119,393,7.9,256,7.9C119,7.9,7.9,119,7.9,256C7.9,393,119,504.1,256,504.1C393,504.1,504.1,393,504.1,256z" />
      <path
        fill="#FFF"
        d="M412.1,345.9c0,7.5-6.1,13.6-13.6,13.6H113.5c-7.5,0-13.6-6.1-13.6-13.6V166.1c0-7.5,6.1-13.6,13.6-13.6h285.1c7.5,0,13.6,6.1,13.6,13.6V345.9z"
      />
      <path
        fill="#E04F5F"
        d="M343.4,247.1l-123.1-75.6c-3.4-2.1-7.8-2.2-11.2-0.2c-3.5,2-5.7,5.7-5.7,9.7v150.6c0,4,2.2,7.7,5.7,9.7c1.7,0.9,3.5,1.4,5.4,1.4c2,0,4-0.6,5.8-1.6l123.1-75c3.3-2,5.3-5.6,5.3-9.5C348.7,252.7,346.7,249.1,343.4,247.1z"
      />
    </svg>
  );
};
