import { store } from "../store";
import { openModalAction, closeModalAction } from "../actions/modal";

export const openModal = async (modalType, payload) => {
  store.dispatch(openModalAction(modalType, payload));
};

export const closeModal = async (modalType = null) => {
  store.dispatch(closeModalAction(modalType));
};
