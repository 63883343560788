import React, { useState, useEffect, Fragment, useRef } from 'react';
import './style.scss';
import withLayout from '../../layout/default';
import { getStoredState } from 'redux-persist';
import Axios from 'axios';
import { connect } from "react-redux";
import { openModal } from '../../redux/helpers/modal';
import { SIGN_IN, UPDATE_NAME } from '../../redux/actions/types';
import { Link } from "react-router-dom";
import countryData from "../../util/countryData"
import cplData from "../../util/cplTeam"
import Countdown from 'react-countdown';
import logo from "./logo"
import saying from "./sayings"
import ApiPart from "../../util/apiPart"
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Mute, { MuteIos } from "../../Components/Mute"
import Cookies from "js-cookie";


const Match = (props) => {

  // const { userId = null } = props.user
  let userId = localStorage.getItem("userId");
  if(!userId){
    userId=Cookies.get("browserId")
  }

  const [btn, setBtn] = useState({
    initialValue1: "VOTE",
    initialValue2: "VOTE"
  });

  const [matchInfo, setMatchInfo] = useState({})
  const [handleError, sethandleError] = useState();
  const matchId = props && props.match && props.match.params && props.match.params.id;
  const [processing, setProcessing] = useState(false);

  const [popUpData, setPopUpData] = useState({});
  const [votesForPlayerOne, setVotesForPlayerOne] = useState([])
  const [votesForPlayerTwo, setVotesForPlayerTwo] = useState([])
  const [showPop, setShowPop] = useState(false);



  useEffect(() => {
    getMatchDetails()
  }, [])
  const getMatchDetails = () => {
    Axios({
      url: `${ApiPart[0].firstPart}/getMatchDetails?matchId=${matchId}&isFromMatchPage=true`,
      method: "GET",
    }).then((response) => {
      setMatchInfo(response.data.data)
    }).catch((error) => {
      console.log(error);
      const erData = error && error.response && error.response.data.data.message;
      sethandleError(erData);
    })
  }

  const showVotersList = () => {
    setProcessing(true);
    Axios({
      url: `${ApiPart[0].firstPart}/getMatchDetails?matchId=${matchId}`,
      method: "GET",
    }).then((response) => {
      setPopUpData(response.data.data)
      setVotesForPlayerOne(response.data.data.matchUser1.votedUsers)
      setVotesForPlayerTwo(response.data.data.matchUser2.votedUsers)
      setShowPop(true)
      setProcessing(false);
    }).catch((error) => {
      console.log(error);
      const erData = error && error.response && error.response.data.data.message;
      setProcessing(false);
    })
  }

  const closeVote = e => {
    setShowPop(false)
  }

  const sendUserVote = (userToVote, initialValue1, initialValue2, loadingData1, loadingData2) => {
   
    setBtn(
      {
        initialValue1: loadingData1,
        initialValue2: loadingData2
      });
    document.getElementById("voteBtn1").disabled = true;
    document.getElementById("voteBtn2").disabled = true;

    let nameToSend = localStorage.getItem("name");
    if(!nameToSend){
      nameToSend = navigator.appVersion;
    }

    Axios({
      url: `${ApiPart[0].firstPart}/makeVote`,
      method: "POST",
      data: {
        "userId": userId,
        "matchId": matchId,
        "userToVote": userToVote,
        "name": nameToSend
      }
    }).then((response) => {
      setBtn(
        {
          initialValue1: initialValue1,
          initialValue2: initialValue2
        });
      getMatchDetails()

      setTimeout(()=>{
        if(!localStorage.getItem("name")){
          openModal(UPDATE_NAME);
        }
      }, 1000)
     
    }).catch((error) => {
      if (error && error.response && error.response.status === 400) {
        document.getElementsByClassName("vote")[0].style.fontSize = "15px"
        document.getElementsByClassName("vote")[1].style.fontSize = "15px"
        setBtn(
          {
            initialValue1: "You Have Already Voted!",
            initialValue2: "You Have Already Voted!"
          });
      } else {
        document.getElementById("voteBtn1").disabled = false;
        document.getElementById("voteBtn2").disabled = false;
        setBtn(
          {
            initialValue1: "Vote",
            initialValue2: "Vote"
          });
      }
    })
  }
  const deleteMatch = e => {
    confirmAlert({
      title: `${"Do you want to end this Match ?"}`,
      buttons: [
        {
          label: 'Yes',
          id: 'yes',
          onClick: () => {
            setProcessing(true);
            Axios({
              url: `${ApiPart[0].firstPart}/updateMatchStatus`,
              method: "POST",
              data: {
                "matchId": matchId,
                "matchStatus": "ended"
              }

            })
              .then((response) => {
                getMatchDetails();
                setProcessing(false);


              }).catch((error) => {
                console.log(error)
                alert("An Error Occured. Please try again.")
                setProcessing(false);
                return
              })
          }
        },
        {
          label: 'No',
          id: 'no',
          onClick: () => {
            return false
          }
        }
      ]
    });

  }
  const handleClick = (e) => {
    
    if (userId) {
      if (e === "player1") {
        sendUserVote(matchInfo.matchUser1.id, "Voted 😃", "Not Voted 😔", "Voting...", "Not Voted 😔")
      } else {
        sendUserVote(matchInfo.matchUser2.id, "Not Voted 😔", "Voted 😃", "Not Voted 😔", "Voting...")
      }


    }
    else {
      openModal(SIGN_IN)
    }
  };
  let congo = Object.entries(matchInfo).length > 0 ? matchInfo.congoMessage : "Congrats player1. Hard luck player2"
  let win = Object.entries(matchInfo).length > 0 ? matchInfo.winMessage : "player1 is winner and good luck to player2 for next match"

  const getEndedMatchStatus = () => {
    if (Object.entries(matchInfo).length > 0) {
      const WinnerVote = matchInfo.matchUser1.vote - matchInfo.matchUser2.vote
      if ((WinnerVote) > 0) {
        let congoNew = congo.replace(/player1/g, `${matchInfo.matchUser1.name}`).replace(/player2/g, `${matchInfo.matchUser2.name}`);
        let winNew = win.replace("player1", `${matchInfo.matchUser1.name}`).replace("player2", `${matchInfo.matchUser2.name}`);
        return (
          <div className="winner">
            <div>{matchInfo.matchUser1.name} won the Match</div>
            {/* <div className="winnerName"><span className="congo">CONGRATS</span><span className="winName">{matchInfo.matchUser1.name}</span></div> */}
            <div className="winnerName">   <div className="fireworks" style={{ left: "-5%" }}></div>{congoNew}   <div className="fireworks" style={{ right: "-5%" }}></div></div>
          </div>
        )
      } else if ((WinnerVote) < 0) {
        let congoNew = congo.replace("player1", `${matchInfo.matchUser2.name}`).replace("player2", `${matchInfo.matchUser1.name}`);
        let winNew = win.replace("player1", `${matchInfo.matchUser2.name}`).replace("player2", `${matchInfo.matchUser1.name}`);
        return (
          <div className="winner">
            <div>{matchInfo.matchUser2.name} won the Match</div>
            {/* <div className="winnerName"><span className="congo">CONGRATULATIONS </span><span className="winName">{matchInfo.matchUser2.name}</span></div> */}
            <div className="winnerName">   <div className="fireworks" style={{ left: "-5%" }}></div>{congoNew}   <div className="fireworks" style={{ right: "-5%" }}></div></div>
          </div>
        )
      } else if ((WinnerVote) === 0) {
        return <div>Match <span className="congo">DRAWS</span> between {matchInfo.matchUser1.name} and {matchInfo.matchUser2.name}</div>
      }
    }
  }
  const videoUrl1 = Object.entries(matchInfo).length > 0 ? matchInfo.matchUser1.videoUrl : ""
  const videoUrl2 = Object.entries(matchInfo).length > 0 ? matchInfo.matchUser2.videoUrl : ""
  const audioUrl = Object.entries(matchInfo).length > 0 ? matchInfo.audioUrl : ""

  let secs = matchInfo.timeGap
  const player1namee = Object.entries(matchInfo).length > 0 && (matchInfo.matchUser1.name.includes(" ") ? matchInfo.matchUser1.name.split(" ")[0] : matchInfo.matchUser1.name);
  const player2namee = Object.entries(matchInfo).length > 0 && (matchInfo.matchUser2.name.includes(" ") ? matchInfo.matchUser2.name.split(" ")[0] : matchInfo.matchUser2.name);

  const Completionist = () => <span>Timer Ends</span>;

  const Ended = () => {
    return (
      <Fragment>
        <div className="blinkText">** MATCH OVER **</div>
        <div className="scoreDetails">
          <div>
            {Object.entries(matchInfo).length > 0 ? matchInfo.matchUser1.name : "Player 1"} gets {Object.entries(matchInfo).length > 0 ? parseFloat((matchInfo.matchUser1.perct ? matchInfo.matchUser1.perct : 0).toFixed(1)) : 0} % votes and {Object.entries(matchInfo).length > 0 ?
              matchInfo.matchUser2.name : "Player 2"} gets {Object.entries(matchInfo).length > 0 ? parseFloat((matchInfo.matchUser2.perct ? matchInfo.matchUser2.perct : 0).toFixed(1)) : 0} % votes
        </div>
          <div className="cracker">{getEndedMatchStatus()}</div>
        </div>
      </Fragment>
    )
  }

  const NotStarted = () => {
    return (
      <Fragment>
        <div className="nostart">Match has not started yet </div>
        <div>
          <marquee className="marq">
            {saying.map((item, index) => {
              return (
                <span key={index}><span style={{ backgroundColor: "white", color: "white", borderRadius: "50%", fontSize: "8px", verticalAlign: "middle" }}>◯</span>&nbsp;&nbsp;<span style={{ fontSize: "15px" }}>{item.line}---{item.author}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              )
            })}
          </marquee>
        </div>
      </Fragment>
    )
  }

  const CountTime = () => {
    return (
      <Fragment>
        <Countdown date={Date.now() + secs * 1000} renderer={
          ({ hours, minutes, seconds, completed }) => {
            if (completed) {
              // Render a completed state
              getMatchDetails();
              return <Completionist />;
            } else {
              // Render a countdown
              return (
                <div className="uiCount">
                  <div className="countdown__section">
                    <div id="hours" className="value">{hours} </div>
                    <div className="countdown__label">HOURS</div>
                  </div>
                  <div className="countdown__section">
                    <div id="minutes" className="value">{minutes}</div>
                    <div className="countdown__label">MINUTES</div>
                  </div>
                  <div className="countdown__section">
                    <div id="seconds" className="value">{seconds}</div>
                    <div className="countdown__label">SECONDS</div>
                  </div>
                </div>
              )
            }
          }
        } />
      </Fragment>
    )
  }
  const Livee = () => {
    return (
      <Fragment>
        <div className="matchLive liveContain">
          {/* <div>This Match is </div> */}
          <div className="liveGif"><img src={require("../../Assets/live3.gif")} /></div>
          <div className="voteText">
            <div className="voteNow"><img src={require("../../Assets/voteNow.gif")} /></div>
            <div className="now">NOW</div><div className="ex">!</div>
          </div>
          {/* <div>. Please Vote.</div> */}
        </div>
      </Fragment>
    )
  }
  const ios = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    return isIOS
  }
  const muteAud = useRef();

  const muteAudio = () => {

    return muteAud.current.paused ? muteAud.current.play() : muteAud.current.pause();
  }

  var matchName = `<div class="matchNamePopup">${Object.entries(popUpData).length > 0 ? popUpData.matchName : "Match Title"}</div>`

  const skillsIntsa = Object.entries(matchInfo).length > 0 ? matchInfo.matchUser1.skills :"skills"
  const skillsPart =skillsIntsa.substr(0,skillsIntsa.indexOf("@"));
  const instaId = skillsIntsa.substr(skillsIntsa.indexOf("@")+1);

  const skillsIntsaSecond = Object.entries(matchInfo).length > 0 ? matchInfo.matchUser2.skills :"skills"
  const skillsPartSecond =skillsIntsaSecond.substr(0,skillsIntsaSecond.indexOf("@"));
  const instaIdSecond = skillsIntsaSecond.substr(skillsIntsaSecond.indexOf("@")+1);
return (
<Fragment>{
      Object.entries(matchInfo).length > 0
        ?
        <Fragment>

          <div className="row row_1 match_container">
            <div style={{ display: processing ? "block" : "none" }} className="loader-container-new">
              <div id="loader-new">
              </div>
            </div>
            {audioUrl !== "" && (
              (ios() !== true) ?
                <audio ref={muteAud} style={{ display: "none" }} id="defaultAudio" controls loop autoPlay>
                  <source src={audioUrl} type="audio/ogg" />
                  <source src={audioUrl} type="audio/mpeg" />
             Your browser does not support the audio element.
         </audio>
                :
                // < audio autoPlay controls ref = {muteAud} id="audioFile"  loop={true}  src={audioUrl} volume="0.5"></audio>
                <audio ref={muteAud} style={{ display: "none" }} id="defaultAudio" controls
                  loop controlsList="nodownload">
                  <source src={audioUrl} type="audio/ogg" />
                  <source src={audioUrl} type="audio/mpeg" />
              Your browser does not support the audio element.
          </audio>
            )}
            {/* {audioUrl !== "" && <audio ref = {muteAud} style={{display:"none"}} id="audioFile" controls loop>
             <source src={audioUrl} type="audio/ogg" />
             <source src={audioUrl} type="audio/mpeg" />
             Your browser does not support the audio element.
         </audio>} */}

            <div className="endShare">
              <div className="endButton">{((localStorage.getItem("userRole") && localStorage.getItem("userRole").toLowerCase() === "admin") && (matchInfo.matchStatus === "live" || matchInfo.matchStatus === "notstarted"))
                && <button className="end" onClick={e => { deleteMatch(e) }}>End Match</button>}
                {(ios() !== true) ? <Mute muteFunc={muteAudio} /> : <MuteIos muteFunc={muteAudio} />}
              </div>
              <div className="sharing-footer">
                <div className="share-prompt">
                  <div className="text">SHARE THIS MATCH</div>
                  <div className="line site-background-color" />
                </div>
                <div className="share-btns">
                  <div className="share-btn-container">
                    <a href={` http://twitter.com/share?text&url=${window.location.href}`} target="_blank" rel="noopener noreferrer">
                      <i className="">
                        <img className="icon" src={require("../../Assets/twitter@3x.png")} alt="" />
                      </i>
                    </a>
                    <div className="underline site-background-color" />
                  </div>
                  <div className="share-btn-container">
                    <a href={`http://www.facebook.com/sharer.php?u=${window.location.href}`} target="_blank" rel="noopener noreferrer">
                      <i className="ion-social-facebook">
                        <img className="icon" src={require("../../Assets/fb@3x.png")} alt="" />
                      </i>
                    </a>
                    <div className="underline site-background-color" />
                  </div>
                  <div className="share-btn-container">
                    <a id="whatsApforDesktop" href={`https://web.whatsapp.com/send?&text=` + encodeURIComponent(window.location.href)} target="_blank" rel="noopener noreferrer">
                      <i className="ion-ios-email">
                        <img style={{ width: "45px" }} className="icon" src={require("../../Assets/WhatsApp.png")} alt="" />
                      </i>
                    </a>
                    <a id="whatsApforMobile" href={`whatsapp://send?&text=${window.location.href}`} target="_blank" rel="noopener noreferrer">
                      <i className="ion-ios-email">
                        <img style={{ width: "45px" }} className="icon" src={require("../../Assets/WhatsApp.png")} alt="" />
                      </i>
                    </a>
                    <div className="underline site-background-color" />
                  </div>
                </div>
              </div>
            </div>
            <div className="match_background">
              <div className="containerQuestion">
                <div className="mashaal"><img className="mashaal1" src={require("../../Assets/light.gif")} /></div>
                <div className="boxes">
                  <div className="match_header" dangerouslySetInnerHTML={{ __html: `${Object.entries(matchInfo).length > 0 ? matchInfo.matchName : "Match Title"}` }}></div>
                  {
                    Object.entries(matchInfo).length > 0 && matchInfo.matchStatus != "live" ?
                      <div className="matchLive">
                        {matchInfo.matchStatus == "notstarted" ? <NotStarted /> : <Ended />}
                      </div>
                      :
                      <div className="matchLive"><Livee /> </div>

                  }
                  <div className="match_question"> <span>{Object.entries(matchInfo).length > 0 && matchInfo.question ? matchInfo.question : "Who is better according to you ?"}</span></div>
                </div>
                <div className="mashaal"><img className="mashaal2" src={require("../../Assets/light.gif")} /></div>
              </div>
              <div className="scoreCard" style={{ display: (matchInfo.matchUser1.perct !== 0 || matchInfo.matchUser2.perct !== 0) ? "flex" : "none" }}>
                <div style={{ width: (Object.entries(matchInfo).length > 0 ? matchInfo.matchUser1.perct : 50) + '%' }} className="player1Score">
                  <div className="name1">
                    {player1namee}
                  </div>
                  <div className="score1"><span>{Object.entries(matchInfo).length > 0 ? parseFloat((matchInfo.matchUser1.perct ? matchInfo.matchUser1.perct : 0).toFixed(1)) : 0}</span>%</div>
                </div>
                <div style={{ width: (Object.entries(matchInfo).length > 0 ? matchInfo.matchUser2.perct : 50) + '%' }} className="player2Score">
                  <div className="name2">
                    {player2namee}
                  </div>
                  <div className="score2"><span>{Object.entries(matchInfo).length > 0 ? parseFloat((matchInfo.matchUser2.perct ? matchInfo.matchUser2.perct : 0).toFixed(1)) : 0}</span>%</div>
                </div>
              </div>

              <div className="match_versus">
                <div className="fireworks" style={{ left: "-2%" }}></div>
                <div className="flex1">
                  <div className="player_info">
                    <div className="flagName">
                      {matchInfo.cpl === "false" ?
                        <Fragment>
                          {countryData.map((item, index) => {
                            if (item.name === matchInfo.matchUser1.country) {
                              return (
                                <div className="flag-image-country" key={index}>
                                  <img src={item.flag} />
                                </div>
                              )

                            }
                          })}
                        </Fragment>

                        : <Fragment>

                          {cplData.map((item, index) => {
                            if (item.name === matchInfo.matchUser1.state) {
                              return (
                                <div className="flag-image cpl-image" key={index}>
                                  <img className={item.id === "Punjab" ? "punjab" : "cplFlag"} src={item.flag} />
                                </div>
                              )

                            }
                          })}
                        </Fragment>
                      }
                      <div className="nameState">
                        <div className="name">{Object.entries(matchInfo).length > 0 ? matchInfo.matchUser1.name : "Player 1"}</div>
                        <div style={{ display: matchInfo.matchUser1.state === "." && "none" }} className="state">({Object.entries(matchInfo).length > 0 ? matchInfo.matchUser1.state : "State"})</div>
                      </div>
                    </div>
                  </div>
                  <div className="versus-left">
                    <video controls playsInline disablePictureInPicture controlsList="nodownload" width="100%" height="100%" className="design_video1" id="video1" autoPlay loop={true} muted>
                      {videoUrl1 !== "" && <source src={videoUrl1} />}Your browser does not support HTML video.</video>
                  </div>
                  {skillsIntsa.indexOf("@") !== -1 ? 
                  <Fragment>
                  <div className="skills">
                  <div className="text">
                    Skills : 
                    </div>
                    <div className="skillsValue"  style={{textAlign:"center"}}>
                       {skillsPart}
                    </div>
                </div>
                <div className="instaValue"  style={{textAlign:"center"}}>
                      <div>Instagram Id :&nbsp;</div>
                      <div><a href={`https://www.instagram.com/${instaId}`} >{instaId}</a></div>
                </div>
                </Fragment>
                  : 
                  <div className="skills">
                  <div className="text">
                    Skills : 
                    </div>
                    <div className="skillsValue"  style={{textAlign:"center"}}>
                       {skillsIntsa}
                    </div>
                </div>
                  }

                  <div className="vote1"><button className="vote upvote" id="voteBtn1" name="player1" disabled={Object.entries(matchInfo).length > 0 && matchInfo.matchStatus === "live" ? false : true} onClick={(e) => handleClick(e.target.name)}>{btn.initialValue1}</button></div>
                </div>

                <div className="flex2">
                  <div className="player_info">
                    <div className="flagName">
                      {matchInfo.cpl === "false" ?
                        <Fragment>
                          {countryData.map((item, index) => {
                            if (item.name === matchInfo.matchUser2.country) {
                              return (
                                <div className="flag-image-country" key={index}>
                                  <img src={item.flag} />
                                </div>
                              )

                            }
                          })}
                        </Fragment>
                        :
                        <Fragment>
                          {cplData.map((item, index) => {
                            if (item.name === matchInfo.matchUser2.state) {
                              return (
                                <div className="flag-image cpl-image" key={index}>
                                  <img className={item.id === "Punjab" ? "punjab" : "cplFlag"} src={item.flag} />
                                </div>
                              )

                            }
                          })}
                        </Fragment>
                      }
                      <div className="nameState">
                        <div className="name">{Object.entries(matchInfo).length > 0 ? matchInfo.matchUser2.name : "Player 2"}</div>
                        <div style={{ display: matchInfo.matchUser2.state === "." && "none" }} className="state">({Object.entries(matchInfo).length > 0 ? matchInfo.matchUser2.state : "State"})</div>
                      </div>
                    </div>
                  </div>

                  <div className="versus-right">
                    <video controls playsInline disablePictureInPicture controlsList="nodownload" width="100%" height="100%" className="design_video2" autoPlay controls loop={true} muted>
                      {videoUrl2 !== "" && <source src={videoUrl2} />}Your browser does not support HTML video.</video>
                  </div>
                  {skillsIntsaSecond.indexOf("@") !== -1 ? 
                  <Fragment>
                  <div className="skills">
                  <div className="text">
                    Skills : 
                    </div>
                    <div className="skillsValue"  style={{textAlign:"center"}}>
                       {skillsPartSecond}
                    </div>
                </div>
                <div className="instaValue"  style={{textAlign:"center"}}>
                      <div>Instagram Id :&nbsp;</div>
                      <div><a href={`https://www.instagram.com/${instaIdSecond}`} >{instaIdSecond}</a></div>
                </div>
                </Fragment>
                  : 
                  <div className="skills">
                  <div className="text">
                    Skills : 
                    </div>
                    <div className="skillsValue"  style={{textAlign:"center"}}>
                       {skillsIntsaSecond}
                    </div>
                </div>
                  }
                  <div className="vote1"><button className="vote downvote" name="player2" id="voteBtn2" disabled={Object.entries(matchInfo).length > 0 && matchInfo.matchStatus === "live" ? false : true} onClick={(e) => handleClick(e.target.name)}>{btn.initialValue2}</button></div>
                </div>
                <div className="fireworks" style={{ right: "-4%" }}></div>
              </div>
              {Object.entries(matchInfo).length > 0 && matchInfo.matchStatus === "live"
                ? <div className="matchEndTimer">
                  <div>
                    <div className="matchEndText">Match Ends In</div>
                    <div>{!secs ? "" : <CountTime />}</div>
                  </div>
                </div>
                : <Fragment>
                  {Object.entries(matchInfo).length > 0 && matchInfo.matchStatus === "notstarted" ?
                    <div className="matchEndTimer">
                      <div>
                        <div className="matchEndText">Match Starts In :</div>
                        {!secs ? "" : <CountTime />}
                      </div>
                    </div>
                    : null
                  }</Fragment>}
              <div className="i-button"><button>👁️ <span style={{ fontSize: "15px", fontWeight: "700", letterSpacing: "1px" }}>{Object.entries(matchInfo).length > 0 ? (matchInfo.totalCount * 2) : 0}</span></button></div>

              <div onClick={showVotersList} style={{ marginTop: "12px", cursor: "pointer" }} >
                <button className="listButton" style={{ backgroundColor: "#cc0909" }}>
                  List of Voters
                </button>
              </div>

              <div className="sponser">
                <div className="text">Sponsored by : </div>
                <div className="images">
                  <img className="sponsorNew" src={require("../../Assets/spons.jpeg")} />
                </div>
              </div>
            </div>
          </div >
        </Fragment >
        : <div style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", minHeight: "400px", width: "100%" }}>
          {handleError ?
            <Fragment>
              <div style={{ color: "#08255d", fontSize: "25px", padding: "2%" }}>{handleError}</div>
              <div style={{ marginTop: "5%" }}><Link to="/" className="goTo">Go To HOME PAGE</Link></div>
            </Fragment> :
            <div className="loader"></div>
          }
        </div>
    }

      <div style={{ display: showPop ? "block" : "none" }} className="loader-container-new ">

        <div className="popUp-container centerPop">
          <div className="closeVote" onClick={e => closeVote(e)} ><img src={require("../../Assets/closeVote.png")} /></div>
          <div className="tablevote">
            <table className="customer-table" id="liveMatchTable">
              <caption dangerouslySetInnerHTML={{ __html: `${matchName}` }}></caption>
              <thead>
                <tr>
                  <th>Votes of {Object.entries(popUpData).length > 0 ? popUpData.matchUser1.name : "Player 1"} ({Object.entries(popUpData).length > 0 ? popUpData.matchUser1.vote : 0})</th>
                  <th>Votes of {Object.entries(popUpData).length > 0 ? popUpData.matchUser2.name : "Player 2"} ({Object.entries(popUpData).length > 0 ? popUpData.matchUser2.vote : 0})</th>

                </tr>
              </thead>
              <tbody style={{border:"1px solid grey"}}>
                <tr>
                  {/* {Object.entries(popUpData).length > 0 &&
                    <Fragment>
                      <td>
                        {votesForPlayerOne.length > 0 ?
                          votesForPlayerOne.map((item, index) => {
                            return (
                              <div style={{ borderBottom: "1px solid grey" }} key={index}>
                                {item && item.Items[0] && item.Items[0].name}
                              </div>)
                          }) : <div style={{ borderBottom: "1px solid grey", color: "red" }} >no one voted yet</div>}
                      </td>
                      <td>
                        {votesForPlayerTwo.length > 0 ?
                          votesForPlayerTwo.map((item, index) => {
                            return (
                              <div style={{ borderBottom: "1px solid grey" }} key={index}>
                                {item && item.Items[0] && item.Items[0].name}
                              </div>)
                          }) : <div style={{ borderBottom: "1px solid grey", color: "red" }} >no one voted yet</div>}
                      </td>
                    </Fragment>
                  } */}
                  {Object.entries(popUpData).length > 0 &&
                  <td colSpan="2">
                    <div style={{display:"flex",width:"100%"}}>
                      <div style={{width:"50%",borderRight:"1px solid grey"}}>
                        {votesForPlayerOne.length > 0 ?
                          votesForPlayerOne.map((item, index) => {
                            return (
                              <div style={{ borderBottom: "1px solid grey" }} key={index}>
                                {item && item.Items[0] && item.Items[0].name}
                              </div>)
                          }) : <div style={{ borderBottom: "1px solid grey", color: "red" }} >no one voted yet</div>}
                      </div>
                      <div style={{width:"50%"}}>
                        {votesForPlayerTwo.length > 0 ?
                          votesForPlayerTwo.map((item, index) => {
                            return (
                              <div style={{ borderBottom: "1px solid grey" }} key={index}>
                                {item && item.Items[0] && item.Items[0].name}
                              </div>)
                          }) : <div style={{ borderBottom: "1px solid grey", color: "red" }} >no one voted yet</div>}
                      </div>
                    </div>
                    </td>
                  }
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment >
  )
}
export default connect(state => ({ user: state.user }))(withLayout(Match));