import { SIGN_IN } from "../actions/types";
const initialState = {}

export default function (state = initialState, action) {
    switch (action.type) {
        case SIGN_IN:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}
