import Cookies from "js-cookie";
import { store } from "../store";
import { getData } from "../../util/api";
import { youtubePopularVideos, fetchYoutubeInterviews, fetchWebsiteVideos } from "../../util/api/youtube";
import { parseContentData, parseYoutubeResponse, parseDate } from "../../util";
import { fetchContentAction } from "../actions/content";

const parse = content => {
  const data = content && content.data ? content.data.data : {};
  const response = { articles: [] };
  for (let i in data) {
    const value = data[i];
    const { module } = value;
    response[module] = value;
    switch (module) {
      case "Instagram":
        const { latestPosts = [], latestVideos = [], topVideos = [] } = value;
        break;
      case "Articles":
        const { cricviewCrawler = [], latestCricview = [], topCricview = [] } = value;
        response["articles"] = [...cricviewCrawler, ...latestCricview, ...topCricview];
        break;
      default:
        break;
    }
  }
  return response;
};

export const fetchContent = async (transLang = null) => {
  let lang = transLang || "en";
  let content,
    response = {};
  try {
    content = await getData(lang);
    response = parse(content);
  } catch (e) {
    console.log(e);
  } finally {
    let el = document.getElementsByClassName("loader-container");
    if (el && el[0]) el[0].style.display = "none";
  }
  store.dispatch(fetchContentAction({ ...response }));
  return new Promise(
    () => null,
    () => null
  );
};

export const fetchYoutubeContent = async () => {
  const { youtube = {} } = store.getState().content || {};
  let youtubeTop, youtubeLatest, top, latest;
  const sessionFlag = Cookies.get("sessionFlag") || false;
  if (sessionFlag && youtube && youtube.top && youtube.latest && youtube.top.length > 0 && youtube.latest.length > 0) {
    top = youtube && youtube.top ? [...youtube.top] : [];
    latest = youtube && youtube.latest ? [...youtube.latest] : [];
  } else {
    youtubeTop = await youtubePopularVideos();
    youtubeLatest = await youtubePopularVideos("date");
    top = youtubeTop && youtubeTop.data ? parseYoutubeResponse(youtubeTop.data) : [];
    latest = youtubeLatest && youtubeLatest.data ? parseYoutubeResponse(youtubeLatest.data) : [];
    Cookies.set("sessionFlag", true, { expires: 1 });
  }
  store.dispatch(fetchContentAction({ youtube: { top, latest } }));
};

export const addYoutubeVideosToStore = async (key = "interviews", order = "viewCount") => {
  const { interviews = [], website = [] } = store.getState().content || {};
  let content = [],
    callback = null;
  switch (key) {
    case "interviews":
      content = interviews;
      break;
    case "website":
      content = website;
      break;
    default:
      content = [];
      break;
  }
  let parsedResponse = {};
  const sessionFlag = Cookies.get("sessionFlag") || false;
  if (sessionFlag && content && content.length > 0) {
    parsedResponse = content ? [...content] : [];
    Cookies.set("sessionFlag", true, { expires: 1 });
  } else {
    switch (key) {
      case "interviews":
        callback = await fetchYoutubeInterviews(order);
        break;
      case "website":
        callback = await fetchWebsiteVideos("date");
        break;
      default:
        break;
    }
    const { data } = callback;
    parsedResponse = data ? parseYoutubeResponse(data) : [];
  }
  store.dispatch(fetchContentAction({ [key]: parsedResponse }));
};
