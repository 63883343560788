import { EDIT_CONTENT, CLEAR_CONTENT } from "../actions/types";
const initialState = {}

export default function (state = initialState, action) {
    switch (action.type) {
        case EDIT_CONTENT:
            return {
                ...state,
                ...action.payload,
            };
        case CLEAR_CONTENT:
            return initialState
        default:
            return state;
    }
}
